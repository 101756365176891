import client from 'api/client';
import axios from 'axios';

export const fetchTranscript = (url) => axios.get(url);

export const fetchSubtitles = (url) => axios.get(url);

export const offset = ({id, offset, from}) =>
  client().post(`collections/v2/${id}/offset`, {offset, from});

export const updateTranscript = ({collectionId, data, locale, checkout = true}) => client().patch(
  `/collections/v2/${collectionId}/transcript`,
  {...data, locale, checkout}
);
