import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {fbFunc} from 'utils/Firebase';
import {useFirestoreConnect} from 'react-redux-firebase';

export default (props = {}) => {
  const {type = 'transcription', language = '', minutes} = props;
  const [prices, setPrices] = useState({});
  const [pricePerMinCents, setPricePerMinCents] = useState({});
  useFirestoreConnect([{collection: 'Corrector', doc: 'Rates'}]);
  const Rates = useSelector(({firestore: {data}}) => ((data.Corrector || {}).Rates)) || {};

  const shortLanguage = language.split('-')[0];
  let rate = (Rates?.user || {})[shortLanguage];
  // 10% uptick for translation precision
  if (type === 'translation' && rate) rate += (rate * .1);

  const fetchPrices = async (bust) => {
    try {
      const {data} = await fbFunc.httpsCallable('getPrices')(bust);
      console.log({data});
      setPrices(data);
    } catch (error) {
      console.log(error);
    }
  };

  const estimateConversionPrice = (text) => {
    const estToken = text.length / 4;
    return parseInt(Math.max(Math.ceil(estToken * 0.00005 * 100), 1), 10);
  };

  useEffect(() => {
    if (rate) {
      rate = parseInt(rate * 100, 10) / 100;
      setPricePerMinCents(rate);
    }
  }, [rate]);

  const pricePerMin = pricePerMinCents / 100;
  const pricePerMinStr = (pricePerMin || 0).toFixed(2);
  const priceStr = (parseInt(minutes * pricePerMinCents) / 100).toFixed(2);
  return {fetchPrices, estimateConversionPrice, prices, pricePerMin, pricePerMinCents, pricePerMinStr, priceStr};
};
