import { FeatureModal } from 'components/common/FeatureModal/FeatureModal';
import React, { createContext, useState } from 'react';
import { featureDetails as featureDetailsData } from './FeatureDetails';

export const FeatureModalContext = createContext();

export const FeatureModalProvider = ({ children }) => {
  const [modal, setModal] = useState({ isOpen: false, featureDetails: null });

  const showFeatureModal = ({ feature, contentDetails }) => {
    if (feature) {
      const featureDetails = featureDetailsData.find(
        (detail) => detail.key === feature
      );

      setModal({ isOpen: true, featureDetails });
    }

    if (contentDetails) {
      setModal({ isOpen: true, featureDetails: contentDetails });
    }
  };

  const closeFeatureModal = () => {
    setModal({ isOpen: false, featureDetails: null });
  };

  return (
    <FeatureModalContext.Provider
      value={{ showFeatureModal, closeFeatureModal, isOpen: modal.isOpen }}
    >
      {children}
      <FeatureModal
        isOpen={modal.isOpen}
        onClose={closeFeatureModal}
        featureDetails={modal.featureDetails}
      />
    </FeatureModalContext.Provider>
  );
};
