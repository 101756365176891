import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.7)',
    boxShadow: theme.shadows[1],
    fontSize: ({spacious}) => spacious ? 12 : 11,
    lineHeight: ({spacious}) => spacious ? '22px' : undefined,
    padding: ({spacious}) => spacious ? '14px 16px' : undefined,
    display: ({interactive}) => interactive ? 'flex' : undefined,
    justifyContent: ({interactive}) => interactive ? 'space-between' : undefined,
    alignItems: ({interactive}) => interactive ? 'center' : undefined,
    '& svg': {
      fontSize: 'medium',
      marginLeft: 10,
      cursor: 'pointer',
      opacity: .5,
      transition: 'opacity .5s',
      '&:hover': {
        opacity: 1,
        transition: 'opacity .5s'
      }
    }
  }
}))(Tooltip);

export default ({children, title, noWraper, noWrapper, spacious, ...rest}) => {
  if (title){
    return (
      <LightTooltip
        title={title}
        spacious={spacious ? 1 : 0}
        {...rest}
      >
        {
          (noWraper || noWrapper) ? children :
            <div>
              {children}
            </div>

        }
      </LightTooltip>
    );
  } else return children;
};
