import {useDropboxChooser} from 'use-dropbox-chooser';
import {DROPBOX_APP_KEY} from 'utils/env';

const appKey = DROPBOX_APP_KEY; // This is required, Dropbox app key must be provided
const chooserOptions = {linkType: 'direct', extensions: ['video', 'audio']};

const useChooser = () => {
  const {open, isOpen} = useDropboxChooser({
    appKey,
    chooserOptions,
  });

  return {open, isOpen};
};

export default useChooser;
