import React, { useEffect } from 'react';
import styled from 'styled-components';
import Overlay from 'components/common/Overlay';
import FadeIn from 'react-fade-in';
// import isEqual from 'lodash/isEqual'

const Wrapper = styled.span`
  cursor: pointer;
  position: relative;
`;
const Trigger = styled.span`
  cursor: pointer;
  user-select: none;
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    cursor: wait;
  `}
  z-index: 1300;
`;

const ContentWrap = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 2001;
`;

const Content = styled.div`
  cursor: pointer;
  opacity: 1;
  position: absolute;
  margin-top: 8px;
  display: inline-block;
  z-index: 1300;
  background-color: #fff;
  color: #37404a;
  padding: ${(props) => props.padding || '8px'};
  border-radius: 6px;
  border: 1px solid rgba(151, 151, 151, 0.12);
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 0.5px;
  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) =>
    props.message &&
    `
    padding: 20px 20px;
    line-height: 25px;
    white-space: normal;
    width: 350px;
  `}
  transform: translateY(-5px);
  ${(props) =>
    props.contentLeft && 'transform: translateX(-50%) translateY(-5px);'}
  ${(props) =>
    props.contentFarLeft && 'transform: translateX(-103%) translateY(-50px);'}
  ${(props) =>
    props.leftTransform &&
    `transform: translateX(${props.leftTransform}) translateY(${
      props.topTransform || '0px'
    });`}
  ${(props) => props.hide && 'opacity:0;'}
  transition: opacity 0.5s;
  ${(props) =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight};
    overflow-x: scroll;
  `}
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  cursor: pointer;
  border-radius: 3px;
  ${(props) =>
    props.noHover
      ? 'opacity: 0.5;'
      : `
    :hover {
      background: #F4F4F4;
    }
  `}
  overflow: hidden;
  text-overflow: ellipsis;
  & span {
    margin-left: 15px;
  }
  ${(props) =>
    props.disabled &&
    `
      opacity: 0.2;
      cursor: default;
      user-select: none;
  `}
`;

export const MenuIcon = styled.div`
  margin-left: -6px;
  width: 18px;
  text-align: center;
`;

const Popover = (props = {}) => {
  const { trigger, content, message, disabled, closeOnClick, close } = props;

  const [open, setOpen] = React.useState();
  const [hide, setHide] = React.useState();

  const toggleContent = () => {
    if (open) {
      closeContent();
    } else openContent();
  };

  const openContent = () => {
    setOpen(true);
    setHide(false);
  };

  const closeContent = (event) => {
    setOpen(false);
    props.onClose && props.onClose();
  };

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [close]);

  useEffect(() => {
    if (!!props.open || !!message) setOpen(true);
  }, [props.open, message]);

  return (
    <Wrapper>
      <Trigger onClick={() => !disabled && toggleContent()} disabled={disabled}>
        {trigger}
      </Trigger>

      <ContentWrap>
        {open ? (
          <FadeIn>
            {content && (
              <Content
                {...props}
                onClick={(e) => closeOnClick && closeContent(e)}
                hide={hide}
              >
                {content}
              </Content>
            )}
          </FadeIn>
        ) : null}
      </ContentWrap>
      {open && <Overlay dark={message} onClick={closeContent} />}
    </Wrapper>
  );
};

export default Popover;
