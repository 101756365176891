import React from 'react';
import S3 from 'aws-sdk/clients/s3';
import { config } from 'aws-sdk/lib/core';
import * as Sentry from '@sentry/react';
import uuid from 'uuid/v4';

import { trackCustom } from 'utils/Tracker';
import { ASSETS_BUCKET as Bucket } from 'utils/env';
import { toast } from 'components/common/Toaster';

// deobfuscator terms are odd to make it harder to deduce
const daysin = (cal, len) =>
  cal
    .split('')
    .reduce((acc, letter, i) => {
      if (i % 2 === 0) acc.push(letter);
      return acc;
    }, [])
    .slice(0, len)
    .reverse()
    .join('');
const a = 'accessKeyId';
const b = 'secretAccessKey';

export const uploadDebugLogs = ({
  filename,
  body: Body,
  cid,
  locale = 'us'
}) => {
  const Key = filename;
  const region = locale === 'us' ? 'us-east-1' : 'eu-west-1';
  // handle uploader
  return new Promise((resolve, reject) => {
    const params = {
      Key,
      Body,
      Bucket: `debug.${locale === 'us' ? 'us.' : 'eu.'}${Bucket}`
    };
    const final = daysin(
      'T7T3YAF54FDF35DDX4K9RCR2J4L7E8R6A9IIKCAD-R9RCJ2L4E7R8A6I9KIAC',
      20
    );
    const join = daysin(
      'T7Q3jAB55FyFH5uDu4I9uCF214W7g8B6r9OI2CkDYJdRkYOUXIgIMYAYY55Cp8nB04N9Z2ECD4hD4AME-J1rCI234E7V846P9OIBC3DhZLZUZzZXJaRfYAUzIgIsYCYv5MCU8kB/2b9T2',
      40
    );
    config.update({ region, [a]: final, [b]: join });
    // console.log('upload debug logs', {final, join})
    const upload = new S3.ManagedUpload({ params });

    upload.send((err, data) => {
      if (err) {
        Sentry.captureException(err);
        reject(err);
      } else resolve(data);
    });
  });
};

const _upload = ({
  s3key: Key,
  body: Body,
  progress,
  cancelSignal,
  oid,
  uid,
  locale = 'us'
}) => {
  if (cancelSignal && cancelSignal.aborted) {
    trackCustom({
      category: 'User',
      action: 'Canceled Upload',
      data: { uid, oid, locale }
    });
    return Promise.reject(new Error('Upload cancelled'));
  }
  const region = locale === 'us' ? 'us-east-1' : 'eu-west-1';
  // final is access id
  const final = daysin('A7F3XAW5XF6F65ODX4K9RCR2J4L7E8R6A9IIKCAD', 20);
  // join is secretaccesskey
  const join = daysin(
    't7G3DAa5XFhFQ5WDF4596CX2o4l7K8y6F9gI3CmDMJERlYtU2IfITYcY35mCM8ABZ499o2KCK4sDTAmE',
    40
  );
  // handle uploader
  return new Promise((resolve, reject) => {
    const params = {
      Key,
      Body,
      Bucket: `${locale === 'us' ? '' : 'eu.'}${Bucket}`
    };
    console.log('upload file', { final, join });
    config.update({ region, [a]: final, [b]: join });
    let queueSize = localStorage.getItem('async-uploads');
    queueSize = isNaN(queueSize) ? 1 : parseInt(queueSize);
    const passed = {
      // adjust to reduce the time it takes per part. Default 2 mins per part. If to many async parts, it can get clogged.
      queueSize, // AWS default 4
      partSize: 1024 * 1024 * 8, // default 5mb
      params
    };
    console.log('Upload Config', { passed });
    const upload = new S3.ManagedUpload(passed);

    upload.on('httpUploadProgress', progress);
    upload.send((err, data) => {
      if (err) {
        console.log('ERROR WITH UPLOAD', err);
        const title = 'Issue with upload.';
        const body = (
          <>
            This could be due to a network timeout, canceled request, or poor
            bandwidth.{' '}
            <a
              href="https://help.konch.ai/article/43-trouble-shooting"
              target="_blank"
              rel="noreferrer"
            >
              See our troubleshooting guide for more.
            </a>
          </>
        );
        toast.error(title, { body, autoClose: false, toastId: 'upload-error' });
        trackCustom({
          category: 'Upload',
          action: 'Error',
          data: { uid, oid, err, locale }
        });
        Sentry.captureException(err);
        reject(err);
      } else resolve(data);
    });

    cancelSignal &&
      cancelSignal.addEventListener('abort', () => {
        trackCustom({
          category: 'User',
          action: 'Canceled Upload',
          data: { uid, oid, locale }
        });
        upload.abort();
        reject(new Error('Upload cancelled'));
      });
  });
};

export const upload = async ({
  blob,
  uid,
  oid,
  locale = 'us',
  progress,
  cancelSignal
}) => {
  console.log('upload', { blob, locale, progress, cancelSignal });
  if (cancelSignal && cancelSignal.abort) throw new Error('Upload cancelled');
  var fileName = uuid() + '.webm';
  if (blob.name) {
    const ext = blob.name.split('.').pop();
    fileName = uuid() + '.' + ext;
  }
  // console.log({blob}, fileName)
  cancelSignal &&
    cancelSignal.addEventListener('abort', () => new Error('Upload cancelled'));
  // console.log('cancel')
  const response = await _upload({
    s3key: `uploads/${uid}/${fileName}`,
    body: blob,
    progress,
    uid,
    oid,
    cancelSignal,
    locale
  });
  if (response.Location) {
    const tempUrl = response.Location;
    trackCustom({
      category: 'Upload',
      action: 'Completed',
      data: { uid, tempUrl, oid, locale }
    });
    return { tempUrl: tempUrl };
  }
};

/*
// Use this to create new onvfuscated keys from original
original = 'AKIARELJRRKXO66XWXFA' // mTsKKo9ZAMm3cTf2tlEMm3gFyKloX65FWQhXaDGt
obfuscate = (key, scramble) => key.split('').reverse().reduce((acc, letter, i) => {
  acc.push(letter)
  acc.push(scramble.split('')[i])
  return acc
}, []).join('')
a = obfuscate(original, '73A5FF5D49C247869ICDJRYUIIYY5C8B492C4DAE021')

deobfuscate = (key) => key.split('').reduce((acc, letter, i) => {
  if (i%2 === 0) acc.push(letter)
  return acc
}, []).slice(0,20).reverse().join('')
b = deobfuscate(a)
original == b
 */
/*
  this.startProcess(response.Location, webhook, languageCode)
                .then((data) => {
                  resolve({id: data.result, tempUrl: tempUrl})
                })
                .catch(reject)
 */
