import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import useProfile from 'hooks/useProfile';
import useUpload from 'hooks/useUpload';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Spinner from '../Spinner';
import YouTubeIcon from './YouTubeIcon';
import SelectLanguage from '../CreatorDialog/SelectLanguage';
import { FeaturesContext } from 'providers/FeaturesProvider';
import { FeatureModalContext } from 'providers/FeatureModalProvider/FeatureModalProvider';
import { Features } from 'utils/constants';

const Container = styled.div`
  background: #fff;
  width: 400px;
  max-width: 400px;
  height: 500px;
  border-radius: 0.6rem;
  padding: 2rem 3rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Heading = styled.h3`
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  color: var(--colorPrimary);
`;

const Subheading = styled.h6`
  font-size: 0.8rem;
  margin: 0;
  color: var(--colorSecondary);
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const InputErrorMsg = styled.div`
  color: red;
  font-size: 0.7rem;
  margin-top: 0.3rem;
`;

const TextFieldContainer = styled.div`
  display: flex;
  position: relative;
`;

const YouTubeUploader = () => {
  const { currentProject: project, settings } = useProfile();
  const { isFreePlanUser } = useContext(FeaturesContext);
  const { showFeatureModal } = useContext(FeatureModalContext);

  const { enhanced } = settings;

  const {
    checkUrl,
    closeDialog,
    editingUpload,
    transcribe,
    defaultLanguage,
    setDefaultLanguage
  } = useUpload();
  const { id } = editingUpload || {};

  const [youTubeLink, setYouTubeLink] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(undefined);
  const [isCheckingUrl, setIsCheckingUrl] = useState(false);
  const [urlErrorMessage, setUrlErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [languageCode, setLanguage] = useState(defaultLanguage);
  const [dictionary, setDictionary] = useState('');
  const [isValidFileDuration, setIsValidFileDuration] = useState(false);

  const [videoData, setVideoData] = useState({
    title: '',
    description: '',
    thumbnail: ''
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!isValidUrl) return;

    if (!isValidFileDuration) {
      showFeatureModal({
        feature: Features.FileSizeLimit
      });

      return;
    }

    const beta = evt.shiftKey;

    const params = {
      dictionary,
      languageCode,
      description: '',
      url: youTubeLink,
      enhanced,
      name: videoData.title,
      id,
      hints: '',
      isPrecision: false,
      beta,
      isLinkUpload: true,
      ignoreSentance: false,
      project
    };

    setIsProcessing(true);
    transcribe(params);

    // Simulate a 5 second delay in order to show the user that something is actually happening with the Spinner
    setTimeout(() => {
      setIsProcessing(false);
      closeDialog();
    }, 7000);
  };

  const handleLanguage = (lang) => {
    if (languageCode !== lang) {
      setDictionary(undefined);
      setLanguage(lang);
    }
  };

  const validateFileLength = ({ lengthInMinutes }) => {
    const MAX_DURATION = 10; // 10 minutes

    // Free plan users can only transcribe videos that are 10 minutes or less
    if (!isFreePlanUser) {
      return true;
    }

    if (lengthInMinutes > MAX_DURATION) {
      return false;
    }

    return true;
  };
  const handleCheckUrl = async (url) => {
    if (!url) {
      setUrlErrorMessage('');
      setIsValidUrl(undefined);
      setVideoData({});
      return;
    }

    setIsCheckingUrl(true);
    setUrlErrorMessage('');
    setIsValidUrl(undefined);

    const { payload } = await checkUrl({ url });

    const lengthInMinutes = payload.data.lengthInMinutes;

    const isValid = validateFileLength({ lengthInMinutes });

    if (payload.error) {
      setIsValidUrl(false);
      setIsCheckingUrl(false);

      const message =
        typeof payload.error === 'object'
          ? payload.error.result
          : payload.error;

      setUrlErrorMessage(
        'Oops! Something went wrong. Please double check the URL and try again.'
      );

      console.error(message);
    } else {
      setVideoData(payload.data);
      setIsValidUrl(true);
      setIsCheckingUrl(false);
      setUrlErrorMessage('');

      if (!isValid) {
        setIsValidFileDuration(false);
        return;
      }

      setIsValidFileDuration(true);
    }
  };

  return (
    <Container>
      <Content>
        <YouTubeIcon textColor="#000" />
        <Heading>YouTube Link Uploader</Heading>
        <Subheading>Effortlessly Transform Videos into Text</Subheading>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextFieldContainer>
            <TextField
              variant="outlined"
              label="YouTube Link"
              required
              size="small"
              fullWidth
              value={youTubeLink}
              onChange={(evt) => setYouTubeLink(evt.target.value)}
              onBlur={(evt) => {
                handleCheckUrl(evt.target.value);
              }}
              style={{
                marginTop: '20px'
              }}
            />
            {isCheckingUrl && (
              <Spinner
                small
                style={{
                  position: 'absolute',
                  left: '-2rem',
                  top: '2rem'
                }}
              />
            )}
            {isValidUrl && (
              <CheckCircle
                style={{
                  position: 'absolute',
                  left: '-2rem',
                  top: '1.9rem',
                  color: 'green',
                  fontSize: '1.2rem'
                }}
              />
            )}
          </TextFieldContainer>
          {urlErrorMessage && (
            <InputErrorMsg className="error">{urlErrorMessage}</InputErrorMsg>
          )}

          <div
            style={{
              marginTop: '1rem'
            }}
          >
            <SelectLanguage
              isLoaded
              onSelectLanguage={handleLanguage}
              onSetLanguageDefault={setDefaultLanguage}
              defaultLanguage={defaultLanguage}
              required
              removeAutoDetect
            />
          </div>

          {isValidUrl && videoData && (
            <ListItem
              style={{
                margin: 'auto 0',
                borderTop: '0.5px solid #ccc',
                padding: '0.6rem 0',
                borderBottom: '0.5px solid #ccc'
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: '48px'
                }}
              >
                <img
                  src={videoData.thumbnail}
                  alt="thumbnail of the video"
                  width={40}
                  height={40}
                  style={{
                    borderRadius: '0.5rem',
                    backgroundColor: 'black',
                    objectFit: 'contain'
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={videoData.title}
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'var(--colorPrimary)',
                    fontSize: '0.8rem',
                    maxWidth: '226px',
                    fontWeight: 'bold'
                  }
                }}
              />
            </ListItem>
          )}

          <Button
            style={{
              marginTop: 'auto',
              height: 36.5
            }}
            type="submit"
            color="primary"
            variant="contained"
            disabled={!youTubeLink || !isValidUrl || !languageCode}
          >
            {isProcessing ? <Spinner small light /> : 'Start Transcribing'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default YouTubeUploader;
