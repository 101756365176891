import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import TuneIcon from '@material-ui/icons/Tune';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRocket} from '@fortawesome/free-solid-svg-icons';

import HeaderLogo from './Logo';
import Notification from 'components/common/Dashboardv2/Notification';
import Avatar from 'components/common/Avatar';
import useHeader from 'hooks/useHeader';
import useProfile from 'hooks/useProfile';

const OpenIcon = styled(ArrowLeftIcon)`
  margin-left: -7px;
  ${({open}) => open ? `
    transform: rotate(0deg);
    transition: transform .2s linear 0s !important;
  ` : `
    transform: rotate(-90deg);
    transition: transform .2s linear .1s !important;
  `}
`;

const OpenIconCont = styled.div`
  ${({open}) => open ? `
    transform: translate(-14px, 3px);
    transition: transform .2s ease-in-out .15s;
  ` : `
    transform: translate(0px, 3px);
    transition: transform .2s ease-in-out 0s;
  `}
  
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Cont = styled(Flex)`
  justify-content: space-between;
  height: 85px;
  padding: 14px 14px 4px 14px;
  margin: 0 20px;
  @media (max-width: 1382px) {
    padding-right: 40px;
  }
  @media (max-width: 774px) {
    padding: 14px 5px;
  }
  z-index: 9;
`;

const MenuListRoot = styled.div`
  display: flex;
  z-index: 50;
  ${({help}) => help && 'z-index: 99999999;'}
  .MuiList-root, .MuiList-root {
    outline: none;
  }
  outline: none;
  .MuiPaper-root {
    padding: 10px;  
    .MuiListItem-root {
      padding-top: 6px;
      padding-bottom: 6px;
      &.list-header {
        margin-bottom: 12px;
        .MuiListItemIcon-root{
          margin-left: -5px;
          min-width: 45px;
        }
      }
      .MuiListItemIcon-root {
        min-width: 40px;
        .fa-rocket {
          font-size: 14px;
        }
        .MuiSvgIcon-root {
          font-size: 18px;  
          &[class*="OpenIcon"] {
            font-size: 24px;
            margin-left: -4px;
          }
        }
      }
    }
  }
`;

const MenuListComposition = ({history, corrector: correctorView, children}) => {
  const {profile, organization, oid, organizations, switchOrganization, isLoaded, isCorrector, updateSettings} = useProfile();
  const {logout} = useHeader();
  const [open, setOpen] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const anchorRef = useRef(null);
  const subAnchorRef = useRef(null);
  const hasOrgs = organizations.length > 1;
  const help = profile?.settings?.help;
  const showUploadHelper = !!help && help?.upload && !help?.headerMenu;

  const switchAccount = async (oid) => {
    console.log({oid});
    await switchOrganization(oid);
    handleClose();
  };

  let corrector = '';

  if (isCorrector) {
    let url = '/corrector';
    let text = 'Corrector Dashboard';
    let icon = <RateReviewIcon />;
    if (window.location.pathname.includes('/corrector')) {
      icon = <ViewAgendaIcon />;
      url = '/dashboard';
      text = 'User Dashbaord';
    }
    corrector = (
      <Link to={url}>
        <MenuItem>
          <ListItemIcon>{icon}</ListItemIcon>
          <div>{text}</div>
        </MenuItem>
      </Link>
    );
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const subHandleToggle = () => {
    setSubOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (event && anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
    setSubOpen(false);
  };

  const subHandleClose = (event) => {
    if (event && subAnchorRef.current && subAnchorRef.current.contains(event.target)) return;
    setSubOpen(false);
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) anchorRef.current.focus();
    prevOpen.current = open;

    if (open === false && showUploadHelper) updateSettings({'help.headerMenu': new Date().getTime()});
  }, [open]);

  React.useEffect(() => {
    if (isLoaded && showUploadHelper) {
      setTimeout(() => setOpen(true), 3000);
    }
  }, [isLoaded, showUploadHelper]);

  return (
    <MenuListRoot help={showUploadHelper}>
      <nav
        style={{cursor: 'pointer', padding: '0 0 0 10px'}}
        ref={anchorRef}
      >
        <span
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          style={{outline: 'none'}}
          tabIndex={0}
          role="button"
          aria-label='avatar menu popper'
          styling="text"
          onKeyDown={(evt) => evt.keyCode === 13 && handleToggle}
          onClick={handleToggle}
        >
          <Avatar
            style={{cursor: 'pointer', outline: 'none'}}
            profile={correctorView ? profile : organization}
            size={35}
            tipPlacement='left'
            showTip={!open}
            isLoading={!isLoaded} />
        </span>
      </nav>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement='left-start'
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Slide {...TransitionProps} style={{zIndex: 50}}>
              <Paper variant='outlined'>

                <MenuList id='menu-list-grow' onKeyDown={handleListKeyDown}>
                  <MenuItem disabled className='list-header'>
                    <ListItemIcon>
                      <Avatar profile={profile} size={25} />
                    </ListItemIcon>
                    <Typography variant='inherit'>
                      <div>{profile.name || profile.email}</div>
                      {
                        profile.name && profile.email &&
                        <div style={{fontSize: '0.6em'}}>{profile.email}</div>
                      }
                    </Typography>
                  </MenuItem>
                  {children}
                  {corrector}
                  <Link to={'/settings'}>
                    <MenuItem>
                      <ListItemIcon><TuneIcon /></ListItemIcon>
                      <Typography variant='inherit'>Account & Settings</Typography>
                    </MenuItem>
                  </Link>
                  {
                    hasOrgs && !correctorView && (
                      <MenuItem ref={subAnchorRef} aria-controls={subOpen ? 'sub-menu-list-grow' : undefined} aria-haspopup='true' onClick={subHandleToggle} >
                        <ListItemIcon>
                          <OpenIconCont open={subOpen}><OpenIcon open={subOpen} fontSize='large' /></OpenIconCont>
                        </ListItemIcon>
                        <Typography variant='inherit'>Switch Workspace</Typography>
                      </MenuItem>
                    )
                  }
                  {
                    !correctorView && (
                      <Link to={'/plans'}>
                        <MenuItem>
                          <ListItemIcon><FontAwesomeIcon icon={faRocket} /></ListItemIcon>
                          <Typography variant='inherit'>Purchase credits</Typography>
                        </MenuItem>
                      </Link>
                    )
                  }
                  <MenuItem onClick={logout}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <Typography variant='inherit'>Logout</Typography>
                  </MenuItem>
                </MenuList>

              </Paper>
            </Slide>
          </ClickAwayListener>
        )}
      </Popper>
      <Popper
        style={{zIndex: 51}}
        open={subOpen}
        anchorEl={subAnchorRef.current}
        role={undefined}
        placement='left-start'
        transition
      >
        {({TransitionProps, placement}) => (
          <Grow {...TransitionProps} style={{zIndex: 51, transformOrigin: 'right top'}}>
            <Paper style={{maxHeight: '400px', overflow: 'scroll'}} >
              <MenuList autoFocusItem={subOpen} id='sub-menu-list-grow' onKeyDown={handleListKeyDown}>
                {
                  (organizations || [])
                    .filter(org => org.id !== oid)
                    .map(organization => (
                      <MenuItem onClick={async () => {
                        await switchAccount(organization.id);
                        subHandleClose();
                      }} key={organization.id}>
                        <ListItemIcon>
                          <Avatar profile={organization} showTip={false} size={25} />
                        </ListItemIcon>
                        <Typography variant='inherit'>{organization.name}</Typography>
                      </MenuItem>
                    ))
                }
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>

    </MenuListRoot>
  );
};

export default ({history, corrector, children, onClick, hideHeader}) => {

  return (

    <Cont>
      <div style={{width: '100px'}}><HeaderLogo /></div>

      <Flex style={{justifyContent: 'flex-end'}}>
        <Notification history={history} />

        {!hideHeader && <MenuListComposition corrector={corrector} history={history} children={children} />}
      </Flex>
    </Cont>

  );
};
