import React from 'react';
import { SelectLanguage } from '../Popover/CollectionMenu';

const RetryWithLanguage = ({ mp3, onUpdateLanguage }) => {
  console.log('mp3', mp3);
  return (
    <div>
      <SelectLanguage
        mp3={mp3}
        languageChipLabel="Retry with Language"
        updateLanguage={onUpdateLanguage}
      />
    </div>
  );
};

export default RetryWithLanguage;
