import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { isProd } from 'utils/env';
import store from 'store';
import App from 'components/App';
import { fb } from 'utils/Firebase';
import EnvBanner from 'components/common/EnvBanner';
import Toaster from 'components/common/Toaster';
import ErrorBoundary from 'utils/ErrorBoundaryV2';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { RecoilRoot } from 'recoil';
import FeaturesProvider from 'providers/FeaturesProvider';
import { FeatureModalProvider } from 'providers/FeatureModalProvider/FeatureModalProvider';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Open Sans',
      'Helvetica Neue'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#1e90ff'
    },
    shape: {
      borderRadius: 10
    }
  }
});

const rrfConfig = {
  userProfile: 'Users',
  useFirestoreForProfile: true
};

const rrfProps = {
  firebase: fb,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

const Root = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <RecoilRoot>
          <FeaturesProvider>
            <BrowserRouter>
              <FeatureModalProvider>
                {/* App must come first for editro to render */}
                <MuiThemeProvider theme={theme}>
                  <App />
                </MuiThemeProvider>
                {!isProd() && <EnvBanner />}
                <Toaster autoClose={2000} />
              </FeatureModalProvider>
            </BrowserRouter>
          </FeaturesProvider>
        </RecoilRoot>
      </ReactReduxFirebaseProvider>
    </Provider>
  </ErrorBoundary>
);

export default Root;
