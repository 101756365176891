import React, { useState } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShareSquare,
  faUnlockAlt,
  faRedo,
  faPlay,
  faDownload,
  faMusic,
  faExclamationTriangle,
  faTrashAlt as faTrash,
  faICursor as faCursor,
  faFolder
} from '@fortawesome/free-solid-svg-icons';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Chip from 'components/common/Dashboardv2/Chip';
import { LanguageLookup } from 'components/common/CreatorDialog/SelectLanguage';
import { MenuItem, MenuIcon } from 'components/common/Popover';
import Export from 'components/common/EditorV2/Header/Buttons/Export';
import useCollection from 'hooks/useCollection';
import useProfile from 'hooks/useProfile';
import MoveWorkspace from '../EditorV2/Header/Buttons/MoveWorkspace';

const MeniIcon = styled(FontAwesomeIcon)`
  color: rgba(0, 0, 0, 0.2);
  font-size: 0.8em;
`;

// const Paused = ({estimatedOver, onClick, retrying}) => {
//   if (retrying) return <Retry retrying={retrying} />
//   return (
//     <FontAwesomeIcon
//       onClick={onClick}
//       icon={faPause}
//       style={{
//         color: estimatedOver ? '#CCC' : '#7DBB91',
//         height: '11px',
//         cursor: estimatedOver ? 'default' : 'pointer'
//       }} />
//   )
// }

export const Retry = ({ onClick, retrying, retried }) => {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      spin={retrying}
      icon={faRedo}
      style={{
        color: retried ? '#CCC' : '#5173E7',
        height: '11px',
        cursor: retrying || retried ? 'default' : 'pointer'
      }}
    />
  );
};

export const SelectLanguage = ({
  mp3,
  updateLanguage,
  languageChipLabel = 'Language'
}) => {
  const [open, setOpen] = useState();
  const [anchorEl, setAnchorEl] = useState();
  // console.log('aaaaa', mp3)
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Chip
          label={languageChipLabel}
          onClick={(event) => {
            setOpen(true);
            setAnchorEl(event.currentTarget);
          }}
        />
        <Chip
          style={{ marginLeft: 5 }}
          label={
            <a href={mp3} target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                icon={faMusic}
                style={{ fontSize: 11, paddingRight: 1 }}
              />
            </a>
          }
        />
      </div>
      <Popper
        style={{ zIndex: 55 }}
        open={!!open}
        anchorEl={anchorEl}
        role={undefined}
        placement="right-end"
        transition
      >
        <ClickAwayListener
          onClickAway={(evt) => {
            setOpen(false);
            setAnchorEl(null);
          }}
        >
          <Paper style={{ transform: 'translateX(-120px)' }}>
            <MenuList
              autoFocusItem={!!open}
              style={{ maxHeight: '250px', overflow: 'scroll' }}
            >
              {LanguageLookup.filter((lang) => lang.value !== 'auto').map(
                ({ value: languageCode, label }) => (
                  <MUIMenuItem
                    onClick={(evt) => {
                      updateLanguage(languageCode);
                    }}
                    key={languageCode}
                  >
                    {label}
                  </MUIMenuItem>
                )
              )}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default (props) => {
  const {
    paused,
    shouldBeDoneButIsNot,
    children,
    type = 'transcript',
    onRetryComplete,
    id: collectionId,
    onChangeOwner,
    languageCode,
    isProcessing,
    isError,
    onEditName,
    onDelete,
    onMoveWorkspace
  } = props;
  const { isAdmin, uid, organizations, oid } = useProfile();
  const {
    showShareModal,
    onRetry,
    onUnPause,
    owner,
    isRetried,
    isDraftComplete,
    logs,
    demo,
    isTranslating,
    updateLanguage,
    collection
  } = useCollection({ id: collectionId, uid });

  const availableOrganizations = organizations.filter(({ id }) => id !== oid);

  const isOwner = owner.uid && owner.uid === uid; // is owner of collection
  const isTextCollection = type === 'text';
  const [retrying, setRetrying] = useState(!!(isRetried && isProcessing));
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatingLang, setUpdatingLang] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // console.log({owner}, collection.userId, collection.id)
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleCloseForMoveWorkspace = () => {
    setAnchorEl(null);
    onMoveWorkspace();
  };

  const handleRetry = async () => {
    console.log({ isRetried, retrying });
    if (!isRetried && !retrying) {
      setRetrying(true);
      await onRetry();
      setRetrying(false);
      onRetryComplete && onRetryComplete();
    }
  };

  const handleUnPause = async () => {
    setRetrying(true);
    await onUnPause();
    onRetryComplete && onRetryComplete();
    setRetrying(false);
  };

  const handleUpdateLanguage = async (lang) => {
    setUpdatingLang(true);
    await updateLanguage(lang);
    await handleRetry();
    setUpdatingLang(false);
  };

  if (!isTextCollection && isProcessing && isTranslating) return '';
  if (!isTextCollection && retrying) return <Retry retrying />;
  if (languageCode === 'auto' && collection.progress === 'ERROR') {
    return !collection?.mp3 ? (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        style={{ color: '#EEE', marginRight: 5 }}
      />
    ) : (
      <Chip
        style={{ marginLeft: 5 }}
        label={
          <a href={collection?.mp3} target="_blank" rel="noreferrer">
            <FontAwesomeIcon
              icon={faMusic}
              style={{ fontSize: 11, paddingRight: 1 }}
            />
          </a>
        }
      />
    );
  }

  if (!isTextCollection && !languageCode && collection.progress === 'ERROR') {
    return updatingLang ? (
      <Retry retrying={true} />
    ) : (
      <SelectLanguage
        mp3={collection?.mp3}
        updateLanguage={handleUpdateLanguage}
      />
    );
  }
  if (
    !isTextCollection &&
    shouldBeDoneButIsNot &&
    !paused &&
    !isRetried &&
    !demo
  )
    return <Retry retrying={retrying} onClick={handleRetry} />;
  if (!isTextCollection && isRetried && !isProcessing && !isDraftComplete) {
    const footer =
      logs.length > 0
        ? `\n\n\n\n\n------------\nlogs: ${logs.map((e) => `${e.details}\n`)}`
        : '';
    return (
      <MeniIcon
        icon={faExclamationTriangle}
        onClick={() => {
          window.Beacon('navigate', '/ask/message');
          window.Beacon('prefill', {
            subject: `Issue with collection ${collectionId}`,
            text: `My Collection ${collectionId} never completed.${footer}`
          });
          window.Beacon('open');
        }}
      />
    );
  }
  if (!isTextCollection && !paused && isProcessing) return '';
  return (
    <div>
      <div
        tabIndex={0}
        role="button"
        styling="text"
        aria-label="collection card menu"
        onKeyDown={(evt) => evt.keyCode === 13 && handleClick()}
        style={{ outline: 'none', cursor: 'pointer' }}
        onClick={handleClick}
      >
        {children}
      </div>
      <Popover
        id={id}
        open={open}
        PaperProps={{
          style: {
            padding: '10px',
            overflowX: 'initial',
            overflowY: 'initial'
          }
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        {(isTextCollection || (isOwner && !isProcessing && !isError)) && (
          <React.Fragment>
            <MenuItem
              onClick={() => {
                handleClose();
                onEditName();
              }}
            >
              <MenuIcon>
                <MeniIcon icon={faCursor} />
              </MenuIcon>
              <span>Rename</span>
            </MenuItem>
          </React.Fragment>
        )}
        {availableOrganizations.length > 0 && (
          <MoveWorkspace
            topTransform="-100%"
            leftTransform="-20px"
            onClose={handleCloseForMoveWorkspace}
          >
            <MenuItem>
              <MenuIcon>
                <MeniIcon icon={faFolder} />
              </MenuIcon>
              <span>Move Workspace</span>
            </MenuItem>
          </MoveWorkspace>
        )}
        {isOwner && (!collection.precisionQueue || collection.tTranslation) && (
          <MenuItem
            onClick={() => {
              handleClose();
              onDelete();
            }}
            color="#CF4923"
          >
            <MenuIcon>
              <MeniIcon icon={faTrash} />
            </MenuIcon>
            <span>Delete</span>
          </MenuItem>
        )}
        {!isOwner && isAdmin && (
          <MenuItem
            onClick={() => {
              handleClose();
              onChangeOwner(collectionId);
            }}
            color="#CF4923"
          >
            <MenuIcon>
              <MeniIcon icon={faUnlockAlt} />
            </MenuIcon>
            <span>Become Owner</span>
          </MenuItem>
        )}

        {!isTextCollection && paused && (
          <MenuItem
            onClick={() => {
              handleClose();
              handleUnPause();
            }}
            color="#CF4923"
          >
            <MenuIcon>
              <MeniIcon icon={faPlay} />
            </MenuIcon>
            <span>Try Again</span>
          </MenuItem>
        )}

        {isTextCollection || (!isProcessing && !isError && !paused) ? (
          <>
            <MenuItem
              onClick={() => {
                // console.log('share', collection.id)
                handleClose();
                showShareModal(true);
              }}
              color="#CF4923"
            >
              <MenuIcon>
                <MeniIcon icon={faShareSquare} />
              </MenuIcon>
              <span>Share</span>
            </MenuItem>
            {!isTextCollection && (
              <Export topTransform="-100%" leftTransform="-20px">
                <MenuItem>
                  <MenuIcon>
                    <MeniIcon icon={faDownload} />
                  </MenuIcon>
                  <span>Export</span>
                </MenuItem>
              </Export>
            )}
          </>
        ) : (
          ''
        )}
      </Popover>
    </div>
  );
};
