import React from 'react';
import axios from 'axios';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';

import { getAuthTokenCookie } from 'utils';
import { getNewUserToken, getCurrentUser } from 'utils/Firebase'; // getAnnonToken
import { getApiUrl, hasAAK } from 'utils/env';
import { toast, POSITION } from 'components/common/Toaster';

const imageAddr =
  'https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/61b4f5d1bab4634ba2bf77a5_download-speed%20copy.jpg';
const pixel =
  'https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/61b4fc4aa620201718512383_pixel.png';
const downloadSize = 3849043; // this must match with the image above
const params = {
  autoClose: false,
  toastId: 'internet-connection-issue',
  position: POSITION.TOP_CENTER
};
let slowTimeout;

export const measureConnectionSpeed = async () => {
  // console.log('measuring speed.....')
  let startTime, endTime;
  startTime = new Date().getTime();
  const cacheBuster = '?nnn=' + startTime;

  const download = new Image();
  download.src = imageAddr + cacheBuster;
  // this returns when the image is finished downloading
  // console.log('test URL', download.src)
  await download.decode();
  endTime = new Date().getTime();
  const duration = (endTime - startTime) / 1000;
  const bitsLoaded = downloadSize * 8;
  const speedBps = (bitsLoaded / duration).toFixed(2);
  var speedKbps = (speedBps / 1024).toFixed(2);
  const speedMbps = (speedKbps / 1024).toFixed(2);
  // console.log('speed is MB', speedMbps)
  return Math.round(Number(speedMbps));
};

export const checkOnlineStatus = async () => {
  try {
    if (!window.navigator.onLine) return false;
  } catch (err) {
    console.log('Could not access navigator'); // issue using navigator
  }

  try {
    const online = await fetch(pixel);
    return online.status === 200;
  } catch (err) {
    return false; // definitely offline
  }
};

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  transformResponse: axios.defaults.transformResponse.concat((data) => {
    // moved logic to here so we can capture other fieds like LastEvaluatedKey
    // instance.interceptors.response.use(
    // RESPONSE TRANSFORM
    try {
      // const {result: {Items, LastEvaluatedKey}, result} = data
      // console.log({LastEvaluatedKey})
      // console.log('apiclient', {data})
      return typeof data === 'string' ? { result: data } : data;
      // return Items || result
    } catch (error) {
      console.log('apiclient error', { data });
      return data;
    }
  })
});

export const checkInternetOnline = async () => {
  const online = await checkOnlineStatus();
  if (!online) {
    toast.warn(
      'We could not find an internet connection. Please re-fresh.',
      params
    );
    return false;
  }
  return true;
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isGoodInternetSpeed = async (called = 0, speeds = []) => {
  if (document.visibilityState !== 'visible') {
    toast.dismiss(params.toastId);
    return console.log('Tab not visible');
  }
  const speed = await measureConnectionSpeed();
  speeds.push(speed || 0);
  const avgSpeed = speed ? speeds.reduce((a, b) => a + b) / speeds.length : 0;
  console.log(
    'This speed:',
    speed,
    ' Called ',
    called,
    ' times. Average speed:',
    avgSpeed,
    speeds,
    new Date()
  );
  if (avgSpeed <= 5) {
    if (called < 4) {
      await sleep(3000);
      await isGoodInternetSpeed(called + 1, speeds);
    } else return false;
  } else return true;
};

const lastCheckedEpoch = () =>
  parseInt(window.localStorage.getItem('last-checked-speed') || 0, 10);

const timeSinceCheck = () => {
  const now = new Date().getTime();
  const lastChecked = lastCheckedEpoch();
  // set to now if not exists
  if (!lastChecked) window.localStorage.setItem('last-checked-speed', now);
  return now - lastChecked;
};

const _checkInternetHealth = async () => {
  // only show speed issues on edit page
  if (window.location.pathname.indexOf('edit') === -1) return;

  const online = await checkInternetOnline();

  if (online) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      console.log('mobile...skipping speed test');
    } else {
      const maxWaitMills = 3 * 60 * 60 * 1000; // 3 hours

      // see if 30 mins has passed
      if (timeSinceCheck() > maxWaitMills) {
        console.log('Is good internet?');
        const message = (
          <div>
            Warning: Slow internet speed or inactivity.
            <a
              href="https://help.konch.ai/article/62-working-with-slow-internet-connections"
              target="_blank"
              style={{ marginLeft: 6, textDecoration: 'underline' }}
              rel="noopener noreferrer"
            >
              Learn more
            </a>
            .
          </div>
        );

        clearTimeout(slowTimeout);
        slowTimeout = setTimeout(() => {
          console.log('....Timeout triggered before function completed.');
          toast.warn(message, params);
          window.localStorage.setItem(
            'last-checked-speed',
            new Date().getTime()
          );
        }, 6000);
        const isGood = await isGoodInternetSpeed();
        clearTimeout(slowTimeout);
        if (!isGood && timeSinceCheck() > maxWaitMills)
          toast.warn(message, params);
        else toast.dismiss(params.toastId);
        window.localStorage.setItem('last-checked-speed', new Date().getTime());
      } else {
        const lc = new Date(lastCheckedEpoch()).toLocaleTimeString();
        const nc = new Date(
          lastCheckedEpoch() + maxWaitMills
        ).toLocaleTimeString();
        console.log(
          'waiting to check speed. Last checked:',
          lc,
          'Next Check:',
          nc
        );
      }
    }
  } else console.log('not online');
};

const checkInternetHealth = _debounce(_checkInternetHealth, 5000);

const throttledGetNewUserToken = _throttle(async () => {
  const newKey = await getNewUserToken();
  console.log('throttle: New Key', newKey);
  return newKey;
}, 3000);

instance.interceptors.request.use(
  async function (options) {
    checkInternetHealth();
    let { uid, organization } = await getCurrentUser(true);

    // // annon key
    // if (!uid) {
    //   uid = localStorage.getItem('temp-uid')
    //   if (!uid) {
    //     uid = (await getAnnonToken()).userId
    //     localStorage.setItem('temp-uid', uid)
    //   }
    // }

    const { locale = 'us' } = organization || {};
    // console.log({data: options.data})
    const dataLocale = options.data?.locale;
    const optionsLocale =
      typeof options.locale === 'string' ? options.locale : null;
    const forceLocale = dataLocale || optionsLocale || false;
    // console.log({forceLocale, options})
    const baseURL = getApiUrl(forceLocale || locale);

    let key = getAuthTokenCookie(locale);
    if (!key && uid) {
      await throttledGetNewUserToken();
      key = getAuthTokenCookie(locale);
      console.log('Request: New Key ', key, getAuthTokenCookie(locale));
    }

    options.headers['key'] = key;
    options.headers['id'] = uid;
    if (hasAAK) options.headers['x-api-key'] = hasAAK;
    options.baseURL = baseURL;
    return options;
  },
  function (error) {
    console.log('Request error: ', error);
    // toast.info('Authorizing connection....', {autoClose: 5000, toastId: 'connection-auth-warning'})
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  // RESPONSE TRANSFORM
  async (response) => {
    // console.log({response})
    try {
      const { result: { Items, LastEvaluatedKey } = {}, result } =
        response.data || {};
      // console.log({LastEvaluatedKey})
      response.data = Items || result;
      if (LastEvaluatedKey) response.LastEvaluatedKey = LastEvaluatedKey;
      // console.log('apiclient response', {response})
    } catch (error) {
      console.log('err', error);
    }
    return response;
  },

  // ERROR RETRY AND TRANSFORM
  async (error) => {
    // console.log('Interceptors:', error?.response, error?.response?.data?.message, error?.response?.status , error?.config)

    // exceptions

    // check upload returns a 403 when a url is not correct for upload
    if ((error?.config?.url || '').indexOf('pipeline/check') > 0) {
      return Promise.reject(error);
    }

    // console.log({error})

    if (
      error?.config &&
      (error?.response?.status === 403 || error?.message === 'Network Error')
    ) {
      console.log(
        'Interceptors: failed with key',
        error.config.headers.key,
        error.config
      );
      const { url, headers, method, data, params } = error.config;
      const locale = url.indexOf('eu.knch.io') > 0 ? 'eu' : 'us';
      await throttledGetNewUserToken();
      await sleep(3000);

      const newKey = getAuthTokenCookie(locale);

      error.config.headers.key = newKey;
      console.log('Interceptors: new key', newKey);

      const newParams = { url, headers, method, data, params };
      console.log('Interceptors: to send again', newParams);
      // toast.info('Authorizing connection...', {autoClose: 5000, toastId: 'connection-auth-warning'})
      const response = await axios(newParams);
      console.log('retried response', response);

      try {
        const {
          result: { Items },
          result
        } = response.data;
        response.data = Items || result;
      } catch (error) {
        console.log('couldnt find items', error);
      }

      console.log('edited retried response', response);

      return response;
    }

    toast.dismiss('connection-auth-warning');
    if (error?.response?.status === 500) {
      // toast.requestRefresh({caller: 'interceptors.response/500'})
      console.log('Intercepted a 500 error', error);
    }
    return Promise.reject(error);
  }
);

const client = () => instance;

export default client;
