export const DASHBOARD_VIEW = {
  GRID: 'GRID_VIEW',
  LIST: 'LIST_VIEW'
};

export const Features = {
  Precision: 'Precision',
  KonchMate: 'KonchMate',
  ContentGeneration: 'ContentGeneration',
  TranscriptTranslation: 'TranscriptTranslation',
  TranscriptSummarization: 'TranscriptSummarization',
  TranscriptExport: 'TranscriptExport',
  MagicDoc: 'MagicDoc',
  // Used only in the FeatureModalProvider and not set in Stripe
  FileSizeLimit: 'FileSizeLimit'
};
