import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';

import HelpIcon from '@material-ui/icons/HelpOutline';
import Button from '@material-ui/core/Button';
import ExportIcon from '@material-ui/icons/GetApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileWord,
  faClosedCaptioning,
  faFileCsv,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons';

import EditorContext from 'context/EditorContext';
import useExporter from 'hooks/useExporter';
import useIsDirty from 'hooks/useIsDirty';
import useProfile from 'hooks/useProfile';

import ProgressBar from 'components/common/ProgressBar';
// import Button from 'components/common/Button'
import Popover, { MenuItem, MenuIcon } from 'components/common/Popover';
import Spinner from 'components/common/Spinner';
import { FeaturesContext } from 'providers/FeaturesProvider';
import { withTranscriptExportCheck } from 'components/common/utils';
import { Features } from 'utils/constants';
import { FeatureModalContext } from 'providers/FeatureModalProvider/FeatureModalProvider';

const Help = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  color: #aaa;
  transition: color 1s;
  :hover {
    color: dodgerblue;
    transition: color 1s;
  }
  svg {
    font-size: 13px;
  }
`;

const Export = React.memo(({ children, ...props }) => {
  const { collectionId, isCorrector, isReviewer } = useContext(EditorContext);

  const isDirty = useIsDirty();
  const {
    settings: { maxCPL = 35, welcomeSettings },
    updateSettings,
    isLoaded
  } = useProfile();

  const { isTranscriptExportEnabled } = useContext(FeaturesContext);
  const { showFeatureModal } = useContext(FeatureModalContext);

  const [generatingDoc, setGeneratingDoc] = React.useState();
  const [generatingHighlights, setGeneratingHighlights] = React.useState();
  const {
    loading,
    downloadingVTT,
    downloadingSRT,
    generateAnnotations,
    generateDoc,
    generateText,
    generateSrtSubtitles,
    generateVttSubtitles,
    downloadCsv
  } = useExporter({ collectionId });

  if (isCorrector || isReviewer) return null;

  return (
    <Fragment>
      {loading && <ProgressBar />}
      <Popover
        leftTransform="-50%"
        {...props}
        trigger={
          children || (
            <Button
              className="export-transcript-button"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: '#5173e7',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                padding: '7px 20px',
                fontSize: 16
              }}
              disableElevation
              endIcon={
                <ExportIcon style={{ fontSize: 21 }} className="hide-small" />
              }
            >
              Export
            </Button>
          )
        }
        content={
          <>
            <Popover
              topTransform="-20px"
              leftTransform="-110px"
              closeOnClick
              trigger={
                <MenuItem>
                  <MenuIcon>
                    {generatingDoc ? (
                      <Spinner small />
                    ) : (
                      <FontAwesomeIcon icon={faFileWord} />
                    )}
                  </MenuIcon>
                  <span>Word Doc</span>
                </MenuItem>
              }
              content={
                <div>
                  <MenuItem
                    onClick={async () => {
                      setGeneratingDoc(true);

                      const generateDocFn = withTranscriptExportCheck({
                        isTranscriptExportEnabled,
                        onSuccess: generateDoc,
                        onShowFeatureModal: () =>
                          showFeatureModal({
                            feature: Features.TranscriptExport
                          }),
                        args: [true]
                      });

                      await generateDocFn();

                      setGeneratingDoc(false);
                    }}
                  >
                    <span>Tabular</span>
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      setGeneratingDoc(true);

                      const generateDocFn = withTranscriptExportCheck({
                        isTranscriptExportEnabled,
                        onSuccess: generateDoc,
                        onShowFeatureModal: () =>
                          showFeatureModal({
                            feature: Features.TranscriptExport
                          }),
                        args: [false]
                      });

                      await generateDocFn();

                      setGeneratingDoc(false);
                    }}
                  >
                    <span>Continuous</span>
                  </MenuItem>
                </div>
              }
            />

            <Popover
              topTransform="-20px"
              leftTransform="-110px"
              closeOnClick
              trigger={
                <MenuItem>
                  <MenuIcon>
                    <FontAwesomeIcon icon={faFileAlt} />
                  </MenuIcon>
                  <span>Plain Text</span>
                </MenuItem>
              }
              content={
                <div>
                  <MenuItem
                    onClick={withTranscriptExportCheck({
                      isTranscriptExportEnabled,
                      onSuccess: generateText,
                      onShowFeatureModal: () =>
                        showFeatureModal({
                          feature: Features.TranscriptExport
                        }),
                      args: [true]
                    })}
                  >
                    <span>
                      {downloadingSRT ? <Spinner small /> : 'Speakers'}
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={withTranscriptExportCheck({
                      isTranscriptExportEnabled,
                      onSuccess: generateText,
                      onShowFeatureModal: () =>
                        showFeatureModal({
                          feature: Features.TranscriptExport
                        }),
                      args: [false]
                    })}
                  >
                    <span>{downloadingVTT ? <Spinner small /> : 'Plain'}</span>
                  </MenuItem>
                </div>
              }
            />

            <MenuItem
              onClick={withTranscriptExportCheck({
                isTranscriptExportEnabled,
                onSuccess: downloadCsv,
                onShowFeatureModal: () =>
                  showFeatureModal({
                    feature: Features.TranscriptExport
                  })
              })}
            >
              <MenuIcon>
                <FontAwesomeIcon icon={faFileAlt} />
              </MenuIcon>

              <span>CSV</span>
            </MenuItem>

            <Popover
              topTransform="-20px"
              leftTransform="-50px"
              closeOnClick
              trigger={
                <MenuItem>
                  <MenuIcon>
                    {isDirty || downloadingVTT || downloadingSRT ? (
                      <Spinner small color="#7DBB91" />
                    ) : (
                      <FontAwesomeIcon icon={faClosedCaptioning} />
                    )}
                  </MenuIcon>

                  <span>Subtitles</span>
                </MenuItem>
              }
              content={
                <div>
                  <MenuItem
                    onClick={withTranscriptExportCheck({
                      isTranscriptExportEnabled,
                      onSuccess: () => {
                        !downloadingSRT && generateSrtSubtitles(maxCPL);
                      },
                      onShowFeatureModal: () =>
                        showFeatureModal({
                          feature: Features.TranscriptExport
                        })
                    })}
                  >
                    <span>SRT</span>
                  </MenuItem>
                  <MenuItem
                    onClick={withTranscriptExportCheck({
                      isTranscriptExportEnabled,
                      onSuccess: () => {
                        !downloadingVTT && generateVttSubtitles(maxCPL);
                      },
                      onShowFeatureModal: () =>
                        showFeatureModal({
                          feature: Features.TranscriptExport
                        })
                    })}
                  >
                    <span>VTT</span>
                  </MenuItem>
                </div>
              }
            />

            <Popover
              topTransform="-20px"
              leftTransform="-50px"
              closeOnClick
              trigger={
                <MenuItem>
                  <MenuIcon>
                    {isDirty || generatingHighlights ? (
                      <Spinner small color="#7DBB91" />
                    ) : (
                      <FontAwesomeIcon icon={faFileCsv} />
                    )}
                  </MenuIcon>
                  <span>Highlights/Comments</span>
                </MenuItem>
              }
              content={
                <div>
                  <MenuItem
                    onClick={async () => {
                      setGeneratingHighlights(true);

                      const generateAnnotationsFn = withTranscriptExportCheck({
                        isTranscriptExportEnabled,
                        onSuccess: generateAnnotations,
                        onShowFeatureModal: () =>
                          showFeatureModal({
                            feature: Features.TranscriptExport
                          }),
                        args: [true]
                      });

                      await generateAnnotationsFn();

                      setGeneratingHighlights(false);
                      if (
                        isLoaded &&
                        !welcomeSettings?.exported &&
                        !isCorrector &&
                        !isReviewer
                      )
                        updateSettings({ [`welcomeSettings.exported`]: true });
                    }}
                  >
                    <span>CSV</span>
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      setGeneratingHighlights(true);

                      const generateAnnotationsFn = withTranscriptExportCheck({
                        isTranscriptExportEnabled,
                        onSuccess: generateAnnotations,
                        onShowFeatureModal: () =>
                          showFeatureModal({
                            feature: Features.TranscriptExport
                          })
                      });

                      await generateAnnotationsFn();

                      setGeneratingHighlights(false);
                      if (
                        isLoaded &&
                        !welcomeSettings?.exported &&
                        !isCorrector &&
                        !isReviewer
                      )
                        updateSettings({ [`welcomeSettings.exported`]: true });
                    }}
                  >
                    <span>XLS</span>
                  </MenuItem>
                </div>
              }
            />

            <MenuItem style={{ marginTop: 10 }}>
              <Help
                onClick={() =>
                  Beacon && Beacon('article', '636dacdf514e5e47b9480188')
                }
              >
                <HelpIcon />
                <span style={{ marginLeft: 5 }}>
                  Learn more about this menu.
                </span>
              </Help>
            </MenuItem>
          </>
        }
      />
    </Fragment>
  );
});

export default Export;
