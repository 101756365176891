import React, {useState, createContext} from 'react';

export const UnderlineContext = createContext();

export const UnderlineProvider = ({children}) => {
  const [underlineId, setUnderlineId] = useState(null);
  const [searchUnderlineIds, setSearchUnderlineIds] = useState(null);
  const [underlineSection, setUnderlineSection] = useState(null);
  const [highlighSliderWord, setHighlighSliderWord] = useState(null);

  return (
    <UnderlineContext.Provider
      value={
        [
          [underlineId, setUnderlineId],
          [searchUnderlineIds, setSearchUnderlineIds],
          [underlineSection, setUnderlineSection],
          [highlighSliderWord, setHighlighSliderWord]
        ]
      }
    >
      {children}
    </UnderlineContext.Provider>
  );
};
