import React from 'react';
import styled from 'styled-components';

// import useGoogleDrivePicker from 'hooks/useGoogleDrivePicker'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogleDrive} from '@fortawesome/free-brands-svg-icons'
import useChooser from 'hooks/useDropboxChooser';
import {faDropbox} from '@fortawesome/free-brands-svg-icons';

const DropboxStyledButton = styled.button`
  width: 100%;
  padding: .7rem 0;
  margin: 1rem 0;
  font-size: 16px;
  cursor: pointer;
`;

export default ({setName, setUrl, handleCheckUrl}) => {
  const {open, isOpen: isDropboxOpen} = useChooser();
  return <DropboxStyledButton onClick={async () => {
    const [file] = await open();
    setName(file.name);
    setUrl(file.link);
    handleCheckUrl(file.link);
  }} disabled={isDropboxOpen}><FontAwesomeIcon icon={faDropbox} /> Select from Dropbox</DropboxStyledButton>;
};