import React from 'react';
import styled from 'styled-components';
import { useMediaPredicate } from 'react-media-hook';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
// import {useHistory} from 'react-router-dom'

import Chip from '@material-ui/core/Chip';
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ButtonBase } from '@material-ui/core';
// import DocIcon from '@material-ui/icons/PostAddRounded'
// import AddIcon from '@material-ui/icons/AddRounded'
import UploadIcon from '@material-ui/icons/PublishRounded';

import useUpload from 'hooks/useUpload';
import Card from './CollectionCard';
import CardSkeleton from 'components/common/CardSkeleton';
import YouTubeIcon from './YouTubeIcon';
// import UploadMenu from 'components/common/Popover/UploadMenu'

TimeAgo.addLocale(en);

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  justify-content: left;
  @media (max-width: 523px) {
    justify-content: center;
    margin-left: 0px;
  }
  // .lang-code-marker {
  //   opacity: 0;
  // }
  :hover .lang-code-marker {
    background-color: #dbe0e3;
    transition: background-color 0.5s;
  }
  .card-title {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 13px !important;
  }
`;

const StyledChip = styled(Chip)`
  position: absolute;
  right: -95px;
  text-align: center;
  background-color: #c8cdd2 !important;
  color: #fff !important;
  font-weight: bold;
`;
const UploadMenuCont = styled.div`
  display: flex;
  transition: opacity 0.6s 0.3s;
  .upload-list {
    margin-left: auto;
    margin-top: -18%;
    margin-right: 5px;
    z-index: 1;
  }
  .MuiSvgIcon-root {
    color: #3f5bb9;
    transition: transform 0.5s 0.2s;
    :hover {
      color: #fff;
      opacity: 1;
      transform: scale(1.2);
      transition:
        color 0.3s,
        transform 0.5s;
    }
  }
`;

const AddCard = styled.div`
  height: var(--cardHeight);
  width: var(--cardWidth);
  border-radius: var(--cardRadius);

  .youtube {
    margin: 60px auto auto auto;
    opacity: 0.7;
    transition: all 0.25s;
  }

  .card-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0 21px;
    font-size: 5em;
    font-size: 12px !important;
    line-height: 1.5em;
    opacity: 0.9;
    transition: transform 1s;
  }
  &.first,
  &.second {
    width: 194px;
    height: 119px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.second {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  margin-left: 25px;
  margin-bottom: 3px;
  background-color: ${(props) => props.backgroundColor || '#5173e7'};
  color: #fff;
  :hover {
    background-color: ${(props) => props.backgroundColor || '#6284e8'};

    transform: scale(1.03);
    transition:
      background-color 0.5s,
      transform 0.7s;
    .card-title {
      opacity: 1;
    }
    div.plus {
      opacity: 1;
      color: #fff;
      transition:
        color 0.4s,
        opacity 0.2s;
    }
  }

  &:hover .youtube {
    opacity: 1;
  }

  &:hover ${UploadMenuCont} .MuiSvgIcon-root {
    opacity: 1;
    color: #fff;
    transition:
      color 0.3s,
      opacity 0.6s 0.3s;
  }

  transition:
    background-color 0.5s,
    transform 0.7s;
  .MuiButtonBase-root {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    h2 {
      text-align: left;
    }

    .plus {
      color: #3f5bb9;
      font-size: 48px;
      line-height: 40px;
      margin: 0 auto;
      opacity: 0.7;
      &.single {
        margin-top: 20px;
      }
      transition:
        color 0.3s,
        opacity 0.2s;
      &:hover {
        transform: scale(1.2);
      }
      svg {
        font-size: 40px;
      }
      &.single svg {
        font-size: 60px;
      }
    }
  }
`;

const CardCont = styled.div`
  @media (max-width: 523px) {
    ${AddCard} {
      margin-left: 0px;
      width: 100%;
    }
  }
`;

// const PopoverPosition = {
//   anchorOrigin: {vertical: 'center', horizontal: 'left'},
//   transformOrigin: {vertical: 'bottom', horizontal: 'right'},
//   margin: '35px 0 30px 0',
//   padding: '15px 22px 15px 22px'
// }

const TimeTab = ({ i, currCollection, prevCollection }) => {
  // responsive
  const showMonthTabWidths = [useMediaPredicate('(min-width: 1587px)')];
  const showMonthTab = showMonthTabWidths.some((m) => m === true);
  // calc if new month, show tab
  const started = new Date(currCollection.started * 1000);
  let month = started.getMonth() + 1;
  let year = started.getFullYear();
  // comapred to previous
  const prevStarted = new Date((prevCollection || {}).started * 1000);
  let prevMonth = prevStarted.getMonth() + 1;
  let pevYear = prevStarted.getFullYear();
  // has changed
  const changed = month !== prevMonth || year !== pevYear;
  // not IE compatible
  try {
    month = month.toString().padStart(2, '0');
  } catch (err) {
    console.log('not allowed tab padding');
  }

  return (
    (i === 0 || changed) &&
    showMonthTab && <StyledChip label={month + '-' + year} />
  );
};

const CollectionListCard = (props) => {
  // const history = useHistory()
  const {
    collections,
    usage,
    className,
    style,
    loading,
    isSharedCollections,
    searchTerm,
    showChipDates
  } = props;
  const { openDialog } = useUpload();

  const availablePlaces = [
    useMediaPredicate('(min-width: 0px)'), // 1
    useMediaPredicate('(min-width: 461px)'), // 2
    useMediaPredicate('(min-width: 902px)'), // 3
    useMediaPredicate('(min-width: 1080px)'), // 4
    useMediaPredicate('(min-width: 1382px)'), // 5
    useMediaPredicate('(min-width: 1586px)') // 6
  ];

  const noCards = collections.length === 0;
  const showAddCard =
    !isSharedCollections && (!loading || (loading && !noCards)) && !searchTerm;
  // accounting for 1 place for the "add card" card
  const extraAddCard = showAddCard ? 1 : 0;
  const places = availablePlaces.filter((place) => place).length || 0;
  const cardCount = collections.length + extraAddCard;
  const overSplill = cardCount % places;
  const toFill = places - overSplill;
  const fillerCards =
    collections.length > 0 && (places === 1 || overSplill === 0)
      ? ''
      : [...new Array(noCards ? places - extraAddCard : toFill)].map((a, i) => (
          <CardSkeleton key={i} />
        ));
  /*
  <AddCard className='createButton-sc first'>
    <ButtonBase onClick={() => history.push('/doc')}>
      <div className='card-title'>Generate A.I. Content</div>
      <div className='plus'><DocIcon /></div>
    </ButtonBase>
  </AddCard>
  */
  return (
    <Root className={className} style={style}>
      {availablePlaces[2] && showAddCard && (
        <>
          <CardCont>
            <AddCard className="createButton-sc">
              <ButtonBase
                onClick={() =>
                  openDialog({ view: 'file-drop', responsive: true })
                }
              >
                <div className="card-title">
                  Click here to upload your audio or video
                </div>
                <div className="plus single">
                  <UploadIcon />
                </div>
              </ButtonBase>
            </AddCard>
          </CardCont>
          <CardCont>
            <AddCard className="createButton-sc" backgroundColor="#0f0f0f">
              <ButtonBase
                onClick={() =>
                  openDialog({ view: 'youTubeUpload', responsive: true })
                }
              >
                <div className="card-title">
                  Click here to upload a YouTube Video
                </div>
                <div className="youtube single">
                  <YouTubeIcon />
                </div>
              </ButtonBase>
            </AddCard>
          </CardCont>
        </>
      )}

      {collections.map((collection, i) => (
        <CardCont key={collection.id}>
          {showChipDates && (
            <TimeTab
              i={i}
              currCollection={collection}
              prevCollection={collections[i - 1]}
            />
          )}
          <Card
            usage={usage}
            id={collection.id}
            prevCollection={collections[i - 1]}
          />
        </CardCont>
      ))}
      {fillerCards}
    </Root>
  );
};

export default CollectionListCard;
/*
draggable
onDragStart={(ev) => ev.dataTransfer.setData('collection', JSON.stringify({name: collection.name, id: collection.id}))}
*/
