// Checks if the feature is enabled for the user and shows the feature modal if not
export const withTranscriptExportCheck = ({
  isTranscriptExportEnabled,
  onSuccess,
  onShowFeatureModal,
  args = []
}) => {
  return async () => {
    if (isTranscriptExportEnabled) {
      await onSuccess(...args);
    } else {
      onShowFeatureModal();
    }
  };
};
