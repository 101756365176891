import {createContext} from 'react';

const WordChunkEditorContext = createContext({
  setShow: () => {},
  showWarning: false,
  showEditor: false,
  spans: [],
  minTime: 0,
  maxTime: 0
});

export default WordChunkEditorContext;
