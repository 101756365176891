import React from 'react';
import {db} from 'utils/Firebase';
import Loading from 'components/common/Loading';
export default function WhiteLabeler (WrappedComponent, params, options = {}) {

  const HOC = (props) => {
    const [settings, setSettings] = React.useState({organization: 'default'});
    const [ready, setReady] = React.useState(false);
    const hostList = window.location.host.split('.');
    const subdomain = hostList[0].toLowerCase();
    const whiteLabeledSubdomains = Object.keys(CustomPageSettings);
    const reservedSubdomains = ['dev', 'app', 'localhost', 'preview'];

    const checkWhiteLabels = async () => {
      const whiteLabelSettings = (await db.collection('WhiteLabels').doc(subdomain).get()).data();
      setSettings(whiteLabelSettings);
      setReady(true);
    };

    React.useEffect(() => {
      if (reservedSubdomains.indexOf('subdomain') >= 0) return setReady(true);
      // check if white labeled in legacy/local/static below
      if (whiteLabeledSubdomains.indexOf(hostList[0]) >= 0) {
        setSettings(CustomPageSettings[subdomain]);
        setReady(true);
      } else {
        // check if white label is remote in new system
        checkWhiteLabels();
      }
    }, []);
    if (!ready) return <Loading />;
    return <WrappedComponent
        {...props}
        settings={settings}
      />;
  };

  return HOC;
}

const CustomPageSettings = {
  nyu: {
    active: true,
    buttonColor: '#57068b',
    backgroundColor: '#57068b',
    allowGoogle: false,
    allowEmail: true,
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-nyu9238uyfeqwo0i%2Fnyu_custom_page_logo.png?alt=media&token=89aad084-52f4-4672-a7bb-84646e03184e'
  },
  lunduniversity: {
    active: false,
    allowGoogle: false,
    allowEmail: true,
    buttonColor: '#466197',
    backgroundColor: '#f9f8f4',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-lunduniversity-839x203-12a219332%2Flund_custom_page_logo.png.svg?alt=media&token=d066633b-08d2-44b7-bfb9-84f09b97ee51'
  },
  cbs: {
    active: true,
    name: 'Copenhagen Business School',
    allowGoogle: false,
    allowEmail: true,
    buttonColor: '#4967aa',
    backgroundColor: '#f7f7f7',
    organization: '-cbs12981hdnc-ou8eoiu',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-cbs12981hdnc-ou8eoiu%2Fcbs_custom_page_logo.png?alt=media&token=e6525c3b-627f-4f7b-875a-f7e4729b439d',
    provider: 'saml.wayf',
    xml: 'https://metadata.wayf.dk/wayf-metadata.xml'
  },
  aau: {
    active: true,
    name: 'Aalborg Universitet',
    allowGoogle: false,
    allowEmail: true,
    buttonColor: '#20224d',
    backgroundColor: '#f7f7f7',
    organization: '-aau7g982022nc-ou9fUgk',
    provider: 'saml.wayf',
    xml: 'https://metadata.wayf.dk/wayf-metadata.xml',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-aau7g982022nc-ou9fUgk%2Faau_custom_page_logo.png?alt=media&token=2a7cff45-cec5-4fa0-8971-5de9c5c3a399'
  },
  hhs: {
    active: true,
    name: 'HHS',
    allowEmail: true,
    buttonColor: '#393939',
    backgroundColor: '#4b4b4b',
    organization: '-hhsstockholm234feqwoiqj',
    provider: 'saml.unkown',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-hhsstockholm234feqwoiqj%2Fhhs_custom_page_logo.jpg?alt=media&token=6fb58aaf-d37c-43ee-9157-0d43b363698e'
  },
  colostate: {
    active: true,
    name: 'Colorado State',
    allowEmail: true,
    buttonColor: '#a0540f',
    backgroundColor: '#1e4d2b',
    organization: '-colostate0192u3uohcd',
    provider: 'saml.unkown',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-colostate0192u3uohcd%2Fcolostate_custom_page_logo.png?alt=media&token=49a13b1b-b99c-449e-8ab0-aea2d02d0181'
  },
  lse: {
    active: true,
    name: 'LSE',
    allowEmail: true,
    buttonColor: '#e73736',
    backgroundColor: '#ec1c2e',
    organization: '-lseoioiw128uihadq8',
    provider: 'saml.unkown',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-lseoioiw128uihadq8%2Flse_custom_page_logo.jpeg?alt=media&token=3942ab58-4f3c-4274-a2bf-2e86a574a643'
  },
  qpl: {
    active: true,
    name: 'Queens Public Library',
    allowEmail: true,
    buttonColor: 'rgb(102 45 140)',
    backgroundColor: '#FFF',
    organization: '-queenspubliclibrary092u3as',
    provider: 'saml.unkown',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-queenspubliclibrary092u3as%2Fqueens_public_library_custom_page_logo.png?alt=media&token=3648e121-71ab-439c-a6ba-8725180f6325'
  },
  alboum: {
    active: true,
    name: 'Alboum Translation',
    allowEmail: true,
    buttonColor: '#0d4e7e',
    backgroundColor: '#fbf9f3',
    organization: '-alboum1209ijeuid0w',
    provider: 'saml.unkown',
    logo: 'https://firebasestorage.googleapis.com/v0/b/konch-eu.appspot.com/o/Organizations%2F-alboum1209ijeuid0w%2FAlboum-Logo-800.png?alt=media&token=f7be2316-4ca0-4c2b-b27a-f9818556547d'
  }
};