console.log(`
Welcome to...
██╗  ██╗ ██████╗ ███╗   ██╗ ██████╗██╗  ██╗
██║ ██╔╝██╔═══██╗████╗  ██║██╔════╝██║  ██║
█████╔╝ ██║   ██║██╔██╗ ██║██║     ███████║
██╔═██╗ ██║   ██║██║╚██╗██║██║     ██╔══██║
██║  ██╗╚██████╔╝██║ ╚████║╚██████╗██║  ██║
╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝╚═╝  ╚═╝
Ask questions or join our team via help@konch.ai

`);

/* global localStorage */
const LOCAL = 'local';
const DEV = 'dev';
const STAGE = 'stage';
const PROD = 'prod';
const TEST = 'test';
const FB_PROD = 'konch-eu';
const FB_DEV = 'konch-dev-eu';
const FB_STAGE = 'konch-stage';
const FB_GITSTART = 'konch-gitstart';

export const { FUNC_LOCAL_DEV, NODE_ENV, ENV, FB_EMULATE } = process?.env;
const FB_PORT = 8081;
export const LOCAL_FB_FUNC_URL = `http://localhost:${FB_PORT}`;

// debug more on/off
const DM = '+92yhd98u12iojdqwiojao9u==';
export const isDM = localStorage.getItem(DM);

// allow impersonate
const IMP = 'beaab+8748347889dd320d267.';
export const isIMP = localStorage.getItem(IMP);

// allow corrector admin
const AAK = 'BQ2hGU-8U4TfPtC8y';
export const hasAAK = localStorage.getItem(AAK);

export const paygPrice = 8.0; // $8 per hour
export const paygPricePerMin = paygPrice / 60.0;

// precision we are cached up for a quick response
export const EASY_PRECISION = ['en', 'es'];

export const getVersion = (short) => {
  return short ? process?.env.BUILD_VERSION_SHORT : process?.env.BUILD_VERSION;
};

export const getRelease = () => {
  return `konch-www@${getVersion()}`;
};

export const getCurrentEnv = () => {
  if (ENV === LOCAL) return LOCAL;
  if (process?.env.GCLOUD_PROJECT === FB_STAGE) return STAGE;
  if (process?.env.GCLOUD_PROJECT === FB_PROD) return PROD;
  return DEV;
};

export const currentEnv = getCurrentEnv();

export const isLocal = () => getCurrentEnv() === LOCAL;

export const isDev = () => getCurrentEnv() === DEV;

export const isStage = () => getCurrentEnv() === STAGE;

export const isProd = () => getCurrentEnv() === PROD;

if (isProd() && !isDM) {
  console.log = () => {};
  console.debug = () => {};
}

export const isTest = () => NODE_ENV === TEST;

export const SEGMENT_KEY = isProd()
  ? 'MFdrYPwmhdnVrvPwJAfh74nJBjr9MqJV'
  : 'mKXMZyHmQN9FUBzqe92x8FYd6FfpAkZy';

export const GA_TRACKING_ID = isProd() ? 'UA-129112571-4' : 'UA-179601044-1';
export const FB_TRACKING_ID = isProd() ? '177321396896677' : '671961220101194';

// to make Anders sleep better, we are hard coding this restriction. Sorry for the problem this caused ;-D
export const getIsUni = (oid) => {
  const isDEiC = oid === '-deicunikeyfororg09252018';
  const isCBS = oid === '-cbs12981hdnc-ou8eoiu';
  const isAAU = oid === '-aau7g982022nc-ou9fUgk';
  const devTestUni = oid === 'c5a109bc-618e-495b-ba91-a61213e7b8e3';
  return isDEiC || isAAU || isCBS || devTestUni;
};

// I Dont think this is used any more
// const WEB_URL_OPTIONS = {
//   [PROD]: 'https://app.konch.ai',
//   [DEV]: 'https://dev.app.konch.ai',
//   [LOCAL]: 'http://localhost:3001'
// }
// export const WEB_URL = WEB_URL_OPTIONS[getCurrentEnv()]
//
export const PROD_URL = 'https://app.konch.ai';

/* ********************** */
/* FIREBASE FUNCTIONS     */
/*                        */
/*   VVVVVVVVVVVVVV       */

export const getFirebaseInstance = () => {
  if (process?.env.GCLOUD_PROJECT === FB_PROD) return FB_PROD;
  if (process?.env.GCLOUD_PROJECT === FB_GITSTART) return FB_GITSTART;
  if (process?.env.GCLOUD_PROJECT === FB_STAGE) return FB_STAGE;
  return FB_DEV;
};

export const FB_REGION = 'europe-west3';

const FIREBASE_KEY_OPTIONS = {
  [FB_PROD]: process?.env.FIREBASE_KEY_PROD,
  [FB_STAGE]: process?.env.FIREBASE_KEY_STAGE,
  [FB_GITSTART]: process?.env.FIREBASE_KEY_GITSTART,
  [FB_DEV]: process?.env.FIREBASE_KEY_DEV
};
export const FIREBASE_KEY = FIREBASE_KEY_OPTIONS[getFirebaseInstance()];

export const FIREBASE_CONFIG = {
  apiKey: FIREBASE_KEY,
  authDomain: `${getFirebaseInstance()}.firebaseapp.com`,
  databaseURL: `https://${getFirebaseInstance()}.firebaseio.com`,
  storageBucket: `${getFirebaseInstance()}.appspot.com`,
  projectId: getFirebaseInstance(),
  locationId: FB_REGION
};

const FUNCTIONSBASE_URL = `https://${FB_REGION}-${getFirebaseInstance()}.cloudfunctions.net`;

const FUNCTIONS_URL_OPTIONS = {
  [PROD]: FUNCTIONSBASE_URL,
  [DEV]: FUNC_LOCAL_DEV
    ? `${LOCAL_FB_FUNC_URL}/${getFirebaseInstance()}/${FB_REGION}`
    : FUNCTIONSBASE_URL,
  [STAGE]: FUNC_LOCAL_DEV
    ? `${LOCAL_FB_FUNC_URL}/${getFirebaseInstance()}/${FB_REGION}`
    : FUNCTIONSBASE_URL,
  [LOCAL]: `${LOCAL_FB_FUNC_URL}/${getFirebaseInstance()}/${FB_REGION}`
};
// console.log(FUNCTIONS_URL_OPTIONS)
// console.log(getCurrentEnv())
export const FUNCTIONS_URL = FUNCTIONS_URL_OPTIONS[getCurrentEnv()];

export const GOOGLE_DRIVE_CLIENT_ID = process?.env.GOOGLE_DRIVE_CLIENT_ID;
export const GOOGLE_DRIVE_DEV_KEY = process?.env.GOOGLE_DRIVE_DEV_KEY;
export const DROPBOX_APP_KEY = process?.env.DROPBOX_APP_KEY;
export const WEBHOOK_HANDLER_URL = FUNCTIONS_URL + '/process?Webook';

/*     ^^^^^^^^^^^^^^     */
/*                        */
/* END FIREBASE FUNCTIONS */
/* ###################### */

/* *****************  */
/* KNCH.IO API        */
/*                    */
/*   VVVVVVVVVVVVVV   */

export const getCurrentAPIEnv = () => {
  if (process?.env.API === PROD) return PROD;
  if (process?.env.API === LOCAL) return LOCAL;
  else return DEV;
};

export const TRANSCRIBER_OID = {
  'konch-eu': '-konchtranscribers',
  'konch-dev-eu': 'ed3cac2f-2000-4e70-b559-b51eacf0e21f'
}[getFirebaseInstance()];
export const getApiUrl = (locale) => {
  // console.log('getApiUrl', locale)
  const subdomain = locale === 'eu' ? 'eu.' : '';
  // console.log('getApiUrl subdomain', subdomain)
  return {
    [PROD]: `https://${subdomain}knch.io`,
    [DEV]: `https://${subdomain}dev.knch.io`,
    [LOCAL]: 'http://localhost:3000'
  }[getCurrentAPIEnv()];
};

const ASSETS_BUCKET_OPTIONS = {
  [PROD]: 'assets.knch.io',
  [DEV]: 'dev.assets.knch.io',
  [LOCAL]: 'dev.assets.knch.io'
};
export const ASSETS_BUCKET = ASSETS_BUCKET_OPTIONS[getCurrentAPIEnv()];

/*   ^^^^^^^^^^^^^^   */
/*                    */
/* END KNCH.IO API    */
/* #################  */

/*        */
/* STRIPE */
export const STRIPE_PK = isProd()
  ? process?.env.STRIPE_PK
  : process?.env.STRIPE_PK_TEST;

export const STRIPE_PAYG_PRICE = {
  [PROD]: 'price_1JEkFIHIgcgEnRzJ85Zb49JR',
  [DEV]: 'price_1J9irdHIgcgEnRzJrw4mEjFF',
  [STAGE]: 'price_1J9irdHIgcgEnRzJrw4mEjFF'
}[getCurrentAPIEnv()];

export const STRIPE_PAYG_PRICE_10 = {
  [PROD]: 'price_1MroGuHIgcgEnRzJ9tAFHKVj',
  [DEV]: 'price_1MqkwTHIgcgEnRzJDLz9361O',
  [STAGE]: 'price_1J9irdHIgcgEnRzJrw4mEjFF'
}[getCurrentAPIEnv()];

// $13 per 1000 credits
export const STRIPE_PAYG_PRICE_13 = {
  [PROD]: 'price_1PURHQHIgcgEnRzJ0jQTcElu',
  [DEV]: 'price_1PURRQHIgcgEnRzJQVOoC9ld',
  [STAGE]: 'price_1J9irdHIgcgEnRzJrw4mEjFF'
}[getCurrentAPIEnv()];

export const STRIPE_PAYG_PRICE_5 = {
  [PROD]: 'price_1MsWMiHIgcgEnRzJj4iAIxAr',
  [DEV]: 'price_1MsBWjHIgcgEnRzJaFRTkAFA',
  [STAGE]: 'price_1J9irdHIgcgEnRzJrw4mEjFF'
}[getCurrentAPIEnv()];

export const STRIPE_SUBSCRIBE = {
  [PROD]: 'price_1NijcWHIgcgEnRzJk5BXdziB',
  [DEV]: 'price_1NijQ8HIgcgEnRzJ7mN7tVYV'
}[getCurrentAPIEnv()];

// LATEST PRICING PLANS
export const STRIPE_BASIC_PLAN = {
  monthly: {
    [PROD]: 'price_1P4SSEHIgcgEnRzJMQ8UVddo',
    [DEV]: 'price_1P4S85HIgcgEnRzJ5HtyAKJw'
  }[getCurrentAPIEnv()],
  yearly: {
    [PROD]: 'price_1PUR9CHIgcgEnRzJpFk2uEsn',
    [DEV]: 'price_1P4S8zHIgcgEnRzJNZFP61cR'
  }[getCurrentAPIEnv()]
};

export const STRIPE_PRO_PLAN = {
  monthly: {
    [PROD]: 'price_1P4oymHIgcgEnRzJ5b3sEqnB',
    [DEV]: 'price_1P4S9kHIgcgEnRzJicImrn5W'
  }[getCurrentAPIEnv()],
  yearly: {
    [PROD]: 'price_1P4p00HIgcgEnRzJxMC5lx9P',
    [DEV]: 'price_1P4SASHIgcgEnRzJD0FKZ8KH'
  }[getCurrentAPIEnv()]
};
// END OF LATEST PRICING PLANS

if (
  (process?.env.NODE_ENV !== 'production' &&
    process?.env.NODE_ENV !== 'test') ||
  isDM
) {
  console.log('PROCESS? ENV', process?.env);
  console.log({
    FUNCTIONS_URL,
    FUNCTIONSBASE_URL,
    SEGMENT_KEY,
    API: getApiUrl()
  });
  console.log('FIREBASE_CONFIG', FIREBASE_CONFIG);
}

export default getCurrentEnv;
