import {useSelector} from 'react-redux';
import {useFirestoreConnect, useFirestore} from 'react-redux-firebase';
import {
  selectProfile,
  selectSettings,
  getEmailKey,
  selectOrganizations,
  selectProjectList,
  selectOrganization,
  selectInvitedOrganizations
} from 'features/profile';
import {v4 as uuidv4} from 'uuid';

import {
  addWelcomeCollection as addWelcomeCollectionApi
} from 'api/user';

import {updateEmail as updateEmailAction, firebaseAuth, addCorrectorLanguage as addCorrectorLanguageAction} from 'utils/Firebase';

export default () => {
  const firestore = useFirestore();

  const profile = useSelector(state => selectProfile(state)) || {};
  const {organization: oid, uid, email} = profile || {};
  const settings = useSelector(state => selectSettings(state, uid)) || {};
  const currentProject = (settings?.project || {})[oid] || undefined;

  // get orgs for user
  const queryOranizationsWithUser = uid ? {collection: 'Organizations', where: [[`users.${uid}`, '==', true]]} : [];
  useFirestoreConnect(queryOranizationsWithUser);
  // get orgs for admins of not users of orgs
  const queryOranizationsWithOwner = uid ? {collection: 'Organizations', where: [[`owners.${uid}`, '==', true]], storeAs: 'OwnerOrganizations'} : [];
  useFirestoreConnect(queryOranizationsWithOwner);

  const indexSafeEmail = email ? getEmailKey(email).toLowerCase() : undefined;
  const invitedToOrgs = email ? {collection: 'Organizations', where: [[`invites.${indexSafeEmail}`, '!=', '']], storeAs: 'invitedTo'} : [];
  useFirestoreConnect(invitedToOrgs);

  const projects = useSelector(state => selectProjectList(state, oid));

  const organization = useSelector(state => selectOrganization(state, oid));

  // console.log('organization.owners', organization.owners, uid)

  const flagInOrgOwners = (organization.owners || {})[uid];

  // console.log({flagInOrgOwners})

  const isOwner = flagInOrgOwners === true;
  const isAdmin = flagInOrgOwners !== undefined;
  profile.isOwner = isOwner;
  profile.isAdmin = isAdmin;

  // console.log({isOwner, profile})

  const {billing = {}} = organization;
  const isEnterprise = billing.enterprise;
  const organizations = useSelector(state => selectOrganizations(state, uid)) || [];
  const invites = useSelector(state => selectInvitedOrganizations(state, email));

  const updateSettings = newSettings => {
    try {
      // const newSettings = Object.assign({}, settings, setting)
      const updateSettings = Object.entries(newSettings).reduce((acc, [id, data]) => {
        acc[`settings.${id}`] = data;
        return acc;
      },{});
      // console.log({updateSettings})
      firestore.collection('Users').doc(uid).update(updateSettings);
    } catch (error) {
      console.log('Could not update settings', error);
    }
  };

  const updateIntegration = (app, setting, data) => {
    updateSettings({[`integrations.${app}.${setting}`]: data});
  };

  const setDefaultLanguage = (defaultLanguage) => {
    console.log({defaultLanguage});
    updateSettings({defaultLanguage});
  };

  const setCurrentProject = (newProject) => {
    const project = currentProject === newProject ? '' : newProject;
    firestore.collection('Users').doc(uid).update({[`settings.project.${oid}`]: project});
  };
  const addCorrectorLanguage = ({lang, uid}) => addCorrectorLanguageAction({lang, uid});

  const updateName = name => firestore.collection('Users').doc(uid).update({name});
  const updateAddress = address => firestore.collection('Users').doc(uid).update({address});
  const updateCorrectorPayment = correctorPayment => updateSettings({correctorPayment});
  const showWelcome = show => firestore.collection('Users').doc(uid).update({'settings.showWelcome': show});

  const updateEmail = email => updateEmailAction(email);

  const currentFBUser = firebaseAuth ? firebaseAuth().currentUser : undefined;
  const isLoaded = organization?.isLoaded && profile?.isLoaded;

  const needsEmailVerified = isLoaded && !billing?.enterprise && currentFBUser.email && !currentFBUser?.emailVerified;

  // console.log({organization})

  const switchOrganization = async organization => {
    await firestore.collection('Users').doc(uid).update({organization});
    await firestore.collection('Users').doc(uid).update({[`organizations.${organization}`]: true});
    window.Beacon('identify', {oid: organization});
  };

  const syncProfileOrganizations = () => {
    console.log('sync.', uid);
    if (organizations.length === 0) return;
    console.log('syncing..');
    const orgs = organizations.reduce((acc, org) => {
      acc[org.id || org.uid] = true;
      return acc;
    },{});
    console.log('syncing', orgs);
    return firestore.collection('Users').doc(uid).update({organizations: orgs});
  };

  const declineInvite = (orgId) => {
    firestore.collection('Organizations').doc(orgId).update({
      [`invites.${indexSafeEmail}`]: firestore.FieldValue.delete()
    });
  };

  const acceptInvite = async (orgId) => {
    await firestore.collection('Organizations').doc(orgId).update({
      [`invites.${indexSafeEmail}`]: firestore.FieldValue.delete(),
      [`users.${uid}`]: true
    });
    await firestore.collection('Users').doc(uid).update({
      [`organizations.${orgId}`]: true,
      organization: orgId
    });
    addWelcomeCollectionApi({uid, oid: orgId});
  };

  const addProject = async () => {
    const uuid = uuidv4();
    // random new color
    const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
    const created = new Date().getTime();
    await firestore.collection('Users').doc(uid).update({
      [`projects.${oid}.${uuid}`]: {name: `Project ${projects.length + 1}`, color, created}
    });
    return uuid;
  };

  const updateProject = ({color, name, id}) => {
    if (!id) return;
    firestore.collection('Users').doc(uid).update({
      [`projects.${oid}.${id}.name`]: name
    });
    firestore.collection('Users').doc(uid).update({
      [`projects.${oid}.${id}.color`]: color
    });
  };

  const removeProject = (id) => {
    if (!id) return;
    if (window.confirm('Are you sure you want to remove this project?') === true) {
      firestore.collection('Users').doc(uid).update({
        [`projects.${oid}.${id}`]: firestore.FieldValue.delete()
      });
    }
  };

  const correctLangCode = (settings?.correctLangCode || 'en').split('-')[0];
  return {
    acceptInvite,
    addCorrectorLanguage,
    addProject,
    billing,
    correctLangCode,
    correctLangCodes: settings?.correctLangCodes || [correctLangCode || 'en'],
    correctorPayment: settings?.correctorPayment,
    integrations: settings?.integrations,
    declineInvite,
    email,
    invites,
    isAdmin,
    currentFBUser,
    needsEmailVerified,
    isOnProbation: profile?.isOnProbation,
    isCorrector: profile?.isCorrector,
    isEnterprise,
    isKonchEmp: email ? !!(email || '').split('@').slice(1).join('').match(/^(konch\.ai|ops\.getwingapp\.com|gitstart.com)$/i) : false,
    isLoaded,
    isOwner,
    oid,
    organization,
    organizations,
    profile,
    projects,
    removeProject,
    setDefaultLanguage,
    setCurrentProject,
    currentProject,
    settings,
    showWelcome,
    switchOrganization,
    syncProfileOrganizations,
    uid,
    updateAddress,
    updateEmail,
    updateName,
    updateCorrectorPayment,
    updateSettings,
    updateProject,
    updateIntegration
  };
};
