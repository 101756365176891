import React from 'react';
import Confetti from 'react-confetti';

export default (props = {}) => {
  const {startPeicesNum = 200, lastSecs = 3, redo} = props || {};
  const {width, height} = [window.innerWidth, window.innerHeight];
  const [run, setRun] = React.useState(startPeicesNum);

  React.useEffect(() => {
    setRun(startPeicesNum + redo);
    const timer = setTimeout(() => {
      setRun(0);
    }, lastSecs * 1000);
    return () => clearTimeout(timer);
  }, [redo]);

  return (
    <Confetti
      width={width}
      height={height}
      numberOfPieces={run}
    />
  );
};