import {
  createAsyncThunk,
  createEntityAdapter,
  // createSelector,
  createSlice
} from '@reduxjs/toolkit';
import {fetchAll} from 'api/docTags';

import {selectUid} from 'features/auth';

// Actions
export const fetchDocTags = createAsyncThunk('docTags/fetchAll', async (args, {getState}) => {
  const state = getState();
  const uid = selectUid(state);
  const response = await fetchAll(uid);

  return response.data;
});

// Slice
export const docTagsAdapter = createEntityAdapter();
const initialState = docTagsAdapter.getInitialState({});
export const slice = createSlice({
  name: 'docTags',
  initialState
});

const reducer = slice.reducer;
export default reducer;

// Selectors
const sliceSelector = state => state.docTags;
const {
  selectIds
} = docTagsAdapter.getSelectors(sliceSelector);
export const selectAllDocTags = selectIds;
