import React, { useContext } from 'react';
import styled from 'styled-components';

import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { Modal as ModalCard } from 'components/common/Overlay';
import Spinner from 'components/common/Spinner';
import useCollection from 'hooks/useCollection';
import useOrganization from 'hooks/useOrganization';
import usePricing from 'hooks/usePricing';
import LearnMore from 'components/common/LearnMore';
import { trackCustom } from 'utils/Tracker';
import usePlan from 'hooks/usePlan';
import useSubscriptions from 'hooks/useSubscriptions';
import { FeaturesContext } from 'providers/FeaturesProvider';

const UpgradeContent = styled.div`
  height: 100vh;
  display: flex;
  .upgrade-text {
    display: inline-block;
    text-align: right;
    white-space: nowrap;
  }
  .MuiButton-root.upgrade {
    min-width: 320px;
    padding-right: 0;
    background-color: ${({ upgrading }) => (upgrading ? 'lightgrey' : 'green')};
    &.small-shake {
      background-color: lightgrey;
    }
    color: #fff;
    :hover {
      background-color: #06a406;
    }
  }
  .fine-print {
    margin-top: 20px;
    margin-top: 20px;
    text-align: right;
    font-size: 13px;
    p {
      color: #bbb;
      font-size: 0.75em;
      text-align: right;
      line-height: 1.7em;
      margin: 0;
    }
  }
`;

export const UpgradeModal = ({ id, onClose }) => {
  const {
    onUpgrade,
    language,
    locale,
    minutes,
    collection: { orgId: oid, tTranslation }
  } = useCollection({ id });
  const { pricePerMin, pricePerMinStr, priceStr } = usePricing({
    language,
    type: tTranslation ? 'translation' : 'transcription',
    minutes
  });
  const { hasSubscription } = useContext(FeaturesContext);
  const { billing } = useOrganization({ oid });
  const { credits, usage, fetchCredits, fetchUsage, loading, loadingUsage } =
    usePlan({ oid });
  const { fetchPaymentMethods, paymentMethods } = useSubscriptions({ oid });
  const [needsTopUp, setNeedsTopUp] = React.useState();

  const { enterprise, customer } = billing || {};

  const [upgrading, setUpgrading] = React.useState(false);
  const [upgradeMessage, setUpgradeMessage] = React.useState();

  console.log({ credits, usage, paymentMethods });

  const handleUpgrade = async () => {
    if (upgrading) return;

    if (
      credits === 0 &&
      !usage?.hasPrecision &&
      (paymentMethods || []).length === 0 &&
      !billing?.paymentMethods
    ) {
      console.log('No pay');
      setUpgrading(false);
      setTimeout(() => setNeedsTopUp(true), 300);
      return;
    }

    setUpgrading(true);
    trackCustom({
      category: 'User',
      action: 'Attempt Upgrade to Precision',
      data: { oid, language, locale }
    });

    setTimeout(() => {
      setUpgradeMessage('Processing may take a minute. Do not exit this page.');
    }, 3000);
    await onUpgrade();
    setUpgrading(false);
    onClose();
  };

  React.useEffect(() => {
    trackCustom({
      category: 'User',
      action: 'Opened Precision Upgrade Modal',
      data: { oid, language, locale }
    });
    if (credits === null) fetchCredits();
    if (usage === {}) fetchUsage();
  }, []);

  React.useEffect(() => {
    if (customer && paymentMethods === null) fetchPaymentMethods(customer);
  }, [customer]);

  console.log({ pricePerMin });

  return (
    <Modal open={true} onClose={onClose}>
      <UpgradeContent>
        <ClickAwayListener onClickAway={onClose}>
          <ModalCard
            style={{
              background: '#FFF',
              textAlign: 'left',
              lineHeight: 2,
              paddingBottom: 0
            }}
          >
            <Box style={{ width: '100%', padding: '0 20px 0' }}>
              <h3>Precision Upgrade</h3>
              <div style={{ margin: 0 }}>
                Improve the accuracy and clarity of your transcripts with our
                certified human experts. Our service carefully refines your
                transcripts to enhance accuracy, streamline speaker segments,
                and include important non-verbal cues
                {enterprise ? (
                  '.'
                ) : !pricePerMin ? (
                  <Spinner small inline style={{ marginLeft: 10 }} />
                ) : (
                  `, for just $${pricePerMinStr} per minute of this file. Enjoy quick results with a typical turnaround time of 24 hours.`
                )}
              </div>
              <div style={{ textAlign: 'right', minHeight: 32, color: '#888' }}>
                <small>{upgradeMessage}</small>
              </div>
              <div className="actions-cont" style={{ marginTop: 0 }}>
                <Grid container justifyContent="flex-end" spacing={0}>
                  {needsTopUp ? (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{
                        textAlign: 'right',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: 10
                      }}
                    >
                      <Button
                        onClick={() => {
                          window.Beacon('prefill', {
                            subject: 'Issue upgrading to precision',
                            text: ''
                          });
                          window.Beacon('open');
                          window.Beacon('navigate', '/ask/message');
                        }}
                        disableElevation
                        style={{ marginRight: 10 }}
                      >
                        Help
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        href="/plans"
                      >
                        Top Up
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={4} />
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{ textAlign: 'right', marginBottom: 10 }}
                  >
                    <Button
                      className={['upgrade', needsTopUp ? 'small-shake' : '']}
                      onClick={handleUpgrade}
                      variant="contained"
                      disabled={upgrading || !pricePerMin}
                      disableElevation
                    >
                      <div style={{ width: 15 }} />
                      <div>
                        <span
                          className="upgrade-text"
                          data-testid="upgrade-text"
                        >
                          {/* {upgrading ? 'Upgrading' : 'Upgrade'} to Precision */}
                          {upgrading
                            ? 'Upgrading to Precision'
                            : 'Confirm upgrade for'}
                        </span>
                        {enterprise ? (
                          ''
                        ) : !pricePerMin ? (
                          <Spinner
                            small
                            light
                            inline
                            style={{ marginLeft: 10 }}
                          />
                        ) : (
                          ` $${pricePerMin ? priceStr : <Spinner small />}`
                        )}
                      </div>
                      <div
                        style={{
                          width: 45,
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        {upgrading && <Spinner light small />}
                      </div>
                    </Button>
                  </Grid>
                </Grid>
              </div>

              {!enterprise && !hasSubscription && (
                <div className="fine-print">
                  <LearnMore
                    light
                    icon
                    text="How we process payments"
                    article="6171eb35efc78d0553e5231b"
                  />
                </div>
              )}
              <div
                style={{ width: '100%', margin: '15px 0 5px 0', minHeight: 20 }}
              >
                {upgrading || loading || loadingUsage ? (
                  <LinearProgress style={{ width: '100%' }} />
                ) : (
                  ''
                )}
              </div>
            </Box>
          </ModalCard>
        </ClickAwayListener>
      </UpgradeContent>
    </Modal>
  );
};

export default UpgradeModal;
