import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  current
} from '@reduxjs/toolkit';
import _get from 'lodash/get';
// import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex';
import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';
import _indexOf from 'lodash/indexOf';
import _last from 'lodash/last';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _slice from 'lodash/slice';
import _some from 'lodash/some';
import _sortBy from 'lodash/sortBy';
import { getTimeStamp } from 'components/common/Timer/convert-time';
import {
  addToProject as addToProjectApi,
  fetch,
  fetchAll,
  search,
  fetchSubtitles as fetchSubtitlesApi,
  rename as renameApi,
  description as updateDescriptionApi,
  notes as updateNotesApi,
  updateLanguage as updateLanguageApi,
  remove as deleteApi,
  translate as translateApi,
  checkin as checkinApi,
  share as shareApi,
  owner as changeOwnerApi,
  retry as retryApi,
  unPause as unPauseApi,
  recode as recodeApi,
  upgradeToPrecision,
  convertToBlog as convertToBlogAPI,
  recoverVersion as recoverVersionApi,
  marker as markerApi,
  audioVersions as audioVersionsApi,
  denoise as denoiseApi,
  getContent as getContentApi,
  saveContent as saveContentApi
} from 'api/collections';
import { createTextCollection as createTextCollectionApi } from 'api/pipeline';

import {
  // fetchTranscript as fetchTranscriptApi,
  fetchSubtitles as fetchSubtitleFileApi
} from 'api/transcripts';

import { addWelcomeCollection as addWelcomeCollectionApi } from 'api/user';

import { selectUid } from 'features/auth';
import { selectProfile } from 'features/profile';
import { selectCollectionHighlights } from 'features/highlights';
import { selectCollectionSpeakers, selectSpeakerIds } from 'features/speakers';
import { selectCollectionWords } from 'features/transcripts';

import { toast } from 'components/common/Toaster';

import { getStripePortalSession } from 'utils/Firebase';

// Slice
export const collectionsAdapter = createEntityAdapter();
const initialState = collectionsAdapter.getInitialState({
  loading: [],
  loadingPage: false,
  nextPageStart: {},
  shareModal: null,
  pendingRequests: [],
  searchTerm: '',
  searchResults: {}
});

// Selectors
const sliceSelector = (state) => state.collections;
const { selectById } = collectionsAdapter.getSelectors(sliceSelector);

export const selectCollectionById = selectById;

export const selectIsCollectionLoading = createSelector(
  [sliceSelector, (_, id) => id],
  ({ loading }, id) => loading.includes(id)
);

export const selectIsCollectionOwner = createSelector(
  [selectCollectionById, selectUid],
  ({ userId, _ownerOverride } = {}, uid) =>
    _ownerOverride || (userId && userId === uid)
);

export const selectCollections = createSelector(
  [sliceSelector],
  ({ entities }) =>
    Object.entries(entities || {}).map(([id, data]) => ({ id, ...data }))
);

export const selectSearchedResults = createSelector(
  [sliceSelector],
  ({ searchResults }) =>
    Object.entries(searchResults || {}).map(([id, data]) => ({ id, ...data }))
);

export const selectOrgCollections = createSelector(
  [
    selectCollections,
    ({
      collections,
      firebase: {
        profile: { organization }
      }
    }) => ({ oid: organization })
  ],
  (collections, { oid }) => collections.filter((c) => c.orgId === oid)
);

export const selectUserCollections = createSelector(
  [
    selectOrgCollections,
    ({
      collections,
      firebase: {
        auth: { uid }
      }
    }) => ({ uid })
  ],
  (collections, { uid }) => collections.filter((c) => c.userId === uid)
);

export const selectSharedCollections = createSelector(
  [
    selectOrgCollections,
    ({
      firebase: {
        auth: { uid }
      }
    }) => uid
  ],
  (collections, uid) => collections.filter((c) => (c.sharedWith || {})[uid])
);

export const selectSearchedCollections = createSelector(
  [
    selectSearchedResults,
    ({
      firebase: {
        auth: { uid }
      }
    }) => uid
  ],
  (collections, uid) => collections.filter((c) => c.userId === uid)
);

export const selectSearchededOrgCollections = createSelector(
  [
    selectSearchedResults,
    ({
      collections,
      firebase: {
        profile: { organization }
      }
    }) => ({ oid: organization })
  ],
  (collections, { oid }) => collections.filter((c) => c.orgId === oid)
);

export const selectCollectionVersionId = createSelector(
  [selectCollectionById],
  ({ version = null } = {}) => version || null
);

export const selectTranscriptCompletionMeta = createSelector(
  [selectCollectionById],
  ({ tCompletion } = {}) => tCompletion || {}
);

export const selectCollectionIsPaused = createSelector(
  [selectCollectionById],
  ({ paused = false } = {}) => paused
);

export const selectCollectionProgress = createSelector(
  [selectCollectionById],
  ({ tProgress = 0 } = {}) => tProgress
);

export const selectCollectionTCompletion = createSelector(
  [selectCollectionById],
  ({ tCompletion = {} } = {}) => tCompletion
);

export const selectCollectionDraftComplete = createSelector(
  [selectCollectionTCompletion],
  ({ draft } = {}) => draft
);

export const selectCollectionAudioVersions = createSelector(
  [selectCollectionById],
  ({ audioVersions } = {}) => audioVersions
);

export const selectCollectionRecode = createSelector(
  [selectCollectionTCompletion],
  ({ recode } = {}) => recode
);

export const selectCollectionTranslating = createSelector(
  [selectCollectionById],
  ({ tTranslation } = {}) => tTranslation
);

export const selectCollectionPeaksUrl = createSelector(
  [selectCollectionById],
  ({ peaks } = {}) => peaks
);

export const selectCollectionLanguage = createSelector(
  [selectCollectionById],
  ({ languageCode } = {}) => languageCode || ''
);

export const selectCollectionLocale = createSelector(
  [selectCollectionById],
  ({ locale } = {}) => locale || 'us'
);

export const selectIsCollectionDemo = createSelector(
  [selectCollectionById],
  ({ demo } = {}) => !!demo
);

export const selectCollectionName = createSelector(
  [selectCollectionById],
  ({ name } = {}) => name
);

export const selectCollectionDescription = createSelector(
  [selectCollectionById],
  ({ description } = {}) => description
);

export const selectCollectionNotes = createSelector(
  [selectCollectionById],
  ({ notes } = {}) => notes
);

export const selectCollectionSharedWith = createSelector(
  [selectCollectionById],
  ({ sharedWith } = {}) => sharedWith
);

export const selectCollectionOwner = createSelector(
  [
    selectCollectionById,
    selectProfile,
    (state) => (state.users ? state.users.entities : {})
  ],
  ({ userId } = {}, profile, users) => {
    if (!userId) return null;
    if (profile.uid === userId) return profile;
    return users[userId];
  }
);

export const selectLockedForEditing = createSelector(
  [selectCollectionById, selectUid],
  ({ editing } = {}, uid) => (editing && editing !== uid ? editing : undefined)
);

export const selectCollectionTranscriptionStarted = createSelector(
  [selectCollectionById],
  ({ transcriptionStarted } = {}) => transcriptionStarted
);

export const selectCollectionStarted = createSelector(
  [selectCollectionById],
  ({ started } = {}) => started
);

export const selectCollectionPerfectionProcessing = createSelector(
  [selectCollectionTCompletion],
  ({ perfection } = {}) => perfection === false
);

export const selectCollectionSpeakersArray = createSelector(
  [selectCollectionSpeakers],
  (speakers) => _sortBy(_keys(speakers), parseFloat)
);

export const selectCollectionLogs = createSelector(
  [selectCollectionById],
  ({ id, errors = [], logs = [] } = {}) => {
    // de-dupe message and skip admin errors
    const all = errors.concat(logs);
    const uniqueMessages = {};
    for (let item of all) {
      if (!item.isAdmin) uniqueMessages[item.details] = item;
    }
    return Object.values(uniqueMessages).sort(
      (a, b) => a.timestamp - b.timestamp
    );
  }
);

export const generateSelectorForWordObjectsBySectionId = () =>
  createSelector(
    [
      selectCollectionWords,
      selectCollectionSpeakersArray,
      (_, __, sectionId) => sectionId
    ],
    (words = [], speakers = [], sectionId) => {
      const sectionIdFloat = parseFloat(sectionId);
      const speakerIds = _map(speakers, parseFloat);
      const nextSectionIndex = _indexOf(speakerIds, sectionIdFloat) + 1;
      const nextSectionId = speakerIds[nextSectionIndex];
      const startIndex = _findIndex(
        words,
        ({ start_time: startTime }) => startTime >= sectionIdFloat
      );
      const endIndex = _findIndex(
        words,
        ({ start_time: startTime }) => startTime >= nextSectionId
      );
      const wordObjectsBySection = _slice(words, startIndex, endIndex);
      return wordObjectsBySection;
    }
  );

export const selectIsCollectionReadOnly = createSelector(
  [
    selectLockedForEditing,
    selectCollectionVersionId,
    selectCollectionDraftComplete,
    selectCollectionPerfectionProcessing,
    selectCollectionRecode
  ],
  (
    isLockedForEditing,
    version,
    draftComplete,
    perfectionProcessing,
    recodeStatus
  ) =>
    !!isLockedForEditing ||
    !!version ||
    (draftComplete && perfectionProcessing) ||
    recodeStatus === false
);

export const selectCollectionRetried = createSelector(
  [selectCollectionById],
  ({ retried = false } = {}) => retried
);

export const selectCollectionTrimmed = createSelector(
  [selectCollectionById],
  ({ trimmed = false } = {}) => trimmed
);

export const selectCollectionProps = createSelector(
  [selectCollectionById],
  ({ props = {} } = {}) => props
);

export const selectCollectionDuration = createSelector(
  [selectCollectionProps],
  ({ duration = null } = {}) => duration
);

// Audio Video
export const selectCollectionMp3 = createSelector(
  [selectCollectionById],
  ({ mp3 = '' } = {}) => mp3.replace('http://', 'https://s3.amazonaws.com/')
);

export const selectCollectionClips = createSelector(
  [selectCollectionById, selectCollectionMp3],
  ({ clips } = {}, mp3) =>
    _map(clips || { 0: mp3 }, (url, startTime) => ({
      url,
      startTime: parseFloat(startTime)
    }))
);

export const selectCollectionClipSize = createSelector(
  [selectCollectionClips],
  (clips) => _get(clips, '1.startTime', 0) || 0
);

export const selectCollectionOffset = createSelector(
  [selectCollectionById],
  ({ offset } = {}) => parseFloat(offset || 0)
);

export const selectCollectionVideo = createSelector(
  [selectCollectionById],
  ({ video } = {}) =>
    video ? video.replace('http://', 'https://s3.amazonaws.com/') : null
);

export const selectCollectionVideoSmall = createSelector(
  [selectCollectionById],
  ({ videoSmall, video } = {}) =>
    videoSmall || video
      ? (videoSmall || video).replace('http://', 'https://s3.amazonaws.com/')
      : null
);

export const selectIsDirty = createSelector(
  [(state) => state],
  ({ collections, speakers, highlights, transcripts }) =>
    _some([
      !!collections.pendingRequests.length,
      !!speakers.pendingRequests.length,
      !!highlights.pendingRequests.length,
      !!transcripts.pendingRequests.length
    ])
);

export const allCollectionsArray = createSelector(
  [(state) => state],
  ({ collections: { entities } }) =>
    Object.entries(entities).map(([id, collection]) => ({
      id,
      name: collection.name,
      isProcessing: false,
      key: id,
      isOwner: true,
      isError: false,
      collection,
      href: `/edit/${id}`,
      text: collection.snippet
    }))
);

export const selectCollectionText = createSelector(
  [
    (state) => state,
    (_, collectionId) => collectionId,
    (_, __, filter) => filter,
    selectSpeakerIds,
    selectCollectionSpeakers,
    selectCollectionWords,
    generateSelectorForWordObjectsBySectionId
  ],
  (
    state,
    collectionId,
    filter,
    speakerIds,
    speakers,
    words,
    wordsBySpeakerSelector
  ) => {
    const text = speakerIds
      .map((speakerId) =>
        wordsBySpeakerSelector(state, collectionId, speakerId)
      )
      .map((wordObjects, index) => {
        const speakerId = speakerIds[index];
        const speaker = speakers[speakerId];
        if (!filter || filter === speaker.name) {
          const speakerName = speaker.name ? speaker.name + ' ' : '';
          const speakerText = speakerName + getTimeStamp(speakerId, true);

          const wordsText = wordObjects
            .map((wordObject, index) => {
              const space =
                index > 0 && wordObject.type === 'pronunciation' ? ' ' : '';
              return space + wordObject.alternatives[0].content;
            })
            .join('');

          return speakerText + '\n' + wordsText + '\n\n';
        }
        return '';
      });

    return text.join('');
  }
);

export const selectCollectionPlainText = createSelector(
  [(state) => state, (_, collectionId) => collectionId, selectCollectionWords],
  (state, collectionId, words) => {
    const wordsText = words
      .map((wordObject, index) => {
        const space =
          index > 0 && wordObject.type === 'pronunciation' ? ' ' : '';
        return space + wordObject.alternatives[0].content;
      })
      .join('');

    return wordsText;
  }
);

export const selectGenerateAnnotations = createSelector(
  [
    selectCollectionWords,
    selectCollectionHighlights,
    selectCollectionSpeakers,
    selectCollectionSpeakersArray
  ],
  (words, highlights, speakers, speakersArray) => {
    const wordsByBookmark = _groupBy(words, 'bookmark');
    console.log({ highlights, speakers, speakersArray });
    return Object.values(
      _reduce(
        highlights,
        (acc, highlight, id) => {
          const highlightedWords = wordsByBookmark[id];
          const speakerIndex = speakersArray.findIndex((id) => {
            if (highlightedWords && highlightedWords.length > 0) {
              const start = parseFloat(highlightedWords[0].start_time);
              const time = parseFloat(id);
              // console.log({start, time})
              return time > start;
            }
            return false;
          });
          const speakerId = speakersArray[Math.max(speakerIndex - 1, 0)];
          // console.log({speakerIndex, speakerId})
          if (highlightedWords) {
            acc[id] = {
              ...highlight,
              id,
              highlighted:
                _map(wordsByBookmark[id], 'alternatives[0].content')
                  .join(' ')
                  .trim() || '',
              speaker: speakers[speakerId]?.name || '',
              startTime: highlightedWords[0].start_time,
              endTime: _last(highlightedWords).end_time
            };
          }

          return acc;
        },
        {}
      )
    ).sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
  }
);

// ACTIONS

export const defaultContent = [{ type: 'paragraph', children: [{ text: '' }] }];

export const getContent = createAsyncThunk(
  'content/getOne',
  async ({ id, url, locale }, { getState }) => {
    let data = defaultContent;
    try {
      if (url) {
        const response = await axios.get(url);
        data = response.data;
        // console.log({response})
      } else {
        data = (await getContentApi({ id, locale })).data;
      }
    } catch (error) {
      toast.requestRefresh({ caller: 'content/fetchOne' });
      console.log('unable to fetch content', id, error);
    }
    return data;
  }
);

export const saveContent = createAsyncThunk(
  'content/updateOne',
  async ({ id, content, snippet, locale }, { getState, dispatch }) => {
    try {
      const { data } = await saveContentApi({ id, content, snippet, locale });
      return data;
    } catch ({ response: { data: response } }) {
      console.log('could not complete patch request');
      throw new Error('could not complete patch request');
    }
  }
);

export const createTextCollection = createAsyncThunk(
  'content/create',
  async (
    { id, uid, oid, name, content, snippet, locale },
    { getState, dispatch }
  ) => {
    try {
      const { data } = await createTextCollectionApi({
        uid,
        cid: id,
        locale,
        oid,
        name
      });
      return data;
    } catch ({ response: { data: response } }) {
      console.log('could not complete patch request');
      throw new Error('could not complete patch request');
    }
  }
);

export const fetchCollection = createAsyncThunk(
  'collections/fetch/setOne',
  async ({ id, locale, pubkey, versionId }, { getState, rejectWithValue }) => {
    try {
      const { data } = await fetch(id, versionId, locale, pubkey);
      return data;
    } catch (error) {
      console.log('fetch collection error', error);
      const { response: { data, status, statusText } = {} } = error;
      return rejectWithValue({ data, status, statusText });
    }
  }
);

export const addWelcomeCollection = createAsyncThunk(
  'collections/welcome',
  async ({ uid, oid }, { getState }) => {
    try {
      const { data } = await addWelcomeCollectionApi({ uid, oid });
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const audioVersions = createAsyncThunk(
  'collections/audio',
  async ({ id }, { getState }) => {
    try {
      const { data } = await audioVersionsApi({ id });
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const switchAudio = createAsyncThunk(
  'collections/audio/switch',
  async ({ id, url }, { getState }) => {
    try {
      return url;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const marker = createAsyncThunk(
  'collections/marker',
  async ({ hash, id }, { getState }) => {
    try {
      const marker = hash
        ? hash.split('#').find((a) => a.indexOf('time') >= 0)
        : undefined;
      const { data } = await markerApi({ id, marker });
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const fetchCollections = createAsyncThunk(
  'collections/fetchAll',
  async ({ oid, uid, nextPage, orgWide, shared }, { getState }) => {
    // if next page is being requested on orgwide make sure you don't ask for user key
    let cleanedNextPage;
    if (nextPage) {
      const { id, started } = nextPage || {};
      cleanedNextPage = { id, started };
      if (orgWide) {
        cleanedNextPage.orgId = oid;
      } else cleanedNextPage.userOrgId = `${uid}--${oid}`;
    }
    const response = await fetchAll({
      oid,
      uid,
      nextPage: cleanedNextPage || undefined,
      orgWide,
      shared
    });
    // console.log({response})
    const { data, LastEvaluatedKey } = response;
    return { data, LastEvaluatedKey };
  }
);

export const searchLocalCollections = createAsyncThunk(
  'collections/search/local',
  async ({ term }, { getState }) => {
    const state = getState();
    state.collections.searchResults = Object.values(
      state.collections.entities || {}
    ).reduce(
      (acc, c) => {
        const term = state.collections.searchTerm.toLowerCase();
        const { name, snippet, id } = c;
        if ((name || '').toLowerCase().indexOf(term) >= 0) acc[id] = c;
        else if ((snippet || '').toLowerCase().indexOf(term) >= 0) acc[id] = c;
        else if ((id || '').toLowerCase().indexOf(term) >= 0) acc[id] = c;
        return acc;
      },
      { ...state.collections.searchResults }
    );
    return state.collections.searchResults;
  }
);

export const searchCollections = createAsyncThunk(
  'collections/search',
  async ({ oid, term, nextPage, orgWide }, { getState, dispatch }) => {
    // don't be pinging for info if search results

    const state = getState();
    const collections = { ...state.collections };
    collections.searchTerm = term;
    state.collections = collections;
    if (!term) {
      state.collections.searchResults = {};
      return {};
    }

    const { data } = await search({ oid, term, nextPage, orgWide });
    return data;
  }
);

export const processingCollection = createAsyncThunk(
  'collections/processing',
  async ({ cid, oid, uid, name, project }, { getState }) => {
    const state = getState();
    const collections = { ...state.collections };
    const started = new Date().getTime() / 1000;
    console.log('collections/processing', { cid, oid, uid, name });
    let projects;
    if (project) {
      projects = { [project]: true };
    }
    collections.entities = {
      ...state.collections.entities,
      [cid]: {
        id: cid,
        orgId: oid,
        userId: uid,
        projects,
        name,
        started,
        tCompletion: { draft: false },
        tProgress: 0
      }
    };
    state.collections = collections;
  }
);

export const fetchSubtitles = createAsyncThunk(
  'subtitles/fetch',
  async ({ id, format, locale, size, breakAllSpeakers }) => {
    const { data, status } = await fetchSubtitlesApi(
      id,
      format,
      size,
      breakAllSpeakers,
      locale,
      { from: 'features' }
    );
    console.log('subtitles', { data, status });
    if (data?.error) {
      console.log('data', data);
      const url = data?.url;
      if (url) {
        // then get the url for the data since to large for response
        const { data } = await fetchSubtitleFileApi(url);
        return data;
      }
    }
    return data;
  }
);

export const renameCollection = createAsyncThunk(
  'collections/renameOne',
  async ({ id, name }) => {
    await renameApi(id, name);
    return { name };
  }
);

export const addToProject = createAsyncThunk(
  'collections/addToProject',
  async ({ id, project, remove = false }) => {
    const { data } = await addToProjectApi({ id, project, remove });
    return data;
  }
);

export const updateDescription = createAsyncThunk(
  'collections/updateDescription',
  async ({ id, text }) => {
    const { data } = await updateDescriptionApi({ id, text });
    console.log({ data });
    return data;
  }
);

export const updateLanguage = createAsyncThunk(
  'collections/update/language',
  async ({ id, languageCode, locale }) => {
    const { data } = await updateLanguageApi({ id, languageCode, locale });
    return data;
  }
);

export const updateNotes = createAsyncThunk(
  'collections/updateNotes',
  async ({ id, text }) => {
    const { data } = await updateNotesApi({ id, text });
    console.log({ data });
    return data;
  }
);

export const deleteCollection = createAsyncThunk(
  'collections/deleteOne',
  async ({ id, name }, { getState }) => {
    await deleteApi(id);
    const state = getState();
    const collections = { ...state.collections };
    collections.ids = collections.ids.filter((cid) => cid !== id);
    collections.entities = { ...state.collections.entities };
    delete collections.entities[id];
    state.collections = collections;
  }
);

export const removeCollectionFromState = createAsyncThunk(
  'collections/move',
  async ({ id, name }, { getState }) => {
    console.log('this is called');
    const state = getState();
    const collections = { ...state.collections };
    collections.ids = collections.ids.filter((cid) => cid !== id);
    collections.entities = { ...state.collections.entities };
    delete collections.entities[id];
    state.collections = collections;
  }
);

export const recoverCurrentVersion = createAsyncThunk(
  'collections/updateOneCurrentVersion',
  async (id, { getState }) => {
    try {
      const state = getState();
      const version = selectCollectionVersionId(state, id);
      await recoverVersionApi(id, version);
    } catch (error) {
      console.log(error);
    }
  }
);

export const cancelRecoverCurrentVersion = createAsyncThunk(
  'collections/cancelRecoverCurrentVersion',
  async (id, { getState }) => {}
);

export const openShareModal = createAsyncThunk(
  'collections/openShareModal',
  async ({ id, on }, { getState }) => {
    const state = getState();
    const newCollections = { ...state.collections };
    if (on) newCollections.shareModal = id;
    else newCollections.shareModal = null;
    state.collections = newCollections;
    return true;
  }
);

export const share = createAsyncThunk(
  'collections/share',
  async ({ id, uid }, { getState }) => {
    const state = getState();
    const entities = { ...state.collections.entities };
    const collection = { ...entities[id] };
    const remove = !!(collection.sharedWith || {})[uid];
    const { data } = await shareApi({ id, uid, remove });
    return data;
  }
);

export const recode = createAsyncThunk(
  'collections/recode',
  async ({ id, languageCode, start, end }, { getState }) => {
    const { data } = await recodeApi({ id, languageCode, start, end });
    return data;
  }
);

export const translate = createAsyncThunk(
  'collections/translate',
  async ({ id, toLang: to }, { getState }) => {
    const { data } = await translateApi({ id, to });
    return data;
  }
);

export const retry = createAsyncThunk(
  'collections/retry',
  async ({ id }, { getState }) => {
    const { data } = await retryApi({ id });
    console.log('retry data', data);
    return data;
  }
);

export const unPause = createAsyncThunk(
  'collections/unPause',
  async ({ id }, { getState }) => {
    const { data } = await unPauseApi({ id });
    return data;
  }
);

export const checkin = createAsyncThunk(
  'collections/checkin',
  async ({ id }, { getState }) => {
    const state = getState();
    const uid = state.firebase.auth.uid;
    const { editing } = state.collections.entities[id];
    if (uid === editing) await checkinApi({ id });
  }
);

export const denoise = createAsyncThunk(
  'collections/denoise',
  async ({ id }, { getState }) => {
    const { data } = await denoiseApi({ id });
    return data;
  }
);

export const owner = createAsyncThunk(
  'collections/owner',
  async ({ id, uid }, { getState }) => {
    const { data } = await changeOwnerApi({ id, uid });
    return data;
  }
);

export const upgrade = createAsyncThunk(
  'collections/upgrade',
  async ({ id, uid, oid }, { getState }) => {
    try {
      const { data } = await upgradeToPrecision(id);
      return data;
    } catch ({ response }) {
      let result = response.data.result || response.data || response;
      const resultIsString = typeof result === 'string';

      const message = resultIsString
        ? result
        : 'Issue with request to upgrade. Check payment method or lack of credits.';
      console.log(message, response);
      const link = await getStripePortalSession(oid, false);
      console.log({ link });
      toast.error(
        <span>
          {message}{' '}
          <b>
            <a
              href={link || '/settings/invoices'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Review Settings
            </a>
          </b>
          .
        </span>,
        { autoClose: 20000 }
      );
      throw new Error(message);
    }
  }
);

export const convertToBlog = createAsyncThunk(
  'collections/convertToBlog',
  async ({ id, uid, oid, prompt }, { getState }) => {
    try {
      const { data } = await convertToBlogAPI({ id, prompt });
      return data;
    } catch ({ response }) {
      const message =
        typeof response.data === 'string'
          ? response.data
          : 'Issue with request to upgrade.';
      console.log(message);
      const link = await getStripePortalSession(oid, false);
      console.log({ link });
      toast.error(
        <span>
          {message}{' '}
          <b>
            <Link
              to="/settings/invoices"
              target="_blank"
              rel="noopener noreferrer"
            >
              Review Settings
            </Link>
          </b>
          .
        </span>,
        { autoClose: 20000 }
      );
      throw new Error(message);
    }
  }
);

export const checkout = createAsyncThunk(
  'collections/checkout',
  async ({ id, uid }, { getState }) => {
    return { id, uid };
  }
);

export const slice = createSlice({
  name: 'collections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const addLoadingId = (state, { meta }) => {
      const { arg: { id } = {} } = meta;
      state.loading.push(id);
    };

    const removeLoadingId = (state, { meta }) => {
      const { arg: { id } = {} } = meta;
      state.loading = state.loading.filter((_id) => _id !== id);
    };

    const addRequestId = (state, { meta: { requestId } }) => {
      state.pendingRequests.push(requestId);
    };

    const removeRequestId = (state, { meta: { requestId } }) => {
      state.pendingRequests = state.pendingRequests.filter(
        (id) => id !== requestId
      );
    };

    builder
      .addCase(renameCollection.pending, addRequestId)
      .addCase(renameCollection.rejected, removeRequestId)
      .addCase(fetchCollection.pending, addLoadingId)
      .addCase(fetchCollection.rejected, removeLoadingId)
      .addCase(fetchCollection.fulfilled, (state, { meta, payload }) => {
        // if version is updated it also triggers transcription/speakers/highlights features state. See features/transcripts and alike for more info
        removeLoadingId(state, { meta });
        const { arg: { id: collectionId } = {} } = meta;
        const { items, highlights, speakers, words, transcripts, ...rest } =
          payload;
        // if version isn't passed overwrite with empty
        collectionsAdapter.setOne(state, { id: collectionId, ...rest });
      })
      .addCase(addWelcomeCollection.fulfilled, collectionsAdapter.upsertOne)
      .addCase(recode.fulfilled, collectionsAdapter.upsertOne)
      .addCase(updateDescription.fulfilled, collectionsAdapter.upsertOne)
      .addCase(updateNotes.fulfilled, collectionsAdapter.upsertOne)
      .addCase(denoise.fulfilled, collectionsAdapter.upsertOne)
      .addCase(upgrade.fulfilled, collectionsAdapter.upsertOne)
      .addCase(addToProject.pending, (state, { meta, payload }) => {
        // assume it worked so doy shows up faster
        const {
          arg: { id, project, remove }
        } = meta;
        const currProjects = state.entities[id].projects || {};
        if (remove) delete currProjects[project];
        else currProjects[project] = true;
        collectionsAdapter.upsertOne(state, { id, projects: currProjects });
      })
      .addCase(addToProject.fulfilled, collectionsAdapter.upsertOne)
      .addCase(fetchCollections.fulfilled, (state, args) => {
        const {
          payload: { data, LastEvaluatedKey }
        } = args;
        state.nextPageStart = LastEvaluatedKey;
        collectionsAdapter.upsertMany(state, data);
      })
      .addCase(audioVersions.fulfilled, (state, { meta, payload }) => {
        const {
          arg: { id }
        } = meta;
        collectionsAdapter.upsertOne(state, { id, audioVersions: payload });
      })
      .addCase(switchAudio.fulfilled, (state, { meta, payload }) => {
        const {
          arg: { id }
        } = meta;
        console.log({ id, payload });
        collectionsAdapter.upsertOne(state, { id, mp3: payload });
      })
      .addCase(searchCollections.fulfilled, (state, { meta, payload }) => {
        console.log({ payload });
        collectionsAdapter.upsertMany(state, payload);

        // state.collections.ids = ids
        let searchResults = { ...state.searchResults };
        searchResults = payload.reduce((acc, c) => {
          // ids.push(cid)
          acc[c.id] = c;
          return acc;
        }, {});
        // const ids = [...state.ids]
        state.searchResults = searchResults;
      })
      .addCase(getContent.fulfilled, (state, { meta, payload }) => {
        collectionsAdapter.upsertOne(state, payload);
      })
      .addCase(createTextCollection.fulfilled, collectionsAdapter.upsertOne)

      .addCase(renameCollection.fulfilled, (state, args) => {
        const { meta } = args;
        const {
          arg: { id, name }
        } = meta;
        console.log(current(state));
        collectionsAdapter.upsertOne(state, { id, name });
        removeRequestId(state, args);
      })
      .addCase(checkin.fulfilled, (state, args) => {
        const { meta } = args;
        const {
          arg: { id }
        } = meta;
        collectionsAdapter.upsertOne(state, { id, editing: '' });
        removeRequestId(state, args);
      })
      .addCase(checkout.fulfilled, (state, args) => {
        const { meta } = args;
        const {
          arg: { id, uid }
        } = meta;
        if (id) {
          collectionsAdapter.upsertOne(state, { id, editing: uid });
          removeRequestId(state, args);
        } else console.log('checkout was called with no id');
      })
      .addCase(owner.fulfilled, (state, { meta, payload }) => {
        removeLoadingId(state, { meta });
        const {
          arg: { id }
        } = meta;
        const { sharedWith, userId } = payload;
        collectionsAdapter.upsertOne(state, { id, sharedWith, userId });
      })
      .addCase(share.fulfilled, (state, { meta, payload }) => {
        removeLoadingId(state, { meta });
        const { arg: { id: collectionId } = {} } = meta;
        const sharedWith = payload;
        collectionsAdapter.upsertOne(state, { id: collectionId, sharedWith });
      })
      .addCase(recoverCurrentVersion.fulfilled, (state, args) => {
        const { meta } = args;
        const { arg: id } = meta;
        collectionsAdapter.updateOne(state, {
          id,
          changes: { version: undefined, modified: undefined }
        });
      })
      .addCase(cancelRecoverCurrentVersion.fulfilled, (state, args) => {
        const { meta } = args;
        const { arg: id } = meta;
        collectionsAdapter.updateOne(state, {
          id,
          changes: { version: undefined, modified: undefined }
        });
      });
  }
});

const reducer = slice.reducer;
export default reducer;

export const { removeCollection, optimisticUpdateCollectionBookmarks } =
  slice.actions;
