import { useSelector, useDispatch } from 'react-redux';
import { useFirestore } from 'react-redux-firebase'; // useFirestoreConnect
import {
  fetchUsers as fetchAllMembersAction,
  fetchUserByEmail as fetchUserByEmailAction,
  removeUserFromOrg
} from 'features/users';

import { getEmailKey, selectOrganization } from 'features/profile';
import {
  createOrganization,
  markOrganizationForDeletion
} from 'utils/Firebase';
import { toast } from 'components/common/Toaster';

const isValidEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export default ({ oid, uid, limit = 20 }) => {
  const dispatch = useDispatch();
  const firestore = useFirestore();

  const organization = useSelector((state) => selectOrganization(state, oid));

  const {
    billing = {},
    invites: invited,
    users: unHydratedUsers = {}
  } = organization || {};
  const { maxUsers } = billing;
  const userCount = Object.keys(unHydratedUsers).length;
  const invitees = Object.entries(invited || {}).map(([index, data]) => ({
    index,
    ...data
  }));

  const users = useSelector((state) => state.users.entities);
  const members = Object.entries(users || {}).reduce((acc, value) => {
    const [uid, data] = value;
    const userHasThisOrg = data.organizations[oid] === true;
    if (userHasThisOrg) {
      const flagInOrgOwners = (organization.owners || {})[uid];
      const isUser = (organization.users || {})[uid];
      const isOwner = flagInOrgOwners === true;
      const isAdmin = flagInOrgOwners !== undefined;
      isUser && acc.push({ uid, ...data, isOwner, isAdmin, isUser });
    }
    return acc;
  }, []);

  const uninviteMember = async (email) => {
    const emailIndex = getEmailKey(email);
    firestore
      .collection('Organizations')
      .doc(oid)
      .update({ [`invites.${emailIndex}`]: firestore.FieldValue.delete() });
  };

  const inviteMember = async (email) => {
    if (!isValidEmail(email)) {
      console.log('invalid email', email);
      return { error: 'Invalid email.' };
    }
    const emailIndex = getEmailKey(email);
    const user = (await fetchUserByEmail({ email })) || {};
    const userIsMember = !!(user.organizations || {})[oid];
    const userIsInvited = !!(organization.invites || {})[emailIndex];
    if (userIsInvited) return { error: 'User already invited.' };
    if (userIsMember) return { error: 'User already a member.' };
    await firestore
      .collection('Organizations')
      .doc(oid)
      .update({
        [`invites.${emailIndex}`]: {
          ignore: false,
          invitedTime: new Date().getTime(),
          email: email
        }
      });
    return {};
  };

  const makeOwner = async ({ uid }) => {
    const key = `owners.${uid}`;
    let newValue = true;
    if (organization.owners[uid] === true) newValue = false;
    firestore
      .collection('Organizations')
      .doc(oid)
      .update({ [key]: newValue });
  };

  const makeAdmin = async ({ uid }) => {
    const key = `owners.${uid}`;
    let newValue = false;
    if (organization.owners[uid] === false)
      newValue = firestore.FieldValue.delete();
    firestore
      .collection('Organizations')
      .doc(oid)
      .update({ [key]: newValue });
  };

  const create = async ({ uid, name, locale }) => {
    const oid = await createOrganization({ uid, name, locale });
    return oid;
  };

  const markForDeletion = async () => {
    try {
      await markOrganizationForDeletion(oid);
    } catch (error) {
      toast.error('Unable to process deletion request.');
    }
  };

  const removeFromDeletionMark = async () => {
    try {
      firestore
        .collection('Organizations')
        .doc(oid)
        .update({ deletedAt: firestore.FieldValue.delete() });
    } catch (error) {
      console.log(error);
      toast.error('Unable to process deletion request.');
    }
  };

  const deleteMember = async ({ uid }) => {
    console.log('deleteMember', { uid, oid });
    firestore
      .collection('Organizations')
      .doc(oid)
      .update({ [`users.${uid}`]: firestore.FieldValue.delete() });
    const { payload } = await dispatch(removeUserFromOrg({ uid, oid }));
    return payload;
  };

  const fetchAllMembers = async ({ oid, q, uids }) => {
    const { payload } = await dispatch(fetchAllMembersAction({ oid, q, uids }));
    return payload;
  };

  const fetchUserByEmail = async ({ email }) => {
    const { payload } = await dispatch(fetchUserByEmailAction({ oid, email }));
    return payload;
  };

  const updateName = (name) =>
    firestore.collection('Organizations').doc(oid).update({ name });

  const updateBilling = (key, value) =>
    firestore
      .collection('Organizations')
      .doc(oid)
      .update({ [`billing.${key}`]: value });

  return {
    billing,
    updateName,
    updateBilling,
    makeOwner,
    makeAdmin,
    deleteMember,
    fetchUserByEmail,
    organization,
    fetchAllMembers,
    members,
    maxUsers,
    invitees,
    uninviteMember,
    inviteMember,
    userCount,
    create,
    markForDeletion,
    removeFromDeletionMark
  };
};
