import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  open,
  close,
  openEditor as openEditorAction,
  sliceSelector,
  // selectIsOpen,
  // selectIsEdit,
  // selectUploads,
  // selectIsShowOverLimit,
  updateDictionary as updateDictionaryAction,
  upload as uploadCall,
  transcribe as transcribeAction,
  checkUrl as checkUrlAction,
  transcribeFromUpload as transcribeFromUploadAction,
  update as updateAction,
  showOverLimit as showOverLimitAction,
  cancelUpload as cancelUploadAction
} from 'features/uploader';
import { fetchUsage, selectOrgUsage } from 'features/usage';
import useProfile from 'hooks/useProfile';
import { trackCustom } from 'utils/Tracker';

const assumeNameFromFile = (file) => {
  if (!file || !file.name) return 'My upload';
  let assumedName = file.name;
  if (assumedName) {
    assumedName = assumedName.replace(/[-_+.]/gi, ' ');
    assumedName = assumedName
      .replace(/(mp3)|(mp4)|(m4a)|(mov)|(wav)/gi, ' ')
      .trim();
    assumedName = assumedName.replace(/\s+/gi, ' ').trim();
    assumedName = assumedName
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }
  return assumedName;
};

export default (props = {}) => {
  const { convertToBlog } = props || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    settings: { defaultLanguage, welcomeSettings },
    uid,
    oid,
    setDefaultLanguage,
    organization = {},
    updateSettings
  } = useProfile();
  const locale = organization.locale || 'us';
  const {
    isOpen,
    uploads = {},
    isEdit,
    isShowOverLimit
  } = useSelector((state) => sliceSelector(state)) || {};

  // TODO: why wouldnt these update?
  // const isOpen = useSelector(state => selectIsOpen(state))
  // const uploads = useSelector(state => selectUploads(state))
  // const isEdit = useSelector(state => selectIsEdit(state))
  // const isShowOverLimit = useSelector(state => selectIsShowOverLimit(state))

  const uploadList = Object.entries(uploads).map(([id, data]) => ({
    id,
    ...data
  }));
  const isReady = uploadList.filter(
    ({ languageCode, progress, complete }) =>
      languageCode && progress === 1 && !complete
  );
  const uploadsWaiting = uploadList.filter(
    ({ languageCode, progress, complete }) =>
      !languageCode && progress === 1 && !complete
  );
  const numberOfUploadsWaiting = uploadsWaiting.length;
  const numberOfUploads = uploadList.length;

  const { hasMinutes } =
    useSelector((state) => selectOrgUsage(state, oid)) || {};

  const editingUpload = isEdit ? { id: isEdit, ...uploads[isEdit] } : null;

  const process = async (uploads) => {
    console.log('Processor called for upload', uploads);
    for (let { id, url } of uploads) {
      if (id && url) {
        try {
          const { error } = await dispatch(
            transcribeFromUploadAction({ id, uid })
          );
          if (!error && !welcomeSettings?.uploaded)
            updateSettings({ [`welcomeSettings.uploaded`]: true });
        } catch (error) {
          console.log('process error', error);
        }
      }
    }
  };

  useEffect(() => {
    isReady.length > 0 && process(isReady);
  }, [JSON.stringify(isReady || [])]); // quick deep compare

  const openEditor = ({ id }) => {
    // if not on the dashboard page and wants to edit then go to dash
    !window.location.pathname.includes('dashboard') &&
      history.push('/dashboard/');
    return dispatch(openEditorAction({ id }));
  };

  const isMobile =
    /Mobile/i.test(window.navigator?.userAgent || '') &&
    window.innerWidth < 500;

  const openDialog = (args = {}) => {
    let { view, responsive } = args;
    if (view === 'youTubeUpload') view = 'youtube-upload';
    if (typeof view !== 'string') view = 'file-drop';
    if (responsive && isMobile) view = 'recorder';
    if (isOpen === view) return;
    // if no usage check again in case it updated
    trackCustom({
      category: 'User',
      action: 'Opened Uploader',
      data: { view, hasMinutes }
    });
    if (!hasMinutes) dispatch(fetchUsage());

    // view is part of evt and is auto passed on empty calls i.e. ={openDialog}

    return dispatch(open({ view }));
  };

  const showOverLimit = (show = true) => {
    show &&
      trackCustom({ category: 'User', action: 'Over Limit Shown', data: {} });
    return dispatch(showOverLimitAction(show));
  };

  const closeDialog = () => {
    return dispatch(close());
  };

  const upload = async ({ blob, id, languageCode = 'auto' }) => {
    const controller = new window.AbortController();

    const name = assumeNameFromFile(blob);
    console.log('assumed name', name);
    trackCustom({
      category: 'User',
      action: 'Uploading',
      data: { id, languageCode }
    });
    await update({ id, name, languageCode, convertToBlog });
    return dispatch(
      uploadCall({
        id,
        blob,
        name,
        oid,
        locale,
        uid,
        abortController: controller
      })
    );
  };

  const update = async ({
    id,
    languageCode,
    name,
    beta,
    isPrecision,
    description,
    hints,
    dictionary,
    ignoreSentance,
    convertToBlog,
    project
  }) =>
    dispatch(
      updateAction({
        id,
        name,
        languageCode,
        beta,
        description,
        isPrecision,
        hints,
        dictionary,
        ignoreSentance,
        convertToBlog,
        project
      })
    );

  const cancelUploads = async () => dispatch(cancelUploadAction());

  const updateDictionary = async ({ id, dictionary }) =>
    dispatch(updateDictionaryAction({ id, dictionary }));

  const transcribe = async ({
    url,
    languageCode,
    isPrecision,
    name,
    description,
    dictionary,
    beta,
    hints,
    isLinkUpload,
    ignoreSentance,
    convertToBlog,
    project
  }) =>
    dispatch(
      transcribeAction({
        uid,
        url,
        beta,
        languageCode,
        description,
        isPrecision,
        hints,
        name,
        oid,
        dictionary,
        isLinkUpload,
        ignoreSentance,
        convertToBlog,
        project
      })
    );

  const checkUrl = async ({ url }) => dispatch(checkUrlAction({ url }));

  return {
    isOpen,
    openDialog,
    isShowOverLimit,
    showOverLimit,
    editingUpload,
    closeDialog,
    openEditor,
    upload,
    update,
    updateDictionary,
    uploadsWaiting,
    setDefaultLanguage,
    transcribe,
    checkUrl,
    cancelUploads,
    uploads: uploadList,
    defaultLanguage,
    numberOfUploads,
    numberOfUploadsWaiting,
    isEdit
  };
};
