import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

import RefreshIcon from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import SwitchIcon from '@material-ui/icons/Cached';
import { Fade } from '@material-ui/core';

import Loader from 'components/common/Loaders/Skeleton';
import useSubscription from 'hooks/useSubscriptions';
import usePlan from 'hooks/usePlan';
import useProfile from 'hooks/useProfile';
import useOrganization from 'hooks/useOrganization';

const Root = styled.div`
  align-items: flext-start;
  justify-content: flext-start;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 122px;
  h2 {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    font-size: 1em;
    :hover svg {
      opacity: 0.5;
      transition: opacity 1s;
    }
    svg {
      cursor: pointer;
      font-size: 18px;
      margin-left: 6px;
      opacity: 0;
      transition: opacity 1s;
      &.spinning {
        opacity: 0.5;
      }
    }
  }
  h3 {
    display: flex;
    justify-content: space-between;
    margin: 0;
    color: #999;
    text-transform: uppercase;
    font-size: 11px;
    .hover {
      opacity: 0;
      transition: opacity 1s;
      transform: scale(0.8);
      transition:
        color 1s,
        opacity 1s,
        transform 1s;
    }
  }

  .switch-hover svg {
    opacity: 0;
    transition: opacity 1s;
  }

  .force-show svg {
    opacity: 0.4;
  }

  :hover {
    .hover {
      opacity: 1;
      :hover {
        transform: scale(0.9);
        color: dodgerblue;
        transition:
          color 1s,
          transform 1s;
      }
    }
    .switch-hover {
      cursor: pointer;
      svg {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }

  .MuiLinearProgress-root,
  .bar {
    border-radius: 6px;
    height: 6px;
    margin-top: 10px;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: #ccc;
  }
  .MuiLinearProgress-barColorSecondary {
    background-color: #1d6fb7;
  }
  .MuiLinearProgress-bar2Buffer {
    background-color: #bbb;
    background: repeating-linear-gradient(
      45deg,
      #aaa,
      #aaa 5px,
      #bbb 5px,
      #bbb 10px
    );
    border-radius: 5px;
  }
  .MuiLinearProgress-dashed {
    display: none;
  }
  .MuiLinearProgress-barColorPrimary {
    ${({ over }) =>
      over &&
      `
      background-color: #CCC;
    `}
  }
  small {
    margin: 8px 0 15px 0;
    color: #aaa;
    font-size: 0.75em;
  }
`;

const TimeLeft = ({ minutes, type = 'draft', onClick }) => {
  let approx;
  if (type === 'precision') {
    approx = 'max';
  }
  if (minutes > 999) {
    const hours = Math.round(minutes / 60);
    return (
      <span onKeyPress={() => {}} role="button" tabIndex={-1} onClick={onClick}>
        <CountUp start={0} end={hours} duration={2} /> {type} hours {approx}
      </span>
    );
  }
  // we round to the highest minute so no need to show seconds
  // if (minutes < 1) {
  //   return <span><CountUp start={0} end={60 * minutes} duration={2} /> seconds</span>
  // }

  return (
    <span onKeyPress={() => {}} role="button" tabIndex={-1} onClick={onClick}>
      <CountUp start={0} end={minutes} duration={2} /> {type} mins {approx}
    </span>
  );
};

const UploadProgress = (props) => {
  const { uid, oid, billing, isLoaded } = useProfile();
  const { updateBilling } = useOrganization({ oid });
  const { enterprise, updated, paymentMethod } = billing;
  const { fetchUsage, fetchCredits, calcForUsageMeter } = usePlan({ oid, uid }); // credits, usage
  const { fetchPaymentMethods, paymentMethods } = useSubscription(billing);
  const [loaded, setLoaded] = useState(false);
  const [minsType, setMinsType] = useState('draft');
  const [loading, setLoading] = useState(true);

  const {
    perc,
    estPerc,
    minutesLeft,
    paygMins,
    estimatedDraft,
    draftPlan,
    paygPrecMins
  } = calcForUsageMeter();

  // console.log({minutesLeft, paygMins})

  const validateCard = (card) => {
    if (!card) return false;
    const today = new Date();
    const expirationDate = new Date(card.exp_year, card.exp_month, 0);
    return card.checks.cvc_check === 'pass' && expirationDate > today;
  };

  const init = async (force) => {
    if ((!loading && oid && uid && !draftPlan) || force) {
      setLoading(true);
      await Promise.all([fetchUsage(), fetchCredits(), fetchPaymentMethods()]);
      setLoading(false);
      // console.log({usage, credits, paymentMethods})
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (paymentMethods) {
      const activePaymentMethod = paymentMethods.find((pm) =>
        validateCard(pm?.card)
      );
      console.log({ activePaymentMethod });
      // use the chance to update payment cache in FB if was null or different now
      if (
        activePaymentMethod &&
        (!paymentMethod || paymentMethod !== activePaymentMethod.id)
      ) {
        updateBilling('paymentMethod', activePaymentMethod.id);
      }
    }
  }, [loaded]);

  useEffect(() => {
    init(true);
  }, [oid, updated]);

  // enterprise dont show anything
  if (enterprise) return '';

  // Loading....
  if (!isLoaded || (!loaded && loading && !enterprise))
    return (
      <div style={{ marginTop: '20px' }}>
        <Loader width="100px" />
        <Loader />
      </div>
    );

  // no plan or big plan or mins and assume there is an admin watching minites
  if (
    (!draftPlan && !paymentMethod && paygMins === undefined) ||
    draftPlan > 59999
  )
    return '';

  const activePlan = draftPlan > 0 && minutesLeft > 0 && billing.subscription;
  const fixPaymentMethod = !paymentMethod && paygMins === 0 && !activePlan;
  const showPAYG = paygMins >= 0 || paymentMethod;

  if (!showPAYG && !fixPaymentMethod && !activePlan)
    return (
      <Root>
        <Link to="/plans">
          <small>No more minutes left.</small>
        </Link>{' '}
      </Root>
    );

  return (
    <Fade in={true} timeout={1000}>
      <Root over={perc >= 100 ? 1 : 0}>
        <h2 style={{ justifyContent: 'space-between' }}>
          Usage
          {loading ? '...' : ''}
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className={
              fixPaymentMethod || (!activePlan && !paygMins) ? 'force-show' : ''
            }
          >
            <RefreshIcon
              fontSize="small"
              style={{ cursor: 'pointer' }}
              onClick={() => init(true)}
            />
            <InfoIcon
              fontSize="small"
              onClick={() => Beacon('article', '601ee3cea4cefb30ae5c8201')}
            />
          </div>
        </h2>
        {activePlan ? (
          <>
            <h3>
              <div>Subscription</div>
              <div className="hover">
                <Link to="/settings/invoices#invoices">More</Link>
              </div>
            </h3>
            <LinearProgress
              aria-label="subscription usage meter"
              style={{ marginBottom: '8px' }}
              variant="buffer"
              value={perc}
              valueBuffer={estPerc}
            />
            <small style={{ marginBottom: '15px' }}>
              {estimatedDraft >= 0 ? (
                <span>
                  <TimeLeft minutes={minutesLeft} />{' '}
                </span>
              ) : (
                ''
              )}{' '}
            </small>
          </>
        ) : (
          ''
        )}
        {fixPaymentMethod ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Link to="/plans">
              <small>Fix payment method</small>
            </Link>
          </div>
        ) : showPAYG ? (
          <>
            <h3 style={{ marginBottom: '5px' }}>
              <div>Pay as you go</div>
              <div className="hover">
                <Link to="/settings/invoices#adjustments">More</Link>
              </div>
            </h3>
            {paygMins ? (
              <small className="switch-hover" style={{ position: 'relative' }}>
                <SwitchIcon
                  style={{
                    position: 'absolute',
                    left: -20,
                    top: 0,
                    fontSize: 14
                  }}
                  role="button"
                  onKeyPress={() => {}}
                  tabIndex={-1}
                  onClick={(evt) => {
                    setMinsType(minsType === 'draft' ? 'precision' : 'draft');
                  }}
                />
                <TimeLeft
                  minutes={minsType === 'draft' ? paygMins : paygPrecMins}
                  type={minsType}
                  role="button"
                  onKeyPress={() => {}}
                  tabIndex={-1}
                  onClick={(evt) => {
                    setMinsType(minsType === 'draft' ? 'precision' : 'draft');
                  }}
                />
              </small>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </Root>
    </Fade>
  );
};

export default UploadProgress;
