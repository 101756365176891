import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  // current,
  createSlice
} from '@reduxjs/toolkit';

// import _omit from 'lodash/omit'

import {
  generateImages as generateImagesApi,
  checkImages as checkImagesApi,
  generateContent as generateContentApi,
  getMedia as getMediaApi,
  retry as retryApi
} from 'api/content';

// import {toast} from 'components/common/Toaster'

// Slice
export const mediaAdapter = createEntityAdapter();
const initialState = mediaAdapter.getInitialState({
  pendingRequests: []
});

// Selectors
const sliceSelector = state => state.media;
const {selectById} = mediaAdapter.getSelectors(sliceSelector);

export const selectMedia = createSelector(
  [selectById],
  (media = {}) => media
);

export const selectProcessing = createSelector(
  [selectMedia],
  ({processing} = {}) => Object.values(processing || {})
);

export const selectErrors = createSelector(
  [selectMedia],
  ({error} = {}) => Object.values(error || {})
);

export const selectLibrary = createSelector(
  [selectMedia],
  ({library} = {}) => Object.values(library || {}).sort((a,b) => {
    let sortOrder = 0;
    if (a.created > b.created) sortOrder = 1;
    else sortOrder = -1;

    // sub sort if grouped
    if ((a.groupIndex || 0) > (b.groupIndex || 0)) sortOrder = 1;
    else sortOrder = -1;

    return sortOrder;
  })
);

// Actions

export const generateImages = createAsyncThunk(
  'media/images/create',
  async ({cid, orgId, prompt, width, height, type, variant, locale}, {getState, dispatch}) => {
    try {
      const {data} = await generateImagesApi({cid, prompt, orgId, variant, width, height, type, locale});
      return data;
    } catch ({response: {data: response}}) {
      console.log('could not complete patch request');
      throw new Error('could not complete patch request');
    }
  }
);

export const checkImages = createAsyncThunk(
  'media/images/check',
  async ({cid, orgId, locale}, {getState, dispatch}) => {
    console.log('check images check', {cid, orgId});
    try {
      const {data} = await checkImagesApi({cid, orgId, locale});
      return data;
    } catch ({response: {data: response}}) {
      console.log('could not complete patch request');
      throw new Error('could not complete patch request');
    }
  }
);

export const generateContent = createAsyncThunk(
  'media/completion/create',
  async ({cid, orgId, prompt, type, from, variant, locale}, {getState, dispatch}) => {
    try {
      console.log({cid, orgId, prompt, from, type, variant, locale});
      const {data} = await generateContentApi({cid, prompt, type, from, variant, orgId, locale});
      return data;
    } catch ({response: {data: response}}) {
      console.log('could not complete patch request');
      throw new Error('could not complete patch request');
    }
  }
);

export const getMedia = createAsyncThunk(
  'media/media',
  async ({cid, locale}, {getState, dispatch}) => {
    try {
      const {data} = await getMediaApi({cid, locale});
      return data;
    } catch ({response: {data: response}}) {
      console.log('could not complete patch request');
      throw new Error('could not complete patch request');
    }
  }
);

export const retry = createAsyncThunk(
  'media/media/retry',
  async ({cid, pid, orgId, locale}, {getState, dispatch}) => {
    try {
      const {data} = await retryApi({cid, pid, orgId, locale});
      return data;
    } catch ({response: {data: response}}) {
      console.log('could not complete patch request');
      throw new Error('could not complete patch request');
    }
  }
);

export const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMedia.fulfilled, (state, {meta, payload}) => {
        mediaAdapter.upsertOne(state, payload);
      });
    builder
      .addCase(checkImages.fulfilled, (state, {meta, payload}) => {
        mediaAdapter.upsertOne(state, payload);
      });
    builder
      .addCase(generateContent.fulfilled, (state, {meta, payload}) => {
        console.log('Generated content', {payload, meta});
        mediaAdapter.upsertOne(state, payload);
      });
    builder
      .addCase(generateImages.fulfilled, (state, {meta, payload}) => {
        console.log('Generated images', {payload, meta});
        mediaAdapter.upsertOne(state, payload);
      });
    builder
      .addCase(retry.fulfilled, (state, {meta, payload}) => {
        console.log('Genetrying again', {payload, meta});
        mediaAdapter.upsertOne(state, payload);
      });
  }
});

const reducer = slice.reducer;
export default reducer;
