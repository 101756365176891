import React from 'react';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';

import { Button } from '@material-ui/core';

import useProfile from 'hooks/useProfile';
import Loader from 'components/common/Loaders/Skeleton';
import useUpload from 'hooks/useUpload';
import { getIsUni } from 'utils/env';

const FilterOptions = {
  'My Collections': 'My transcripts',
  'Shared with Me': 'Shared with me'
};

const OrgAdminFilterOptions = {
  'Org Collections': 'Team transcripts',
  ...FilterOptions
};

const FilterSelector = styled(Button)`
  && {
    text-transform: uppercase;
    padding: 0;
    ${(props) => props.onClick && 'cursor: pointer;'}
    transition: color .5s;
    width: 183px;
    margin: unset;
    margin-left: 2px;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 32px;
    color: #37404a;
    padding-left: 10px;
    span {
      display: block;
      text-align: left;
    }
    opacity: ${(props) => (props.selected ? 1 : 0.6)};
  }
`;

const H1 = styled.h1`
  padding-left: 2px;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1em;
  width: 130px;
  margin: 0 0 10px 7px;
`;

export default (props) => {
  const {
    settings = {},
    updateSettings,
    organization,
    isOwner,
    oid
  } = useProfile();
  const { numberOfUploads } = useUpload();
  const isUni = getIsUni(oid);
  isUni &&
    console.log('DEiC Org, so manually blocking filter options.', {
      isOwner,
      isUni
    });
  // show Team Wide view is isOwner and NOT a Uni

  const views = Object.entries(
    isOwner && !isUni ? OrgAdminFilterOptions : FilterOptions
  );

  const filter =
    settings?.dashFilter === 'Org Collections' && isUni
      ? 'My Collections'
      : settings?.dashFilter;
  console.log('aaaaaa', filter);
  return (
    <>
      <H1>Dashboard</H1>
      {organization.isLoaded ? (
        views.map(([key, value], i) => {
          const uploadIndicator =
            numberOfUploads &&
            key === 'My Collections' &&
            filter === 'Shared with Me'
              ? `[ ${numberOfUploads} ]`
              : '';
          const selected =
            filter === key || (key === 'My Collections' && !filter);
          return (
            <FadeIn key={key} delay={i * 200}>
              <FilterSelector
                key={key}
                selected={selected}
                onClick={() => {
                  updateSettings({ dashFilter: key });
                }}
              >
                {value} {uploadIndicator}
              </FilterSelector>
            </FadeIn>
          );
        })
      ) : (
        <div>
          <p>
            <Loader width="200px" />
          </p>
          <p>
            <Loader width="180px" />
          </p>
        </div>
      )}
    </>
  );
};
