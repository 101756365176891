import React, {useState, useEffect} from 'react';
import {useReactMediaRecorder} from 'react-media-recorder';
import styled from 'styled-components';
import {StButton, Border, RecButton, RecWrapper,
  ButtonBorder, Instructions, InstuctionsHighlight, Button, ErrorMessage} from './Recorder';

const RecorderCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  div[class*="render-actions__ActionsWrapper"] {
    top: 70%;
  }
`;

const RecorderWrapper = styled.div`
  max-width: 690px;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
`;

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit:contain;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  z-index: 1000;
  color: #fff;
`;

const IdleActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  > * {
    margin: 4px;
  }
`;

const MediaRecorder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #000;
`;

const RecordButton = (props) => (
  <RecWrapper>
    <Instructions>
      <div>PRESS</div>
      <InstuctionsHighlight>REC</InstuctionsHighlight>
        WHEN READY
    </Instructions>
    <ButtonBorder>
      <RecButton {...props}/>
    </ButtonBorder>
  </RecWrapper>
);

const StopButton = (props) => (
  <Border>
    <StButton {...props}/>
  </Border>
);

const ErrorView = () => (
  <ErrorMessage>
    Oh snap! Your browser failed to record your screen.
    <br/>
    <br/>
    Please restart it and try again 👍
  </ErrorMessage>
);

const RecorderState = {
  IDLE: 'IDLE',
  RECORDING: 'RECORDING',
  STOPPED : 'STOPPED',
  ERROR : 'ERROR',
  ACQUIRING_MEDIA : 'ACQUIRING_MEDIA'
};

const ScreenRecorder = (props) => {
  const {closing, onUpload} = props;
  const [recorderState, setRecorderState] = useState(RecorderState.IDLE);
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();
  const onStop = (blobUrl, blob) => {
    setBlobUrl(blobUrl);
    setBlob(blob);
  };

  const {
    error,
    status,
    startRecording,
    stopRecording,
  } = useReactMediaRecorder({screen : true, onStop});

  useEffect(() => {
    //permission_denied is the error produced when the chooser is cancelled
    //we don't want to show the error view when the chooser is cancelled.
    if((error.length > 0) && error !== 'permission_denied'){
      setRecorderState(RecorderState.ERROR);
    }
    switch(status){
    case 'stopped':
      setRecorderState(RecorderState.STOPPED);
      break;
    case 'acquiring_media':
      setRecorderState(RecorderState.ACQUIRING_MEDIA);
      break;
    case 'recording':
      setRecorderState(RecorderState.RECORDING);
      break;
    case 'idle':
      setRecorderState(RecorderState.IDLE);
      break;
    default:
    }
  }, [status, error]);

  useEffect(() => {
    if (closing && recorderState === RecorderState.RECORDING) stopRecording();
  },[closing]);

  const handleReRecord = () => {
    setBlobUrl(null);
    //when recording is stopped, internal status is 'stopped'.
    //we reset ours to idle so we can show the idle UI and be ready to start recording again.
    setRecorderState(RecorderState.IDLE);
  };

  return (
    <RecorderCont>
      <RecorderWrapper>
        <MediaRecorder>
          {(recorderState === RecorderState.ERROR) && <ErrorView/>}
          {recorderState === RecorderState.IDLE && <Actions><RecordButton onClick={startRecording}/></Actions>}
          {(recorderState === RecorderState.RECORDING) && <Actions><StopButton onClick={stopRecording}/></Actions>}
          {recorderState === RecorderState.STOPPED &&
            <Actions>
              <IdleActionsWrapper>
                <Button onClick={() => onUpload(blob)}>UPLOAD</Button>
                <Button onClick={handleReRecord}>RE-RECORD</Button>
              </IdleActionsWrapper>
            </Actions>
          }
          {blobUrl && <Video src={blobUrl} autoPlay loop>
            <track default
              kind="captions"
              srcLang="en"
            />
          </Video>}
        </MediaRecorder>
      </RecorderWrapper>
    </RecorderCont>
  );
};

export default ScreenRecorder;
