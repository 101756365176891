import { Button } from '@material-ui/core';
import Popover, { MenuIcon, MenuItem } from 'components/common/Popover';
import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import ExportIcon from '@material-ui/icons/GetApp';
import useProfile from 'hooks/useProfile';
import EditorContext from 'context/EditorContext';
import {
  moveCollectionToOrganization
} from 'api/collections';
import Spinner from 'components/common/Spinner';

const MoveWorkspace = ({children, onClose, ...props}) => {
  const {oid, organizations} = useProfile();
  const [isLoading, setIsLoading] = useState(false);

  const filteredOrganization = organizations.filter(({id}) => id !== oid);

  const {collectionId} = useContext(EditorContext);

  const handleMoveWorkspace = async (orgId) => {
    setIsLoading(true);

    if (!orgId) {
      throw new Error('No organization id provided');
    }

    if (!collectionId) {
      throw new Error('No collection id provided');
    }

    try {
      const response = await moveCollectionToOrganization({
        cid: collectionId,
        oid: orgId,
      });

      if (response.status === 200) {
        onClose();
        setIsLoading(false);
      }

    } catch(err) {
      console.log('err',err);
    }
  };

  return (
    <React.Fragment>
      <Popover
        leftTransform='-50%'
        {...props}
        trigger={children || (
          <Button
            className='export-transcript-button'
            color='primary'
            variant='contained'
            style={{
              backgroundColor: '#5173e7',
              textTransform: 'capitalize',
              fontWeight: 'bold',
              fontSize: 16
            }}
            disableElevation
            endIcon={<ExportIcon style={{fontSize: 21}} className='hide-small' />}
          >
          Move Workspace
          </Button>
        )}
        content={
          isLoading ? (
            <div>
              <h5 style={{
                marginTop: 6,
                marginBottom: 6,
                paddingLeft: 10,
              }}>
              Move to:
              </h5>
              <MenuItem>
                <MenuIcon>
                  <FontAwesomeIcon icon={faFileAlt} />
                </MenuIcon>
                <span>
                  <Spinner small />
                </span>
              </MenuItem>
            </div>
          ) : (
            <div>
              <h5 style={{
                marginTop: 6,
                marginBottom: 6,
                paddingLeft: 10,
              }}>
                Move to:
              </h5>
              {filteredOrganization.map(({id, name}) => (
                <MenuItem onClick={() => handleMoveWorkspace(id)}

                  key={`${id}-i`}>
                  <MenuIcon>
                    <FontAwesomeIcon icon={faFileAlt} />
                  </MenuIcon>
                  <span>{name}</span>
                </MenuItem>
              ))}
            </div>
          )
        }
      />
    </React.Fragment>
  );
};

export default MoveWorkspace;

