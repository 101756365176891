import React, { useEffect, useState } from 'react';

import EditorContext from 'context/EditorContext';
import { LocalDirtyProvider } from 'context/LocalDirtyContext';
import { UnderlineProvider } from 'context/UnderlineContext';
import VideoPlayerActionsContext from 'context/VideoPlayerActionsContext';
import VideoPlayerStateContext from 'context/VideoPlayerStateContext';
import FindReplaceContext from 'context/FindReplaceContext';
import WordChunkEditorContext from 'context/WordChunkEditorContext';
import WordChunkSliderContext from 'context/WordChunkSliderContext';
import EditMenuContext from 'context/EditMenuContext';

const EditorContextsProvider = ({
  collectionId,
  isCorrector,
  isReviewer,
  pubkey,
  locale,
  children,
  openEditOptions,
  setOpenEditOptions,
  maxCPL,
  language,
  versionId,
  showScrollHelper
}) => {
  const [editorContextValues, setEditorContextValues] = useState({
    collectionId,
    isCorrector,
    isReviewer,
    locale,
    pubkey,
    openEditOptions,
    setOpenEditOptions,
    showScrollHelper,
    language,
    maxCPL,
    versionId
  });

  useEffect(() => {
    setEditorContextValues({
      collectionId,
      versionId,
      language,
      isCorrector,
      isReviewer,
      pubkey,
      locale,
      openEditOptions,
      setOpenEditOptions,
      maxCPL,
      showScrollHelper
    });
  }, [
    collectionId,
    versionId,
    isCorrector,
    language,
    locale,
    isReviewer,
    pubkey,
    openEditOptions,
    setOpenEditOptions,
    maxCPL,
    showScrollHelper
  ]);

  const [videoPlayerActions, setVideoPlayerActions] = useState({
    setVideoPlayerActions: () => {},
    handleQuickRewind15: () => {},
    handleQuickRewind: () => {},
    handleFastForward: () => {},
    handleFastForward15: () => {},
    onPlay: () => {},
    onPause: () => {},
    goSlow: () => {},
    goNormal: () => {},
    goFast: () => {},
    goFaster: () => {},
    onSeek: () => {}
  });
  useEffect(() => {
    setVideoPlayerActions((rest) => ({ ...rest, setVideoPlayerActions }));
  }, []);

  const [videoPlayerState, setVideoPlayerState] = useState({
    setVideoPlayerState: () => {},
    fullScreen: false,
    large: false,
    popout: false,
    playing: false,
    speed: 1
  });

  const [findReplaceState, setFindReplaceState] = useState({
    setShow: () => {},
    show: false
  });

  const [wordChunkEditorState, setWordChunkEditorState] = useState({
    setShow: () => {},
    showWarning: false,
    showEditor: false,
    spans: [],
    minTime: 0,
    maxTime: 0,
    useBlockProps: {}
  });

  const [wordChunkSliderState, setWordChunkSliderState] = useState({
    setShow: () => {},
    showSlider: window.localStorage.getItem('slider') === 'debug,open',
    spans: [],
    minTime: 0,
    maxTime: 0,
    useBlockProps: {}
  });

  const [editMenuState, setEditMenuState] = useState({
    setShow: () => {},
    showContext: false,
    showHighlights: false
  });

  useEffect(() => {
    setVideoPlayerState((rest) => ({ ...rest, setVideoPlayerState }));
    setFindReplaceState((rest) => ({ ...rest, setFindReplaceState }));
    setWordChunkEditorState((rest) => ({ ...rest, setWordChunkEditorState }));
    setWordChunkSliderState((rest) => ({ ...rest, setWordChunkSliderState }));
    setEditMenuState((rest) => ({ ...rest, setEditMenuState }));
  }, []);

  return (
    <EditorContext.Provider
      value={{ ...editorContextValues, setEditorContextValues }}
    >
      <VideoPlayerActionsContext.Provider value={videoPlayerActions}>
        <VideoPlayerStateContext.Provider value={videoPlayerState}>
          <FindReplaceContext.Provider value={findReplaceState}>
            <WordChunkEditorContext.Provider value={wordChunkEditorState}>
              <WordChunkSliderContext.Provider value={wordChunkSliderState}>
                <EditMenuContext.Provider
                  value={{ ...editMenuState, setEditMenuState }}
                >
                  <UnderlineProvider>
                    <LocalDirtyProvider>{children}</LocalDirtyProvider>
                  </UnderlineProvider>
                </EditMenuContext.Provider>
              </WordChunkSliderContext.Provider>
            </WordChunkEditorContext.Provider>
          </FindReplaceContext.Provider>
        </VideoPlayerStateContext.Provider>
      </VideoPlayerActionsContext.Provider>
    </EditorContext.Provider>
  );
};

export default EditorContextsProvider;
