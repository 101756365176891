import React, {useState, useEffect} from 'react';

import {resetPasswordWithCode, verifyPasswordResetCode} from 'utils/Firebase';
import styled from 'styled-components';
import qs from 'query-string';
import Button from '@material-ui/core/Button';
import KonchButton from 'components/common/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {useForm} from 'react-hook-form';
import LogoImg from 'static/images/logo.png';
import {toast} from 'components/common/Toaster';
import Spinner from 'components/common/Spinner';

const RegisterButton = styled(KonchButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
`;

const ShowPasswordIconOn = styled(VisibilityIcon)`
  min-width: 48px;
  color: #999;
`;

const ShowPasswordIconOff = styled(VisibilityOffIcon)`
  min-width: 48px;
  color: #999;
`;

const Root = styled.div`
  height: 100vh;
  background: none;
`;

const Logo = styled.div`
  position: absolute;
  padding: 20px 0 0 20px;
  & img {
    width: 80px;
  }
  @media (max-width: 1000px) {
    & img {
      width: 49px;
    }
  }
`;

const Cont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 550px;
  & button {
    text-transform: initial;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    & .left {
      max-height: 600px;
    }
    & .right {
      justify-content: end;
      max-height: 900px;
    }
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  &.right {
    background: #fff1e5;
    display: flex;
    align-items: left;
    flex-direction: column;
  }
`;

const PanelContent = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    padding: 30px 100px 0;
    width: 100%;
`;

const H1 = styled.h1`
  margin-bottom: 35px;
  font-weight: 100;
  color: #444;
  @media (max-width: 700px) {
    font-size: 1.4em;
    padding: 0;
    margin: 19px;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  width: 304px;
  text-align: left;
  p {
    text-align: center;
  }
  input {
    text-align:left;
    display: block;
    padding: 6px 0 3px 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #CCC;
    border-radius: 4px;
  }
  .MuiButton-containedPrimary {
    background-color: #4fae32;
    margin-top: 10px;
  }
`;

const InputCont = styled.div`
  width: 100%;
  position: relative;
  input {
    padding-right: 50px;
  }
  div {
    position: absolute;
    top: 6px;
    right: 0;
  }
`;

const A = styled.a`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`;

const Footer = styled.p`
  color: #777;
  padding: 10px;
  font-size: .9em;
`;

const Input = styled.input`
  ${({error}) => error && 'border-color: #e25d5d;'}
`;

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  text-align:left;
  padding: 8px 0 6px 0;
  width: 100%;
  margin-left: 7px;
  color: #333;
  font-size: .9em;
  ${({error}) => error && 'color: #e25d5d;'}
`;

const ErrorLabel = styled.span`
  min-height: 20px;
  font-size: .9em;
  &:empty {
    opacity: 0;
    transition: opacity 1s;
  }
  opacity: 1;
  color: #e25d5d;
  text-align: right;
  transition: opacity 1s;
  margin-right: 9px;
`;

const EnterprisePanel = ({settings}) => (
  <Panel className='right' style={settings}>
    <img src={settings.logo} alt='custom organization logo' style={{maxHeight: '60%', maxWidth: '60%'}} />
  </Panel>
);

const DefaultPanel = () => (
  <Panel className='right'>
    <PanelContent>
      <H1>Let's get you back in!</H1>
    </PanelContent>
    <div style={{height: '100%', width: '550px', overflow: 'hidden'}}>
      <img
        src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/6022008f5b8d8759df49e651_security-art.svg'
        alt='classroom setting'
      />
    </div>
  </Panel>
);

// <ErrorLabel direction='down'>{issues.all?.message}</ErrorLabel>
// watch()
export default (props) => {
  const {history, settings = {}} = props;
  const [processing, setProcessing] = useState(false);
  const [code, setCode] = useState();
  const [notMatching, setNotMatching] = useState();

  const {register, handleSubmit, errors, getValues} = useForm({
    mode: 'onBlur',
    shouldFocusError: true
  });
  const [showPassword, setShowpassword] = useState(false);

  const onSubmit = async ({password, verifyPassword}) => {
    if (processing) return;
    if (password !== verifyPassword) {
      toast.error('Passwords must match', {autoClose: 5000});
      return;
    }

    setProcessing(true);
    const {error, message} = await resetPasswordWithCode({code, password});
    if (!error) history.push('/signin');
    else {
      console.log({error, message, code});
      toast.error(message.split(':')[1] || message, {autoClose: 5000});
    }
    setProcessing(false);
  };

  const verifyCode = async (code) => {
    if (!code) history.push('/reset/request');
    else {
      const {error} = await verifyPasswordResetCode(code);
      if (!error) {
        setCode(code);
      } else history.push('/reset/request');
    }
  };

  useEffect(() => {
    console.log(props.location.search);
    const {oobCode} = qs.parse(props.location.search.substr(1));
    verifyCode(oobCode);
    document.title = 'Reset password';
    return () => {
      document.title = 'Konch';
    };
  }, []);

  return (

    <Root>
      {
        !settings.active && <Logo><img src={LogoImg} width='100px' alt='logo' /></Logo>
      }
      <Cont>
        <Panel className='left'>
          <Form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <H1>Reset you password</H1>

            <Label error={errors.password}>
                Password
              <ErrorLabel>{errors.password && ` ${errors.password.message}`}</ErrorLabel>
            </Label>
            <InputCont>
              <Input
                name='password'
                type={showPassword ? 'text' : 'password'}
                error={errors.password}
                className={errors.password ? 'small-shake' : ''}
                onBlur={() => {
                  const {password, verifyPassword} = getValues();
                  if (password === verifyPassword) setNotMatching(false);
                }}
                autoComplete='off'
                ref={register({
                  required: 'Please enter a password.',
                  minLength: {value: 6, message: 'Must be at least 6 characters'}
                })} />
              <div
                tabIndex={0}
                role="button"
                styling="text"
                onKeyDown={(evt) => evt.keyCode === 13 && setShowpassword(!showPassword)}
                style={{outline: 'none'}}
                onClick={() => setShowpassword(!showPassword)}
              >
                {
                  showPassword
                    ? <ShowPasswordIconOn />
                    : <ShowPasswordIconOff />
                }
              </div>
            </InputCont>

            <Label error={errors.verifyPassword}>
                Verify Password
              <ErrorLabel>
                {
                  errors.verifyPassword
                    ? ` ${errors.verifyPassword.message}`
                    : notMatching ? 'Passwords must match' : ''
                }
              </ErrorLabel>
            </Label>
            <InputCont>
              <Input
                name='verifyPassword'
                type={showPassword ? 'text' : 'password'}
                error={errors.verifyPassword}
                onBlur={() => {
                  const {password, verifyPassword} = getValues();
                  if (password !== verifyPassword) setNotMatching(true);
                  else setNotMatching(false);
                }}
                className={errors.verifyPassword ? 'small-shake' : ''}
                autoComplete='off'
                ref={register({required: 'Please verify the password.'})} />
            </InputCont>

            <Button
              type='submit'
              variant='contained'
              color='primary'
              style={{backgroundColor: settings.buttonColor || '#21b573', fontSize: '1em', height: '48px'}}
              disableElevation>
              { processing ? <Spinner small color='#FFF' /> : 'Reset Password' }
            </Button>
            <Footer>Don't have an account, yet? <A href='/register'>Create one here</A></Footer>
          </Form>
        </Panel>
        {
          settings.active
            ? <EnterprisePanel {...props} />
            : <DefaultPanel />
        }
      </Cont>
      {
        !settings.active &&
          <RegisterButton raised primary onClick={() => history.push('/register')}>Register for Free</RegisterButton>
      }
    </Root>

  );
};
