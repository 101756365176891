import { atom, useRecoilState } from 'recoil';
import {
  getSubscription,
  getInvoices,
  getPaymentMethods,
  getAdjustments,
  getCharges,
  getCustomerMetadata
} from 'utils/Firebase';

const subscriptionState = atom({
  key: 'subscriptionState',
  default: null
});

const paymentMethodsState = atom({
  key: 'paymentMethodsState',
  default: null
});

const invoicesState = atom({
  key: 'invoicesState',
  default: null
});

const adjustmentsState = atom({
  key: 'adjustmentsState',
  default: null
});

const customerMetadataState = atom({
  key: 'customerMetadataState',
  default: null
});

const chargesState = atom({
  key: 'chargesState',
  default: null
});

export default (props) => {
  const { customer: customerId, subscription: subscriptionId } = (props = {});
  const [subscription, setSubscription] = useRecoilState(subscriptionState);
  const [paymentMethods, setPaymentMethods] =
    useRecoilState(paymentMethodsState);
  const [invoices, setInvoices] = useRecoilState(invoicesState);
  const [adjustments, setAdjustments] = useRecoilState(adjustmentsState);
  const [customerMetadata, setCustomerMetadata] = useRecoilState(
    customerMetadataState
  );
  const [charges, setCharges] = useRecoilState(chargesState);

  const fetchSubscription = async (subscriptionIdParam) => {
    const sid = subscriptionIdParam || subscriptionId;
    if (!sid) {
      setSubscription({});
      return;
    }
    try {
      const data = await getSubscription(sid);
      if (!data) return setSubscription({});
      const s = { ...data, id: sid };
      setSubscription(s);
      return s;
    } catch (error) {
      console.log('error', error);
    }
    return subscription;
  };

  const fetchInvoices = async (customerIdParam) => {
    const cid = customerIdParam || customerId;
    console.log({ cid });
    setInvoices(null);
    if (!cid) return;
    const invoices = (await getInvoices(cid)) || [];
    setInvoices(invoices);
    return invoices;
  };

  const fetchAdjustments = async (customerIdParam) => {
    const cid = customerIdParam || customerId;
    console.log({ cid });
    setAdjustments(null);
    if (!cid) return;
    const adjustments = (await getAdjustments(cid)) || [];
    setAdjustments(adjustments);
    return adjustments;
  };

  const fetchCustomerMetadata = async ({ customerId }) => {
    if (!customerId) return;
    const metadata = await getCustomerMetadata({ customerId });
    setCustomerMetadata(metadata);
    return metadata;
  };

  const fetchCharges = async (customerIdParam) => {
    const cid = customerIdParam || customerId;
    setCharges(null);
    if (!cid) return;
    const charges = (await getCharges(cid)) || [];
    setCharges(charges);
    return charges;
  };

  const fetchPaymentMethods = async (customerIdParam) => {
    const cid = customerIdParam || customerId;
    setPaymentMethods(null);
    if (!cid) return;
    const paymentMethods = (await getPaymentMethods(cid)) || [];
    console.log({ paymentMethods });
    setPaymentMethods(paymentMethods);
    return paymentMethods;
  };

  return {
    fetchSubscription,
    fetchInvoices,
    fetchPaymentMethods,
    fetchAdjustments,
    fetchCustomerMetadata,
    fetchCharges,
    subscription,
    invoices,
    paymentMethods,
    adjustments,
    customerMetadata,
    charges
  };
};
