import React, {Component} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faUnlock, faEnvelope, faLink} from '@fortawesome/free-solid-svg-icons';

const Subtext = styled.p`
  min-height: 16px;
  font-size: 13px;
`;

const Cont = styled.div`
  position: relative;
  width: 100%;
  & svg.svg-inline--fa.fa-w-16 {
    width: inherit;
    height: 30px;
    width: 20px;
  }

  & input:focus {
    border-bottom: 2px solid #5173E7;
    ${props => props.invalid && `
      border-bottom: 2px solid rgba(255, 0, 0, 0.8);
    `}
    ${props => props.light && `
      border-bottom: 2px solid #7396F9;      
    `}
    ${props => (props.light && props.invalid) && `
      border-bottom: 2px solid rgba(255, 50, 50, 0.8);
    `}
  }
  & input::placeholder {
    ${props => props.light
    ? 'color: rgba(255, 255, 255, 0.3);'
    : 'color: rgba(0, 0, 0, 0.2);'}
  }
  & input {
    ${props => props.light && `
    color: rgba(255, 255, 255, 0.85);
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box; 
  `}

  ${props => props.invalid && `
    border-bottom: 2px solid rgba(255, 0, 0, 0.8);
    color: rgba(255, 0, 0, 0.8);
  `}

  ${props => (props.light && props.invalid) && `
    color: 2px solid rgba(255, 100, 100, 1);
    border-bottom: 2px solid rgba(255, 50, 50, 0.8);
  `}

  ${props => (props.password || props.email || props.url) && `
    padding-left: 45px;
  `}

  }

  & ${Subtext} {
    ${props => props.light
    ? 'color: rgba(255, 255, 255, 0.2);'
    : `color: rgba(0,0,0,0.3);
    `}
    ${props => props.invalid && `
      color: rgba(255,100,100,0.6);
    `}
  }

  & svg {
    ${props => (props.password || props.email || props.url)
    ? 'display: block;'
    : 'display: none;'}
    ${props => props.invalid &&
      'color: rgba(255, 0, 0, 0.9);'}
    ${props => props.light &&
      'color: rgba(255, 255, 255, 0.3);'}
    ${props => props.light &&
      props.invalid &&
      'color: rgba(255, 100, 100, 0.3);'}
  } 
`;

export const StyledInput = styled.input`
  border: none;
  border-bottom: 2px solid #d3d3d3;
  min-height: 40px;
  width: 100%;
  color: rgba(0,0,0,0.7);
  outline: none;
  letter-spacing: 0.3px;
  transition: all ease 200ms;
  background: transparent;
  box-sizing: border-box;
  font-size: 20px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: auto;
  padding-top: ${props => props.top ? props.top : '10'}px;
`;

const LeftIcon = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  color: rgba(0, 0, 0, 0.2);
`;

class Input extends Component {
  state = {}

  componentDidMount = () => {
    // console.log(this.props, 'mounty')
    if (this.props.defaultValue) this.setState({value: this.props.defaultValue});
  }

  handleChange = (event) => {
    this.setState({value: event.target.value});
    this.props.onChange && this.props.onChange(event);
    // if (this.props.dontCheck) return
    // if (this.props.email) this.setState({isValid: this.isValidEmail(event.target.value)})
    // else if (this.props.password) this.setState({isValid: this.isValidPassword(event.target.value)})
  }

  forceInvalidState = () => {
    this.setState({
      isValid: false
    });
  }

  reset = () => {
    this.setState({
      isValid: true,
      value: ''
    });
  }

  value = () => this.state.value

  isValid = () => {
    const {value} = this.state;
    const {email, password, url} = this.props;
    // console.log(value, email, password, url)
    let valid = true;
    if (email) valid = this.isValidEmail(value);
    else if (password) valid = this.isValidPassword(value);
    else if (url) valid = value && value.indexOf('http') >= 0;
    this.setState({isValid: valid});
    return valid;
  }

  isValidEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(email) || this.state.value === '';
    return isValid;
  }

  isValidPassword = (password) => {
    // Minimum eight characters, at least one letter and one number:
    // const re1 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    // Minimum eight characters, at least one letter, one number and one special character:
    const {re = /.*/} = this.props;
    // // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
    // const re3 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    // // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
    // const re4 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    // // Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
    // const re5 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/
    const isValid = re.test(password) || password === '';
    return isValid;
  }

  focus = () => {
    this.input && this.input.focus();
  }

  select = () => {
    this.input && this.input.select();
  }

  render = () => {
    const {isValid, value} = this.state;
    const {className, dontCheck, password, email, url, light, invalid, placeholder, invalidSubtext, subtext, onSubmit, onClick, onFocus, onBlur} = this.props;
    const finalValue = this.state.value || this.props.value || '';
    let finalIsValid = true;
    if (isValid !== undefined || invalid !== undefined) {
      finalIsValid = isValid || invalid === false;
    }

    let icon;
    if (password) icon = <LeftIcon><StyledIcon icon={finalIsValid ? faLock : faUnlock} /></LeftIcon>;
    else if (email) icon = <LeftIcon><StyledIcon icon={faEnvelope} top={6} /></LeftIcon>;
    else if (url) icon = <LeftIcon><StyledIcon icon={faLink} /></LeftIcon>;

    return (
      <Cont className={className} invalid={!finalIsValid} light={light} password={password} email={email} url={url}>

        {icon}

        <StyledInput
          onClick={onClick}
          type={password ? 'password' : 'text'}
          password={password}
          onBlur={evt => {
            if (!dontCheck) {
              this.isValid(evt);
            }
            onBlur && onBlur(evt);
          }}
          ref={t => (this.input = t)}
          onFocus={onFocus}
          onKeyDown={evt => {
            if (evt.keyCode === 13 && onSubmit) {
              this.props.onSubmit(value);
            }
          }}
          onChange={this.handleChange}
          value={finalValue}
          placeholder={placeholder} />

        <Subtext>
          {subtext || (!finalIsValid && invalidSubtext ? invalidSubtext : '')}
        </Subtext>

      </Cont>
    );
  }
}

export default Input;
