import Cookies from 'universal-cookie';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// if there is an ad blocker anon wont fire for JS client
// but we need an anon user for our server side tracking
// this ensures we have an anon user no matter what
export const initAnonUser = () => {
  const cookies = new Cookies();
  const key = 'ajs_anonymous_id';
  let ajsaid = localStorage.getItem(key) || cookies.get(key);
  if (!ajsaid) {
    ajsaid = uuidv4();

    const oneYear = 60 * 60 * 24 * 7 * 52; // sec * mins * hours * days * weeks

    localStorage.setItem('ajs_anonymous_id', ajsaid);
    cookies.set(key, ajsaid, { domain: 'konch.ai', maxAge: oneYear * 2 });
  }
  return ajsaid;
};

export const downloadText = (text, ext = '.txt') => {
  const link = document.createElement('a');
  link.setAttribute('href', encodeURI('data:text/csv;charset=utf-8,' + text));
  link.setAttribute('download', 'transcript' + ext);
  link.click();
};

export const downloadTxtFromTranscript = (transcript) => {
  const { words = [] } = transcript || {};
  const text = words
    .map((word) => {
      const content = word.alternatives[0].content;
      const isPunc = word.type === 'Punctuation';
      return isPunc ? content : ' ' + content;
    })
    .join('')
    .trim();

  downloadText(text);
};

export const spellCheck = async (word) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `http://suggestqueries.google.com/complete/search?client=chrome&q=${word}&gl=fr&output=toolbar`,
      json: true
    });

    const suggestion = data[1][0].split(' ')[0];
    if (suggestion !== word) return suggestion;
  } catch ({ message }) {
    console.log('error checking spelling');
  }
  return null;
};

export const setAuthTokenCookie = (key, locale = 'us') => {
  const cookies = new Cookies();
  if (!locale) console.log('locale not in get auth token request');
  cookies.set('__sk' + (locale || 'us'), key, {
    path: '/',
    maxAge: 60 * 60 * 24 * 7
  });
};

export const getAuthTokenCookie = (locale = 'us') => {
  const cookies = new Cookies();
  if (!locale) console.log('locale not in get auth token request');
  const key = '__sk' + (locale || 'us');
  const cookieVal = cookies.get(key);
  return cookieVal && cookieVal !== 'undefined' ? cookieVal : null;
};

export const removeAuthTokenCookie = (key) => {
  const cookies = new Cookies();
  cookies.remove('__skus');
  cookies.remove('__skeu');
};
