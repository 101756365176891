import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    width: 550,
    paddingTop: 20,
    borderRadius: 16,
    '@media (max-width: 600px)': {
      width: '100%',
      padding: 0,
      borderRadius: 0
    },
    position: 'relative',
    backgroundImage:
      'linear-gradient(180deg, rgba(255,248,0,0.4) 0%, rgba(241,255,0,0.2) 21%, rgba(254,255,0,0) 38%)',
    '&:hover': {
      '&:after': {
        top: '25px',
        left: '-150px',
        transition: 'top 1s, right 1s'
      },
      '&:before': {
        top: '-25px',
        left: '-90px',
        transition: 'top 1s, right 1s'
      },
      '& .one': {
        display: 'block'
      },
      '& .two': {
        display: 'none'
      }
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '200px',
      height: '200px',
      border: '19px solid ',
      borderColor: 'rgb(255, 229, 127)',
      borderRadius: '50%',
      top: '35px',
      left: '-150px',
      transition: 'top 1s, right 1s'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '200px',
      height: '200px',
      border: '3px solid ',
      borderColor: 'rgb(255, 229, 127)',
      borderRadius: '50%',
      top: '-35px',
      left: '-90px',
      transition: 'top 1s, right 1s'
    }
  },
  content: {
    padding: 20,
    '@media (max-width: 600px)': {
      paddingTop: 90
    },
    '& h2': {
      textAlign: 'center',
      fontSize: '1.375rem',
      paddingTop: 20
    },
    '& h3': {
      fontSize: '1.0625rem',
      fontWeight: 500,
      marginLeft: 10,
      marginBottom: 3
    },
    '.MuiTypography-body1': {
      fontSize: 'inherit !important',
      fontFamily: 'inherit !important'
    },
    '& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body2': {
      fontSize: '0.9375rem',
      '@media (max-width: 600px)': {
        fontSize: '0.75rem'
      }
    },
    '& p': {
      margin: 'auto',
      textAlign: 'center',
      fontSize: '0.875rem',
      color: '#777',
      lineHeight: '1.5em',
      width: 330
    },
    '& .MuiListItemIcon-root': {
      color: '#333',
      minWidth: 33
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 9
    }
  },
  check: {
    borderRadius: '100%',
    background: '#000',
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#FFF',
      fontSize: '0.9375rem'
    }
  },
  actions: {
    padding: '1rem 1.625rem',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      textAlign: 'center',
      padding: '1rem 1.625rem 0 0',
      margin: '0 auto',
      width: 340
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .footer': {
      '& p': {
        fontSize: '1rem',
        color: '#888',
        display: 'inline-block',
        marginLeft: 10,
        fontWeight: 400,
        lineHeight: '1.2em',
        top: -2,
        position: 'relative'
      }
    },
    '& .MuiButtonBase-root': {
      borderRadius: 7,
      padding: '10px 20px',
      minWidth: 110,
      fontWeight: 700,
      // backgroundColor: 'rgb(255, 229, 127)',
      // border: '1px solid #555555',
      '&.Mui-disabled': {
        background: '#eaeaea',
        color: '#c0c0c0'
      },
      '&:hover': {
        // backgroundColor: 'rgb(255, 240, 160)',
      }
    }
  },
  preview: {
    position: 'absolute',
    right: 20,
    top: 15,
    borderRadius: 11,
    color: '#eec750',
    backgroundColor: 'transparent',
    transition: 'color .5s',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#555',
      transition: 'color .5s'
    }
  },
  medal: {
    margin: 'auto',
    width: '70px',
    height: '70px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgb(255 229 86)',
    boxShadow: '0px 1px 0px rgba(0,0,0,.2)',
    '& .one': {
      display: 'none'
    }
  }
}));
