import React from 'react';
import uaParser from 'ua-parser-js';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';

import Overlay, {Modal} from 'components/common/Overlay';
import Button from 'components/common/Button';
import ChromeLogo from 'static/images/chrome_logo.png';

const A = styled.a`
  color: #5173E7;
  cursor: pointer;
`;

const Secondary = styled.div`
  display: flex;
  margin: 20px auto 0px;
`;

const P = styled.p`
  line-height: 1.8em;
  color: #666;
  max-width: 467px;
  margin: 26px auto 16px;
  font-size: 15px;
`;

export const isNotCompatible = () => (navigator.userAgent.toLowerCase().indexOf('edge') >= 0 || navigator.userAgent.toLowerCase().indexOf('msie') >= 0);

export const isNotChrome = () => (navigator.userAgent.toLowerCase().indexOf('chrome') === -1);

export const isMobile = () => {
  const userAgent = uaParser(window.navigator.userAgent);
  return userAgent?.device?.type === 'mobile';
};

const BrowserSupport = ({noclose, show: initShow = false, ignorable = false, onGenDoc, suggest}) => {
  const [show, setShow] = React.useState(initShow);

  React.useEffect ( () => {
    setShow(isNotCompatible() || initShow || (isNotChrome() && suggest));
  }, []);

  const close = () => {
    setShow(false);
  };

  if (!show) return '';

  console.log({ignorable});

  return (<Overlay dark>

    <FadeIn>
      <Modal>
        <img src={ChromeLogo} alt='Chrome Logo' />
        <h2>{
          isNotCompatible() ? <span>Your browser is not supported</span> : <span>Chrome Preferred</span>
        }</h2>
        <P>
          {
            isNotCompatible()
              ? <span>
                      Unfortunately, we do not currently support Microsoft Edge or Internet Explorer.
                      Good news is we support Safari, <A href='https://www.mozilla.org/' rel="noopener noreferrer" target='_blank'>Firefox</A> and <A rel="noopener noreferrer" href='https://www.google.com/chrome/' target='_blank'>Chrome</A>.
                <br />(We suggest Chrome for the richest editing experience.)
              </span>
              : <span>We suggest Chrome on a rugularly sized screen for the richest editing experience, and support most features with Firefox.</span>
          }
          {onGenDoc && <span>You may also <A rel="noopener noreferrer" onClick={onGenDoc}>Download the transcript in Microsoft Word</A>.</span>}
        </P>
        <br />

        {
          ignorable ? <Button onClick={close} style={{minWidth: 200}} primary raised>Ignore</Button> :
            <a href='https://www.google.com/chrome/' target='_blank' rel='noopener noreferrer'>
              <Button primary raised>Download Chrome</Button>
            </a>
        }

        <Secondary>
          {!isNotCompatible() && !noclose && <Button secondary onClick={close}>Close</Button>}
          <Button secondary onClick={(evt) => {
            window.Beacon('open');
            close();
          }}>Get Help</Button>
        </Secondary>
      </Modal>
    </FadeIn>

  </Overlay>
  );
};

export default BrowserSupport;
