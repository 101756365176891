import React from 'react';
import styled from 'styled-components';
import {Link as ReactRouterLink} from 'react-router-dom';
import HelpIcon from '@material-ui/icons/HelpOutline';

import LightTooltip from 'components/common/LightTooltip';

export const Link = styled(ReactRouterLink)`
  color: ${({light}) => light ? '#BBB' : '#1d70b8'};
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: ${({light}) => light ? '#1d70b8' : 'blue'};
    transition: color .5s;
  }
  transition: color .5s;
`;

export const SpanLink = styled.span`
  color: ${({light}) => light ? '#BBB' : '#1d70b8'};
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: ${({light}) => light ? '#1d70b8' : 'blue'};
    transition: color .5s;
  }
  transition: color .5s;
`;

const DefaultChildren = ({icon, text, verticalAlign = 'bottom'}) => (
  <span style={{display: 'inline-flex', alignItems: 'center', verticalAlign, marginLeft: 5}}>
    {text === undefined ? '' : <span>{text || 'Learn More'}</span>}
    {icon ? <HelpIcon style={{marginLeft: 5, fontSize: 17, marginTop: -2}} /> : !text ? <>&nbsp;&#187;</> : ''}
  </span>
);

export default ({children, icon, article, text, verticalAlign, ...props}) => (
  <LightTooltip title={!text ? 'Learn more' : ''} placement="top">
    {
      article ?
        <SpanLink
          {...props}
          role='link'
          tabIndex={-1}
          onClick={() => window.Beacon('article', article)}
          onKeyPress={() => window.Beacon('article', article)}>
          {children || <DefaultChildren text={text} icon={icon} verticalAlign={verticalAlign} />}
        </SpanLink> :
        <Link {...props} >{children || <DefaultChildren text={text} icon={icon} />}</Link>
    }
  </LightTooltip>
);

