export const pad = (n, width, z) => {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const stampToSeconds = (stamp) => {
  if (!stamp.match(/^\d\d:\d\d:\d\d$/i)) return null;
  const [hrs, mins, secs] = stamp.split(':');
  let seconds = parseFloat(secs);
  seconds += parseFloat(hrs) * 3600;
  seconds += parseFloat(mins) * 60;
  return seconds;
};

export const dictToTimeStamp = (timeDict, inclMs, exHrs, trim) => {
  return `${!exHrs ? trim && !timeDict.hours ? '' : timeDict.hh + ':' : ''}${timeDict.mm}:${timeDict.ss}${inclMs ? `.${timeDict.ms}` : ''}`;
};

export const getTimeStamp = (secs, inclMs, exHrs, trim) => dictToTimeStamp(getTimeDict(secs, !inclMs), inclMs, exHrs, trim);

export const getHumanTime = (secs) => {
  const timeDict = getTimeDict(secs, true);
  // console.log(timeDict)
  if (timeDict.hours > 0) return `${timeDict.hours} hour${timeDict.hours === 1 ? '' : 's'}`;
  if (timeDict.minutes > 0) return `${timeDict.minutes} minute${timeDict.minutes === 1 ? '' : 's'}`;
  if (timeDict.seconds > 0) return `${timeDict.seconds} second${timeDict.seconds === 1 ? '' : 's'}`;
};

export const getTimeDict = (secs, round) => {
  const time = {};
  secs = secs || 0;
  const dec = secs % 1; // get just decimal valie
  const minutes = Math.floor(secs / 60);
  time.seconds = (round ? Math.round(secs) : Math.trunc(secs)) % 60;
  time.hours = Math.floor(minutes / 60);
  time.minutes = minutes % 60;
  time.milSecs = round ? 0 : parseFloat(dec.toFixed(3), 10) * 1000;

  time.hh = pad(time.hours, 2);
  time.mm = pad(time.minutes, 2);
  time.ss = pad(time.seconds, 2);
  time.ms = pad(time.milSecs, 3);
  return time;
};

export default getTimeDict;
