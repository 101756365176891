import React from 'react';
import styled from 'styled-components';
import {Circle} from 'rc-progress';
import Check from '@material-ui/icons/DoneOutlineRounded';
import Uploading from '@material-ui/icons/CloudUploadRounded';

const Root = styled.div`
  position: relative;
  height: fit-content;
  width: 110px;
`;

const Icon = styled.div`
  position: absolute;
  left: 30px;
  top: 30px;
  
  color: rgb(81, 115, 231);
`;

const Text = styled.div`
  font-size: 15px;
  color: rgba(255, 255, 255, 0.2);
  letter-spacing: 0;
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translate(-50%, 0);
`;

export default ({value, className}) => (
  <Root className={className}>
    <Circle
      percent={value}
      strokeWidth={8}
      strokeColor='#5173E7'
    />
    <Icon>
      {value === 100 ? <Check style={{fontSize: '52px'}} /> : <Uploading style={{fontSize: '49px'}} />}
    </Icon>
    <Text>{value < 100 ? `${value.toFixed(2)}%` : ''}</Text>
  </Root>
);
