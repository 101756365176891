import React, {useEffect} from 'react';
import qs from 'query-string';
import styled from 'styled-components';
import {useMediaPredicate} from 'react-media-hook';
import {init as hjInit} from 'utils/Hotjar';
import * as Sentry from '@sentry/react';

import Grid from '@material-ui/core/Grid';

import DownloadAppBanner from 'components/common/DownloadAppBanner';
import ProfileHeader from 'components/common/Dashboardv2/Header';
// import BrowserSupportModal from 'components/common/BrowserSupportModal'
import Collections from 'components/common/Dashboardv2/Collections';
import Sidebar from 'components/common/Dashboardv2/Sidebar';
import CreateButton from 'components/common/Dashboardv2/CreateButton';
import CreateWorkspace from 'components/common/Dashboardv2/CreateWorkspace';
import UpdateEmail from 'components/common/Dashboardv2/UpdateEmail';
import Share from 'components/common/Share';

import useSearch from 'hooks/useSearch';
import useUpload from 'hooks/useUpload';
import useProfile from 'hooks/useProfile';
import useCollections from 'hooks/useCollections';
import SearchContext from 'context/SearchContext';
import CollectionsContext from 'context/CollectionsContext';

// import {toast} from 'components/common/Toaster'
import GettingStartedSteps from 'components/common/Dashboardv2/GettingStartedSteps';
import Container from 'components/common/Dashboardv2/Container';
import DropZone from 'components/common/Dashboardv2/DropZone';
import UploadQueue from 'components/common/Dashboardv2/UploadQueue';
import OverLimit from 'components/common/CreatorDialog/OverLimit';

import {isProd} from 'utils/env';

// let firstTimeTimeout
export const StyledGrid = styled(Grid)`
  &.showSidebar {
    padding: 30px 15px 300px;
  }
`;

export const StyledContainer = styled(Container)`
  max-width: 1590px;
`;

export default (props) => {
  document.title = 'Dashboard - Konch';
  const {history: routerHistory, onSetLanguageDefault} = props;
  // const [wait, setWait] = useState(true)
  const {updateSettings, settings, profile, isLoaded, organization, oid, uid} = useProfile();
  const {openDialog} = useUpload();
  const showSidebar = useMediaPredicate('(min-width: 780px)');
  const history = routerHistory.history || routerHistory;
  const {searchTerm, setSearchTerm, onChange, loading, setLoading} = useSearch({});
  const {selectCollection, count, ids, clearAll, deleteSelected, deleting} = useCollections({});

  const {product} = qs.parse(props.location.search.substr(1));

  const handleDrag = (evt) => {
    if (evt.dataTransfer.items.length === 0) return;
    for (let item of evt.dataTransfer.items) {
      const {kind} = item;
      if (kind !== 'file') {
        // console.log(kind, type, 'not file type so no upload trigger allowed')
        return;
      }
    }
    openDialog(evt);
  };

  useEffect(() => {
    const origBgColor = document.body.bgColor;
    document.body.bgColor = '#ECF0F4';

    updateSettings({homePage: 'dashboard'});

    const stillCookieBanner = document.querySelector('.cc-btn.cc-dismiss');
    if (stillCookieBanner) stillCookieBanner.classList.add('shake');
    if (product) openDialog();
    return () => {
      document.body.bgColor = origBgColor;
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      try {
        const {email = '', name, locale: profLocale = 'us', isCorrector, creationTime} = profile || {};
        const {locale: orgLocale = 'us', billing} = organization || {};
        const {customer, plan, enterprise} = billing || {};
        const locale = `${profLocale} | ${orgLocale}`;

        // HOTJAR: DON'T START HJ FOR NON DK AND EMPLOYEES
        if (!(email || '').endsWith('.dk') && !(email || '').endsWith('konch.ai') && !isCorrector && isProd()) {
          let isNewUser = true;
          try {
            isNewUser = (new Date() - new Date(creationTime)) / 1000 < (60 * 60 * 48); // 48 hours in seconds
          } catch (error) {
            console.log('error getting new usr date check');
          }
          isNewUser && hjInit({uid, email});
        }

        Sentry.setUser({email, uid, oid, locale});
        const params = {
          uid,
          oid,
          locale,
          customer,
          plan: enterprise ? 'enterprise' : plan,
        };
        if (email) params.email = email;

        window.Beacon('identify', params);

        window.Beacon('prefill', {
          email,
          name,
          subject: 'Help with Konch',
          text: `\n\n[Pictures are worth a thousand words. When possible, try a screen shot :-). No matter what you choose, we are happy to to hear from you!]`});

        console.log(Beacon('info'));
      } catch (error) {
        console.log('dashboard isloaded error', error);
      }
    }
  }, [isLoaded]);

  return (
    <>
      <DownloadAppBanner />
      <SearchContext.Provider value={{searchTerm, setSearchTerm, loading, setLoading}}>
        <CollectionsContext.Provider value={{selectCollection, count, ids, deleting}}>
          <ProfileHeader history={history} onSetLanguageDefault={onSetLanguageDefault} />
          <StyledContainer onDragOver={handleDrag} role='main'>
            <StyledGrid className='showSidebar' container direction='row' justifyContent='center' alignItems='flex-start'>

              {
                showSidebar && (
                  <Grid item md={2} sm={2} xs={1}>
                    <Sidebar onSearch={onChange} router={props.history} />
                  </Grid>
                )
              }

              <Grid item md={10} sm={10} xs={11} >
                <Collections oid={oid} count={count} deleting={deleting} onDeleteSelected={deleteSelected} onClearAll={clearAll} ids={ids} />
              </Grid>
            </StyledGrid>

            {
              profile?.isLoaded && (
                <>
                  <Share />
                  <OverLimit />
                  <UploadQueue />
                </>
              )
            }

            <CreateWorkspace />
            <UpdateEmail />

            <DropZone />

          </StyledContainer>
        </CollectionsContext.Provider>
      </SearchContext.Provider>

      {isLoaded && !settings?.welcomeSettings?.completed ? <GettingStartedSteps settings={settings} updateSettings={updateSettings} product={product} /> : ''}

      <footer><CreateButton oid={oid} uid={uid} /></footer>
    </>
  );
};

//             <BrowserSupportModal />
