import React from 'react';
import _debounce from 'lodash/debounce';
import styled from 'styled-components';

import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import {Modal as ModalCard} from 'components/common/Overlay';
import Spinner from 'components/common/Spinner';
import useCollection from 'hooks/useCollection';
import useOrganization from 'hooks/useOrganization';
import usePricing from 'hooks/usePricing';

import {trackCustom} from 'utils/Tracker';

const UpgradeContent = styled.div`
  height: 100vh; 
  display: flex;
  .upgrade-text {
    display: inline-block;
    text-align: right;
    white-space: nowrap;
  }
  .MuiButton-root:not(.done) {
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    background-color: ${({upgrading}) => upgrading ? 'lightgrey' : 'green'};
    color: #FFF;  
    :hover {
      background-color: #06a406;
    }
  }
  .MuiButton-root.Mui-disabled {
    background-color: lightgrey;
  }
  .fine-print {
     margin-top: 20px;
     p {
      color: #BBB;
      font-size: .75em;
      text-align: right;
      line-height: 1.7em;
      margin: 0;
     }
  }  
`;

const Actions = styled.div`
  display: flex;
  justify-content: ${({center}) => center ? 'center' : 'flex-end'};
  width: 100%;
  text-align: center;
  margin: 7px;
  .MuiButton-root {
    margin-left: 6px;
  }
`;

export const GenerateContentModal = ({id, onClose}) => {
  const {onConvertToBlog, language, locale, collection: {orgId: oid}, words} = useCollection({id});
  const {estimateConversionPrice} = usePricing({language});
  const {billing} = useOrganization({oid});
  const {enterprise, firstFree} = billing || {};
  const isFirstFree = !firstFree?.convertedToBlog;
  const [newCollection, setNewCollection] = React.useState();
  const [upgrading, setUpgrading] = React.useState(false);
  const [upgradeMessage, setUpgradeMessage] = React.useState();
  const [prompt, setPrompt] = React.useState('Write an essay based on this transcript');

  const _handleConvertToBlog = React.useCallback(async () => {
    if (upgrading) return;
    trackCustom({category: 'User', action: 'Attempt Generate Content From Transcript', data: {oid, language, locale}});
    setUpgrading(true);
    setTimeout(() => {
      setUpgradeMessage('Processing may take a minute. Do not exit this page.');
    }, 3000);
    const {payload: {id}} = await onConvertToBlog(prompt);
    setUpgrading(false);
    setNewCollection(id);
  });

  const handleConvertToBlog = _debounce(_handleConvertToBlog, 2000);
  const price = (estimateConversionPrice(words.join(' ')) / 100).toFixed(2);
  console.log(price);

  React.useEffect(() => {
    trackCustom({category: 'User', action: 'Opened Convert to Blog Modal', data: {oid, language, locale}});
  },[]);

  return (
    <Modal
      open={true}
      onClose={onClose}
    >
      <UpgradeContent>
        <ClickAwayListener onClickAway={onClose}>

          {
            newCollection ? (
              <ModalCard style={{background: '#FFF', width: 400}}>
                <h3 style={{textAlign: 'center'}}>Conversion started</h3>
                <Actions center>
                  <Button
                    variant='contained'
                    disableElevation
                    color='primary'
                    className='done'
                    href={`/dashboard`}
                  >
                    View in Dashboard
                  </Button>
                </Actions>
              </ModalCard>
            ) : (
              <ModalCard style={{background: '#FFF', textAlign: 'left', lineHeight: 2, paddingBottom: 0}}>
                <Box style={{width: '100%', padding: '0 20px 0'}}>
                  <h3 style={{marginTop: 0}}>Generate Content from Transcript</h3>
                  <p style={{margin: '0 0 20px 0'}}>
                    Convert your transcript into elequently written content with A.I. Once in the Magic Doc Text Editor, you can continue to edit, generate, and listen to the content.
                  </p>
                  <div>
                    <TextField
                      label='Prompt'
                      variant="outlined"
                      inputProps={{
                        style: {lineHeight: '1.4em'}
                      }}
                      value={prompt}
                      onChange={evt => setPrompt(evt.target.value)}
                      helperText={
                        <div>
                        Try other types like an email, summary, or blog post.
                          <span
                            role='link'
                            aria-label='try alternate prompt'
                            tabIndex={-1}
                            onClick={() => setPrompt('Explain the setting and sentiment of the interviewees in this transcript')}
                            onKeyPress={() => setPrompt('Explain the setting and sentiment of the interviewees in this transcript')}
                            style={{borderBottom: '1px dashed lightgrey', marginLeft: 5, cursor: 'pointer'}}
                          >
                          Or try this.
                          </span>
                        </div>
                      }
                      fullWidth
                      multiline
                    />
                  </div>
                  <div className='actions-cont' style={{marginTop: 0}}>
                    <div>
                      <div style={{textAlign: 'right', minHeight: 32, color: '#888'}}>
                        <small>{upgradeMessage}</small>
                      </div>

                      <Button
                        onClick={() => {
                          setUpgrading(true);
                          handleConvertToBlog(prompt);
                        }}
                        variant='contained'
                        disabled={upgrading || !price || prompt.length < 10}
                        style={{minWidth: 320}}
                        disableElevation
                      >
                        <div style={{width: 15}}/>
                        <div>
                          <span className='upgrade-text'>
                            {upgrading ? 'Generating' : 'Generate'} Unique Content
                          </span>
                          {
                            enterprise || isFirstFree ? '' : !price ? <Spinner small light inline style={{marginLeft: 10}} /> : ` for approx $${price ? price : <Spinner small />}`
                          }

                        </div>
                        <div style={{width: 45, display: 'flex', justifyContent: 'center'}}>
                          {upgrading && <Spinner light small />}
                        </div>
                      </Button>
                    </div>
                  </div>

                  <div style={{width: '100%', margin: '15px 0 5px 0', minHeight: 20}}>
                    {upgrading ? <LinearProgress style={{width: '100%'}} /> : '' }
                  </div>
                </Box>
              </ModalCard>
            )
          }

        </ClickAwayListener>
      </UpgradeContent>
    </Modal>
  );
};

export default GenerateContentModal;