import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import {
  fetchCollection as fetchCollectionAction,
  fetchCollections as fetchCollectionsAction,
  fetchSubtitles as fetchSubtitlesAction,
  renameCollection as renameCollectionAction,
  updateDescription as updateDescriptionAction,
  updateNotes as updateNotesAction,
  updateLanguage as updateLanguageAction,
  deleteCollection as deleteCollectionAction,
  removeCollectionFromState as removeCollectionFromStateAction,
  saveContent as saveContentAction,
  getContent as getContentAction,
  createTextCollection as createTextCollectionAction,
  selectCollectionById,
  selectCollectionSharedWith,
  selectCollectionOwner,
  selectCollectionDraftComplete,
  selectCollectionTranslating,
  selectCollectionDuration,
  selectCollectionLanguage,
  selectCollectionName,
  selectCollectionDescription,
  selectCollectionNotes,
  selectCollectionSpeakersArray,
  selectCollectionTranscriptionStarted,
  selectCollectionIsPaused,
  selectIsCollectionLoading,
  selectIsCollectionReadOnly,
  selectCollectionRecode,
  selectCollectionStarted,
  selectCollectionRetried,
  selectCollectionVersionId,
  searchCollections as searchCollectionsAction,
  searchLocalCollections as searchLocalCollectionsAction,
  selectSearchededOrgCollections,
  selectCollections,
  selectUserCollections,
  selectCollectionProgress,
  selectSearchedCollections,
  selectSharedCollections,
  selectLockedForEditing,
  selectOrgCollections,
  selectCollectionLogs,
  selectCollectionAudioVersions,
  selectTranscriptCompletionMeta,
  openShareModal as openShareModalAction,
  share as shareAction,
  denoise as denoiseAction,
  translate as translateAction,
  addToProject as addToProjectAction,
  owner as ownerAction,
  recoverCurrentVersion as recoverCurrentVersionAction,
  cancelRecoverCurrentVersion as cancelRecoverCurrentVersionAction,
  retry as retryAction,
  recode as recodeAction,
  unPause as unPauseAction,
  checkin as checkinAction,
  audioVersions as audioVersionsAction,
  switchAudio as switchAudioAction,
  addWelcomeCollection as addWelcomeCollectionAction,
  upgrade as upgradeAction,
  convertToBlog as convertToBlogAction,
  marker as markerAction,
  defaultContent
} from 'features/collections';

import {
  selectCollectionSpeakers,
  selectUniqueSpeakerNames,
  selectUniqueSpeakerNameObjects
} from 'features/speakers';

import { selectProjects } from 'features/profile';

import { EASY_PRECISION } from 'utils/env';

// import {toast} from 'components/common/Toaster'
import { selectCollectionHighlights } from 'features/highlights';
import { selectCollectionWords } from 'features/transcripts';
import { selectCollectionPeaks } from 'features/peaks';
import EditorContext from 'context/EditorContext';

const useCollectionV2 = ({ id, oid, uid, searchTerm, project } = {}) => {
  const dispatch = useDispatch();
  const { isCorrector, locale, isReviewer } = useContext(EditorContext);
  const collection =
    useSelector((state) => selectCollectionById(state, id)) || {};
  const duration = useSelector((state) => selectCollectionDuration(state, id));
  const draft = useSelector((state) =>
    selectCollectionDraftComplete(state, id)
  );
  const translating = useSelector((state) =>
    selectCollectionTranslating(state, id)
  );
  const isPaused = useSelector((state) => selectCollectionIsPaused(state, id));
  const progress = useSelector((state) => selectCollectionProgress(state, id));
  const isLoading = useSelector((state) =>
    selectIsCollectionLoading(state, id)
  );
  const isReadOnly = useSelector((state) =>
    selectIsCollectionReadOnly(state, id)
  );
  const tCompletion = useSelector((state) =>
    selectTranscriptCompletionMeta(state, id)
  );
  const isRetried = useSelector((state) => selectCollectionRetried(state, id));
  const lockedForEditing = useSelector((state) =>
    selectLockedForEditing(state, id, uid)
  );
  const transcriptionStarted = useSelector((state) =>
    selectCollectionTranscriptionStarted(state, id)
  );
  const started = useSelector((state) => selectCollectionStarted(state, id));
  const audioVersions = useSelector((state) =>
    selectCollectionAudioVersions(state, id)
  );
  const isShareModalOpen = useSelector((state) => state.collections.shareModal);

  const bookmarks = useSelector((state) =>
    selectCollectionHighlights(state, id)
  );
  const language = useSelector((state) => selectCollectionLanguage(state, id));
  const name = useSelector((state) => selectCollectionName(state, id));
  const description = useSelector((state) =>
    selectCollectionDescription(state, id)
  );
  const notes = useSelector((state) => selectCollectionNotes(state, id));
  const speakers = useSelector((state) => selectCollectionSpeakers(state, id));
  const recodeStatus = useSelector((state) =>
    selectCollectionRecode(state, id)
  );
  const speakersArray = useSelector((state) =>
    selectCollectionSpeakersArray(state, id)
  );
  const uniqueSpeakerNames = useSelector((state) =>
    selectUniqueSpeakerNames(state, id)
  );
  const uniqueSpeakerObjects = useSelector((state) =>
    selectUniqueSpeakerNameObjects(state, id)
  );
  const version = useSelector((state) => selectCollectionVersionId(state, id));
  const words = useSelector((state) => selectCollectionWords(state, id));
  const peaks = useSelector((state) => selectCollectionPeaks(state, id));
  const sharedWith =
    useSelector((state) => selectCollectionSharedWith(state, id)) || {};
  const owner = useSelector((state) => selectCollectionOwner(state, id)) || {};
  const logs = useSelector((state) => selectCollectionLogs(state, id)) || [];

  const collections = useSelector((state) => selectCollections(state));
  const orgCollections = useSelector((state) => selectOrgCollections(state));
  const userCollections = useSelector((state) => selectUserCollections(state));
  const sharedCollections = useSelector((state) =>
    selectSharedCollections(state)
  );
  const searchedCollections = useSelector((state) =>
    selectSearchedCollections(state, searchTerm, project)
  );
  const searchededOrgCollections = useSelector((state) =>
    selectSearchededOrgCollections(state, searchTerm, project)
  );

  const isRecoding = recodeStatus === false;

  const userProjects = useSelector((state) => selectProjects(state, oid)) || {};

  const projects = Object.keys(collection.projects || {}).reduce((acc, id) => {
    const project = userProjects[id];
    if (project) acc.push({ id, ...project });
    return acc;
  }, []);

  // for search results that don't have draft precision data. TODO: put draft daat in search?
  const isDraftComplete = draft === undefined ? progress >= 50 : draft;
  const isTranslating = translating && translating?.status !== 'COMPLETE';
  const isConvertingToBlog =
    collection?.type === 'text' && collection?.tCompletion?.blog === false;

  const minTimeToWait = 60; // 1 min
  // minimize the need to ping for updates.....
  const now = new Date().getTime() / 1000;
  const sinceStartedSeconds = now - (transcriptionStarted || started);
  // estimated time is 1/2 duration, with 10% padding
  const safeDuration = Math.max(minTimeToWait, duration);
  // half duration is estimate for transcribe, but no less than 30
  const expectedSeconds = Math.max(safeDuration / 2, minTimeToWait);
  const expectedTimeLeft = expectedSeconds - sinceStartedSeconds;
  // x times longer than expected to max out wait
  const maxTimeLeft = safeDuration * 2 - sinceStartedSeconds;

  // if it should have been done a while ago, don't ping
  // either there is an issue, or will take a lot of time and user can refresh or return to page later
  const shouldBeDoneButIsNot = !isDraftComplete && maxTimeLeft < 0;

  // draft is not complete - tprogress to show if precsiion is used
  // if you want to keep pinging while precision for tanslation (translating?.status === 'COMPLETE' && tCompletion.perfection === false)
  const isProcessing =
    (!isDraftComplete && !shouldBeDoneButIsNot) ||
    isTranslating ||
    isConvertingToBlog ||
    (!collection?.transcription && collection.tProgress > 0);
  const startedString = new Date(started * 1000)
    .toLocaleDateString()
    .replace(/\//g, ' - ');

  let message;
  if (isPaused)
    message = 'Not enough credits or payment issue. See tag for more details.';
  else if (isTranslating) message = 'Translating...';
  else if (collection?.type === 'text')
    message = isProcessing
      ? collection.progress === 'PENDING'
        ? 'Waiting...'
        : 'Converting...'
      : '[No content]';
  else if (isProcessing && !shouldBeDoneButIsNot) message = 'Processing...';
  else if (!isProcessing && shouldBeDoneButIsNot && isRetried)
    message = 'Could not complete.';
  else if (shouldBeDoneButIsNot && collection?.progress !== 'ERROR')
    message = 'Taking longer than expected. Retry?';
  else if (collection?.progress === 'ERROR')
    message =
      'Could not complete. Could be due to issue with media or lack of content to transcribe.';

  const renameCollection = (name) =>
    dispatch(renameCollectionAction({ id, name }));

  const updateDescription = (text) =>
    dispatch(updateDescriptionAction({ id, text }));

  const updateNotes = (text) => dispatch(updateNotesAction({ id, text }));

  const updateLanguage = (languageCode) =>
    dispatch(updateLanguageAction({ id, languageCode, locale }));

  const deleteCollection = (name) => dispatch(deleteCollectionAction({ id }));

  const removeCollectionFromState = () =>
    dispatch(removeCollectionFromStateAction({ id }));

  const fetchCollection = (versionId) => {
    // console.log('content', {id, versionId, locale})
    return isLoading
      ? null
      : dispatch(fetchCollectionAction({ id, versionId, locale }));
  };

  const fetchCollections = ({ nextPage, orgWide, shared }) =>
    dispatch(fetchCollectionsAction({ uid, oid, nextPage, orgWide, shared }));

  const addWelcomeCollection = ({ uid, oid }) =>
    dispatch(addWelcomeCollectionAction({ uid, oid }));

  const searchCollections = async ({ term, nextPage, orgWide }) => {
    return Promise.all([
      dispatch(searchLocalCollectionsAction({ oid, term, nextPage, orgWide })),
      dispatch(searchCollectionsAction({ oid, term, nextPage, orgWide }))
    ]);
  };

  const share = (uid) => dispatch(shareAction({ id, uid }));

  const setMarker = async (hash) => {
    await dispatch(markerAction({ id, hash }));
  };

  const fetchAudioVersions = async () => {
    await dispatch(audioVersionsAction({ id }));
  };

  const translate = (toLang) => dispatch(translateAction({ id, toLang }));

  const makeOwner = (uid) => dispatch(ownerAction({ id, uid }));

  const showShareModal = (on) => dispatch(openShareModalAction({ on, id }));

  const fetchSubtitles = (format = 'srt', size = 35, breakAllSpeakers, from) =>
    dispatch(
      fetchSubtitlesAction({ id, format, size, breakAllSpeakers, from })
    );

  const recoverCurrentVersion = () => dispatch(recoverCurrentVersionAction(id));

  const cancelRecoverCurrentVersion = () =>
    dispatch(cancelRecoverCurrentVersionAction(id));

  const addToProject = async ({ project, id, remove }) => {
    await dispatch(addToProjectAction({ id, project, remove }));
  };

  const onRetry = async () => {
    await dispatch(retryAction({ id }));
    await fetchCollection();
  };

  const onDenoise = async () => {
    await dispatch(denoiseAction({ id }));
  };

  const onUnPause = async () => {
    try {
      await dispatch(unPauseAction({ id }));
      await fetchCollection();
    } catch (error) {
      console.log(error);
    }
  };

  const onCheckin = () => dispatch(checkinAction({ id }));

  const onUpgrade = () => {
    const { orgId: oid } = collection;
    return dispatch(upgradeAction({ id, oid }));
  };

  const onConvertToBlog = async (prompt) => {
    const { orgId: oid } = collection;
    return dispatch(convertToBlogAction({ id, oid, prompt }));
  };

  const onRecode = ({ start, end, languageCode }) => {
    console.log({ start, end, id });
    return dispatch(recodeAction({ id, start, end, languageCode }));
  };

  const switchAudio = (url) => {
    return dispatch(switchAudioAction({ id, url }));
  };

  const saveContent = ({ content, snippet }) =>
    dispatch(saveContentAction({ id, content, snippet }));

  const createTextCollection = async ({ content, snippet, name }) => {
    const r = await dispatch(
      createTextCollectionAction({ uid, id, locale, oid, name, project })
    );
    await saveContent({ content: defaultContent, snippet: '' });
    return r;
  };

  const getContent = (url) => dispatch(getContentAction({ id, url }));

  const shortLanguage = language ? (language || '').split('-')[0] : language;

  const isCorrectorPage = window.location.pathname.indexOf('/corrector') >= 0;
  const isReviewPage =
    window.location.pathname.indexOf('/corrector/us/review/') >= 0;

  const markers = collection?.markers || {};
  const marker = markers[uid];

  const canUpgrade = !collection?.demo && tCompletion?.perfection === undefined;
  const canUpgradeQuickly = EASY_PRECISION.includes(shortLanguage);

  const creditsPerMinDraft = 0.13;
  const draftCost = Math.ceil(duration / 60) * creditsPerMinDraft;

  // console.log({duration})

  return {
    addToProject,
    audioVersions,
    addWelcomeCollection,
    bookmarks,
    canUpgradeQuickly,
    canUpgrade,
    isCorrected: tCompletion?.perfection,
    cancelRecoverCurrentVersion,
    collection,
    collections,
    createTextCollection,
    description,
    notes,
    deleteCollection,
    removeCollectionFromState,
    duration,
    defaultContent,
    minutes: duration / 60,
    draftCost,
    expectedTimeLeft,
    fetchAudioVersions,
    fetchCollection,
    fetchCollections,
    fetchSubtitles,
    saveContent,
    getContent,
    isCorrector,
    isDraftComplete,
    isLoading,
    isProcessing,
    isPaused,
    isRecoding,
    // if it is a corrector, the only reason for read only is if using versions,
    // otherwise, respect readonly seelctor for user
    isReadOnly:
      isCorrectorPage && (isCorrector || isReviewer) ? !!version : isReadOnly,
    isRetried,
    isShareModalOpen,
    isConvertingToBlog,
    language: (language || '').split('-')[0],
    locale: 'us',
    isCorrectorPage,
    isReviewPage,
    lockedForEditing: isCorrector && isCorrectorPage ? false : lockedForEditing,
    logs,
    makeOwner,
    message,
    markers,
    marker,
    name,
    onCheckin,
    onUpgrade,
    onRetry,
    onUnPause,
    onConvertToBlog,
    orgCollections,
    onRecode,
    onDenoise,
    owner,
    peaks,
    userProjects,
    projects,
    recoverCurrentVersion,
    renameCollection,
    searchCollections,
    searchedCollections,
    searchededOrgCollections,
    share,
    isTranslating,
    sharedCollections,
    sharedWith,
    setMarker,
    switchAudio,
    shouldBeDoneButIsNot,
    shortLanguage,
    showShareModal,
    speakers,
    speakersArray,
    startedString,
    transcriptionStarted,
    tCompletion,
    translate,
    uniqueSpeakerNames,
    uniqueSpeakerObjects,
    updateDescription,
    updateLanguage,
    updateNotes,
    userCollections,
    version,
    words
  };
};

export default useCollectionV2;
