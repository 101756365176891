import React from 'react';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';

import Slide from '@material-ui/core/Slide';
import CheckIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import Confetti from 'components/common/Confetti';

const Root = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: -100px;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(-70px);
  button {
    pointer-events: auto;
    bottom: 50px;
    position: fixed;
    margin-left: -75px;
    width: 150px;
    background-color: #ecf0f4;
    opacity: 0.9;
    :hover {
      background-color: #fff;
      opacity: 1;
    }
  }
`;

const SlideUpModal = styled.div`
  pointer-events: auto;
  box-shadow: 0px 1px 10px 1px rgb(0 0 0 / 20%);
  background-color: #f4f6f8;
  color: #124877;
  padding: 30px 40px 40px;
  border-radius: 6px;
  width: 460px;
  position: relative;
  .skip {
    opacity: 0;
    padding-left: 20px;
    text-transform: UPPERCASE;
    font-size: 13px;
    transition: opacity 0.1s 0s;
  }
  :hover .skip {
    opacity: 0.75;
    transition: opacity 1s 3s;
  }
  .lid {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    opacity: 0.2;
    padding-right: 15px;
  }
  p {
    margin: 0 0 19px 28px;
    font-size: 1.3em;
  }
  .task {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 5px;

    .text {
      display: flex;
    }

    :hover div:not(.step) svg {
      opacity: 0.8;
      transition: opacity 0.3s;
    }
    div:not(.step) svg {
      vertical-align: bottom;
      margin-left: 5px;
      opacity: 0;
      cursor: pointer;
      :hover {
        opacity: 1;
      }
      transition: opacity 1s;
    }

    .step {
      .MuiAvatar-root {
        height: 18px;
        width: 18px;
        font-size: 11px;
        font-weight: bold;
        margin-right: 10px;
        background-color: #5173e7;
        color: #fff;
      }
      svg {
        display: none;
        color: #7fd1e7;
        vertical-align: bottom;
        height: 19px;
        width: 19px;
        margin: 1px 10px 0 -1px;
        border: 1px solid #5173e7;
        border-radius: 50%;
      }
    }
    &.done {
      opacity: 0.5;
      .step svg {
        display: block;
      }
      .step .MuiAvatar-root {
        display: none;
      }
    }
  }
`;

const GettingStartedSteps = ({
  product,
  settings: { welcomeSettings } = {},
  updateSettings
}) => {
  const [redo, setRedo] = React.useState(0);
  const { show, uploaded, highlihghted, exported, translated, generated } =
    welcomeSettings || {};

  const { page } = useParams();

  const done = uploaded && highlihghted && exported && translated && generated;

  const updateWelcomeSettings = async (key, value) => {
    updateSettings({ ['welcomeSettings.' + key]: value });
  };

  const closeWelcome = async () => {
    if (!welcomeSettings) {
      await updateSettings({ welcomeSettings: { show: false } });
    } else {
      updateWelcomeSettings('show', false);
      if (done) setTimeout(() => updateWelcomeSettings('completed', true), 300);
    }
  };

  const showWelcome = async (show) => {
    if (!welcomeSettings) {
      await updateSettings({ welcomeSettings: { show: true } });
    } else {
      updateWelcomeSettings('show', true);
    }
  };

  if (product) return '';

  // Do not show guide on KonchMate Page
  if (page === 'meetings') return '';

  return (
    <>
      {done && show && redo !== -1 ? <Confetti lastSecs={3} redo={redo} /> : ''}
      <Root>
        {!show ? (
          <FadeIn delay={150}>
            {' '}
            <Button color="primary" onClick={showWelcome} variant="outlined">
              Show Guide
            </Button>{' '}
          </FadeIn>
        ) : (
          ''
        )}
        <Slide direction="up" in={show} mountOnEnter unmountOnExit>
          <SlideUpModal>
            <div className="lid">
              <div className="skip">Skip</div>
              <CloseIcon onClick={closeWelcome} />
            </div>
            <p>
              {done ? (
                <span
                  role="button"
                  aria-label="easter-egg-confetti"
                  tabIndex={-2}
                  onKeyDown={() => setRedo(redo + 1)}
                  onClick={() => setRedo(redo + 1)}
                >
                  You did it! &nbsp;
                  <span role="img" aria-label="party emoji">
                    🎉
                  </span>
                </span>
              ) : (
                `Let's help get you started`
              )}
            </p>

            <div className={`task ${uploaded ? 'done' : ''}`}>
              <div className="step">
                <CheckIcon />
                <Avatar>1</Avatar>
              </div>
              <div className="text">
                Upload and edit an audio or video file.
                <InfoIcon
                  role="button"
                  fontSize="small"
                  onClick={() => Beacon('article', '5c04462d2c7d3a31944ea0bf')}
                />
              </div>
            </div>
            <div className={`task ${highlihghted ? 'done' : ''}`}>
              <div className="step">
                <CheckIcon />
                <Avatar>2</Avatar>
              </div>
              <div className="text">
                Highlight and comment the transcript.
                <InfoIcon
                  role="button"
                  fontSize="small"
                  onClick={() => Beacon('article', '62d473bbc74a080359c8a774')}
                />
              </div>
            </div>
            <div className={`task ${exported ? 'done' : ''}`}>
              <div className="step">
                <CheckIcon />
                <Avatar>3</Avatar>
              </div>
              <div className="text">
                Export transcription by annotations
                <InfoIcon
                  role="button"
                  fontSize="small"
                  onClick={() => Beacon('article', '636dacdf514e5e47b9480188')}
                />
              </div>
            </div>
            <div className={`task ${translated ? 'done' : ''}`}>
              <div className="step">
                <CheckIcon />
                <Avatar>4</Avatar>
              </div>
              <div className="text">
                Translate transcription to another language
                <InfoIcon
                  role="button"
                  fontSize="small"
                  onClick={() => Beacon('article', '62d23d5cd242501d78c650a5')}
                />
              </div>
            </div>
            <div className={`task ${generated ? 'done' : ''}`}>
              <div className="step">
                <CheckIcon />
                <Avatar>5</Avatar>
              </div>
              <div className="text">
                Create "Magic Doc" with text and images.
                <InfoIcon
                  role="button"
                  fontSize="small"
                  onClick={() => Beacon('article', '63844fbe283f25254be91cdf')}
                />
              </div>
            </div>
          </SlideUpModal>
        </Slide>
      </Root>
    </>
  );
};

export default GettingStartedSteps;
