import React, { useEffect, useState } from 'react';

import Loader from 'components/common/Loaders/Skeleton';
import useSubscription from 'hooks/useSubscriptions';
import usePlan from 'hooks/usePlan';
import useProfile from 'hooks/useProfile';
import useOrganization from 'hooks/useOrganization';

const UploadProgress = (props) => {
  const { uid, oid, billing, isLoaded } = useProfile();
  const { updateBilling } = useOrganization({ oid });
  const { enterprise, updated, paymentMethod } = billing;
  const { fetchUsage, fetchCredits, calcForUsageMeter, credits } = usePlan({
    oid,
    uid
  }); // credits, usage
  const { fetchPaymentMethods, paymentMethods } = useSubscription(billing);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    perc,
    estPerc,
    minutesLeft,
    paygMins,
    estimatedDraft,
    draftPlan,
    paygPrecMins
  } = calcForUsageMeter();

  // console.log({minutesLeft, paygMins})

  const validateCard = (card) => {
    if (!card) return false;
    const today = new Date();
    const expirationDate = new Date(card.exp_year, card.exp_month, 0);
    return card.checks.cvc_check === 'pass' && expirationDate > today;
  };

  const init = async (force) => {
    if ((!loading && oid && uid && !draftPlan) || force) {
      setLoading(true);
      await Promise.all([fetchUsage(), fetchCredits(), fetchPaymentMethods()]);
      setLoading(false);
      // console.log({usage, credits, paymentMethods})
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (paymentMethods) {
      const activePaymentMethod = paymentMethods.find((pm) =>
        validateCard(pm?.card)
      );
      console.log({ activePaymentMethod });
      // use the chance to update payment cache in FB if was null or different now
      if (
        activePaymentMethod &&
        (!paymentMethod || paymentMethod !== activePaymentMethod.id)
      ) {
        updateBilling('paymentMethod', activePaymentMethod.id);
      }
    }
  }, [loaded]);

  useEffect(() => {
    init(true);
  }, [oid, updated]);

  // enterprise dont show anything
  if (enterprise) return '';

  // Loading....
  if (!isLoaded || (!loaded && loading && !enterprise))
    return (
      <div style={{ marginTop: '20px' }}>
        <Loader width="100px" />
        <Loader />
      </div>
    );

  // no plan or big plan or mins and assume there is an admin watching minites
  if (
    (!draftPlan && !paymentMethod && paygMins === undefined) ||
    draftPlan > 59999
  )
    return '';

  const activePlan = draftPlan > 0 && minutesLeft > 0 && billing.subscription;
  const fixPaymentMethod = !paymentMethod && paygMins === 0 && !activePlan;
  const showPAYG = paygMins >= 0 || paymentMethod;

  return {
    perc,
    estPerc,
    minutesLeft,
    paygMins,
    estimatedDraft,
    credits: credits * 100,
    draftPlan,
    paygPrecMins,
    isOutOfPlan: !showPAYG && !fixPaymentMethod && !activePlan,
    uid,
    oid,
    billing,
    isLoaded,
    updateBilling,
    enterprise,
    updated,
    paymentMethod,
    fetchUsage,
    fetchCredits,
    calcForUsageMeter,
    fetchPaymentMethods,
    paymentMethods,
    loaded,
    loading
  };
};

export default UploadProgress;
