import {createContext} from 'react';

const EditorContext = createContext({
  collectionId: null,
  locale: null,
  isCorrector: false,
  isReviewer: false,
  showVersions: false,
  pubkey: false
  // v3: false,
  // openEditOptions: true
});

export default EditorContext;
