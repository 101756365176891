import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ContentEditable from 'react-contenteditable';
import { useInterval } from 'react-interval-hook';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle as faCheck,
  faUser,
  faEllipsisH,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import DocIcon from '@material-ui/icons/DescriptionOutlined';
import PrecisionReviewedIcon from '@material-ui/icons/AccountBox';
import TranslateIcon from '@material-ui/icons/Translate';
import DeleteIcon from '@material-ui/icons/Close';
import Grouper from '@material-ui/lab/AvatarGroup';

import { toast } from 'components/common/Toaster';
import { PausedChip } from 'components/common/Dashboardv2/Chip';
import Avatar from 'components/common/Avatar';
import TranscriptionProgress from 'components/common/CreatorDialog/TranscriptionProgress';
import CollectionMenu, {
  Retry
} from 'components/common/Popover/CollectionMenu';
import Spinner from 'components/common/Spinner';
import useUsers from 'hooks/useUsers';
import useProfile from 'hooks/useProfile';
import useCollection from 'hooks/useCollection';
import LightTooltip from 'components/common/LightTooltip';
import EditorContexts from 'context/EditorContexts';
import CollectionsContext from 'context/CollectionsContext';
import { Tooltip } from '@material-ui/core';
import RetryWithLanguage from '../RetryWithLanguage';

momentDurationFormatSetup(moment);
TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

const TimeLabel = styled.div`
  display: flex;
  color: var(--colorSecondary);
  font-size: 0.625rem;
  justify-content: space-between;
  transition: color 1s;
  width: 100%;
  margin-right: 1rem;
`;

const Dot = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 1px solid #fff;
  margin: 0 -3px !important;
  transition:
    margin 0.5s 0.5s,
    transform 0.5s 0.4s;

  :hover {
    margin: -1px 4px 0 !important;
    :first-child {
      margin: -1px 5px 0 -3px !important;
    }
    transform: scale(1.4);
    transition:
      margin 0.5s 0.5s,
      transform 0.5s;
  }
`;
const RootCont = styled.div`
  position: relative;
  // &.react-draggable-dragging {
  //   z-index: 99;
  // }
`;

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 25px;
  margin-bottom: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  background: white;
  border-radius: var(--cardRadius);
  padding: 20px 16px 30px 16px;
  cursor: pointer;
  transition:
    opacity ease 200ms,
    transform ease 200ms;
  box-sizing: border-box;
  height: var(--cardHeight);
  width: var(--cardWidth);
  @media (max-width: 523px) {
    margin-left: 0px;
    width: 100%;
  }

  ${({ selected }) => (selected ? 'border: 1px solid blue;' : '')}

  :hover {
    transform: scale(1.06);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
    .lang-code-marker {
      // background-color: #fdf2f4;
      box-shadow: inset 0 0 0 rgb(100 100 100 / 0%);
      color: #888;
      transition:
        background-color 0.8s,
        box-shadow 0.8s,
        color 0.4s;
    }
  }

  ${(props) =>
    props.deleting &&
    `
    transition: opacity 4s, transform 5s;
    opacity: 0.1 !important;
    transform: scale(.10) !important;
  `}

  ${(props) =>
    props.isError &&
    `
    color: rgba(0, 0, 0, 0.4);
  `}
`;

const LangCodeMarker = styled.div`
  margin: 0px 5px 0px 0px;
  font-size: 0.6em;
  align-items: center;
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ecf0f4;
  padding: 4px 7px;
  border-top-left-radius: var(--cardRadius);
  color: #9e9e9e;
  font-weight: bold;
  border-bottom-right-radius: var(--cardRadius);
  box-shadow: inset -2px -2px 3px rgb(100 100 100 / 5%);
  transition:
    background-color 1s,
    box-shadow 1s;
`;

const LockedIndicator = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  background: #ecf0f4;
  left: calc(50% - 20px);
  top: -13px;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  box-shadow: inset 0px -1px 1px rgba(50, 50, 50, 0.07);
`;

const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -13px;
  height: 30px;
  z-index: 1;
`;

const NameField = styled(ContentEditable)`
  color: var(--colorPrimary)
  font-size: 0.875rem;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  outline: none;
  ${(props) => !props.disabled && 'background-color: #EFEFEF;'}
  overflow: hidden;
  max-height: 96px;
  max-width: 170px;
  word-wrap: break-word;
`;

const Text = styled.p`
  color: var(--colorSecondary);
  font-size: 0.8125rem;
  line-height: 20.8px;
  margin-top: 0.75rem;
  font-weight: 400;
`;

const Accuracy = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  color: #7dbb91;
  letter-spacing: 1.29px;
  line-height: 30px;
  flex: 1;
  min-width: 35px;
`;

const AccuracyData = styled.div`
  display: flex;
  align-items: center;
`;

const AIChecked = styled.div`
  margin: 0 12px 0 0;
  font-size: 1.2em;
  & ${Spinner} {
    position: absolute;
    left: -6px;
    top: 1px;
  }
  & svg {
    margin-right: 6px;
  }
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  margin: 0 12px 0 0;
  font-size: 1.3em;
  color: red;
`;

const CustomLink = styled.div`
  // (Link)
  flex: 1;
  overflow: hidden;
  margin-bottom: 8px;
  text-decoration: none;
`;

const OwnerLock = ({ uid, isChangingOwner }) => {
  const { user } = useUsers({ uid });
  if (isChangingOwner)
    return (
      <LockedIndicator>
        <Spinner size={20} />
      </LockedIndicator>
    );
  return (
    <LockedIndicator>
      {user ? (
        <Avatar profile={user} src={user.avatar} small size={22} />
      ) : (
        <FontAwesomeIcon icon={faUser} />
      )}
    </LockedIndicator>
  );
};

const hadIssuesProcessingMedia = ({ collection }) => {
  const noLanguageCode =
    collection?.languageCode === 'auto' || !collection?.languageCode;

  const isErrorStatus =
    noLanguageCode &&
    !!collection?.logs?.find(
      (log) =>
        log.status === 'Error' && log.details === 'Issue processing media.'
    );

  if (isErrorStatus) {
    return true;
  }

  if (collection?.status === 'COMPLETE' && noLanguageCode) {
    return true;
  }
};

const InfoBox = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '0.5rem',
        backgroundColor: '#f8d7da',
        padding: '0.5rem',
        marginBottom: '0.3rem'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ErrorIcon
            icon={faExclamationTriangle}
            style={{ fontSize: '0.7rem' }}
          />
          <h6 style={{ margin: 0, fontSize: '0.6rem', fontWeight: 500 }}>
            Couldn't Auto-Determine Language
          </h6>
        </div>
        <p
          style={{
            fontSize: '0.6rem',
            fontWeight: 400,
            margin: 0,
            marginTop: '0.2rem'
          }}
        >
          Retry by specifying the language using the button below.
        </p>
      </div>
    </div>
  );
};

export const Status = ({
  isError,
  collection,
  shouldBeDoneButIsNot,
  isTranslating,
  dontShowProgress,
  isTableRow,
  onRetryComplete
}) => {
  const {
    tCompletion,
    props,
    transcriptionStarted,
    tProgress,
    type = 'transcription',
    transcription,
    paused,
    tTranslation
  } = collection || {};

  const isProcessing = tProgress < 50 && type !== 'text';

  const [isRetrying, setIsRetrying] = useState(false);

  const [updatingLang, setUpdatingLang] = useState(false);

  const { updateLanguage, onRetry } = useCollection({
    id: collection.id,
    uid: collection.userId
  });

  const isProcessingMediaError = hadIssuesProcessingMedia({ collection });

  const { draft, perfection, blog } = tCompletion || {};
  const { duration } = props || {};

  const handleRetry = async () => {
    if (isRetrying) {
      return;
    }

    setIsRetrying(true);
    await onRetry?.();
    setIsRetrying(false);
    onRetryComplete && onRetryComplete();
  };

  const handleUpdateLanguage = async (lang) => {
    setUpdatingLang(true);
    await updateLanguage(lang);
    await handleRetry();
    setUpdatingLang(false);
  };

  // default status
  let status = (
    <AIChecked>
      <FontAwesomeIcon icon={faCheck} />
    </AIChecked>
  );

  if (isError) return <ErrorIcon icon={faExclamationTriangle} />;
  if (collection?.type === 'text') {
    if (blog === false) {
      return <Spinner small color="#7DBB91" style={{ marginRight: '10px' }} />;
    }

    return (
      <div style={{ display: 'flex', flex: '1' }}>
        <Tooltip
          title="Harness voice command recording for transcript editing and leverage AI for a wide range of tasks, including essay generation, image creation, and much more."
          arrow
          placement="top"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#9966CC',
              padding: '0.25rem 0.5rem',
              color: '#ffffff',
              fontWeight: 600,
              borderRadius: '0.5rem'
            }}
          >
            <DocIcon
              fontSize="small"
              style={{
                color: '#ffffff',
                marginRight: '0.2rem',
                fontSize: '1rem'
              }}
            />
            <span
              style={{
                fontSize: '0.5rem'
              }}
            >
              Magic Doc
            </span>
          </div>
        </Tooltip>
      </div>
    );
  }

  // console.log(collection.id, {isTranslating, tTranslation,tCompletion})
  if (paused) {
    status = <PausedChip />;
  } else if (isTranslating) {
    status = <Spinner small color="#7DBB91" style={{ marginRight: '10px' }} />;
  } else if (tTranslation) {
    status = (
      <>
        <Tooltip
          title={`This transcript has been translated from ${collection.tTranslation.from?.toUpperCase()} to ${collection.languageCode.toUpperCase()}.`}
          arrow
          placement="top"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#FF7F50',
              color: '#ffffff',
              padding: '0.25rem 0.5rem',
              fontWeight: 600,
              borderRadius: '0.5rem',
              lineHeight: 'normal',
              height: '24px'
            }}
          >
            <TranslateIcon
              fontSize="small"
              style={{
                fontSize: '0.7rem'
              }}
            />
            <span
              style={{
                fontSize: '0.5rem',
                marginLeft: '0.2rem'
              }}
            >
              AI Translated Transcript
            </span>
          </div>
        </Tooltip>

        {perfection === false && (
          <div style={{ position: 'absolute', top: -4, left: -4 }}>
            <Spinner
              small
              size={27}
              defaultColor={'#7dbb91'}
              thickness={0}
              secondThickness={1}
            />
          </div>
        )}
      </>
    );
  } else if (perfection) {
    status = (
      <Tooltip
        title="This transcript has undergone a thorough human review process, ensuring a high level of accuracy and quality assurance."
        arrow
        placement="top"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#50C878',
            color: '#ffffff',
            padding: '0.25rem 0.5rem',
            fontWeight: 600,
            borderRadius: '0.5rem',
            lineHeight: 'normal'
          }}
        >
          <PrecisionReviewedIcon
            style={{
              fontSize: '1rem'
            }}
          />

          <span
            style={{
              fontSize: '0.5rem',
              fontWeight: 600,
              marginLeft: '0.2rem'
            }}
          >
            Precision Reviewed
          </span>
        </div>
      </Tooltip>
    );
  }
  // if no draft and no progress it is working
  else if (draft === undefined && !tProgress) {
    return <Spinner small color="#7DBB91" style={{ marginRight: '10px' }} />;
  } else if (draft) {
    // if (trimmed) status = <PartialChip />
    if (!transcription) {
      status = (
        <Spinner small color="#7DBB91" style={{ marginRight: '10px' }} />
      );
    } else {
      if (isProcessingMediaError) {
        return updatingLang ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Retry retrying={true} />
            <p style={{ fontSize: '0.7rem', marginLeft: '0.5rem' }}>
              Updating language...
            </p>
          </div>
        ) : (
          <RetryWithLanguage
            mp3={collection.mp3}
            onUpdateLanguage={handleUpdateLanguage}
          />
        );
      }

      if (perfection === undefined) {
        status = (
          <Tooltip
            title="This transcript has been intelligently generated by our advanced AI technology."
            placement="top"
            arrow
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#246BCE',
                color: '#ffffff',
                padding: '0.25rem 0.5rem',
                fontWeight: 600,
                borderRadius: '0.5rem',
                lineHeight: 'normal',
                height: '24px'
              }}
            >
              <FontAwesomeIcon icon={faCheck} style={{ fontSize: '0.7rem' }} />
              <span
                style={{
                  fontSize: '0.5rem',
                  marginLeft: '0.2rem'
                }}
              >
                AI Transcript
              </span>
            </div>
          </Tooltip>
        );
      } else {
        status = perfection === false && (
          <Tooltip
            title="This transcript is currently in the process of undergoing a thorough human review to ensure a high level of accuracy and quality assurance."
            arrow
            placement="top"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#50C878',
                color: '#ffffff',
                padding: '0.25rem 0.5rem',
                fontWeight: 600,
                borderRadius: '0.5rem',
                lineHeight: 'normal'
              }}
            >
              <PrecisionReviewedIcon
                style={{
                  fontSize: '1rem'
                }}
              />

              <span
                style={{
                  fontSize: '0.5rem',
                  fontWeight: 600,
                  marginLeft: '0.2rem'
                }}
              >
                Precision Review Queued
              </span>
            </div>
          </Tooltip>
        );
      }
    }
  } else if (!dontShowProgress && duration && transcriptionStarted) {
    if (collection?.languageCode === '' || collection?.progress === 'ERROR') {
      status = '';
    } else {
      status = (
        <div style={{ marginRight: 4 }}>
          <TranscriptionProgress
            bar
            started={transcriptionStarted}
            duration={duration}
            disabled={paused}
            id={collection.id}
          />
        </div>
      );
    }
  } else if (isProcessing && !shouldBeDoneButIsNot)
    status = <Spinner small color="#7DBB91" style={{ marginRight: '10px' }} />;
  else if (shouldBeDoneButIsNot) status = '-';

  return (
    <Accuracy>
      <AccuracyData>{status}</AccuracyData>
    </Accuracy>
  );
};

export const Name = ({ id, name, nameIsEditable, onBlur, fullWidth }) => {
  const { renameCollection } = useCollection({ id });

  const handleNameClick = (evt) => {
    if (nameIsEditable) {
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  const handleNameBlur = (evt) => {
    console.log(evt.target.textContent);
    renameCollection(evt.target.textContent);
    onBlur(evt.target.textContent, evt);
  };

  const handleNameKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      evt.stopPropagation();
      evt.target.blur();
    }
  };

  return nameIsEditable ? (
    <NameField
      onBlur={handleNameBlur}
      onKeyDown={handleNameKeyDown}
      html={name}
      onClick={(evt) => handleNameClick(evt)}
    />
  ) : (
    <NameField
      html={name ? name.slice(0, 55) : 'No Name'}
      disabled
      alt={name}
      title={name}
    />
  );
};

export const Snippet = ({
  cid,
  snippet: text,
  message,
  languageCode,
  type,
  style,
  isTranslating
}) => {
  let snippet = text
    ? text
        .replace(/[ \n\t]+(!|\?|,|\.)[ \n\t]+/g, '$1 ')
        .replace(/ (\.|,|\?|!)$/, '$1')
        .slice(0, 250)
    : text === undefined || isTranslating
    ? ''
    : '[No Data]';

  if (snippet.length > 50) {
    snippet = snippet.slice(0, snippet.length - 3).trim() + '...';
  }

  if (!snippet) snippet = message;

  if (languageCode === '') {
    snippet = 'Could not determine language. Select language and try again.';
  }
  return <Text style={style}>{snippet}</Text>;
};

export default ({ id, usage, prevCollection, onSelect = () => {} }) => {
  const history = useHistory();
  const { uid, oid, setCurrentProject } = useProfile();

  const {
    addToProject,
    deleteCollection,
    removeCollectionFromState,
    fetchCollection,
    makeOwner,
    message,
    projects,
    collection,
    isProcessing,
    isTranslating,
    isConvertingToBlog,
    startedString,
    shouldBeDoneButIsNot,
    expectedTimeLeft
    // isRetried
  } = useCollection({ id, oid });

  const {
    selectCollection,
    ids = {},
    deleting
  } = React.useContext(CollectionsContext);

  const [name, setName] = useState(collection.name ? collection.name : '-');
  const [copyId, setCopyId] = useState(collection.name ? collection.name : '-');
  const [nameIsEditable, setNameIsEditable] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // const [isDragging, setIsDragging] = useState(false)
  // const [dragPosition, setDragPosition] = useState(null)

  const [isChangingOwner, setChangingOwner] = useState(false);
  const {
    languageCode,
    snippet,
    isError,
    tProgress,
    started,
    props = {}
  } = collection;
  const { languageCode: prevLanguageCode } = prevCollection || {};
  const isOwner = collection.userId === uid;
  const isTextDoc = collection.type === 'text';
  const href = `/${isTextDoc ? 'doc' : 'transcript'}/${collection.id}/`;

  const isProcessingMediaError = hadIssuesProcessingMedia({ collection });

  // shorten and compare
  const langCode = (languageCode || '').split('-')[0] || '';
  const prevLangCode = (prevLanguageCode || '').split('-')[0] || '';
  const sameLangCode = prevLangCode === langCode;
  const { duration } = props;
  const momentDuration = duration
    ? moment.duration(duration, 'seconds').format('HH:mm:ss', { trim: false })
    : '';

  // either wait for initial collection to load
  //   or wait for time left to hit 1/2 duration
  //   or keep pinging every 10 seconds
  const waitFor = isTranslating
    ? 3000
    : !duration
    ? 5000
    : expectedTimeLeft > 0
    ? expectedTimeLeft * 1000
    : 15000;

  const handleNameBlur = (name) => {
    setNameIsEditable(false);
    setName(name);
  };

  const handleDelete = (evt) => {
    setIsDeleting(true);
    deleteCollection();
  };

  const handleMoveWorkspace = () => {
    removeCollectionFromState();
  };

  const handleEditName = () => setNameIsEditable(true);

  const handleMakeOwner = async () => {
    setChangingOwner(true);
    await makeOwner(uid);
    setChangingOwner(false);
  };

  const { start, isActive, stop } = useInterval(
    //stop
    async () => {
      try {
        const { payload } = (await fetchCollection()) || {};
        // console.log('Payload', payload?.tProgress > 0)
        if (payload?.tProgress > 0) stop();
      } catch (err) {
        console.log('Issue checking for progress', collection);
      }
    },
    waitFor,
    {
      autoStart: false,
      immediate: false,
      selfCorrecting: true,
      onFinish: async () => {
        // console.log('Callback when timer is stopped')
        if (!snippet || (isProcessing && !collection?.transcription))
          fetchCollection();
      }
    }
  );

  const handleOnRetryComplete = () => {
    !isTextDoc && collection?.id && !isActive() && start();
  };

  const handleCardClick = (evt) => {
    isError && evt.preventDefault();
    if (evt.shiftKey) {
      console.log(id);
      evt.preventDefault();
      if (evt.metaKey) {
        try {
          setCopyId(true);
          var copyText = document.getElementById('input-' + id);
          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */
          document.execCommand('copy');
          toast.success('Collection ID Copied!');
        } catch (error) {
          console.log(error);
        }
        setCopyId(false);
      } else {
        selectCollection(id, collection.type);
      }
    } else if (!isTranslating && !isConvertingToBlog) {
      if (evt.metaKey) {
        window.open(href, '_blank');
      } else history.push(href);
    }
  };

  useEffect(() => {
    if (isProcessing && tProgress !== 50 && id) {
      start();
    }
    // tProgress !== undefined && tProgress === 200 && isActive() && stop()
  }, [tProgress]);

  // tProgress < 100 && console.log({waitFor, isActive: isActive(), isProcessing, tProgress})

  // console.log({prevLangCode, sameLangCode, langCode, name})

  const handleDragStart = (event) => {
    var crt = event.target
      .querySelector('[class*="CollectionCard__NameField"]')
      .cloneNode(true);
    crt.style.backgroundColor = 'rgba(255, 255, 255, .8)';
    crt.style.padding = '10px';
    crt.style.borderRadius = '7px';
    crt.classList.add('dragging');

    document.body.appendChild(crt);
    event.dataTransfer.setDragImage(crt, 0, 0);
    event.dataTransfer.setData('text', collection.id);
  };

  const handleDrag = (event) => {};

  const handleDragEnd = (event) => {
    document.querySelectorAll('.dragging').forEach((elem) => {
      try {
        elem.parentNode.removeChild(elem);
      } catch (error) {
        console.log('Issue with drag', elem, error);
      }
    });
  };

  return (
    <EditorContexts collectionId={collection.id} isCorrector={false}>
      <RootCont>
        <Root
          draggable="true"
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDrag={handleDrag}
          selected={ids[id]}
          deleting={isDeleting || (ids[id] && deleting)}
          isError={isError}
          isText={collection?.type === 'text'}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '0.5rem',
              height: '24px'
            }}
          >
            <TimeLabel deleting={isDeleting}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <div title={startedString}>
                  Uploaded {timeAgo.format((started || 0) * 1000)}
                </div>
                <div>{momentDuration}</div>
              </div>
            </TimeLabel>
            <CollectionMenu
              {...collection}
              shouldBeDoneButIsNot={shouldBeDoneButIsNot}
              onEditName={handleEditName}
              onRetryComplete={handleOnRetryComplete}
              onChangeOwner={handleMakeOwner}
              onDelete={handleDelete}
              onMoveWorkspace={handleMoveWorkspace}
              isProcessing={isProcessing}
              isError={isError}
            >
              <FontAwesomeIcon style={{ color: '#CCC' }} icon={faEllipsisH} />
            </CollectionMenu>
          </div>
          <CustomLink className="card" onClick={handleCardClick}>
            <Name
              name={name}
              id={collection.id}
              nameIsEditable={nameIsEditable}
              onBlur={handleNameBlur}
              isError={isError}
            />
            <Body>
              <div className="collection-id" style={{ display: 'none' }}>
                {id}
              </div>
              {isOwner === false && (
                <OwnerLock
                  isChangingOwner={isChangingOwner}
                  uid={collection.userId}
                />
              )}
              <div style={{ display: 'flex' }}>
                {(!prevLangCode || !sameLangCode) && langCode ? (
                  <LangCodeMarker className="lang-code-marker">
                    {langCode}
                  </LangCodeMarker>
                ) : (
                  ''
                )}
              </div>

              <Snippet
                cid={id}
                snippet={snippet}
                message={message}
                languageCode={collection?.languageCode}
                type={collection?.type}
                isTranslating={isTranslating}
              />
              {copyId && (
                <div style={{ opacity: 0, position: 'absolute', zIndex: 0 }}>
                  <input
                    id={`input-${id}`}
                    type="text"
                    aria-label="hidden collection id helper"
                    value={id}
                    readOnly
                  />
                </div>
              )}
              {isProcessingMediaError && (
                <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  <InfoBox />
                </div>
              )}
            </Body>
          </CustomLink>
          <Footer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Status
                isError={isError}
                isTranslating={isTranslating}
                shouldBeDoneButIsNot={shouldBeDoneButIsNot}
                collection={collection}
                onRetryComplete={handleOnRetryComplete}
              />
              <Grouper>
                {projects.map(({ id: pid, name, color } = {}, i) => (
                  <LightTooltip
                    key={i}
                    noWrapper
                    interactive
                    enterDelay={1500}
                    enterNextDelay={1500}
                    placement="top"
                    title={
                      <>
                        <div>{name}</div>
                        <DeleteIcon
                          onClick={() =>
                            addToProject({ id, project: pid, remove: true })
                          }
                        />
                      </>
                    }
                  >
                    <Dot
                      style={{ backgroundColor: color }}
                      onClick={() => setCurrentProject(pid)}
                    />
                  </LightTooltip>
                ))}
              </Grouper>
            </div>
            {ids[id] && deleting ? <Spinner small /> : null}
          </Footer>
        </Root>
      </RootCont>
    </EditorContexts>
  );
};
