import client from 'api/client';

const url = '/collections/v2';

export const fetch = (id, versionId, locale, pubkey) =>
  client().get(`/collections/v2/${id}`, {params: {pubkey, versionId}, locale});

export const moveCollectionToOrganization = ({cid, oid}) => client().post(`/collections/v2/${cid}/move`, {orgId: oid});

export const fetchAll = ({uid, oid: orgId, nextPage, orgWide, shared, limit = 50}) =>
  client().post(`/user/${shared ? '' : 'v2/'}${uid}/collections${shared ? '/shared' : ''}?limit=${limit}`,
    {orgId, nextPage, orgWide}
  );

export const search = ({oid, term, nextPage, orgWide}) =>
  client().post(`/collections/v2/search`, {orgId: oid, term, nextPage, orgWide});

export const fetchSubtitles = (id, format = 'srt', size = 35, breakAllSpeakers, locale, {pubkey, from}) =>
  client().get(`/collections/v2/${id}/subtitles`, {params: {format, size, breakAllSpeakers, pubkey, from}, locale});

export const rename = (id, name) =>
  client().patch(`collections/v2/${id}`, {name});

export const addToProject = ({id, project, remove}) =>
  client().patch(`collections/v2/${id}`, {project, remove});

export const remove = (id, name) =>
  client().delete(`collections/v2/${id}`);

export const share = ({id, uid: userId, remove}) => {
  console.log('share api', {id, userId, remove});
  const call = remove ? client().delete : client().post;
  return call(`collections/v2/${id}/share`, {userId, data: {userId}});
};

export const translate = ({id, to}) =>
  client().post(`collections/v2/${id}/translate`, {to});

export const owner = ({id, uid: userId}) =>
  client().post(`collections/v2/${id}/owner`, {userId});

export const audioVersions = ({id}) =>
  client().get(`collections/v2/${id}/audio`);

export const marker = ({id, marker}) =>
  marker ? client().post(`collections/v2/${id}/marker`, {marker}) : client().delete(`collections/v2/${id}/marker`, {});

export const retry = ({id}) =>
  client().post(`/pipeline/collection/${id}/retry`);

export const description = ({id, text}) =>
  client().post(`/collections/v2/${id}/description`, {text});

export const notes = ({id, text}) =>
  client().post(`/collections/v2/${id}/notes`, {text});

export const unPause = ({id}) =>
  client().post(`/pipeline/collection/${id}/unpause`);

export const checkin = ({id}) =>
  client().post(`/collections/v2/${id}/checkin/`);

export const recoverVersion = (id, versionId) =>
  client().patch(`/collections/v2/${id}/versions/${versionId}`);

export const upgradeToPrecision = (id) =>
  client().post(`/collections/v2/${id}/precision`);

export const convertToBlog = ({id, prompt}) =>
  client().post(`/collections/v2/${id}/blog`, {prompt});

export const convertToDoc = (id) =>
  client().post(`/collections/v2/${id}/doc`);

// change a portion of the transcript into a different language
export const recode = ({id, languageCode, start, end, locale}) =>
  client().post(`/pipeline/collections/${id}/recode`, {id, languageCode, start, end, locale});

export const denoise = ({id}) => client().post(`/pipeline/collections/${id}/denoise`, {});

export const saveContent = ({id, content, snippet, locale}) => client().patch(`${url}/${id}/content`, {content, snippet, locale});

export const getContent = ({id, locale}) => client().get(`${url}/${id}/content`, {locale});

export const updateLanguage = ({id, languageCode, locale}) => client().patch(`${url}/${id}/lang/${languageCode}`, {locale});

