import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import SelectLanguage, { isPrecisionEnabled } from './SelectLanguage';
import SelectDictionary from './SelectDictionary';
import AddHints from './AddHints';
import Description from './Description';
import useUpload from 'hooks/useUpload';
import useProfile from 'hooks/useProfile';
import useDictionary from 'hooks/useDictionary';
import useKaltura from 'hooks/useKaltura';
import usePlan from 'hooks/usePlan';
import DropboxButton from './DropboxButton';
import YouTubeUploader from '../Dashboardv2/YouTubeUploader';
// import useGoogleDrivePicker from 'hooks/useGoogleDrivePicker'

// import {faGoogleDrive} from '@fortawesome/free-brands-svg-icons'

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
`;

const Form = styled.div`
  max-width: 370px;
  width: 100%;
  @media (max-width: 523px) {
    border-left: none;
    padding: 0;
  }
  a:hover {
    color: #fff;
    transition: color 0.8s;
  }
  div.options {
    cursor: pointer;
    color: #7f7f7f;
    margin-bottom: 16px;
    font-size: 0.8em;
    transition: color 0.5s 0.8s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      font-size: 1.4em;
      opacity: 0;
      transition: opacity 0.6s 0.8s;
    }
    :hover {
      color: #fff;
      transition: color 0.6s;
      svg {
        opacity: 1;
        transition: opacity 0.6s;
      }
    }
  }
  .MuiFormControlLabel-root {
    justify-content: space-between;
    display: flex;
    margin-right: 0px;
    margin-bottom: 9px;
    .MuiFormControlLabel-label {
      opacity: 0.5;
      margin-left: -10px;
    }
  }

  .error {
    color: #ff4242;
    font-size: 16px;
    margin-bottom: 2rem;
    font-weight: bolder;
    text-shadow: 0 0 3px black;
  }

  .auto-detect-lang-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0.3;
    margin-top: -16px;
    margin-bottom: 18px;
  }
`;

const FormTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 44px;
`;

const Root = styled.div`
  margin-bottom: 20px;
  .MuiFormLabel-root {
    color: #fff;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #888;
  }
  .MuiSelect-nativeInput {
    color: #fff;
  }
  .MuiInputBase-root {
    color: #fff;
    font-weight: bold;
  }
`;

const InputErrorMsg = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 2rem;
`;

// const GoogleDriveButton = styled.button`
//   width: 100%;
//   padding: .7rem 0;
//   margin: 1rem 0;
//   font-size: 16px;
//   cursor: pointer;
// `

const Kaltura = ({ url: initUrl, onChange }) => {
  const { list, media = [], kalturaAppKey } = useKaltura();
  const [url, setUrl] = useState(initUrl || '');

  React.useEffect(() => {
    kalturaAppKey && list();
  }, [kalturaAppKey]);

  return (
    <Root>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="select-media-from-integration-label">
          {url ? 'Selected media' : 'Select media from Kaltura'}
        </InputLabel>
        <Select
          labelId="select-media-from-integration-label"
          id="select-media-from-integration"
          value={url}
          onChange={(evt, { props: { children: name } }) => {
            const url = evt.target.value;
            setUrl(url);
            onChange({ name, url });
          }}
        >
          {media.map((m, i) => (
            <MenuItem key={i} value={m.url}>
              {m.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
};

export default ({ isUrlForm, isLinkUpload, setUrlValidityStatus }) => {
  const { uid, oid, currentProject: project, settings } = useProfile();
  const { enhanced } = settings;

  const { usage = {} } = usePlan({ oid, uid });
  const {
    isOpen,
    editingUpload,
    defaultLanguage,
    uploads,
    setDefaultLanguage,
    update,
    checkUrl,
    closeDialog,
    updateDictionary,
    transcribe
  } = useUpload();
  const { name: initName, id } = editingUpload || {};
  const [name, setName] = useState(initName);
  const [languageCode, setLanguage] = useState(defaultLanguage);
  const [ignoreSentance, setIgnoreSentance] = useState(false);
  // const [showLanguageOptions, setShowLanguageOptions] = useState(false)

  const { dictionaries, fetchDictionaries } = useDictionary({
    uid,
    languageFilter: languageCode || defaultLanguage
  });

  const [url, setUrl] = useState('');
  const [dictionary, setDictionary] = useState('');
  const [hints, setHints] = useState('');
  const [description, setDescription] = useState('');
  const [urlErrorMessage, setUrlErrorMessage] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  // const {open:openGoogleDrivePicker, data, authRes} = useGoogleDrivePicker()

  // if workspace has precision minutes, let them request it
  const allowPrecision = usage.hasPrecision;
  const showPrecision =
    false && allowPrecision && isPrecisionEnabled({ languageCode });

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [advancedLoaded, setAdvancedLoaded] = useState(false);

  const handleShowAdvanced = async () => {
    setShowAdvanced(!showAdvanced);
    !advancedLoaded && (await fetchDictionaries());
    setAdvancedLoaded(true);
  };

  // useEffect(() => {
  //   if(data){
  //     if(data.docs.length < 1) return
  //     const doc = data.docs[0]
  //     console.log(authRes)
  //     // const {name, id, url} = doc
  //     // const accessToken = authRes.access_token
  //     const {name, id} = doc

  //     // setName(name)
  //     // setUrl(url)
  //     // handleCheckUrl(`https://www.googleapis.com/drive/v3/files/${id}?alt=media`, {'Authorization': `Bearer ${accessToken}`})
  //     // handleCheckUrl(url)

  //     // get the webContentLink using the ID.
  //     window.gapi.client.drive.files
  //       .get({fileId: id, fields: '*'})
  //       .then((res) => {
  //         const webContentLink = JSON.parse(res.body).webContentLink
  //         setName(name)
  //         setUrl(webContentLink)
  //         handleCheckUrl(webContentLink)
  //       })
  //       .catch((err) => {
  //         console.log('is error', err)
  //       })
  //   }
  // },[data])

  useEffect(() => {
    setName(initName);
  }, [initName]);

  useEffect(() => {
    !isUrlForm && !isLinkUpload && updateDictionary({ id, dictionary });
  }, [dictionary]);

  const handleLanguage = (lang) => {
    if (languageCode !== lang) {
      setDictionary(undefined);
      setLanguage(lang);
    }
  };

  const handleCheckUrl = async (link) => {
    if (!link) {
      setUrlValidityStatus({
        loadingStatus: false,
        urlValidity: false
      });
      return;
    }

    setUrlValidityStatus({
      loadingStatus: true,
      urlValidity: false
    });
    setUrlErrorMessage('');
    const { payload } = await checkUrl({ url: link });

    if (payload.error) {
      setUrlValidityStatus({
        loadingStatus: false,
        urlValidity: false
      });
      setIsValidUrl(false);
      const message =
        typeof payload.error === 'object'
          ? payload.error.result
          : payload.error;
      setUrlErrorMessage(message);
    } else {
      setUrlValidityStatus({
        loadingStatus: false,
        urlValidity: true
      });
      setUrlErrorMessage('');
      setIsValidUrl(true);
    }
  };

  const onSubmit = (evt, isPrecision) => {
    if (disabled) return;
    const beta = evt.shiftKey;
    const params = {
      dictionary,
      languageCode,
      description,
      url,
      enhanced,
      name,
      id,
      hints,
      isPrecision,
      beta,
      isLinkUpload,
      ignoreSentance,
      project
    };

    if (isUrlForm || isLinkUpload) transcribe(params);
    else update(params);
    closeDialog();
  };

  const disabled = !name || !languageCode || (isLinkUpload && !isValidUrl);

  // console.log({id, uploads, editingUpload, isOpen})
  const [prevUploads, setPrevUploads] = React.useState();

  React.useEffect(() => {
    if (uploads.length === 0 && uploads.length < prevUploads) {
      closeDialog();
    } else setPrevUploads(uploads.length);
  }, [uploads.length]);

  if (isOpen === 'youtube-upload') {
    return <YouTubeUploader />;
  }

  return (
    <Form isUrlForm={isUrlForm}>
      {isUrlForm ? (
        <Kaltura
          onChange={({ name, url }) => {
            setName(name);
            setUrl(url);
          }}
        />
      ) : (
        <>
          <FormTitle>Extra details?</FormTitle>

          {/*
              isOpen === 'google-drive' && (
              <GoogleDriveButton onClick={() => {
                openGoogleDrivePicker()
              }}><FontAwesomeIcon icon={faGoogleDrive} /> Select from Google Drive</GoogleDriveButton>)
            */}
          {isOpen === 'dropbox' && (
            <DropboxButton
              setName={setName}
              setUrl={setUrl}
              handleCheckUrl={handleCheckUrl}
            />
          )}
          <Input
            value={name || ''}
            id="uploader-name-input"
            light
            onChange={(evt) => {
              setName(evt.target.value);
            }}
            placeholder="Give your transcript a name"
          />
          <>
            {isOpen === 'link' && (
              <Input
                value={url || ''}
                id="uploader-url-input"
                light
                onBlur={(evt) => handleCheckUrl(evt.target.value)}
                onChange={(evt) => {
                  setUrl(evt.target.value);
                }}
                placeholder="Enter a URL"
              />
            )}
            {urlErrorMessage && (
              <InputErrorMsg className="error">{urlErrorMessage}</InputErrorMsg>
            )}
          </>
        </>
      )}

      <SelectLanguage
        isLoaded
        languageCode={languageCode}
        onSelectLanguage={handleLanguage}
        onSetLanguageDefault={setDefaultLanguage}
        defaultLanguage={defaultLanguage}
      />

      <div
        role="button"
        tabIndex={-1}
        className="options"
        onClick={handleShowAdvanced}
        onKeyPress={console.log}
      >
        Advanced Options & Accuracy
        <a
          href="https://help.konch.ai/article/70-advanced-options-hints-and-dictionary"
          target="_blank"
          rel="noreferrer"
        >
          <HelpOutlineIcon />
        </a>
      </div>

      {showAdvanced && (
        <>
          <AddHints onChange={(hints) => setHints(hints)} />
          <Description
            onChange={(description) => setDescription(description)}
          />
          {dictionaries && dictionaries.length > 0 ? (
            <SelectDictionary
              onChange={(dictionary) => setDictionary(dictionary)}
              languageCode={languageCode}
              dictionaries={dictionaries}
            />
          ) : !advancedLoaded ? (
            <p style={{ color: '#7f7f7f', margin: '30px 0px 30px 10px' }}>
              Loading dictionaries...
            </p>
          ) : (
            <p style={{ color: '#7f7f7f', margin: '30px 0px 30px 10px' }}>
              No dictionaries for this language.{' '}
              <Link to="/settings/dictionaries">Add one?</Link>
            </p>
          )}

          <FormControlLabel
            labelPlacement="start"
            control={
              <Checkbox
                checked={ignoreSentance}
                onChange={() => setIgnoreSentance(!ignoreSentance)}
                name="ignoreSentance"
                color="primary"
              />
            }
            label="Do not estimate end of sentence:"
          />
        </>
      )}

      <Flex direction="column">
        <Button
          accent
          raised
          fullWidth
          medium
          onClick={(evt) => onSubmit(evt, false)}
          disabled={disabled}
        >
          Start Transcribing
        </Button>

        {showPrecision && (
          <p>
            <Button
              accent
              raised
              fullWidth
              medium
              primary
              onClick={(evt) => onSubmit(evt, true)}
              disabled={disabled}
            >
              Request Precision
            </Button>
          </p>
        )}
      </Flex>
    </Form>
  );
};
