import { createContext } from 'react';

const VideoPlayerActionsContext = createContext({
  setVideoPlayerValues: () => {},
  handleQuickRewind15: () => {},
  handleQuickRewind: () => {},
  handleFastForward: () => {},
  handleFastForward15: () => {},
  onPlay: () => {},
  onPause: () => {},
  goSlow: () => {},
  goNormal: () => {},
  goFast: () => {},
  goFaster: () => {},
  onSeek: () => {}
});

export default VideoPlayerActionsContext;
