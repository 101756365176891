import React, {useState} from 'react';
// import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    color: '#FFF',
    '& .MuiInputBase-input': {
      color: '#EEE'
    },
    '& .MuiFormLabel-root': {
      color: '#7f7f7f'
    },
    '& .MuiFilledInput-underline:after': {
      borderColor: '#7f7f7f'
    }
  },

}));

export default ({onChange}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleChange = event => {
    const text = event.target.value;
    setValue(text);
    onChange && onChange(text.split(',').map(content => ({content: content.trim()})));
  };

  return (
    <div style={{marginBottom: '14px'}}>
      <TextField
        id="outlined-multiline-flexible"
        label="Hints (Comma seperated)"
        multiline
        maxRows={15}
        variant='filled'
        value={value || ''}
        fullWidth
        className={classes.formControl}
        onChange={handleChange}
      />
    </div>
  );
};

