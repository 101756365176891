import React from 'react';
import styled from 'styled-components';
import skeletonImage from './skeleton.png';

const Image = styled.img`
  height: var(--cardHeight);
  width: var(--cardWidth);
  margin-left: 25px;
  margin-bottom: 12px;
  border-radius: var(--cardRadius);
`;

const CardSkeleton = (props) => (
  <Image aria-label="Card Skeleton" src={skeletonImage} {...props} />
);

export default CardSkeleton;
