import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  current,
  createSlice
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {toast} from 'components/common/Toaster';
import {upload as uploadAction} from 'api/s3';
import {processingCollection, fetchCollections} from 'features/collections';
import {check as checkUrlAction, transcribe as transcribeAction} from 'api/pipeline';
import {trackCustom} from 'utils/Tracker';

export const updateProgress = createAsyncThunk(
  'uploader/progress',
  ({id, loaded, total, abortController}, {getState, dispatch}) => {
  });

export const open = createAsyncThunk(
  'uploader/open',
  async (args, {getState, dispatch}) => {}
);

export const showOverLimit = createAsyncThunk(
  'uploader/overlimit/open',
  async (args, {getState, dispatch}) => {
  }
);

export const openEditor = createAsyncThunk(
  'uploader/openEditor',
  async ({id}, {getState, dispatch}) => {
  }
);

export const close = createAsyncThunk(
  'uploader/close',
  async (args, {getState, dispatch}) => {
  }
);

export const cancelUpload = createAsyncThunk(
  'uploader/cancelUpload',
  async (args, {getState, dispatch}) => {
  }
);

export const upload = createAsyncThunk(
  'uploader/upload',
  async ({blob, id, oid, uid, locale, name = 'Name your file', abortController}, {getState, dispatch}) => {
    const state = getState();
    if (state.uploader.uploads[id]?.loaded) {
      console.log('already uploading', id);
      return null;
    }
    const progress = ({loaded, total}) => dispatch(updateProgress({id, loaded, total, abortController}));

    // TODO: break into its own thread to unlock initial creation
    const {tempUrl} = await uploadAction({blob, oid, uid, progress, cancelSignal: abortController.signal, locale});
    return tempUrl;
  }
);

export const update = createAsyncThunk(
  'uploader/update',
  async ({id, name, languageCode, beta, updateAction, hints, description, isPrecision, convertToBlog, ignoreSentance, project}, {getState, dispatch}) => {
    console.log({id, name, languageCode, beta, updateAction, hints, description, isPrecision, ignoreSentance, project, convertToBlog});
  }
);

export const updateDictionary = createAsyncThunk(
  'uploader/update/dictionary',
  async ({id, dictionary}, {getState, dispatch}) => {
  }
);

export const transcribeFromUpload = createAsyncThunk(
  'uploader/uploads/transcribe',
  async ({id, uid}, {getState, dispatch}) => {
    const state = getState();
    const {
      url,
      languageCode,
      isPrecision,
      name,
      oid,
      complete,
      description,
      hints,
      beta,
      dictionary,
      ignoreSentance,
      convertToBlog,
      project
    } = {...state.uploader.uploads[id]} || {};
    // ignore anythng beyond the first request to complete
    if (url && complete <= 1) {
      try {
        const response = await transcribeAction({
          uid,
          oid,
          url,
          name,
          description,
          hints,
          languageCode,
          beta,
          isPrecision,
          dictionary,
          ignoreSentance,
          convertToBlog,
          project
        });
        const {data} = response;
        console.log({response, data});
        let {id, cid, convertToBlog: c2bCID} = data || {};
        cid = id || cid;
        await dispatch(processingCollection({cid, name, uid, oid, project, convertToBlog: c2bCID}));
        if (c2bCID) await dispatch(fetchCollections({oid, uid}));
        return {cid};
      } catch ({response: {data}}) {
        const uploadName = state.uploader.uploads[id].name;
        const {error = `There was an issue transcribing ${uploadName}`} = data;
        const message = `Issue with ${uploadName}: ${error}`;
        trackCustom({category: 'Upload', action: 'Error', data: {...data, message, uid, id}});
        Sentry.captureMessage(message);
        toast.error(message, {autoClose: false});
        return {error};
      }
    }
    return null;
  }
);

export const transcribe = createAsyncThunk(
  'uploader/transcribe',
  async (params, {getState, dispatch}) => {
    const {uid, url, enhanced, languageCode, isPrecision, description, hints, beta, name, oid, dictionary, ignoreSentance, project, convertToBlog} = params;
    console.log({params});
    if (url && languageCode && oid) {
      try {
        const response = await transcribeAction({
          uid,
          oid,
          url,
          enhanced,
          description,
          name,
          hints,
          beta,
          languageCode,
          isPrecision,
          dictionary,
          ignoreSentance,
          convertToBlog,
          project
        });
        const {data} = response;
        console.log({response, data});
        const cid = data.id || data.cid;
        await dispatch(processingCollection({cid, name, uid, oid, project, convertToBlog}));
        return cid;
      } catch ({response: {data}}) {
        const {error = `There was an issue transcribing ${name}`} = data;
        toast.error(`Issue with ${name}: ${error}`, {autoClose: false});
        return null;
      }
    }
    return null;
  }
);

export const checkUrl = createAsyncThunk(
  'uploader/checkUrl',
  async ({url,headers}) => {
    // console.log(url, headers)
    if (url) {
      try {
        const response = await checkUrlAction({url});
        // console.log('response', {response})
        return response;
      } catch (err) {
        // console.log('response', {err})
        const {response: {data: error}} = err;
        return {error};
      }
    }
  }
);

// Slice
export const uploaderAdapter = createEntityAdapter();
const initialState = uploaderAdapter.getInitialState({
  uploads: {},
  isOpen: false,
  isEdit: ''
});

export const slice = createSlice({
  name: 'uploader',
  initialState,
  extraReducers: builder => {

    builder
      .addCase(open.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        const view = meta?.arg?.view;
        if (state.isOpen !== view) state.isOpen = view || 'file-drop';
      });
    builder
      .addCase(close.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        state.isOpen = false;
        state.isEdit = '';
      });

    builder
      .addCase(openEditor.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        state.isEdit = meta?.arg?.id;
        state.isOpen = true;
      });

    builder
      .addCase(upload.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        const {id, oid, name = 'Name your file'} = meta?.arg || {};
        if (!state.uploads[id]) state.uploads[id] = {name, oid, url: payload};
        else {
          if (!state.uploads[id].name) state.uploads[id].name = name;
          state.uploads[id].fileName = name; // original name
          state.uploads[id].oid = oid;
          state.uploads[id].url = payload;
        }
      })
      .addCase(update.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        const {
          id,
          name,
          languageCode,
          beta,
          isPrecision,
          description,
          hints,
          ignoreSentance,
          convertToBlog,
          project
        } = meta?.arg || {};
        if (!state.uploads[id]) state.uploads[id] = {
          languageCode,
          name,
          description,
          hints,
          isPrecision,
          ignoreSentance,
          project,
          convertToBlog,
          beta
        };
        else {
          state.uploads[id].languageCode = languageCode;
          if (name !== undefined && name !== state.uploads[id].fileName ) state.uploads[id].name = name;
          if (isPrecision !== undefined) state.uploads[id].isPrecision = isPrecision;
          if (hints !== undefined) state.uploads[id].hints = hints;
          if (description !== undefined) state.uploads[id].description = description;
          if (ignoreSentance !== undefined) state.uploads[id].ignoreSentance = ignoreSentance;
          if (project !== undefined) state.uploads[id].project = project;
          if (beta) state.uploads[id].beta = beta;
          if (convertToBlog) state.uploads[id].convertToBlog = convertToBlog;
        }
      })
      .addCase(updateDictionary.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        const {id, dictionary} = meta?.arg || {};
        if (!state.uploads[id]) state.uploads[id] = {dictionary};
        else state.uploads[id].dictionary = dictionary;
      });

    builder
      .addCase(cancelUpload.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        state.uploads = {};
      });

    builder
      .addCase(updateProgress.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        const {id, loaded, total, abortController} = meta?.arg || {};
        state.uploads[id].loaded = loaded;
        state.uploads[id].total = total;
        state.uploads[id].progress = loaded / total;
        state.uploads[id].abortController = abortController;
      });

    builder
      .addCase(transcribe.fulfilled, (state, {meta, payload}) => {
        console.log('uploader', {state: current(state), meta, payload});
        if (meta?.arg.isLinkUpload) return;
        const {id, loaded, total} = meta?.arg || {};
        state.uploads[id].loaded = loaded;
        state.uploads[id].total = total;
        state.uploads[id].progress = loaded / total;
      })
      .addCase(transcribeFromUpload.pending, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        const {id} = meta?.arg || {};
        // ensure we don't get multiple threads
        state.uploads[id].complete = (state.uploads[id].complete || 0) + 1;
      })
      .addCase(transcribeFromUpload.fulfilled, (state, {meta, payload}) => {
        // console.log('uploader', {state: current(state), meta, payload})
        const {id} = meta?.arg || {};
        const {error, cid} = payload || {};
        console.log('done?', cid, payload);
        if (payload === null) return; // ignore thread until there is a payload
        if (error) {
          state.uploads[id].error = error;
        } else {
          // remove it from queue since it is ready to push
          const uploads = {...state.uploads};
          delete uploads[id];
          state.uploads = uploads;
        }
      });

    builder
      .addCase(showOverLimit.fulfilled, (state, {meta, payload}) => {
        const show = meta?.arg;
        state.isShowOverLimit = !!show;
      });

  }
});

const reducer = slice.reducer;
export default reducer;

// Selectors
export const sliceSelector = state => state.uploader;

export const selectIsOpen = createSelector(
  [sliceSelector],
  ({isOpen}) => isOpen || false
);

export const selectIsEdit = createSelector(
  [sliceSelector],
  ({isEdit}) => isEdit || false
);

export const selectIsShowOverLimit = createSelector(
  [sliceSelector],
  ({isShowOverLimit}) => isShowOverLimit || false
);

export const selectUploads = createSelector(
  [sliceSelector],
  ({uploads}) => uploads || {}
);
