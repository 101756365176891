import React, {useState, useEffect} from 'react';
import FadeIn from 'react-fade-in';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import NotificationsAlertIcon from '@material-ui/icons/NotificationsActiveOutlined';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import PromoIcon from '@material-ui/icons/Loyalty';
// import CloseIcon from '@material-ui/icons/HighlightOff'

import {Card, Heading, ContentWrapper, SubHeading, TitleText} from '../Settings/StyledComponent';
import useProfile from 'hooks/useProfile';
import useOrganization from 'hooks/useOrganization';
import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import Invites from 'components/common/Settings/Invites';
import {fixMissingDBUser, checkCoupon} from 'utils/Firebase';

const PageWrapper = styled.div`
  z-index: 2001;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(236, 240, 244,.9);
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 10vh;
  .link-click {
    color: #CCC;
    cursor: pointer;
    transition: color 1s;
    :hover {
      color: dodgerblue;
      transition: color 1s;
    }
  }

`;

export default (props = {}) => {
  const {hideOptions, onCreated, onClose} = props || {};
  const {uid, oid, organizations, profile, switchOrganization, showCreate:initShowCreate, updateSettings, acceptInvite, invites, settings} = useProfile(); // syncProfileOrganizations
  const {isLoaded, coupon, locale} = profile;

  const {hasSeenWorkspaceModal} = settings || {};
  const showFirstTimeModal = !hasSeenWorkspaceModal && invites.length > 0; // after this was released

  const {create} = useOrganization({uid});
  const [name, setName] = useState('');
  const [processing, setProcessing] = useState(false);
  const [couponData, setCouponData] = useState();
  const [loadingCoupon, setLoadingCoupon] = useState();

  const [showCreate, setShowCreate] = useState(initShowCreate);

  const profileOrgs = profile.organizations || {};
  const profileOrgCount = Object.keys(profileOrgs).length;
  const hasProfileOrgs = profileOrgCount > 0;

  const [isEnoughTimeSinceJoining, setIsEnoughTimeSinceJoining] = useState(false);

  const createWorkspace = async () => {
    setProcessing(true);
    await create({uid, name, locale});
    onCreated && onCreated();
    updateSettings({hasSeenWorkspaceModal: true});
    setProcessing(false);
  };

  const getCouponInfo = async (coupon) => {
    console.log('getting coupon');
    setLoadingCoupon(true);
    const data = await checkCoupon({code: coupon});
    if (data.valid) setCouponData(data);
    else setCouponData(null);
    setLoadingCoupon(false);
    console.log('done coupon');
  };

  useEffect(() => {

    if (isLoaded && organizations.length > 0 && !oid && !profile.isEmpty) {
      console.log('syncing', {uid, isLoaded, organizations, oid, profile});
      // syncProfileOrganizations()
      switchOrganization(organizations[0].uid);
    } else if (isLoaded && !profile.email && !(profile.providerId || profile.provider)) {
      console.log('we have a problem');
      Sentry.captureMessage(`User was missing data in DB ${uid}`);
      fixMissingDBUser(uid);
    }

  }, [isLoaded, organizations.length]);

  useEffect(() => {
    if (coupon) getCouponInfo(coupon);
  }, [coupon]);

  useEffect(() => {
    if (profile.creationTime) {
      const minutesInSeconds = 10;
      const secondsinMilli = minutesInSeconds * 1000;
      const now = new Date().getTime();
      const isEnough = now - profile.creationTime > secondsinMilli;
      setIsEnoughTimeSinceJoining(isEnough);
      if (!isEnough) setTimeout(() => setIsEnoughTimeSinceJoining(true), 5000);
    }
  }, [profile.creationTime]);

  useEffect(() => {
    if (isEnoughTimeSinceJoining) {
      !oid && invites.length === 0 && setShowCreate(true);
    }
  }, [isEnoughTimeSinceJoining]);

  // console.log({isLoaded, oid, profileOrgCount, hasProfileOrgs, orgs: profile.organizations})

  // wait for profile to load
  if (!isLoaded) return '';
  // if it has profile hors and they have already since the inite options modal
  if (oid && hasProfileOrgs && !showFirstTimeModal) return '';
  // give it more time
  if (!isEnoughTimeSinceJoining) return '';

  if (!window.navigator.onLine) return '';

  return (
    <PageWrapper>
      <FadeIn>
        <ContentWrapper className='create-workspace'>
          <Heading>{showCreate ? 'Create a Workspace' : 'Workspace Options'}</Heading>
          <SubHeading>{showCreate ? 'Seperate billing and user management into your own account.' : <>Join an invite to someone\'s workspace, or switch to one you are already a part of.</>}</SubHeading>
          <Card style={{minHeight: 'initial'}}>
            {
              showCreate ? (
                <form
                  noValidate
                  autoComplete='off'
                  onSubmit={evt => {
                    createWorkspace();
                    evt.preventDefault();
                  }}>
                  <p/>
                  <TextField label="Workspace Name" fullWidth variant="outlined" onChange={evt => setName(evt.target.value)} />
                  <div style={{margin: '20px 0',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {
                      loadingCoupon && <Spinner small />
                    }
                    {
                      couponData && (
                        <Chip
                          style={{
                            backgroundColor: 'transparent',
                            marginLeft: '12px',
                            borderRadius: '11px',
                            borderColor: '#DDD'
                          }}
                          avatar={<PromoIcon/>}
                          label={`Includes ${couponData.name}`}
                          variant="outlined"
                        />
                      )
                    }
                    {onClose ? <Button onClick={onClose} light secondary>Cancel</Button> : ''}
                    <Button
                      type='button'
                      disabled={!name || processing}
                      onClick={createWorkspace}
                      raised
                      primary
                      style={{
                        marginLeft: '12px',
                      }}
                    >
                      {
                        processing ?
                          <Spinner small color='#FFF' /> :
                          'Create Workspace'
                      }
                    </Button>
                  </div>
                </form>
              ) : ''
            }

            {
              !hideOptions ? (<>
                <div>
                  <p/>
                  <Invites
                    noHead
                    noMessageOnEmpty
                    hideDecline
                    onAccept={(oid) => {
                      updateSettings({hasSeenWorkspaceModal: true});
                      acceptInvite(oid);
                    }}
                    header={
                      <TitleText
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}
                      >
                        You have invites to join a Workspace
                        <NotificationsAlertIcon fontSize='small' style={{marginLeft: 15, opacity: .7}}/>
                      </TitleText>}
                  />
                </div>
                <div>
                  <p/>
                  <Invites
                    noHead
                    noMessageOnEmpty
                    hideDecline
                    acceptText='Select'
                    onAccept={(oid) => {
                      switchOrganization(oid);
                      updateSettings({hasSeenWorkspaceModal: true});
                    }}
                    orgs={organizations}
                    header={<TitleText>Or, you can select your existing Workspace</TitleText>}
                  />
                </div>
              </>) : ''
            }
            {
              showCreate ? '' : (
                <p style={{marginTop: 40, textAlign: 'center'}}>
                  <small className="link-click" onClick={() => setShowCreate(true)} onKeyPress={() => {}} tabIndex={-1} role='link'>
                    Or, click here to create a new workspace.
                  </small>
                </p>
              )
            }
          </Card>
        </ContentWrapper>
      </FadeIn>
    </PageWrapper>
  );
};
