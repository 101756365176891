import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import slug from 'slug';
import * as XLSX from 'xlsx';

import { fetchUsage as fetchUsageAction, selectOrgUsage } from 'features/usage';

import {
  fbFunc,
  getStripePortalSession,
  downloadOrgUsageReport
} from 'utils/Firebase';
import { STRIPE_PK, paygPricePerMin, paygPrice } from 'utils/env';
import { loadStripe } from '@stripe/stripe-js/pure';
import { toast } from 'components/common/Toaster';
import {
  fetchCredits as fetchCreditsAction,
  selectOrgCredits
} from 'features/credits';
import { selectOrganization } from 'features/profile';

let stripePromise;
const getStripe = () => {
  try {
    if (!stripePromise) {
      stripePromise = loadStripe(STRIPE_PK);
    }
    return stripePromise;
  } catch (err) {
    toast.warn(
      'Issues loading payment platform. Please refresh and try again.',
      { autoClose: 10000, toastId: 'stripe-issue' }
    );
    return;
  }
};

export default ({ oid, uid, limit = 20 }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingUsage, setLoadingUsage] = useState(false);
  const [coupon, setCoupon] = useState({});

  const { billing = {}, name } = useSelector((state) =>
    selectOrganization(state, oid)
  );
  const usage = useSelector((state) => selectOrgUsage(state, oid)) || {};
  const { credits = null } =
    useSelector((state) => selectOrgCredits(state, oid)) || {};

  const fetchUsage = async () => {
    setLoadingUsage(true);
    const { payload } = await dispatch(fetchUsageAction({ oid, uid }));
    setLoadingUsage(false);
    return payload;
  };

  const fetchCredits = async () => {
    setLoading(true);
    const { payload } = await dispatch(
      fetchCreditsAction({ oid, customer: billing.customer })
    );
    setLoading(false);
    return payload;
  };

  const getCoupon = async ({ isCheckout, coupon: couponId }) => {
    if (!uid || !couponId) return;
    try {
      const { data } = await fbFunc.httpsCallable('getCoupon')({
        couponId,
        isCheckout
      });
      // addUserCoupon(profile, data.id)
      setCoupon(data);
      return data;
    } catch ({ message }) {
      console.log('Not a valid coupon', message);
    }
  };

  const cancelPlan = async () => {
    if (!uid) return;
    const params = { uid, oid };
    const { data } = await fbFunc.httpsCallable('cancelSubscription')(params);
    return data;
  };

  const handleCheckout = async ({
    plan,
    coupon,
    mode = 'subscription',
    qty = 1,
    successRedirect
  }) => {
    try {
      const params = {
        uid,
        oid,
        plan,
        coupon,
        qty,
        cancelRedirect: window.location.pathname,
        baseUrl: window.location.origin,
        successRedirect,
        mode
      };
      console.log('handleCheckout', params);
      const { data: sessionId } = await fbFunc.httpsCallable(
        'stripeCreateCheckoutSession'
      )(params);
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await getStripe();
      const checkout = await stripe.redirectToCheckout({ sessionId });
      return checkout;
    } catch ({ message }) {
      console.error(message);
      return { error: message };
    }
  };

  const getStripePortalUrl = async () => {
    try {
      const url = await getStripePortalSession(oid);
      window.location.href = url;
    } catch (error) {
      console.log('Could not get user', oid, error);
      return error;
    }
  };

  const downloadStats = async ({ span, maxSpan, filter }) => {
    const jsonData = await downloadOrgUsageReport({
      uid,
      oid,
      span,
      maxSpan,
      filter
    });
    console.log({ jsonData });
    if (jsonData.length === 0)
      return toast.warn('No data for that time period.', {
        autoClose: 5000,
        toastId: 'no-report-data'
      });

    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, jsonData);

    // help calculate all formulas to
    const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

    //rows (no header row, non 0)
    for (let r = 0; r < jsonData.length; r++) {
      const rowNum = r + 1;

      // cols
      for (let c = 0; c < jsonData[r].length; c++) {
        const cell = jsonData[r][c];
        if (typeof cell === 'string' && cell.indexOf('=') === 0) {
          // https://stackoverflow.com/questions/29520596/how-to-set-formula-for-cell-data-for-export-to-xlsx-sheetjs-js-xlsx-https
          const cords = `${cols[c]}${rowNum}`;
          const f = 'trunc((' + cell.replace('=', '') + ') * 100) / 100';
          // console.log(cords, f, {r, c})
          XLSX.utils.encode_cell({ c, r });
          ws[cords] = { f }; // :'SUM(B8:B10)'
        }
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    XLSX.writeFile(
      wb,
      `${slug(name, { lower: false, replacement: '' })}-${span}Mo-Report.xlsx`
    );
  };

  const changePlans = async ({
    plan,
    coupon,
    mode = 'subscription',
    qty = 1
  }) => {
    // console.log('Update Plan')
    const params = {
      uid,
      oid,
      qty,
      cancelUrl: window.location.href,
      plan
    };
    try {
      const { data } = await fbFunc.httpsCallable('changeStripePlans')(params);
      console.log('Changing...', data);
      if (data.error) {
        if (data.checkout)
          await handleCheckout({ plan, coupon, mode: 'subscription', qty });
        else
          toast.error('There was an issue processing your request.', {
            toastId: 'changePlans'
          });
      }
      await fetchUsage();
      return data;
    } catch ({ message }) {
      console.error(message);
      return { error: message };
    }
  };

  const getUpcomingInvoiceAmount = async ({ newPlanId, qty = 1 }) => {
    const params = {
      uid,
      oid,
      quantity: qty,
      newPlanId,
      cancelUrl: window.location.href
    };

    try {
      const { data } = await fbFunc.httpsCallable('getUpcomingInvoiceAmount')(
        params
      );
      console.log('Retrieving upcoming invoice amount...', data);
      if (data.error) {
        toast.error(
          'There was an issue retrieving the upcoming invoice amount.',
          {
            toastId: 'getUpcomingInvoiceAmount'
          }
        );
      }
      return data;
    } catch ({ message }) {
      console.error(message);
      return { error: message };
    }
  };

  const updateSubscription = async ({ newPlanId, qty = 1, prorationDate }) => {
    const params = {
      uid,
      oid,
      quantity: qty,
      newPlanId,
      cancelUrl: window.location.href,
      prorationDate
    };

    console.log('params', params);

    try {
      const { data } = await fbFunc.httpsCallable('updateSubscription')(params);
      console.log('Updating...', data);
      if (data.error) {
        toast.error('There was an issue updating your subscription.', {
          toastId: 'updateSubscription'
        });
      }
      await fetchUsage();
      return data;
    } catch ({ message }) {
      console.error(message);
      return { error: message };
    }
  };

  const calcPlanPrecision = useCallback(() => {
    const { plan = {}, usage: currUsage = {} } = usage;

    const planDraft = plan.draft >= 999999 ? 'unlimited' : plan.draft;
    const planPrecision =
      plan.precision >= 999999 ? 'unlimited' : plan.precision;
    // using max as we migrate to using rate based Credits
    const currUsagePrecision = currUsage.precisionCredits;

    // precision calcs
    let precisionPerc = plan.precision
      ? (currUsagePrecision / plan.precision) * 100
      : 0;
    let precisionLeft = plan.precision
      ? plan.precision - currUsagePrecision
      : 0;
    if (currUsagePrecision > plan.precision) precisionPerc = 100;
    if (precisionLeft < 0) precisionLeft = 0;

    // draft calcs
    let draftPerc = plan.draft
      ? (currUsage.subscription / plan.draft) * 100
      : 0;
    if (plan.draft > 9999999 && currUsage.subscription > 0) draftPerc = 20;
    let draftLeft = plan.draft ? plan.draft - currUsage.subscription : 0;
    if (currUsage.subscription > plan.draft) draftPerc = 100;
    if (draftLeft < 0) draftLeft = 0;

    return {
      planDraft,
      planPrecision,
      precisionPerc,
      draftPerc,
      draftLeft,
      precisionLeft
    };
  }, [usage, fetchCredits, fetchUsage]);

  const calcForUsageMeter = useCallback(() => {
    const { plan = {}, usage: currUsage = {} } = usage;
    const { subscription, estimatedDraft } = currUsage || {};
    const { draft: draftPlan } = plan || {};
    const perc = Math.min(
      isNaN(draftPlan) || 0 ? 0 : (subscription / draftPlan) * 100,
      100
    );
    const estPerc = Math.min(
      isNaN(estimatedDraft) || isNaN(draftPlan)
        ? 0
        : (estimatedDraft / draftPlan) * 100,
      100
    );
    const minutesLeft = Math.max(0, draftPlan - estimatedDraft);
    const paygMins =
      credits > 0 ? parseInt(credits / paygPricePerMin) : undefined;
    const paygPrecMins = credits > 0 ? parseInt(credits) : undefined;

    return {
      perc,
      estPerc,
      minutesLeft,
      paygPrice,
      paygMins,
      estimatedDraft,
      draftPlan,
      paygPrecMins
    };
  }, [usage, fetchCredits, fetchUsage]);

  return {
    calcForUsageMeter,
    calcPlanPrecision,
    cancelPlan,
    changePlans,
    updateSubscription,
    getUpcomingInvoiceAmount,
    coupon,
    credits,
    downloadStats,
    fetchCredits,
    fetchUsage,
    getStripePortalUrl,
    handleCheckout,
    loading,
    loadingUsage,
    usage,
    getCoupon
  };
};
