import React from 'react';
import SVGInline from 'react-svg-inline';
import uuid from 'uuid/v4';

const svg = () => {
  const id = uuid(); // accessibility
  return `<svg width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect id="text-line-loader-1${id}" fill-opacity=".8" fill="#DDD"  width="80%" height="16" rx="8">
        <animate attributeName="fill" values="#DDD;#EEE;#DDD;#DDD" dur="2s" repeatCount="indefinite" />
    </rect>          
  </svg>`;
};

export default (props) => <SVGInline svg={svg()} {...props} height={props.height || '20px'} />;
