import React, { useState } from 'react';
import styled from 'styled-components';
import FadeIn from 'react-fade-in';
import { trackCustom } from 'utils/Tracker';
import Spinner from 'components/common/Spinner';
import Overlay, { Modal } from 'components/common/Overlay';
import KonchButton from 'components/common/Button';

const Header = styled.h2`
  margin-bottom: -4px;
`;

const Subtitle = styled.p`
  margin-bottom: 31px;
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  width: 290px;
  & button {
    width: 150px;
  }
`;

export const AreYouSure = ({ cancelPlan, close }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Overlay darkish>
      <FadeIn>
        <Modal>
          <Header>Are you sure?</Header>
          <Subtitle>You will be canceling your subscription.</Subtitle>
          <Options>
            <KonchButton
              raised
              primary
              backgroundHoverColor={'red'}
              onClick={(params) => {
                trackCustom({
                  category: 'User',
                  action: 'Confirmed Cancel Request'
                });
                setLoading(true);
                cancelPlan(params);
              }}
            >
              {loading ? <Spinner small /> : `Yes, I'm sure`}
            </KonchButton>
            <KonchButton
              raised
              secondary
              onClick={(params) => {
                trackCustom({
                  category: 'User',
                  action: 'Canceled Cancel Request'
                });
                close();
              }}
            >
              Cancel
            </KonchButton>
          </Options>
        </Modal>
      </FadeIn>
    </Overlay>
  );
};

export const Redirecting = () => {
  return (
    <Overlay darkish>
      <FadeIn>
        <Modal>
          <Header>Redirecting to checkout page.</Header>
          <Subtitle>We will have you transcribing soon!</Subtitle>
          <div>
            <Spinner />
          </div>
        </Modal>
      </FadeIn>
    </Overlay>
  );
};

export const UpdatingPlan = () => {
  return (
    <Overlay dark>
      <FadeIn>
        <Modal>
          <Header>Connecting to payments could take a minute.</Header>
          <Subtitle>Thank you for your patience</Subtitle>
          <div>
            <Spinner />
          </div>
        </Modal>
      </FadeIn>
    </Overlay>
  );
};

// Corrector Options
// Reject queue confirmation modal
export const RejectQueue = ({ rejectQueue, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Overlay dark>
      <FadeIn>
        <Modal>
          <Header>Are you sure?</Header>
          <Subtitle>
            This will put the transcription back into the queue
          </Subtitle>
          <Options>
            <KonchButton
              raised
              primary
              backgroundHoverColor={'red'}
              onClick={() => {
                setIsLoading(true);
                trackCustom({
                  category: 'User',
                  action: 'Confirmed Reject Queue'
                });
                rejectQueue();
              }}
            >
              {isLoading ? <Spinner small /> : `Yes, I'm sure`}
            </KonchButton>
            <KonchButton
              raised
              secondary
              onClick={(params) => {
                trackCustom({
                  category: 'User',
                  action: 'Canceled Reject Queue'
                });
                onClose();
              }}
            >
              Cancel
            </KonchButton>
          </Options>
        </Modal>
      </FadeIn>
    </Overlay>
  );
};

// ExportModal

export const ExportModal = ({ onClose, children }) => {
  return (
    <Overlay dark>
      <FadeIn>
        <Modal>
          <Header>We are now exporting your transcripts.</Header>
          <Subtitle
            style={{
              padding: '0 20px'
            }}
          >
            This may take a few minutes depending on the size of your
            collection. You can monitor the progress below.
          </Subtitle>
          {children}
          <KonchButton
            style={{
              marginTop: 20
            }}
            primary
            onClick={() => {
              onClose();
            }}
          >
            Close
          </KonchButton>
        </Modal>
      </FadeIn>
    </Overlay>
  );
};
