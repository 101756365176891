import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import FadeIn from 'react-fade-in';
import NotificationIcon from '@material-ui/icons/NotificationsRounded';
import MailIcon from '@material-ui/icons/MailOutline';
import InviteIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import CreditIcon from '@material-ui/icons/CreditCard';
import styled from 'styled-components';
import useProfile from 'hooks/useProfile';
import usePlan from 'hooks/usePlan';
import {trackCustom} from 'utils/Tracker';
import {useMediaPredicate} from 'react-media-hook';

const promoPlans = [
  'price_1HLsWPHIgcgEnRzJJWBiBVCv',
  'price_1IThFvHIgcgEnRzJ8tnt7Sjg',
  'price_1IzTAkHIgcgEnRzJkrmnCAbK',
  'price_1IzTMFHIgcgEnRzJxGtZn7dv',
  'price_1IaNNKHIgcgEnRzJnygG3P2s',
  'price_1HQzXfHIgcgEnRzJyhdn3tZ1',
  'price_1HQzXRHIgcgEnRzJPUtxuqn6',
  'price_1HLwhdHIgcgEnRzJ6Uu147Fa'
];

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: -2px 10px 0 0;
  flex-direction: row-reverse;
  ${({small}) => small && `
    display: none;
  `}
`;

const Text = styled.div`
  opacity: 1;
  font-size: 14px;
  white-space: nowrap;
  flex: 1;
  text-align: left;
  margin-left: 4px;
  transition: opacity 1s 3.7s, width 1s, height .3s;
`;

const NoticeCont = styled.div`
  cursor: pointer;
  box-shadow: 0;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  text-transform: uppercase;
  padding: 7px 10px 7px 8px;
  background-color: #f14877;
  margin-right: 5px;
  border-radius: 5px;
  color: #FFF;
  transform: translateY(-100px);
  display: none;
  svg {
    margin-right: 5px;
  }
  ${({show}) => show && `
    display: flex;
    transform: translateY(0px);
  `}
  :hover {
    background-color: #D10606;
    color: #FFF;
    transition: background-color .5s, color .5s;
  }
  :active {
    box-shadow: none;
    transform: scale(.98);
    transition: transform .1s;
  }
  transition: transform .1s, background-color .5s, color .5s;
`;

export default ({history}) => {
  const {invites, isCorrector, needsEmailVerified} = useProfile();
  const [showUpgrade, setShowUpgrade] = useState(false);

  const inviteCount = invites ? invites.length : 0;
  const hasInvites = inviteCount > 0;
  const {profile, organization, oid, uid} = useProfile();
  const {enterprise, paymentMethod, subscription, plan} = organization?.billing || {};
  const {usage, credits} = usePlan({oid, uid});

  const checkForUpgrade = () => {
    const now = new Date().getTime();
    const secondsSinceCreation = ((now - profile.creationTime) / 1000);
    const notHasRealSubscription = (!subscription || (subscription && promoPlans.includes(plan)));
    const shouldShowUpgrade = profile.uid && organization.uid && !isCorrector && !enterprise && !paymentMethod && notHasRealSubscription;
    console.log({credits, shouldShowUpgrade, paymentMethod, notHasRealSubscription, plan, promoPlans, subscription}, usage);

    if (secondsSinceCreation > 15 && shouldShowUpgrade) {
      if (usage.draftLeft <= 1 && !credits) {
        setShowUpgrade(true);
      } else setShowUpgrade(false);
    }
  };

  useEffect(() => {
    oid && checkForUpgrade();
  }, [oid, usage.draftLeft, credits]);

  const small = useMediaPredicate('(max-width: 775px)');
  let count = 0;
  if (hasInvites) count++;
  if (needsEmailVerified) count++;
  if (showUpgrade) count++;

  return (
    small && count ?
      <FadeIn delay={300}>
        <Link to={'/settings'}>
          <NoticeCont show >
            <NotificationIcon fontSize='small' />
            <Text>{count}</Text>
          </NoticeCont>
        </Link>
      </FadeIn>
      :
      <Root>
        {
          needsEmailVerified &&
          <FadeIn delay={100}>
            <Link to={'/settings'}>
              <NoticeCont show>
                <MailIcon fontSize='small' />
                <Text>Verify your email</Text>
              </NoticeCont>
            </Link>
          </FadeIn>
        }
        {
          hasInvites &&
          <FadeIn delay={300}>
            <Link to={'/settings'}>
              <NoticeCont show >
                <InviteIcon fontSize='small' />
                <Text>
                  {inviteCount} pending invite{inviteCount > 1 ? 's' : ''}
                </Text>
              </NoticeCont>
            </Link>
          </FadeIn>
        }
        {
          showUpgrade &&
          <FadeIn delay={600}>
            <NoticeCont show
              onClick={() => {
                trackCustom({category: 'User', action: 'Dashboard Upgrade Clicked', data: {uid: profile.uid}});
                history.push('/plans');
              }}
              size='small'
              className={'small-shake-delay'}>
              <CreditIcon fontSize='small' />
              <Text>Update Payment Method</Text>
            </NoticeCont>
          </FadeIn>
        }
      </Root>
  );
};
