import useProfile from 'hooks/useProfile';
import useSubscriptions from 'hooks/useSubscriptions';
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { Features } from 'utils/constants';
import useUsage from 'hooks/useUsage';
import Spinner from 'components/common/Spinner';

export const FeaturesContext = createContext();

const FeaturesProvider = ({ children }) => {
  const { billing, profile, isEnterprise } = useProfile();
  const {
    subscription,
    fetchSubscription,
    fetchCharges,
    charges,
    fetchCustomerMetadata,
    customerMetadata
  } = useSubscriptions(billing);
  const { credits } = useUsage();
  const [features, setFeatures] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (billing.customer) {
      // Fetch the charges and customer metadata
      // Charges are used to determine if the user has topped up their credits
      fetchCharges(billing.customer);
      fetchCustomerMetadata({ customerId: billing.customer });
    }

    if (billing.subscription && !subscription) {
      fetchSubscription(billing.subscription);
    }
  }, [billing]);

  useEffect(() => {
    const initialize = () => {
      // If logged out
      if (profile.isEmpty && profile.isLoaded) {
        setIsInitialized(true);
        return;
      }

      if (profile.isLoaded && !profile.isEmpty && credits !== undefined) {
        if (subscription && subscription.id) {
          setFeatures(subscription.features);
        }
        setIsInitialized(true);
      }
    };

    initialize();
  }, [subscription, profile, credits]);

  const hasSubscription = useMemo(() => Boolean(billing.plan), [billing]);

  /* This is used to distinguish between pay-as-you-go users
  and new users who receive some credits for free */
  const hasPaidCharges = useMemo(
    () =>
      charges?.some(
        (charge) => charge.status === 'succeeded' && charge.paid === true
      ),
    [charges]
  );

  const isTrialUser = customerMetadata?.isTrialUser === 'true';

  const isPayAsYouGoUser = credits > 0 && (hasPaidCharges || isTrialUser);

  const isFreePlanUser =
    (!hasSubscription && !isEnterprise && !isTrialUser && credits === 0) ||
    (!hasSubscription && !isEnterprise && !isTrialUser && !hasPaidCharges);

  const checkFeatureAccess = (feature) =>
    isEnterprise || isPayAsYouGoUser || features?.includes(feature);

  const getDefaultValue = () => ({
    isPrecisionEnabled: true,
    isKonchMateEnabled: true,
    isContentGenerationEnabled: true,
    isTranscriptTranslationEnabled: true,
    isTranscriptSummarizationEnabled: true,
    isTranscriptExportEnabled: true,
    isMagicDocEnabled: true,
    hasSubscription: false,
    subscription,
    isFreePlanUser: false,
    isPayAsYouGoUser: false,
    isInitialized
  });

  // New pricing plans
  const getNewPricingPlansValue = () => ({
    isPrecisionEnabled: checkFeatureAccess(Features.Precision),
    isKonchMateEnabled: checkFeatureAccess(Features.KonchMate),
    isContentGenerationEnabled: checkFeatureAccess(Features.ContentGeneration),
    isTranscriptTranslationEnabled: checkFeatureAccess(
      Features.TranscriptTranslation
    ),
    isTranscriptSummarizationEnabled: checkFeatureAccess(
      Features.TranscriptSummarization
    ),
    isTranscriptExportEnabled: checkFeatureAccess(Features.TranscriptExport),
    isMagicDocEnabled: checkFeatureAccess(Features.MagicDoc),
    hasSubscription,
    subscription,
    isFreePlanUser,
    isPayAsYouGoUser
  });

  // const showNewPricingPlans = localStorage.getItem('showNewPricingPlans');
  const showNewPricingPlans = true;

  const value = useMemo(
    () => (showNewPricingPlans ? getNewPricingPlansValue() : getDefaultValue()),
    [
      showNewPricingPlans,
      features,
      hasSubscription,
      subscription,
      isFreePlanUser,
      isPayAsYouGoUser,
      isInitialized
    ]
  );

  if (!isInitialized) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <FeaturesContext.Provider value={value}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesProvider;
