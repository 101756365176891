import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar from 'components/common/Avatar';
import useProfile from 'hooks/useProfile';
import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const MemberInfo = styled.div`
  display: flex;
  align-items: center;
`;

const TableBodyCont = styled(TableBody)`
  ${({ single }) =>
    single
      ? `
    & .MuiTableCell-root {
      border-bottom: none;
    }
  `
      : ''}
`;

const NoData = styled.div`
  color: #ccc;
  padding: 20px;
  font-weight: 100;
  text-align: center;
`;

const OrgName = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #3f3f3f;
  margin: 0 0 0 12px;
  max-width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default ({
  noHead,
  header,
  orgs,
  onAccept,
  onDecline,
  acceptText = 'Accept',
  load,
  noMessageOnEmpty,
  hideDecline
}) => {
  const { invites, acceptInvite, declineInvite } = useProfile();

  const [processing, setProcessing] = useState([]);
  const orginizations = orgs || invites;

  const decline = async (oid) => {
    processing.push(oid);
    setProcessing(processing);
    await declineInvite(oid);
    setProcessing(processing.filter((item) => item !== oid));
  };

  const accept = async (oid) => {
    processing.push(oid);
    setProcessing(processing);
    if (onAccept) await onAccept(oid);
    else await acceptInvite(oid);
    setProcessing(processing.filter((item) => item !== oid));
  };

  return orginizations.length > 0 ? (
    <TableContainer>
      {header || ''}
      <Table stickyHeader aria-label="sticky table">
        {!noHead ? (
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '100%' }}>User</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
        ) : null}
        <TableBodyCont single={orginizations.length === 1 ? 1 : 0}>
          {orginizations.map((org, index) => {
            const isProcessing = processing.includes(org.id);
            return (
              <TableRow hover key={index}>
                <TableCell style={{ width: '100%' }}>
                  <MemberInfo>
                    <Avatar profile={org} size={25} />
                    <OrgName>{org.name || 'No name'}</OrgName>
                  </MemberInfo>
                </TableCell>
                <TableCell align="right" style={{ padding: 0 }}>
                  {hideDecline ? (
                    ''
                  ) : (
                    <Button
                      mini
                      secondary
                      onClick={() => !isProcessing && decline(org.id)}
                    >
                      {isProcessing ? '' : 'Decline'}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Button
                    mini
                    primary
                    raised
                    onClick={() => !isProcessing && accept(org.id)}
                  >
                    {isProcessing ? <Spinner small color="#FFF" /> : acceptText}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBodyCont>
      </Table>
    </TableContainer>
  ) : noMessageOnEmpty ? (
    ''
  ) : (
    <NoData>You have no pending invites at this time.</NoData>
  );
};
