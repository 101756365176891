import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';
import Overlay from './Overlay';

const Message = styled.div`
  margin: 20px 10px;
  text-align: center;
  font-weight: bold;
  color: ${({textColor}) => textColor || 'rgba(0, 0, 0, 0.5)'};
`;

const Loading = (props) => {
  const {show = true, textColor} = props;
  return (
    show
      ? <Overlay {...props}>

        <Spinner center />
        {
          props.message &&
          <Message textColor={textColor} >{props.message}</Message>
        }

      </Overlay> : ''
  );
};

export default Loading;
