import React, { useState, useCallback, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import Avatar from 'components/common/Avatar';
import Spinner from 'components/common/Spinner';
import Loader from 'components/common/Loaders/Skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import BaseTabs from '@material-ui/core/Tabs';
import BaseTab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRowMUI from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import useProfile from 'hooks/useProfile';
import useOrganization from 'hooks/useOrganization';
import useCollection from 'hooks/useCollection';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import _debounce from 'lodash/debounce';

import { faTrashAlt, faCheck } from '@fortawesome/free-solid-svg-icons';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

const TableRow = styled(TableRowMUI)`
  ${({ processing }) => processing && 'opacity: .4;'}
  transition: opacity .5s;
`;

const MembersWrapper = styled.div`
  a {
    color: #2091d0;
  }
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  min-width: 590px;
  & tr:hover svg {
    display: inline-block;
  }
  & tr.self {
    &:hover {
      opacity: 1;
    }
    background: #fafafa;
    opacity: 0.5;
    transition: opacity 1s;
  }
  & tr.self td {
    border-bottom-width: 2px;
  }
`;
const MemberInfo = styled.div`
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  height: 14px;
  color: #c6c6c6;
  display: none;
  ${({ on }) => on === 1 && 'display: block;'}
  ${({ allowed, on }) =>
    allowed === 1
      ? `
    cursor: pointer;
    &:hover {
      color: ${!on ? 'green' : 'red'};
    }
  `
      : 'cursor: initial;'}
  ${({ allowed, on }) => !allowed && !on && 'display: none !important;'}
  ${({ icon }) =>
    icon === faTrashAlt &&
    `
    color: '#C6C6C6';
    &:hover {
      color: red;
    }
  `}
  transition: color .5s;
`;

const Email = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #3f3f3f;
  margin: 0 0 0 12px;
  max-width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SeenTime = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #989898;
  margin-right: 10px;
`;

const CollapseCell = styled(TableCell)`
  @media (max-width: 1041px) {
    max-width: 230px;
  }
`;

const TabAndFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoData = styled.div`
  color: #ccc;
  padding: 20px;
  font-weight: 100;
  text-align: center;
`;

const FilterAndPopoverWrapper = styled.div`
  color: #ccc;
  text-align: right;
  cursor: pointer;
  display: flex;
  justify-content: right;
`;

const MembersTab = styled.div`
  padding: 25px 0;
`;

const Tabs = withStyles({
  indicator: {
    backgroundColor: '#4153AF'
  }
})(BaseTabs);

const Tab = withStyles((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: '24px',
    padding: '0 10px',
    textTransform: 'uppercase',
    color: '#757575',
    minWidth: 122,

    '&$selected': {
      color: '#4153af',
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}))((props) => <BaseTab disableRipple {...props} />);

export default ({ orgId, cid }) => {
  const {
    oid: profileOrganization,
    uid,
    profile: self,
    isAdmin
  } = useProfile();
  const oid = orgId || profileOrganization; //  if OID is passed in or based on current user
  const {
    members,
    userCount: totalUsers,
    fetchAllMembers
  } = useOrganization({ uid, oid });
  const {
    collection,
    share: onShare,
    makeOwner,
    sharedWith,
    owner
  } = useCollection({ id: cid, oid, uid });

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState();
  const [value, setValue] = useState(0);
  const [modifingMembers, setModifingMembers] = useState({});

  const sharedWithCount = Object.keys(sharedWith || {}).length;

  let userList = members.reduce((acc, member) => {
    // 1 | 0 used due to this known issue https://github.com/styled-components/styled-components/issues/1198
    member.isSharedWith = (collection.sharedWith || {})[member.uid] ? 1 : 0;
    member.isMemberOwner = collection.userId === member.uid ? 1 : 0;
    member.isSelf = member.uid === uid ? 1 : 0;
    member.canChangeOwner = self.isOwner || member.isMemberOwner ? 1 : 0;
    member.processing = modifingMembers[member.uid];
    acc.push(member);
    return acc;
  }, []);

  // if filtering
  filter &&
    (userList =
      filter.length > 1
        ? userList.filter((member) => {
            const emailMatch =
              (member.email || '')
                .toString()
                .toLowerCase()
                .indexOf(filter.toLowerCase()) >= 0;
            const nameMatch =
              (member.name || '')
                .toString()
                .toLowerCase()
                .indexOf(filter.toLowerCase()) >= 0;
            return emailMatch || nameMatch;
          })
        : []);

  !filter && totalUsers > 30 && (userList = []);

  // if show ownly shared with
  value === 1 && (userList = userList.filter(({ uid }) => !!sharedWith[uid]));

  // if show ownly shared with
  value === 2 && (userList = userList.filter((member) => member.uid === uid));

  const [userCount] = [userList.length];

  const share = async (uid, isSharedWith) => {
    modifingMembers[uid] = { isSharedWith };
    setModifingMembers(modifingMembers);
    const data = await onShare(uid);
    console.log('got data', data);
    delete modifingMembers[uid];
    console.log('reset processing', modifingMembers);
    setModifingMembers(modifingMembers);
    await fetchAllMembers({ oid, q: filter });
  };

  const onMakeOwner = async (uid) => {
    modifingMembers[uid] = { isOwner: uid };
    setModifingMembers(modifingMembers);
    const data = await makeOwner(uid);
    console.log('got data', data);
    delete modifingMembers[uid];
    console.log('reset processing', modifingMembers);
    setModifingMembers(modifingMembers);
    await fetchAllMembers({ oid, q: filter });
  };

  const callFilter = async () => {
    setLoading(true);
    await fetchAllMembers({ oid, q: filter });
    setLoading(false);
  };

  const fetchFilter = useCallback(_debounce(callFilter, 1000), []);

  const fetchSharedWith = async () => {
    setLoading(true);
    await fetchAllMembers({ oid, uids: Object.keys(sharedWith || {}) });
    setLoading(false);
  };

  const fetchOwner = async () => {
    setLoading(true);
    await fetchAllMembers({ oid, uids: [collection.userId] });
    setLoading(false);
  };

  const handleChange = async (event, newValue) => {
    if (newValue === 1) fetchSharedWith();
    if (newValue === 2) fetchOwner();
    setValue(newValue);
  };

  const init = async () => {
    setLoading(true);
    if ((collection || {}).userId) {
      await Promise.all([fetchOwner(), fetchAllMembers({ oid })]);
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, [(collection || {}).userId]);

  return (
    <Media query={{ minWidth: 1040 }}>
      {(matches) => (
        <MembersWrapper>
          <Input
            style={{ marginBottom: '30px' }}
            value={filter || ''}
            onChange={({ target: { value: text } }) => {
              if (oid && text && text.length > 1) {
                // if on owner tab go to main members tab
                value === 2 && setValue(0);
                fetchFilter();
              }
              setFilter(text || '');
            }}
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <TabAndFilterWrapper>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab
                label={`Members [ ${
                  filter && userCount > 0 && value === 0
                    ? `${userCount} of ${totalUsers}`
                    : totalUsers
                } ]`}
              />
              <Tab label={`Shared With [ ${sharedWithCount || 0}  ]`} />
              <Tab label={`Owner`} />
            </Tabs>
            <FilterAndPopoverWrapper>
              <div style={{ position: 'relative' }} />
            </FilterAndPopoverWrapper>
          </TabAndFilterWrapper>
          {value === 2 ? (
            <MemberInfo style={{ padding: '5px', margin: '30px 0 0 15px' }}>
              {loading ? (
                <Fragment>
                  <Avatar size={25} />
                  <Loader width="250px" style={{ margin: '10px 0 0 12px' }} />
                </Fragment>
              ) : (
                <Fragment>
                  <Avatar profile={owner} size={25} />
                  <Email style={{ maxWidth: 'initial' }}>
                    {owner.name || owner.email}
                  </Email>
                </Fragment>
              )}
            </MemberInfo>
          ) : (
            <MembersTab>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{
                    maxHeight: '100vh',
                    width: '590px',
                    display: 'block'
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <CollapseCell style={{ width: '330px' }}>
                        Users
                      </CollapseCell>
                      {matches && <TableCell>Last Seen</TableCell>}
                      <CollapseCell>Shared</CollapseCell>
                      <CollapseCell>Owner</CollapseCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList.map((member, index) => {
                      let {
                        isSelf,
                        processing,
                        email,
                        lastSignInTime,
                        canChangeOwner,
                        uid,
                        isMemberOwner,
                        isSharedWith
                      } = member;
                      if (processing) {
                        console.log({ processing });
                        isSharedWith = processing.isSharedWith ? 1 : 0;
                        isMemberOwner = processing.isOwner ? 1 : 0;
                      }
                      return (
                        <TableRow
                          hover
                          key={index}
                          className={isSelf ? 'self' : ''}
                          processing={!isSelf && processing ? 1 : 0}
                        >
                          <CollapseCell>
                            <MemberInfo>
                              {processing ? (
                                <Spinner small />
                              ) : (
                                <Avatar profile={member} size={25} />
                              )}
                              <Email>{email}</Email>
                            </MemberInfo>
                          </CollapseCell>
                          {matches && (
                            <TableCell>
                              {!!lastSignInTime && (
                                <SeenTime>
                                  {timeAgo.format(new Date(lastSignInTime))}
                                </SeenTime>
                              )}
                            </TableCell>
                          )}
                          <CollapseCell>
                            <CheckIcon
                              icon={faCheck}
                              on={isSharedWith}
                              allowed={!isMemberOwner ? 1 : 0}
                              onClick={() =>
                                !processing && share(uid, !isSharedWith)
                              }
                            />
                          </CollapseCell>
                          <CollapseCell>
                            {canChangeOwner ? (
                              <CheckIcon
                                icon={faCheck}
                                allowed={canChangeOwner}
                                on={isMemberOwner}
                                onClick={() =>
                                  !processing &&
                                  canChangeOwner &&
                                  onMakeOwner(uid)
                                }
                              />
                            ) : (
                              ''
                            )}
                          </CollapseCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {loading && (
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ width: '590px', display: 'block' }}
                  >
                    <TableBody>
                      <TableRow>
                        <CollapseCell colSpan={4} style={{ minWidth: '590px' }}>
                          <MemberInfo>
                            <Avatar size={25} />
                            <Loader
                              width="250px"
                              style={{ margin: '10px 0 0 12px' }}
                            />
                          </MemberInfo>
                        </CollapseCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
              {value === 0 && filter && userList.length === 0 && !loading ? (
                <NoData>No results for search.</NoData>
              ) : (
                (!filter || filter === '') &&
                value === 0 &&
                totalUsers > members.length &&
                !loading && <NoData>Search for users to share with.</NoData>
              )}
              {value === 1 && userList.length === 0 && !loading && !filter ? (
                <NoData>Not yet shared with any users.</NoData>
              ) : (
                value === 1 &&
                filter &&
                userList.length === 0 && (
                  <NoData>Not shared with any users with that filter.</NoData>
                )
              )}
            </MembersTab>
          )}

          {isAdmin && filter && (
            <NoData>
              Can't find who you are looking for?{' '}
              <Link to="/settings/users">Invite them to your workspace</Link>.
            </NoData>
          )}
        </MembersWrapper>
      )}
    </Media>
  );
};
