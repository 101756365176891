import React from 'react';
import styled from 'styled-components';
import Progress from 'components/common/CreatorDialog/UploadProgress';
import DetailsForm from 'components/common/CreatorDialog/DetailsFormV2';
import useUpload from 'hooks/useUpload';
import Loading from 'components/common/Loading';

const UploadingCont = styled.div`
  @media (min-width: 523px) {
    width: 150px;
  }
  
`;

const DetailsFormCont = styled.div`
  width: 440px;
  @media (max-width: 523px) {
    max-width: 330px;
    margin: 65px auto 10px;
  }
`;

const Split = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 523px) {
    flex-direction: column;
  }
  
`;

const UploadProgress = (props) => {
  const {editingUpload} = useUpload();
  if (!editingUpload) return <Loading />;
  return (
    <Split className='upload-form-split-cont'>
      <UploadingCont>
        <Progress value={editingUpload.progress * 100} />
      </UploadingCont>
      <DetailsFormCont>
        <DetailsForm />
      </DetailsFormCont>
    </Split>
  );
};

export default UploadProgress;
