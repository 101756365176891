import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import useProfile from 'hooks/useProfile';
import CloseIcon from '@material-ui/icons/Cancel';
import {getCurrentEnv, getCurrentAPIEnv, FUNCTIONS_URL} from 'utils/env';

const Root = styled.div`
  position: fixed;
  top: 0;
  z-index: 9999;
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  width: 100%;
  border-top: 2px solid orange;
`;

const Tray = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform .5s;
  transition-delay: 1s;
  ${({loaded}) => !loaded ? 'transform: translateY(-40px)' : 'transform: translateY(0);'}

`;

const Banner = styled.div`
  text-align: center;
  pointer-events: all;
  padding: 3px 10px 5px;
  border-bottom-left-radius: 4px;
  background: #e19b19;
  color: #FFF;
  font-size: .5em;
  font-weight: bold;
  transition: background 1s;
  cursor: pointer;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  
  ${({minimize}) => minimize ? 'transform: translateY(-46px);' : 'transform: translateY(-30px);'}
  transition: transform .5s;
  &:hover {
    transform: translateY(-5px);
    transition-delay: 1s;
  }
  svg {
    font-size: 12px;
  }
`;

const BannerContent = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default () => {
  const {organization, uid} = useProfile();
  const [hide, setHide] = useState(false);
  const {locale = 'US', billing = {}} = organization || {};
  const minimize = window.location.pathname.includes('transcript') || window.location.pathname === ('/d');
  return (
    !hide
      ? <Root >
        <Tray loaded={uid}>
          <Banner minimize={minimize}>
            <div style={{margin: 10}}>
              <Link to='/d' target="_blank" >
                {FUNCTIONS_URL.split('/').slice(2, 4).join('/')}
              </Link>
            </div>
            <BannerContent>
              <Link to='/d' target="_blank" >
                {getCurrentEnv().toUpperCase()} &rarr; {getCurrentAPIEnv().toUpperCase()}
                &nbsp;&nbsp;
                [ {!billing?.paymentMethod ? 'NO' : ''} CARD ]
                &nbsp;&nbsp;
                [ {billing?.enterprise > 0 ? 'ENTERPRISE' : 'PERSONAL'} ]
                &nbsp;&nbsp;
                [ {(locale || 'US').toUpperCase()} ]
              </Link>
                &nbsp;&nbsp;&nbsp;
              <CloseIcon onClick={() => setHide(true)} fontSize='small' />
            </BannerContent>
          </Banner>
        </Tray>
      </Root> : ''
  );
};
