import React from 'react';
import cx from 'classnames';

const Word = React.memo(({isFirst, flash, isLast, wordObject, caller, language: collectionLanguage}) => {
  const {
    bold: boldStr,
    bookmark,
    end_time: endTime,
    start_time: startTime,
    strike: strikeStr,
    italic: italicStr,
    link: linkStr,
    type,
    cutoff,
    skip,
    alternatives: [{content, confidence, language}]
  } = wordObject;

  // pronunciatin or content with more than just punctuation
  const isPronunciation = type === 'pronunciation' || !!content.match(/[^\p{P}]/gu);

  const bold = boldStr === 'true' || boldStr === true || undefined;
  const strike = strikeStr === 'true' || strikeStr === true || undefined;
  const italic = italicStr === 'true' || italicStr === true || undefined;
  const link = linkStr || undefined;

  const classes = {
    bold,
    strike,
    italic,
    link,
    bookmark: !!bookmark,
    'low-confidence': parseFloat(confidence) < .84 ,
    pronunciation: isPronunciation,
    cutoff,
    flash
  };

  if (isNaN(startTime) || isNaN(endTime)) {
    console.log('!!word', caller);// does not have a start or end time {content, type, startTime, endTime}
    return '';
  }

  const lang = language || collectionLanguage;

  const nonSpaceContent = lang === 'yue' || lang === 'cmn' || lang === 'ja' || lang === 'th';

  return (
    <>
      {isPronunciation && !isFirst && !nonSpaceContent && (
        <span
          className={cx('space', {bookmark: !!bookmark})}
        >
          {' '}
        </span>
      )}

      <span
        className={cx('word', classes)}
        data-bookmark={bookmark}
        data-start_time={startTime}
        data-end_time={endTime}
        data-skip={skip}
        data-type={type}
        data-confidence={confidence}
        data-bold={bold}
        data-strike={strike}
        data-italic={italic}
        data-link={link}
        data-language={lang}
      >
        {content}
      </span>

      {isLast && !nonSpaceContent && (
        <span
          className={cx('space', {bookmark: !!bookmark})}
        >
          {' '}
        </span>
      )}

    </>
  );
});

export default Word;
