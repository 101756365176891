import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {verifyEmail} from 'utils/Firebase';
import styled from 'styled-components';
import qs from 'query-string';
import KonchButton from 'components/common/Button';
import LogoImg from 'static/images/logo.png';
import Spinner from 'components/common/Spinner';

const RegisterButton = styled(KonchButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
`;

// const ShowPasswordIconOn = styled(VisibilityIcon)`
//   min-width: 48px;
//   color: #999;
// `

// const ShowPasswordIconOff = styled(VisibilityOffIcon)`
//   min-width: 48px;
//   color: #999;
// `

const Root = styled.div`
  height: 100vh;
  background: none;
`;

const Logo = styled.div`
  position: absolute;
  padding: 20px 0 0 20px;
  & img {
    width: 80px;
  }
  @media (max-width: 1000px) {
    & img {
      width: 49px;
    }
  }
`;

const Cont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 550px;
  & button {
    text-transform: initial;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    & .left {
      max-height: 600px;
    }
    & .right {
      justify-content: end;
      max-height: 900px;
    }
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  &.right {
    background: #fff1e5;
    display: flex;
    align-items: left;
    flex-direction: column;
  }
`;

const PanelContent = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    padding: 30px 100px 0;
    width: 100%;
`;

const H1 = styled.h1`
  margin-bottom: 35px;
  font-weight: 100;
  color: #444;
  @media (max-width: 700px) {
    font-size: 1.4em;
    padding: 0;
    margin: 19px;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  width: 326px;
  text-align: left;
  p {
    text-align: center;
  }
  input {
    text-align:left;
    display: block;
    padding: 6px 0 3px 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #CCC;
    border-radius: 4px;
  }
  .MuiButton-containedPrimary {
    background-color: #4fae32;
    margin-top: 10px;
  }
`;

// const InputCont = styled.div`
//   width: 100%;
//   position: relative;
//   input {
//     padding-right: 50px;
//   }
//   div {
//     position: absolute;
//     top: 6px;
//     right: 0;
//   }
// `

const A = styled(Link)`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`;

const Footer = styled.p`
  color: #777;
  padding: 10px;
  font-size: .9em;
`;

// const Input = styled.input`
//   ${({error}) => error && 'border-color: #e25d5d;'}
// `

// const Label = styled.label`
//   display: flex;
//   justify-content: space-between;
//   text-align:left;
//   padding: 8px 0 6px 0;
//   width: 100%;
//   margin-left: 7px;
//   color: #333;
//   font-size: .9em;
//   ${({error}) => error && 'color: #e25d5d;'}
// `

// const ErrorLabel = styled.span`
//   min-height: 20px;
//   font-size: .9em;
//   &:empty {
//     opacity: 0;
//     transition: opacity 1s;
//   }
//   opacity: 1;
//   color: #e25d5d;
//   text-align: right;
//   transition: opacity 1s;
//   margin-right: 9px;
// `

const EnterprisePanel = ({settings}) => (
  <Panel className='right' style={settings}>
    <img src={settings.logo} alt='custom organization logo' style={{maxHeight: '60%', maxWidth: '60%'}} />
  </Panel>
);

const DefaultPanel = () => (
  <Panel className='right'>
    <PanelContent>
      <H1>Making sure you are you :-)</H1>
    </PanelContent>
    <div style={{maxWidth: '650px'}}>
      <img
        style={{maxWidth: '750px'}}
        src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/626854649ad4b4d88bc50419_send_email_market.svg'
        alt='classroom setting'
      />
    </div>
  </Panel>
);

// <ErrorLabel direction='down'>{issues.all?.message}</ErrorLabel>
// watch()
export default (props) => {
  const {history, settings = {}} = props;
  const [processing, setProcessing] = useState(true);
  const [result, setResult] = useState(true);

  const verifyCode = async (code) => {
    if (!code) history.push('/account?mode=verifyEmail');
    else {
      const {error, response} = await verifyEmail(code);
      console.log({response});
      if (!error || result !== 'asdfdsdsf') {
        setResult(<span>Verified. <A to='/dashboard'>Open dashboard.</A></span>);
      } else {
        console.log({error});
        setResult(
          <div style={{textAlign: 'center'}}>
            <p><b>Unable to verify your email.</b></p>
            <small style={{color: '#444'}}>{error.message}</small>
          </div>
        );
      }
    }
    setProcessing(false);
  };

  useEffect(() => {
    console.log(props.location.search);
    const {oobCode} = qs.parse(props.location.search.substr(1));
    if (oobCode) verifyCode(oobCode);
    else {
      setProcessing(false);
      setResult('Unable to verify your email.');
    }
    document.title = 'Verifying email';
    return () => {
      document.title = 'Konch';
    };
  }, []);

  return (

    <Root>
      {
        !settings.active && <Logo><img src={LogoImg} width='100px' alt='logo' /></Logo>
      }
      <Cont>
        <Panel className='left'>
          <Form noValidate autoComplete='off' >
            <H1>Verifying your email</H1>
            {
              processing ? <Spinner /> : result
            }
            <Footer>Don't have an account, yet? <A to='/register'>Create one here</A></Footer>
          </Form>
        </Panel>
        {
          settings.active
            ? <EnterprisePanel {...props} />
            : <DefaultPanel />
        }
      </Cont>
      {
        !settings.active &&
          <RegisterButton raised primary onClick={() => history.push('/register')}>Register for Free</RegisterButton>
      }
    </Root>

  );
};
