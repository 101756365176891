import React from 'react';
import styled from 'styled-components';

import {TwitterPicker as ColorPicker} from 'react-color';

import UnFilterIcon from '@material-ui/icons/VisibilityOff';
import FilterIcon from '@material-ui/icons/Visibility';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DeleteIcon from '@material-ui/icons/Close';
// import TrashIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Fade from '@material-ui/core/Fade';

import Loader from 'components/common/Loaders/Skeleton';
import useProfile from 'hooks/useProfile';
import useCollection from 'hooks/useCollection';

const Root = styled.div`
  align-items: flext-start;
  justify-content: flext-start;
  display: flex;
  flex-direction: column;
  width: 122px;
  padding: 5px 0 0;
  margin-top: 15px;
  margin-left: 4px;
  margin-bottom: 0;
  border: 3px dashed transparent;
  h2 {
    padding: 5px;
    margin: 0 0 0 -3px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 1em;
    width: 130px;
    
    :hover svg {
      opacity: 0.5;
      transition: opacity 1s;
    }
    svg {
      cursor: pointer;
      font-size: 18px;
      margin-left: 6px;
      opacity: 0;
      transition: opacity 1s;
      &.spinning {
        opacity: .5;
      }
    }
  }

  .switch-hover svg {
    opacity: 0;
    transition: opacity 1s;
  }
  .no-projects, .remove-projects {
    cursor: grabbing;
    opacity: 1;
    padding: 5px 8px;
    font-size: 13px;
    color: #555;
    margin: 2px 0 0 2px;
    transition: opacity .5s;
    width: 170px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;

    }
  }
  :hover {
    .switch-hover {
      cursor: pointer;
     svg {
        opacity: 1;
        transition: opacity .5s;
      }
    }
  }
`;

const ProjectRoot = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 170px;
  margin: 3px 0 0;
  padding: 4px;
  ${({$activeDrop}) => $activeDrop ? `
    background-color: #DEDEDE;
    border-radius: 4px;
  ` : ''}
  .twitter-picker {
    z-index: 101;
  }
  :hover svg {
    opacity: .3;
    transition: opacity .5s;
  }
  svg {
    height: 15px;
    &.delete {
      position: absolute;
      left: -25px;  
    }
    
    opacity: 0;
    cursor: pointer;
    transition: opacity .5s;
    :hover {
      opacity: 1;
      transition: opacity .5s;
    }
  }
`;

const Dot = styled.div`
  min-width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: ${props => props.color};
  margin-right: 10px;
  border: 1px solid #FFF;
  cursor: pointer;
  ${({active}) => active ? `
    border-color: #555;
  ` : ''}
`;

const Name = styled.input`
  text-transform: uppercase; 
  font-size: 12px; 
  font-weight: normal; 
  color: #555;
  -webkit-touch-callout: none;
  user-select: none;
  outline: 0;
  border: none;
  width: 130px;
  background-color: transparent;
    ${({active}) => active ? `
    font-weight: bold;
  ` : ''}
`;

const Project = ({
  onAddToProject,
  onRemove,
  onUpdate,
  onFilter,
  filteredBy: initFilterBy,
  color: initColor,
  name: initName,
  id: project
}) => {

  const [activeDrop, setActiveDrop] = React.useState();
  const [showColors, setShowColors] = React.useState();
  const timer = React.useRef();
  const [color, setColor] = React.useState(initColor);
  const [name, setName] = React.useState(initName);
  const [filteredBy, setFilteredBy] = React.useState(initFilterBy);

  const handleDragOver = evt => {
    evt.preventDefault();
    clearTimeout(timer.current);
    setActiveDrop(true);
    console.log('hovering', {project});
    timer.current = setTimeout(() => setActiveDrop(false), 3000);
  };

  const handleDragOut = evt => {
    evt.preventDefault();
    clearTimeout(timer.current);
    setActiveDrop(false);
  };

  const handleDrop = async (event, header = 0) => {
    event.preventDefault();
    const id = event.dataTransfer.getData('Text');
    setActiveDrop(false);
    clearTimeout(timer.current);
    // update collection with project id
    console.log('on add to project', {id, project, name});
    await onAddToProject(id);
  };

  const handleUpdate = (newColor = color, newName = name) => {
    onUpdate(newColor, newName);
  };

  React.useEffect(() => {
    setColor(initColor);
    setName(initName);
    setFilteredBy(initFilterBy);
  }, [initColor, project, initName, initFilterBy]);

  return (
    <ProjectRoot
      onDragOver={handleDragOver}
      onDragLeave={handleDragOut}
      onDrop={handleDrop}
      $activeDrop={activeDrop ? 1 : 0}
    >
      <DeleteIcon onClick={onRemove} className='delete' />
      <ClickAwayListener onClickAway={() => setShowColors(false)}>
        <Dot
          active={filteredBy ? 1 : 0}
          color={color}
          onClick={() => {
            setShowColors(true);
          }}
        >
          {
            showColors ?
              <ColorPicker
                triangle='hide'
                color={color}
                onChangeComplete={({hex}) => {
                  setShowColors(false);
                  setColor(hex);
                  handleUpdate(hex);
                }}
              /> : ''
          }
        </Dot>
      </ClickAwayListener>
      <Name
        active={filteredBy ? 1 : 0}
        onChange={evt => setName(evt.target.value)}
        onBlur={evt => handleUpdate(color, name)}
        value={name}
      />
      {filteredBy ? <UnFilterIcon onClick={onFilter} className='filter' /> : <FilterIcon onClick={onFilter} className='filter' /> }
    </ProjectRoot>
  );
};

const ProjectList = (props) => {
  const {
    oid,
    projects: initProjects = [],
    currentProject: project,
    setCurrentProject,
    addProject,
    updateProject,
    removeProject,
    isLoaded
  } = useProfile();
  const {addToProject} = useCollection({oid});

  const [active, setActive] = React.useState();
  const [projects, setProjects] = React.useState(initProjects);
  const timer = React.useRef();

  const hasProjects = projects.length > 0;

  const handleDragOut = (evt) => {
    evt.preventDefault();
    clearTimeout(timer.current);
    timer.current = setTimeout(() => setActive(0), 500);
  };

  const handleDragOver = (evt) => {
    evt.preventDefault();
    setActive(1);
  };

  const noIdError = () => {
    const p = document.getElementById('projects-root');
    p.classList.remove('small-shake');
    setTimeout(() => p.classList.add('small-shake'), 200);
    console.log('not a card');
    setActive(0);
  };

  const handleAddToProject = ({id, project, remove}) => {
    console.log({id, project});
    if (!id) return noIdError();
    else {
      return addToProject({id, project, remove});
    }
  };

  const handleDrop = async (event, secondaryAction) => {
    setActive(0);
    if (hasProjects && !secondaryAction) return;
    event.preventDefault();
    const id = event.dataTransfer.getData('Text');
    // if it is not a collection
    if (!id) return noIdError();

    // update collection with project id
    console.log({secondaryAction});
    if (secondaryAction === 1) {
      // if dropped on header, add project first
      const project = await addProject();
      await handleAddToProject({id, project});
    }
  };

  const handleProjectFilter = id => {
    setCurrentProject(id);
  };

  React.useEffect(() => {
    // console.log('changed', initProjects)
    setProjects(initProjects);
  },[initProjects.length]);

  // console.log({project, projects})

  if (!isLoaded) return <div><p><Loader width='200px' /></p><p><Loader width='180px' /></p></div>;
  return (
    <>
      <Fade in={true} timeout={1000}>
        <Root id='projects-root' >
          <h2
            style={{justifyContent: 'space-between', backgroundColor: active === 1 ? '#DEDEDE' : 'initial'}}
            onDragLeave={handleDragOut}
            onDragOver={handleDragOver}
            onDrop={evt => handleDrop(evt, 1)}
          >
            <div
              role='button'
              tabIndex={-1}
              onKeyPress={() => {}}
              style={{display: 'flex', alignItems: 'center'}}
              onClick={() => addProject()}
            >
              <span>Projects</span>
              <AddIcon />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <InfoIcon fontSize='small' onClick={ () => Beacon('article', '63a895781e9b0d75c7275b2d')} />
            </div>
          </h2>
          {
            projects
              .sort((a,b) => a.created > b.created ? -1 : 0)
              .map((p, i) => (
                <Project
                  key={i}
                  filteredBy={project === p.id}
                  onFilter={() => handleProjectFilter(p.id)}
                  onAddToProject={(id) => handleAddToProject({id, project: p.id})}
                  onRemove={() => removeProject(p.id)}
                  onUpdate={(color, name) => updateProject({color, name, id: p.id})}
                  {...p} />
              ))}
          <div style={{height: 25, width: 170}}>
            {
              active === 1 && projects.length === 0 ?
                <div
                  onDragLeave={handleDragOut}
                  onDragOver={handleDragOver}
                  onDrop={evt => handleDrop(evt, 1)}
                  className='no-projects'
                  onClick={() => addProject()}
                  role='button'
                  tabIndex={-1}
                  onKeyPress={() => {}}
                >
                  <span>Drop for new project</span>
                </div>
                : ''
            }
          </div>
        </Root>
      </Fade>
    </>
  );
};

export default ProjectList;
