import client from 'api/client';

const url = '/collections/v2';

export const generateImages = ({cid, prompt, type, variant, orgId, locale}) => client().post(`${url}/${cid}/images`, {prompt, type, variant, orgId, locale});

// TODO: changve to check processing. More generic
export const checkImages = ({cid, orgId, locale}) => client().get(`${url}/${cid}/processing?orgId=${orgId}`, {locale});

export const generateContent = ({cid, prompt, text, from, type, variant, orgId, locale}) => client().post(`${url}/${cid}/completion`, {prompt, from, text, type, variant, orgId, locale});

export const getMedia = ({cid, locale}) => client().get(`${url}/${cid}/media`, {locale});

export const retry = ({cid, pid, orgId, locale}) => client().post(`${url}/${cid}/media/${pid}/retry`, {locale, orgId});

