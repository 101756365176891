import React from 'react';
import { ToastContainer, toast as parent } from 'react-toastify';
import querystring from 'query-string';

import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';

export const DEFAULT = {
  position: parent.POSITION.BOTTOM_RIGHT,
  type: parent.TYPE.INFO,
  pauseOnHover: true,
  className: 'toasterv3',
  hideProgressBar: true
};

export const POSITION = parent.POSITION;
export const PARENT = parent;

export const INFO = {
  ...DEFAULT,
  className: 'toasterv3 toasterv3-info'
};

export const SUCCESS = {
  ...DEFAULT,
  className: 'toasterv3 toasterv3-success'
};

export const ERROR = {
  ...DEFAULT,
  className: 'toasterv3 toasterv3-error'
};

export const WARN = {
  ...DEFAULT,
  className: 'toasterv3 toasterv3-warn'
};

const post =
  (func, style = {}) =>
  (title, { body, actions, ...options } = {}) =>
    func(
      <div className={'cont ' + (body ? 'has-body' : '')}>
        <h2>
          {title}{' '}
          <div className="icon-cont">
            <SuccessIcon className="success" />
            <ErrorIcon className="error" />
            <InfoIcon className="info" />
          </div>
        </h2>
        {body ? <div className="body">{body}</div> : ''}
        {actions ? <div className="actions">{actions}</div> : ''}
      </div>,
      { ...style, ...options }
    );

export const toast = {
  POSITION: parent.POSITION,
  success: post(parent.success, SUCCESS),
  info: post(parent.success, INFO),
  error: post(parent.error, ERROR),
  warn: post(parent.warn, WARN),
  requestRefresh: (props) => {
    const { text = 'Issue with request.', caller, autoClose } = props || {};
    const { from = window.location.href } =
      querystring.parse(window.location.search.split('?')[1]) || {};
    console.log({ from });
    return post(parent.error, {
      autoClose: autoClose || 10000,
      toastId: 'refresh-error',
      ...ERROR
    })(text, {
      body: <>Please refresh and try again. {caller ? `[@${caller}]` : ''} </>,
      actions: from ? (
        <Button onClick={() => (window.location.href = from.split('#')[0])}>
          Refresh
        </Button>
      ) : (
        ''
      )
    });
  },
  isActive: (id) => {
    console.log('isActive', id);
    parent.isActive(id);
  },
  dismissAll: () => {
    console.log('dismissAll');
    parent.dismiss();
  },
  dismiss: (id) => {
    parent.dismiss(id);
  },
  update: (id, options) => {
    console.log('update', id);
    parent.update(id, { ...options });
  },
  factory: parent
};

export default (props) => <ToastContainer {...props} />;
