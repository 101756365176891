import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import uaParser from 'ua-parser-js';

import Tooltip from '@material-ui/core/Tooltip';
// import Typography from '@material-ui/core/Typography'

import UploadIcon from '@material-ui/icons/PublishRounded';
import DocIcon from '@material-ui/icons/PostAddRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import Fab from '@material-ui/core/Fab';
// import Chip from '@material-ui/core/Chip'

import useUpload from 'hooks/useUpload';

const RootCont = styled.div`
  position: fixed;
  bottom: 30px;
  right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .MuiChip-root {
    display: none;
  }
  .MuiFab-root:not(.trigger) {
    position: absolute;
    bottom: 0;
    right: 9px;
    opacity: 0;
    transform: rotate(-180deg);
    transition: opacity .3s 1s, bottom .3s 1s, transform .3s 1s;
    .MuiChip-root {
      position: absolute;
      right: 50px;
      background-color: #FFF;
    }
    :hover .MuiChip-root {
      display: flex;
    }
  }
  
  :hover {
    .MuiFab-root:not(.trigger) {
      bottom: 65px;
      opacity: 1;
      transform: rotate(0deg);
      transition: opacity .3s .1s, bottom .3s .1s, transform .3s .1s;
      &.two {bottom: 115px;}
    }
  }
  .trigger {
    transform: rotate(90deg);
    transition: transform .3s 1s;
    :hover {
      transform: rotate(0deg);
      transition: transform .3s .1s;
    }
  }
  :hover .trigger {
    transform: rotate(0deg);
    transition: transform .3s;
  }
`;

export default ({uid, oid}) => {
  const {openDialog} = useUpload();
  const history = useHistory();

  const openUploader = (view) => {
    openDialog({view, responsive: true});
  };

  useEffect(() => {}, []);

  const userAgent = uaParser(window.navigator.userAgent);
  const isMobile = userAgent?.device?.type === 'mobile';

  return (
    <RootCont className='fab-button-container' >
      <Tooltip title={<div style={{fontSize: 14, padding: 7}}>Transcribe</div>} placement='left' arrow >
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => openUploader('file-drop')}
        >
          <UploadIcon />
        </Fab>
      </Tooltip>

      <Tooltip title={<div style={{fontSize: 14, padding: 7}}>Create with AI</div>} placement='left' arrow>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          className="two"
          onClick={() => history.push('/doc')}
        >
          <DocIcon />
        </Fab>
      </Tooltip>

      <Fab
        className='trigger'
        color="primary"
        aria-label="add"
        onClick={() => !isMobile && openUploader('file-drop')}
      >
        <AddIcon fontSize="large" />
      </Fab>
    </RootCont>
  );
};