import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import { isLocal } from 'utils/env';
import './static/styles/main.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './static/styles/toaster.css';
import './static/fonts/font.css';
import { getCurrentEnv } from 'utils/env';
import * as Sentry from '@sentry/react';

// https://stackoverflow.com/questions/49604821/cache-busting-with-create-react-app/49605422#49605422
// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker';

if (
  isLocal() &&
  window.localStorage.getItem('check-accessibility') === 'true'
) {
  console.error(
    'You are urnning AXE CORE. This should only be used in Dev Eenv'
  );
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

// if (getCurrentEnv === 'prod' && process?.env.REACT_APP_SENTRY_RELEASE) {
const SentryParams = {
  dsn: 'https://3806769f664e4ec2ae799ee9e56eca54@o91533.ingest.sentry.io/1320787',
  release: process?.env.SENTRY_RELEASE_VERSION,
  environment: getCurrentEnv(),
  debug: false,
  ignoreUrls: [/connect\.facebook\.net/, /en_US\/fbevents\.js/]

  /*
      commenting out sice it effects testing performance too much
      re-enable only after adding ability to pass param or localstorage to site
      to turn it off for a given user.
  */

  // replaysSessionSampleRate: getCurrentEnv() === 'prod' ? 0.1 : 1,
  // replaysOnErrorSampleRate: 1.0,
  // tracesSampleRate: getCurrentEnv() === 'prod' ? 0.1 : 1.0,
  // integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()]
  // ignoreErrors: [/Failed to execute 'drawImage' on 'CanvasRenderingContext2D'/]
};

// let us play with it in dev for a while before unleashing
if (getCurrentEnv() === 'prod') {
  delete SentryParams.integrations;
  delete SentryParams.tracesSampleRate;

  delete SentryParams.replaysOnErrorSampleRate;
  delete SentryParams.replaysSessionSampleRate;
}

Sentry.init(SentryParams);
console.log({ SentryParams });

ReactDOM.render(<Root />, document.getElementById('root'));
// registerServiceWorker()
unregister();
