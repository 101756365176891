import {
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import {getCredits} from 'utils/Firebase';

// Actions
export const fetchCredits = createAsyncThunk(
  'user/credits',
  async (params) => {
    const {customer} = params || {};
    if (!customer) return null;
    try {
      return await getCredits(customer) || 0;
    } catch (error) {
      console.log(error);
      return null;
    }
  });

// Slice
export const creditsAdapter = createEntityAdapter();
const initialState = creditsAdapter.getInitialState({});
export const slice = createSlice({
  name: 'credits',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCredits.fulfilled, (state, {meta, payload}) => {
        const {customer, oid} = meta?.arg;
        creditsAdapter.upsertOne(state, {id: oid, customer, credits: payload});
      });
  }
});

const reducer = slice.reducer;
export default reducer;

// Selectors
const sliceSelector = state => state.credits;

export const selectCredits = createSelector(
  [sliceSelector],
  (credits) => credits.entities
);

export const selectOrgCredits = createSelector(
  [selectCredits, (_, oid) => oid],
  (credits, oid) => credits[oid]
);
