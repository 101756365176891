import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  getTimeStamp,
  stampToSeconds
} from 'components/common/Timer/convert-time';
import ResolveAllCheckIcon from '@material-ui/icons/CheckCircleOutline';
import {
  getMinTimeBeforeSpan,
  getMaxTimeAfterSpan
} from 'components/common/EditorV2/HTMLEditor/utils';

const Recoder = ({ time, id }) => {
  const recodeLangs = JSON.parse(
    localStorage.getItem('recodeLangs' + id) || '{}'
  );
  const [language, setLanguage] = React.useState(recodeLangs[time]?.lang);

  const handleUpdateRecode = (evt) => {
    let lang = (language || '').toLowerCase();
    console.log({ lang });
    if (lang) {
      const wordsOfSection =
        evt.target.parentElement.parentElement.querySelectorAll('.word');
      const start = Math.floor(getMinTimeBeforeSpan(wordsOfSection[0]));
      const end = Math.floor(
        getMaxTimeAfterSpan(wordsOfSection[wordsOfSection.length - 1])
      );
      recodeLangs[time] = { start, end, lang };
      console.log('saving', recodeLangs, start, end, lang);
    } else {
      delete recodeLangs[time];
    }

    localStorage.setItem('recodeLangs' + id, JSON.stringify(recodeLangs));
  };

  return (
    <input
      className={clsx(['recode-lang', language ? 'has-lang' : ''])}
      value={language || ''}
      onChange={(evt) => {
        setLanguage(evt.target.value);
      }}
      onBlur={handleUpdateRecode}
    />
  );
};

const TimeStamp = React.memo(function TimeStamp ({
  id,
  time: initTime,
  onMarkAllAsResolved,
  isKonchEmp,
  onOffset
}) {
  const [edit, setEdit] = React.useState(false);
  const [newTime, setNewTime] = React.useState();
  const secs = parseFloat(initTime);
  const timeStamp = getTimeStamp(secs);

  const handleChange = (evt) => {
    setNewTime(evt.target.value);
  };

  const handleToggleEditable = (evt) => {
    if (evt.altKey) {
      // if in edit mode and click off then update
      // else turn it on
      if (edit) {
        const isTimeCode = /\d\d:\d\d:\d\d/.test(newTime);
        if (isTimeCode && onOffset) {
          onOffset({ newSecs: stampToSeconds(newTime), secs });
        } else {
          console.log('Not a valid time code', newTime);
          setNewTime(timeStamp);
        }
      } else {
        setNewTime(timeStamp);
      }
      setEdit(!edit);
    }
  };

  return (
    <TimeCode onKeyPress={console.log} onClick={handleToggleEditable}>
      {isKonchEmp && <Recoder time={secs} id={id} />}
      {isKonchEmp && edit ? (
        <input value={newTime} onChange={handleChange} style={{ width: 58 }} />
      ) : (
        <h1>{timeStamp}</h1>
      )}
      <ResolveAllCheckIcon onClick={onMarkAllAsResolved} />
    </TimeCode>
  );
});

export default TimeStamp;

const TimeCode = styled.div.attrs({
  contentEditable: false
})`
  user-select: none;
  position: absolute;
  @media (max-width: 863px) {
    position: initial;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 100%;
  margin: -10px 8px 0 0;

  input.recode-lang {
    margin-right: 5px;
    display: none;
    outline: none;
    border: none;
    font-size: 12px;
    text-transform: uppercase;
    color: #999;
    width: 27px;
    text-align: center;
    background-color: #eee;
    height: 13px;
    line-height: 13px;
    &.has-lang {
      display: block;
      background-color: transparent;
    }
    :focus {
      display: block;
    }
  }

  :hover input.recode-lang {
    display: block;
  }

  h1 {
    color: #979797;
    font-weight: bold;
    font-size: 15px;
  }
  svg {
    margin-left: 6px;
    opacity: 0;
    font-size: 18px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    color: #979797;
    transition:
      color 1s,
      opacity 0.3s 0.3s,
      background-color 0.5s;
    :hover {
      opacity: 1;
      color: #159115;
      transition:
        color 1s,
        background-color 0.5s;
    }
    :active {
      background-color: #077307;
      color: #fff;
    }
  }
  :hover svg {
    opacity: 1;
    transition: opacity 0.3s 0.3s;
  }
`;
