import rangy from 'rangy';

// !!only words if is visible in virtualization!!
// if you want real times you will need to access JSON storage of word transcripts array

export const isUrl = (str) => {
  var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  var url = new RegExp(urlRegex, 'i');
  return str.length < 2083 && url.test(str);
};

export const getElementsInRange = range => {
  if (range.startContainer.isSameNode(range.endContainer)) {
    return [range.startContainer?.parentNode];
  }

  return range.getNodes([1], node => node.tagName === 'SPAN' && node.classList.contains('word'));
};

export const getAllElementsInRange = range => {
  if (range.startContainer.isSameNode(range.endContainer)) {
    console.log('is same node');
    return [range.startContainer?.parentNode];
  }

  return range.getNodes([1], node => node.tagName === 'SPAN' && (node.classList.contains('word') || node.classList.contains('space')));
};

export const getSelectedSpans = (all) => {
  // console.log('get selected spans')
  const sel = rangy.getSelection() || {};
  let range;
  try {
    range = sel.getRangeAt(0);
  } catch (err) {
    console.log('No selection to get');
    return {};
  }

  const spans = all ? getAllElementsInRange(range) : getElementsInRange(range);
  // console.log({spans})
  const anchorEl = spans.slice(-1)[0];
  let box;
  try {
    if (anchorEl) {
      box = anchorEl.getBoundingClientRect();
    }
  } catch (error) {
    console.log('No bounding box.');
  }

  return {spans, range, sel, anchorEl, box};
};

// TODO: this is better: document.querySelector('.word[data-start_time="1088.601"]').closest(".section")
export const getSectionElofWord = (el) => {
  try {
    const section = el.parentElement.parentElement.parentElement.querySelector('.section');
    return section;
  } catch {
    console.log('Could not find section for word');
  }
};

// this is better: document.querySelector('.word[data-start_time="1088.601"]').closest(".section")
export const getPrevSectionofWord = (el) => {
  try {
    const prevSection = getSectionElofWord(el).parentElement.previousSibling.querySelector('.section');
    return prevSection;
  } catch {
    console.log('Could not find previous section');
  }
};

export const getNextSectionofWord = (el) => {
  try {
    const nextSection = getSectionElofWord(el).parentElement.nextSibling.querySelector('.section');
    return nextSection;
  } catch {
    console.log('Could not find next section');
  }
};

export const getMinTimeBeforeSpan = (span) => {
  let previous = span?.previousSibling;
  let minTime = span?.dataset?.start_time;

  // if there is no prev sibling check previous section
  if (!previous) {
    const prevSectionEl = getPrevSectionofWord(span);

    // if there is no previous section, 0 is min time
    if (prevSectionEl) {

      // get last word in that section
      const word = prevSectionEl.querySelector('.word:last-child');
      const endTime = word?.dataset?.end_time;
      if (word && endTime) return parseFloat(endTime);
    } else {
      // this is the first section and min time is 0
      return 0;
    }

  }

  let count = 0;
  while (previous && count < 5) {
    try {
      count++;
      const isSpace = previous.classList && previous.classList.contains('space');
      // console.log('is Space?', isSpace, previous)

      if (isSpace) {
        previous = previous?.previousSibling;
        continue;
      }

      if (!previous) break;
      else if (previous && previous?.dataset?.end_time) {
        minTime = previous.dataset.end_time;
        break;
      }
    } catch (error) {
      console.log('issue grabbing earlier time', error);
      break;
    }
  }
  return parseFloat(minTime);
};

// get the startime of the next imidiate word (or section)
// if none then add 1 second to current end_time
export const getMaxTimeAfterSpan = (span) => {
  // console.log('getMaxTimeAfterSpan', span)
  let next = span?.nextSibling;
  let maxTime = span?.dataset?.end_time;
  let count = 0;

  if (!next) {
    // if there is a next section use the start time of that as the max allowed
    const nextSection = getNextSectionofWord(span);
    if (nextSection) {
      return parseFloat(nextSection?.dataset?.startTime);
    } else {
      // no more content so search for end of audio
      console.log('TODO: should get max time of audio here');
    }
  }

  while (next && count < 5) {
    count++;
    try {
      // console.log('checking next')
      const isSpace = next.classList && next.classList.contains('space');
      // console.log('is Space?', isSpace, next)
      if (isSpace) {
        next = next?.nextSibling;
        continue;
      }

      if (!next) {
        console.log('No next element');
        // TODO: Get start time of next section
        // if none, add 1 second to end_time
        break;
      }
      else if (next && next?.dataset?.start_time) {
        maxTime = next.dataset.start_time;
        break;
      }
    } catch (error) {
      console.log('issue grabbing earlier time', error);
      break;
    }
  }
  // console.log('next:', next)
  return parseFloat(maxTime);
};

export const safeFloatAddFraction = (float, addFraction, maxDecPlaces = 15) => parseFloat((float + addFraction).toFixed(maxDecPlaces));
export const padDecimalPlaces = (n, int = 1) => parseFloat('.' + '0'.repeat(n) + `${int}`);

export const incrementByMinTime = (minTime, maxTime) => {
  // console.log({minTime, maxTime})
  let newTime = minTime;
  let decPlaces = 5;
  try {
    decPlaces = maxTime.toString().split('.')[1].length - 1;
  } catch (err) {
    console.log('Could not next dec places, using default.');
  }
  let decNum = 1;
  let tries = 0;
  const shift = () => {
    // shift
    // .009 => .0001
    decPlaces++;
    decNum = 1;
  };
  // console.log('time:', {minTime, maxTime, decPlaces, decNum})

  while (tries < 20) {
    if (decNum >= 10) shift(); // no use in going from .009 to .010
    const inc = padDecimalPlaces(decPlaces, decNum);
    newTime = safeFloatAddFraction(minTime, inc, decPlaces + 1);
    // console.log('   ->', newTime, '<', maxTime, {tries, inc, decPlaces, decNum})

    if (newTime < maxTime) {
      // console.log('   -> fits criterea')
      break;
    } else {
      // console.log('   -> shifted', {decPlaces, decNum})
      shift();
    }
    tries++;
  }
  // console.log({newTime})
  return newTime;
};

export const placeCaretAtElem = (focusEl, offset, select) => {
  try {
    const range = document.createRange();
    const sel = window.getSelection();
    if (!focusEl || !document.body.contains(focusEl)) {
      return;
    }

    const nodes = focusEl.childNodes;
    const node = nodes.length === 0 ? focusEl : nodes[0];
    const isEmpty = nodes.length === 0;

    if (isEmpty) {
      const previousSpace = node.previousSibling;

      if (previousSpace && previousSpace.childNodes.length) {
        range.setStart(previousSpace.childNodes[0], previousSpace.textContent.length);
      } else {
        const nextSpace = node.nextSibling;

        if (nextSpace && nextSpace.childNodes.length) {
          range.setStart(nextSpace.childNodes[0], 0);
        }
      }

      sel.removeAllRanges();
      sel.addRange(range);

      return;
    }

    if (offset !== undefined && offset !== null && !isNaN(offset)) {
      if (node.length || offset <= 0) {
        range.setStart(node, offset);
      } else {
        range.setStart(node, node.length || 0);
      }
    } else {
      range.setStart(node, 1);
    }

    if (select) range.setEnd(node, node.length);

    sel.removeAllRanges();
    sel.addRange(range);
  } catch (err) {
    console.error('Could not place caret', err);
  }
};

export const goToNextLineOrSection = (fwd, page) => {
  let {anchorEl} = getSelectedSpans(true);
  console.log('Checking anchor', {anchorEl});
  // if cursor is not in section then scroll to last visible page
  if (page) {
    console.log('Paging. Anchor a word?', {anchorEl});
    const allSectionsOnPage = document.querySelectorAll('.section');
    if (fwd) {
      anchorEl = allSectionsOnPage[allSectionsOnPage.length - 1].querySelector('.word');
      anchorEl.scrollIntoView({block: 'center'});
      return placeCaretAtElem(anchorEl, 0);
    } else {
      anchorEl = allSectionsOnPage[0].querySelector('.word');
      anchorEl.scrollIntoView({block: 'center'});
      return placeCaretAtElem(anchorEl, 0);
    }
  }
  const isAnchorWord = anchorEl ? anchorEl.classList.contains('word') : false;
  if (!isAnchorWord) {
    const words = document.querySelectorAll('.word');
    const middle = Math.max(parseInt(words.length / 2, 10) - 1, 0);
    anchorEl = words[middle];
    placeCaretAtElem(anchorEl, 0);
    return;
  }
  console.log('Anchor is a word', {anchorEl});
  // if cursor is in a word go to next/prev section
  if (fwd) {
    console.log('next sibling', anchorEl.nextSibling);
    if (!anchorEl.nextSibling) {
      // try to go to next section
      const next = getNextSectionofWord(anchorEl);
      if (!next) return;
      const firstWordNextSection = next.querySelector('.word');
      firstWordNextSection.scrollIntoView({block: 'center'});
      placeCaretAtElem(firstWordNextSection, 0);
      return;
    } else {
      const currSection = getSectionElofWord(anchorEl);
      console.log({currSection});
      const words = Array.prototype.slice.call( currSection.querySelectorAll('.word') );
      let lastWord = words[Math.min(words.indexOf(anchorEl) + 100, words.length - 1)];
      console.log('last word', {lastWord});
      lastWord.scrollIntoView({block: 'center'});
      placeCaretAtElem(lastWord, 0);
    }
    // same same but previous section direction
  } else {
    console.log('prev sibling', anchorEl.previousSibling);

    if (!anchorEl.previousSibling) {
      const prev = getPrevSectionofWord(anchorEl);
      if (!prev) return;
      // try to go to next section
      console.log('no prev sibling', {prev});
      const words = prev.querySelectorAll('.word');
      const lastWordPrevSection = words[words.length - 1];
      console.log({lastWordPrevSection});
      if (lastWordPrevSection) {
        lastWordPrevSection.scrollIntoView({block: 'center'});
        placeCaretAtElem(lastWordPrevSection, 0);
      }
      return;
    } else {
      const currSection = getSectionElofWord(anchorEl);
      console.log({currSection});
      const words = Array.prototype.slice.call( currSection.querySelectorAll('.word') );
      let firstWord = words[Math.max(words.indexOf(anchorEl) - 80, 0)];
      console.log('first word', {firstWord});
      firstWord.scrollIntoView({block: 'center'});
      placeCaretAtElem(firstWord, 0);
    }
  }
};

export default {
  getSelectedSpans,
  getElementsInRange,
  placeCaretAtElem
};