import {
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';
import {
  getDictionaries as fetchAll,
  getDictionary as fetch,
  updateDictionary as update,
  deleteDictionary as deleteDictionaryApi
} from 'api/dictionary';

// Actions
export const fetchDictionaries = createAsyncThunk(
  'dictionary/fetchAll',
  async ({uid}, {getState}) => {
    const {data} = await fetchAll({uid});
    // console.log(data)
    return data;
  });

export const fetchDictionary = createAsyncThunk(
  'dictionary/fetch',
  async ({id, uid}, {getState}) => {
    const response = await fetch({uid});
    return response.data;
  });

export const updateDictionary = createAsyncThunk(
  'dictionary/update',
  async ({id, uid, dictionary}, {getState}) => {
    console.log({id, uid, dictionary});
    const {data} = await update({uid, id: id || dictionary.id, dictionary});
    return data;
  });

export const deleteDictionary = createAsyncThunk(
  'dictionary/delete',
  async ({id, uid}, {getState}) => {
    console.log({id});
    const {data} = await deleteDictionaryApi({uid, id});
    return data;
  });

// Slice
export const dictionaryAdapter = createEntityAdapter();
const initialState = dictionaryAdapter.getInitialState({});
export const slice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDictionaries.fulfilled, dictionaryAdapter.upsertMany)
      .addCase(fetchDictionary.fulfilled, dictionaryAdapter.upsertOne)
      .addCase(updateDictionary.fulfilled, dictionaryAdapter.upsertOne)
      .addCase(deleteDictionary.fulfilled, dictionaryAdapter.removeOne)
      .addCase(updateDictionary.pending, (state, {meta}) => {
        const {arg: {dictionary}} = meta;
        state.selectedDictionary = dictionary.id;
      });
  }
});

const reducer = slice.reducer;
export default reducer;

// Selectors
const sliceSelector = state => state.dictionaries;
export const selectedDictionary = state => state.dictionaries.selectedDictionary;
const {
  selectById,
  selectEntities,
  selectAll
} = dictionaryAdapter.getSelectors(sliceSelector);

export const selectDictionaryById = selectById;
export const selectDictionaries = selectEntities;

export const selectDictionary = createSelector(
  [selectDictionaryById],
  (dictionary) => dictionary
);

export const selectOrderedDictionaries = createSelector(
  [selectAll, (_, language) => language],
  (dictionaries, language) => language ? dictionaries.filter(dictionary => {
    // console.log({dictionary})
    return (dictionary.language || '').split('-')[0] === language.split('-')[0];
  }) : dictionaries
);
