import {createContext} from 'react';

const WordChunkSliderContext = createContext({
  setShow: () => {},
  showSlider: false,
  spans: [],
  minTime: 0,
  maxTime: 0
});

export default WordChunkSliderContext;
