/* eslint-disable quotes */
/* eslint-disable indent */
import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import _debounce from 'lodash/debounce';
// import {useMediaPredicate} from 'react-media-hook'
import FadeIn from 'react-fade-in';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';
import DoneIcon from '@material-ui/icons/DoneRounded';
import { FeaturesContext } from 'providers/FeaturesProvider';
import SelectAllIcon from '@material-ui/icons/PlaylistAddCheck';
import LinearProgress from '@material-ui/core/LinearProgress';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import MUIButton from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/HighlightOff';
import Chip from '@material-ui/core/Chip';
import ExportIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import TocIcon from '@material-ui/icons/ViewDayOutlined'; //'@material-ui/icons/Toc'
import AppsIcon from '@material-ui/icons/Apps';

import SubFolderIcon from '@material-ui/icons/ChevronRightOutlined';

import { getIsUni } from 'utils/env';
import Spinner from 'components/common/Spinner';
import Button from 'components/common/Button';
import { toast } from 'components/common/Toaster';
import useCollection from 'hooks/useCollection';
import usePlan from 'hooks/usePlan';
import CollectionListCard from './CollectionsListCard';
import CollectionsTableView from './CollectionsTableView';
import useProfile from 'hooks/useProfile';
import useUpload from 'hooks/useUpload';
import SearchContext from 'context/SearchContext';
import { DASHBOARD_VIEW, Features } from 'utils/constants';
import useMultiExporter from 'hooks/useMultiExporter';
import Popover, { MenuIcon, MenuItem } from '../Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClosedCaptioning,
  faFileAlt,
  faFileCsv,
  faFileWord
} from '@fortawesome/free-solid-svg-icons';
import { ExportModal } from '../Plans/Modals';
import { List } from '@material-ui/core';
import { withTranscriptExportCheck } from '../utils';
import { FeatureModalContext } from 'providers/FeatureModalProvider/FeatureModalProvider';

TimeAgo.addLocale(en);

const Cont = styled.div`
  ${({ loading }) => loading && `animation: Pulsate 1s linear infinite;`}
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
  justify-content: flex-end;

  @media (max-width: 550px) {
    display: none;
  }
`;

const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
`;

const HeaderCont = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px 0 8px;
  margin-left: -10px;
  border-radius: 8px;
  height: 23px;
  h2 {
    text-transform: uppercase;
    color: #777;
    font-size: 0.8em;
    margin-right: 5px;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 925px) {
      display: none;
    }
    span {
      color: #777;
      transition: color 1s 0.5s;
      :hover {
        color: #333;
        transition: color 1s;
      }
    }
  }
  &.hover:hover {
    background-color: #e9e9e9;
    cursor: pointer;
  }
  :hover svg.MuiSvgIcon-root {
    opacity: 1;
    transition: opacity 1s 1.5s;
  }
  svg.MuiSvgIcon-root {
    :hover {
      cursor: pointer;
    }
    opacity: 0;
    font-size: 1em;
    color: #777;
    transition: opacity 1s;
    ${({ short }) => !short && `transform: rotate(180deg);`}
    &.folder {
      opacity: 1;
      transform: rotate(0deg);
      font-size: 23px;
    }
  }
`;

const NoMoreMessages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  text-align: center;
  line-height: 44px;
  font-weight: 400;
  margin: 40px auto 100px 40px;
  color: rgba(0, 0, 0, 0.3);
`;
const Body = styled.div`
  padding: 20px;
  min-height: 100vh;
`;

const BigMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  text-align: center;
  line-height: 44px;
  font-weight: 400;
  transform: translateY(-230px);

  @media (max-width: 500px) {
    line-height: 30px;
    width: 100%;
    margin: auto;
    left: 0;
    top: 0;
    position: relative;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  ${(props) =>
    props.mode === DASHBOARD_VIEW.LIST
      ? `
    width: 100%;
    margin-left: 30px;
  `
      : `
    margin-left: 15px;
    width: 1071px;

    @media (min-width: 1586px) {
      width: 1281px;
    }
    @media (max-width: 1382px) {
      width: 860px;
    }
    @media (max-width: 1080px) {
      width: 654px;
    }
    @media (max-width: 898px) {
      width: 441px;
    }
    @media (max-width: 548px) {
      width: 231px;
    }
  `}
`;

const BigMessageButton = styled(Button)`
  margin-top: 32px;
`;
// const UploadButton = styled(MuiButton)`
//   && {
//     background-color: #5173E7;
//     color: #FFF;
//     padding: 5px 15px 5px 50px;

//     :hover {
//       background-color: #5173E7;
//       opacity: 1;
//     }
//   }
// `

// const UploadList = styled.div`
//   border-right: solid 2px #4968D0;
//   margin: 2px -40px 2px 0;
//   z-index: 1;
//   max-height: 32px;
//   color: #A0B3EF;
//   : hover {
//     color: #FFF;
//   }
// `
// const ArrowDropDown = styled(ArrowDropDownRounded)`
//   font-size: 35px !important;
// `

// const PopoverPosition = {
//   anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
//   transformOrigin: {vertical: 'top', horizontal: 'left'},
//   margin:'5px 0 30px -21px',
//   padding: '15px 30px 15px 30px',
//   borderRadius: '10px'
// }

const Help = () => (
  <HelpOutlineIcon
    style={{
      color: 'rgb(81, 115, 230)',
      cursor: 'pointer',
      fontSize: '.7em',
      margin: '6px'
    }}
    onClick={() => [
      Beacon('article', '605f616124ce107ab4d1a144'),
      Beacon('open')
    ]}
  />
);
const Welcome = (props) => {
  const { openDialog } = useUpload();
  const { updateSettings } = useProfile();
  const { profile } = props;
  const { settings } = profile;

  const sharedWithView = settings && settings.dashFilter === 'Shared with Me';
  const message = sharedWithView ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>Nothing is shared with you at this time. </div> <Help />
    </div>
  ) : (
    'Welcome to Konch, ready to start transcribing?'
  );
  const showButton = sharedWithView
    ? 'Return to your Collections'
    : `+ Let's go!`;
  const clickAction = sharedWithView
    ? () => updateSettings({ dashFilter: 'My Collections' })
    : openDialog;
  // chaneg in design to alwasy have at least the add card.
  if (!sharedWithView) return '';
  return (
    <FadeIn delay={200}>
      <BigMessage>
        {message}
        <div style={{ display: 'flex' }}>
          {showButton && (
            <BigMessageButton big raised primary onClick={clickAction}>
              {showButton}
            </BigMessageButton>
          )}
        </div>
      </BigMessage>
    </FadeIn>
  );
};

const getFreshViews = () => ({
  'Org Collections': {},
  'Shared with Me': {},
  'My Collections': {}
});

const SearchMessage = ({ searchTerm }) => (
  <BigMessage>
    <span>
      No transcripts matching <b>"{searchTerm}"</b>.<br /> Please try another
      search.
    </span>
  </BigMessage>
);

export default ({
  render,
  count,
  onClearAll,
  onSelectAll,
  ids,
  onDeleteSelected,
  deleting,
  filter,
  showChipDates = true,
  selectedCollectionTypes
}) => {
  const { isTranscriptExportEnabled } = useContext(FeaturesContext);
  const { showFeatureModal } = useContext(FeatureModalContext);

  const { searchTerm, loading: searchLoading } = useContext(SearchContext);
  const {
    profile,
    uid,
    oid,
    settings = {},
    currentProject: project,
    organization,
    updateSettings,
    setCurrentProject,
    isCorrector,
    isReviewer
  } = useProfile();

  const collectionIdsToExport = Object.keys(ids);

  const {
    generateDocs,
    generateCsv,
    generateText,
    generateSrtSubtitles,
    generateVttSubtitles,
    generateAnnotations,
    completedCollections,
    isGeneratingDocs
  } = useMultiExporter({
    collectionIds: collectionIdsToExport
  });

  const completedCount = collectionIdsToExport.filter((collectionId) =>
    completedCollections.has(collectionId)
  ).length;

  const isMagicDocSelected = [...selectedCollectionTypes.values()].some(
    (collectionType) => collectionType === 'text'
  );

  const [showExportModal, setShowExportModal] = useState(false);

  const randomLoaderMessage = React.useRef(
    [
      "We're stretching our digital and loading your workspace...",
      'Our digital fingers are working fast to load your workspace...',
      'Your workspace will be ready soon!',
      "We're whipping up some digital magic for your workspace...",
      'Loading your workspace...',
      'Digital elves are hard at work loading your workspace...',
      "Takes a second, but it'll be worth it!",
      'Our hamsters are running as fast as they can to load your workspace!'
    ][Math.floor(Math.random() * 8)]
  );

  const isUni = getIsUni(oid);
  isUni && console.log('Uni Org, manually blocking filter options.');

  let {
    dashFilter,
    dashboardView,
    showShortHeader,
    maxCPL = 35,
    welcomeSettings
  } = settings;
  const {
    userProjects,
    fetchCollections,
    userCollections,
    orgCollections,
    sharedCollections,
    searchedCollections,
    searchededOrgCollections
  } = useCollection({
    uid,
    oid,
    searchTerm,
    searchLoading,
    project
  });

  const [loading, setLoading] = useState(true);
  // const {openDialog} = useUpload()
  const { deletedAt } = organization;

  if (filter) dashFilter = filter;
  else if (settings.dashFilter) dashFilter = settings.dashFilter;

  const viewOrgWide = !isUni && dashFilter === 'Org Collections';
  const viewSharedCollections = dashFilter === 'Shared with Me';
  const { usage: currentUsage } = usePlan({ oid, uid });

  let collections = userCollections;
  if (searchTerm && viewOrgWide) collections = searchededOrgCollections;
  else if (searchTerm && !viewOrgWide) collections = searchedCollections;
  else if (viewSharedCollections) collections = sharedCollections;
  else if (viewOrgWide) collections = orgCollections;

  // reduce reloading already loaded data based on current workspace or views
  // TODO:
  //    This is probably better managed by saving the query in redux
  //    an preventing reload before request based on params.
  const [whatIsLoaded, setWhatIsLoaded] = useState({});

  const [scrolling, setScrolling] = useState(false);
  const mounted = useRef(true);
  const loadedOrgInfo = whatIsLoaded[oid] || getFreshViews();
  const loadedView = loadedOrgInfo[dashFilter];
  const { nextPage, loaded } = loadedView || {};

  const loadPage = async (opts) => {
    // don't allow uni to load org wide collections but allow shared
    dashFilter =
      dashFilter === 'Org Collections' && isUni ? 'My Collections' : dashFilter;
    const { loadNextPage } = opts || {};
    if ((loaded && !nextPage) || (loaded && !loadNextPage)) return null;
    setLoading(true);
    const { payload: response } = await fetchCollections({
      nextPage,
      shared: viewSharedCollections,
      orgWide: !!viewOrgWide
    });
    const payload = response?.data;
    const LastEvaluatedKey = response?.LastEvaluatedKey;

    // work around for mistake not passing meta down to client.
    // TODO: allow response count and page meta to present in redux from api response
    if (!LastEvaluatedKey || payload.length === 0) {
      delete loadedOrgInfo[dashFilter].nextPage;
    } else {
      loadedOrgInfo[dashFilter].nextPage = LastEvaluatedKey;
      whatIsLoaded[oid] = loadedOrgInfo;
      mounted.current && setWhatIsLoaded(whatIsLoaded);
    }

    loadedOrgInfo[dashFilter].loaded = true;

    // if it is a first time reg user hide the welcome interstitial from reg if still showing
    // settings?.showWelcome && showWelcome(false)

    // hide loading spinner
    mounted.current && setLoading(false);
  };

  const updateCollections = async (args = {}) => {
    await loadPage(args);
  };

  useEffect(() => {
    if (isGeneratingDocs) {
      setShowExportModal(true);
    }
  }, [isGeneratingDocs]);

  useEffect(() => {
    if (completedCount === collectionIdsToExport.length) {
      const timeoutId = setTimeout(() => {
        setShowExportModal(false);
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [completedCount, collectionIdsToExport]);

  const scrollHandler = _debounce(
    () => {
      if (oid) {
        console.log({ oid, loading });
        loadPage({ loadNextPage: true });
        setScrolling(false);
      }
    },
    5000,
    {
      leading: true,
      trailing: false
    }
  );

  // use to ensure state is updated on scroll
  // other ways i tried either didn't work or were messier
  const scrollHandlerHelper = () => {
    const el = document.querySelector('.load-more');
    if (el) {
      const { top } = el.getBoundingClientRect();
      if (top < window.innerHeight + 100 && !scrolling) {
        setScrolling(true);
        scrollHandler();
      }
    }
  };
  useEffect(() => {
    if (scrolling) {
      !loading && scrollHandler();
    }
  }, [scrolling]);

  useEffect(() => {
    if (oid && whatIsLoaded[oid] === undefined) {
      whatIsLoaded[oid] = getFreshViews();
      setWhatIsLoaded(whatIsLoaded);
      updateCollections();
    }

    if (deletedAt) {
      const hours = moment().diff(moment(deletedAt), 'hours');
      const hoursLeft = 48 - parseInt(hours);
      toast.warn(
        `This Workspace will delete in ${hoursLeft} hour${
          hoursLeft > 1 ? 's' : ''
        }`,
        { autoClose: false, toastId: 'deletion-warning' }
      );
    }
  }, [oid]);

  useEffect(() => {
    if (oid && profile.isLoaded) updateCollections();
  }, [dashFilter, render]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandlerHelper);
    return () => {
      window.removeEventListener('scroll', scrollHandlerHelper);
      mounted.current = false;
    };
  }, []);

  collections = collections.sort((a, b) => b.started - a.started);
  if (project)
    collections = collections.filter((c) => (c.projects || {})[project]);

  // const showAddCard = !viewSharedCollections && (!loading || (loading && collections.length !== 0)) && !searchTerm
  let name = organization?.name || '';
  const nameHasWorspace = name.toLowerCase().indexOf('workspace') >= 0;
  let headerName = nameHasWorspace ? name : `${name} Workspace`;
  if (!showShortHeader) {
    if (searchTerm) headerName = 'Searching ' + headerName;
    else if (viewOrgWide) headerName = 'Team wide content in ' + headerName;
    else if (viewSharedCollections)
      headerName = headerName + `'s content shared with me`;
    else
      headerName = (
        <>
          <span>My content in </span>&nbsp;<span>{headerName}</span>
        </>
      );
  }
  // if there is no default view and it is loaded then use grid.
  // Also, don't allow table view on very small screens as it is hard to use.
  dashboardView =
    window.innerWidth < 550
      ? DASHBOARD_VIEW.GRID
      : loading
      ? dashboardView
      : dashboardView || DASHBOARD_VIEW.GRID;

  let inFolder = '';
  try {
    const { name } = userProjects[project];
    inFolder = (
      <HeaderCont>
        <h2>
          <SubFolderIcon className="folder" />
          &nbsp;
          {name}
        </h2>
      </HeaderCont>
    );
  } catch (_) {
    // nothing
  }

  // console.log(userProjects, userProjects.length > 0, userProjects[project])
  return (
    <Body>
      <Cont loading={loading && collections.length === 0 ? 1 : 0}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'sticky',
            top: 81,
            height: 46,
            marginBottom: 20,
            zIndex: 2
          }}
        >
          <Flex
            mode={dashboardView}
            style={{
              justifyContent: 'flex-start',
              height: 43,
              paddingRight: 10,
              marginLeft: 15,
              width: 'initial',
              background: '#eef2f6',
              borderBottomRightRadius: 12
            }}
          >
            <IconContainer style={{ marginRight: 10 }}>
              {count ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Chip
                    style={{ height: 30, marginRight: 10, fontWeight: 'bold' }}
                    label={count}
                    color="primary"
                    icon={<CancelIcon style={{ opacity: 0.7, height: 19 }} />}
                    deleteIcon={
                      <SelectAllIcon style={{ opacity: 0.7, height: 19 }} />
                    }
                    onClick={() => (deleting ? {} : onClearAll())}
                    onDelete={() => {
                      if (!deleting) {
                        const ids = collections
                          .filter((collection) => collection.type !== 'text')
                          .map((collection) => collection.id);
                        onSelectAll(ids);
                      }
                    }}
                  />

                  {!isMagicDocSelected && (
                    <Popover
                      close={isGeneratingDocs}
                      trigger={
                        <MUIButton
                          className="hide-small"
                          style={{
                            color: '#555',
                            marginLeft: 7,
                            marginRight: 7,
                            paddingLeft: 5,
                            paddingRight: 9,
                            fontSize: 12
                          }}
                        >
                          <ExportIcon
                            style={{ fontSize: 21, marginRight: 5 }}
                          />
                          {`Export`}
                        </MUIButton>
                      }
                      content={
                        <>
                          <Popover
                            closeOnClick
                            topTransform="-20px"
                            leftTransform="-10px"
                            trigger={
                              <MenuItem>
                                <MenuIcon>
                                  {isGeneratingDocs ? (
                                    <Spinner small />
                                  ) : (
                                    <FontAwesomeIcon icon={faFileWord} />
                                  )}
                                </MenuIcon>
                                <span>Word Doc</span>
                              </MenuItem>
                            }
                            content={
                              <div>
                                <MenuItem
                                  onClick={withTranscriptExportCheck({
                                    isTranscriptExportEnabled,
                                    onSuccess: generateDocs,
                                    onShowFeatureModal: () =>
                                      showFeatureModal({
                                        feature: Features.TranscriptExport
                                      }),
                                    args: [true]
                                  })}
                                >
                                  <span>Tabular</span>
                                </MenuItem>
                                <MenuItem
                                  onClick={withTranscriptExportCheck({
                                    isTranscriptExportEnabled,
                                    onSuccess: generateDocs,
                                    onShowFeatureModal: () =>
                                      showFeatureModal({
                                        feature: Features.TranscriptExport
                                      }),
                                    args: [false]
                                  })}
                                >
                                  <span>Continuous</span>
                                </MenuItem>
                              </div>
                            }
                          />
                          <Popover
                            closeOnClick
                            topTransform="-20px"
                            leftTransform="-10px"
                            trigger={
                              <MenuItem>
                                <MenuIcon>
                                  {isGeneratingDocs ? (
                                    <Spinner small />
                                  ) : (
                                    <FontAwesomeIcon icon={faFileAlt} />
                                  )}
                                </MenuIcon>
                                <span>Plain Text</span>
                              </MenuItem>
                            }
                            content={
                              <div>
                                <MenuItem
                                  onClick={withTranscriptExportCheck({
                                    isTranscriptExportEnabled,
                                    onSuccess: generateText,
                                    onShowFeatureModal: () =>
                                      showFeatureModal({
                                        feature: Features.TranscriptExport
                                      }),
                                    args: [true]
                                  })}
                                >
                                  <span>Speakers</span>
                                </MenuItem>
                                <MenuItem
                                  onClick={withTranscriptExportCheck({
                                    isTranscriptExportEnabled,
                                    onSuccess: generateText,
                                    onShowFeatureModal: () =>
                                      showFeatureModal({
                                        feature: Features.TranscriptExport
                                      }),
                                    args: [false]
                                  })}
                                >
                                  <span>Plain</span>
                                </MenuItem>
                              </div>
                            }
                          />
                          <Popover
                            closeOnClick
                            topTransform="-20px"
                            leftTransform="-10px"
                            trigger={
                              <MenuItem
                                onClick={withTranscriptExportCheck({
                                  isTranscriptExportEnabled,
                                  onSuccess: generateCsv,
                                  onShowFeatureModal: () =>
                                    showFeatureModal({
                                      feature: Features.TranscriptExport
                                    })
                                })}
                              >
                                <MenuIcon>
                                  {isGeneratingDocs ? (
                                    <Spinner small />
                                  ) : (
                                    <FontAwesomeIcon icon={faFileAlt} />
                                  )}
                                </MenuIcon>
                                <span>CSV</span>
                              </MenuItem>
                            }
                          />
                          <Popover
                            topTransform="-20px"
                            leftTransform="-50px"
                            closeOnClick
                            trigger={
                              <MenuItem>
                                <MenuIcon>
                                  {isGeneratingDocs ? (
                                    <Spinner small color="#7DBB91" />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faClosedCaptioning}
                                    />
                                  )}
                                </MenuIcon>

                                <span>Subtitles</span>
                              </MenuItem>
                            }
                            content={
                              <div>
                                <MenuItem
                                  onClick={withTranscriptExportCheck({
                                    isTranscriptExportEnabled,
                                    onSuccess: generateSrtSubtitles,
                                    onShowFeatureModal: () =>
                                      showFeatureModal({
                                        feature: Features.TranscriptExport
                                      }),
                                    args: [maxCPL]
                                  })}
                                >
                                  <span>SRT</span>
                                </MenuItem>
                                <MenuItem
                                  onClick={withTranscriptExportCheck({
                                    isTranscriptExportEnabled,
                                    onSuccess: generateVttSubtitles,
                                    onShowFeatureModal: () =>
                                      showFeatureModal({
                                        feature: Features.TranscriptExport
                                      }),
                                    args: [maxCPL]
                                  })}
                                >
                                  <span>VTT</span>
                                </MenuItem>
                              </div>
                            }
                          />
                          <Popover
                            topTransform="-20px"
                            leftTransform="-50px"
                            closeOnClick
                            trigger={
                              <MenuItem>
                                <MenuIcon>
                                  {isGeneratingDocs ? (
                                    <Spinner small color="#7DBB91" />
                                  ) : (
                                    <FontAwesomeIcon icon={faFileCsv} />
                                  )}
                                </MenuIcon>
                                <span>Highlights/Comments</span>
                              </MenuItem>
                            }
                            content={
                              <div>
                                <MenuItem
                                  onClick={async () => {
                                    const generateAnnotationsFn =
                                      withTranscriptExportCheck({
                                        isTranscriptExportEnabled,
                                        onSuccess: generateAnnotations,
                                        onShowFeatureModal: () =>
                                          showFeatureModal({
                                            feature: Features.TranscriptExport
                                          }),
                                        args: [true]
                                      });

                                    await generateAnnotationsFn();
                                    if (
                                      !welcomeSettings?.exported &&
                                      !isCorrector &&
                                      !isReviewer
                                    )
                                      updateSettings({
                                        [`welcomeSettings.exported`]: true
                                      });
                                  }}
                                >
                                  <span>CSV</span>
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    const generateAnnotationsFn =
                                      withTranscriptExportCheck({
                                        isTranscriptExportEnabled,
                                        onSuccess: generateAnnotations,
                                        onShowFeatureModal: () =>
                                          showFeatureModal({
                                            feature: Features.TranscriptExport
                                          })
                                      });

                                    await generateAnnotationsFn();

                                    if (
                                      !welcomeSettings?.exported &&
                                      !isCorrector &&
                                      !isReviewer
                                    )
                                      updateSettings({
                                        [`welcomeSettings.exported`]: true
                                      });
                                  }}
                                >
                                  <span>XLS</span>
                                </MenuItem>
                              </div>
                            }
                          />
                        </>
                      }
                    />
                  )}

                  <MUIButton
                    className="hide-small"
                    onClick={() => (deleting ? {} : onDeleteSelected())}
                    style={{
                      color: '#555',
                      marginLeft: 2,
                      marginRight: 4,
                      paddingLeft: 5,
                      paddingRight: 9,
                      fontSize: 12
                    }}
                  >
                    {deleting ? (
                      <Spinner
                        small
                        style={{ height: 16, width: 16, margin: '0 8px 0 0' }}
                      />
                    ) : (
                      <DeleteIcon
                        style={{ fontSize: 18, marginRight: 5, marginTop: -3 }}
                      />
                    )}
                    {`Delete`}
                  </MUIButton>
                </div>
              ) : (
                ''
              )}
              {showExportModal && (
                <ExportModal onClose={() => setShowExportModal(false)}>
                  <List dense>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <p>
                        Exporting {completedCount} of{' '}
                        {collectionIdsToExport.length} collections
                      </p>
                      {completedCount === collectionIdsToExport.length && (
                        <DoneIcon style={{ fill: 'green', marginLeft: 5 }} />
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <h5>{`${Math.round(
                        (completedCount / collectionIdsToExport.length) * 100
                      )}%`}</h5>
                      <div
                        style={{
                          width: 450,
                          marginLeft: 20
                        }}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={
                            (completedCount / collectionIdsToExport.length) *
                            100
                          }
                        />
                      </div>
                    </div>
                  </List>
                </ExportModal>
              )}
              {loading ? (
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <Spinner small />
                </div>
              ) : (
                !count && (
                  <>
                    {dashboardView === DASHBOARD_VIEW.GRID && (
                      <IconButton
                        onClick={() =>
                          updateSettings({ dashboardView: DASHBOARD_VIEW.LIST })
                        }
                        aria-label="delete"
                        size="small"
                      >
                        <TocIcon fontSize="medium" />
                      </IconButton>
                    )}
                    {dashboardView === DASHBOARD_VIEW.LIST && (
                      <IconButton
                        onClick={() =>
                          updateSettings({ dashboardView: DASHBOARD_VIEW.GRID })
                        }
                        aria-label="delete"
                        size="small"
                      >
                        <AppsIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </>
                )
              )}
            </IconContainer>
            {!count ? (
              <>
                <HeaderCont
                  onClick={() => inFolder && setCurrentProject('')}
                  className={!loading && inFolder && 'hover'}
                  mode={dashboardView}
                  short={showShortHeader ? 1 : 0}
                >
                  <h2>
                    {name && !loading
                      ? headerName
                      : randomLoaderMessage.current}
                  </h2>
                </HeaderCont>

                {!loading ? inFolder : ''}
              </>
            ) : (
              ''
            )}
          </Flex>
        </div>
        {dashboardView === DASHBOARD_VIEW.GRID ? (
          <CollectionListCard
            showChipDates={showChipDates}
            isSharedCollections={viewSharedCollections}
            loading={loading}
            searchTerm={searchTerm}
            collections={collections || []}
            usage={currentUsage}
          />
        ) : dashboardView === DASHBOARD_VIEW.LIST ? (
          <CollectionsTableView
            loadPage={loadPage}
            nextPage={nextPage}
            isSharedCollections={viewSharedCollections}
            searchTerm={searchTerm}
            loading={loading}
            collections={collections || []}
            usage={currentUsage}
          />
        ) : (
          ''
        )}
      </Cont>

      {nextPage && (searchTerm === '' || searchTerm === null) && (
        <NoMoreMessages>
          <Button
            onClick={() => loadPage({ loadNextPage: true })}
            disabled={loading}
            raised
            primary
            style={{ minWidth: '200px' }}
          >
            {loading ? (
              <Spinner small center color={'white'} />
            ) : (
              <span className="load-more">Load More</span>
            )}
          </Button>
        </NoMoreMessages>
      )}
      {!searchTerm && !loading && collections.length === 0 && !nextPage && (
        <Welcome profile={profile} />
      )}
      {searchTerm && !loading && !searchLoading && collections.length === 0 && (
        <SearchMessage searchTerm={searchTerm} />
      )}
      {loading && (
        <>
          <ProgressBar>
            <LinearProgress aria-label="loading collection" />
          </ProgressBar>
        </>
      )}
    </Body>
  );
};
