import {useContext} from 'react';

import {useSelector} from 'react-redux';

import {selectIsDirty} from 'features/collections';
import {LocalDirtyContext} from 'context/LocalDirtyContext';

const useIsDirty = () => {
  const isDirty = useSelector(selectIsDirty);
  const [localDirtyBlocks] = useContext(LocalDirtyContext);

  return isDirty || !!localDirtyBlocks.length;
};

export default useIsDirty;
