import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { InputBase } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/HighlightOffRounded';
import SearchIcon from '@material-ui/icons/Search';
import LinearProgress from '@material-ui/core/LinearProgress';
import useCollections from 'hooks/useCollection';
import useProfile from 'hooks/useProfile';
import Spinner from 'components/common/Spinner';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchContext from 'context/SearchContext';

const ProgressBar = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
`;

const Root = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: rgba(55, 64, 74, 0.2);
  max-width: 160px;
  height: 32px;
  margin-bottom: 35px;
  ::placeholder {
    font-size: 12px;
  }
`;

const InputBaseStyled = styled(InputBase)`
  padding: 2px 9px 0;
  svg {
    color: #fff;
  }
  .MuiInputBase-input {
    font-size: 14px;
    color: #fff;
    ::placeholder {
      opacity: 1;
    }
  }
`;

export default (props) => {
  const { placeholder, label } = props;
  const {
    oid,
    settings: { dashFilter }
  } = useProfile();
  const { searchCollections } = useCollections({ oid });
  const orgWide = dashFilter === 'Org Collections';
  const { searchTerm, setSearchTerm, loading, setLoading } =
    useContext(SearchContext);
  const [term, setTerm] = useState(null);

  const clear = () => {
    setTerm('');
    setSearchTerm('');
  };

  let debounce;
  useEffect(() => {
    if (term && term !== null && term !== '') {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        setLoading(true);
        console.log({ orgWide });
        setSearchTerm(term);
        await searchCollections({ term, orgWide });
        setLoading(false);
      }, 800);
    } else if (term === '') {
      clear();
    }
    return () => clearTimeout(debounce);
  }, [term]);

  return (
    <Root>
      <InputBaseStyled
        onChange={(evt) => {
          setTerm(evt.target.value);
        }}
        value={term || ''}
        placeholder={placeholder}
        inputProps={{ 'aria-label': label }}
        endAdornment={
          <InputAdornment position="end">
            {loading ? (
              <Spinner small color="#FFF" className="loading-search-input" />
            ) : searchTerm ? (
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={clear}
                className="close-search-input"
              />
            ) : (
              <SearchIcon className="no-search-input" />
            )}
          </InputAdornment>
        }
      />

      {loading && (
        <ProgressBar>
          <LinearProgress />
        </ProgressBar>
      )}
    </Root>
  );
};
