import {useState, useEffect, useRef, useCallback} from 'react';

export default ({
  onSearch,
  throttle
}) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);
  const throttleRef = useRef(null);

  const onChange = (ev) => {
    ev.persist();
    const {value} = ev.target;

    setSearchTerm(value);
  };

  const throttleSearch = useCallback((searchTerm) => {
    if (!onSearch) return;
    if (throttleRef.current) clearTimeout(throttleRef.current);

    throttleRef.current = setTimeout(() => {
      onSearch && onSearch(searchTerm);
    }, throttle || 1000);
  }, [onSearch, throttle]);

  useEffect(() => {
    if (searchTerm) throttleSearch(searchTerm);
  }, [searchTerm, throttleSearch]);

  return {searchTerm, setSearchTerm, loading, setLoading, onChange};
};
