import React from 'react';
// import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    color: '#FFF',
    '& .MuiSelect-select': {
      color: '#EEE'
    },
    '& .MuiInputLabel-formControl': {
      color: '#7f7f7f'
    },
    '& .MuiFilledInput-underline:after': {
      borderColor: '#7f7f7f'
    }
  },
  label: {
    color: '#7f7f7f'
  }
}));

export default (props) => {
  const classes = useStyles();

  const {
    dictionaries = [],
    onChange
  } = props;

  const [id, setId] = React.useState('');

  const handleChange = event => {
    const id = event.target.value;
    setId(id);
    onChange && onChange(id);
  };

  const value = id || '';

  return (
    <div style={{marginBottom: '14px'}}>
      <FormControl variant="filled" value={value} className={classes.formControl}>
        <InputLabel className={classes.label}>{
          id ? 'Selected dictionary' : 'Select a dictionary (optional)'
        }</InputLabel>
        <Select
          value={value}
          onChange={handleChange}
        >
          <MenuItem value=''><em>None</em></MenuItem>
          {dictionaries.map(dictionary => <MenuItem key={dictionary.id} value={dictionary.id}>{dictionary.name}</MenuItem>)}
        </Select>
      </FormControl>
    </div>
  );
};

