import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {deleteCollection} from 'features/collections';

export default () => {
  const dispatch = useDispatch();
  const [ids, setSelectedIds] = useState({});
  const [count, setSelectCount] = useState(0);
  const [deleting, setDeleting] = useState(0);
  const [selectedCollectionTypes, setSelectedCollectionTypes] = useState(new Map());

  const selectCollection = (id, type) => {
    if (ids[id]) delete ids[id];
    else ids[id] = true;
    setSelectedIds(ids);

    // Type
    if (type) {
      if (selectedCollectionTypes.has(id)) {
        selectedCollectionTypes.delete(id);
      }
      else {
        setSelectedCollectionTypes(new Map(selectedCollectionTypes).set(id, type));
      }
    }

    setSelectCount(Object.keys(ids).length);
  };

  const clearAll = () => {
    setSelectedIds({});
    setSelectedCollectionTypes(new Map());
    setSelectCount(0);
  };

  const selectAll = (ids) => {
    setSelectedIds(ids.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {})
    );
    setSelectedCollectionTypes(new Map());
    setSelectCount(ids.length);
  };

  const deleteSelected = async () => {
    console.log(ids);
    setDeleting(true);
    const calls = Object.keys(ids).map(id => dispatch(deleteCollection({id})));
    await Promise.all(calls);
    setDeleting(false);
    clearAll();
  };

  return {selectCollection,selectedCollectionTypes, deleting, deleteSelected, clearAll, selectAll, count, ids};
};
