import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';
import Loading from 'components/common/Loading';
import {hasAAK} from 'utils/env';
import {isSafeEmailForEnv} from 'utils/Firebase';

// Is General User?
export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => '/',
  allowRedirectBack: true,
  authenticatedSelector: (props) => {
    const {firebase: {auth}} = props;
    const isAuthenticated = auth && auth.isLoaded && !auth.isEmpty && isSafeEmailForEnv(auth.email);
    // console.log({isAuthenticated})
    return isAuthenticated;
  },
  authenticatingSelector: (state, props) => {
    const {auth, profile} = state.firebase;
    return auth === undefined || !auth.isLoaded || !profile.isLoaded;
  },
  AuthenticatingComponent: Loading,
  wrapperDisplayName: 'userIsAuthenticated'
});

const isCorrector = ({auth, profile}) => auth && auth.isLoaded && !auth.isEmpty && auth.email && (profile.isCorrector || profile.organization === '-konchtranscribers');

// Is a Konch Corrector?
export const UserIsCorrector = connectedRouterRedirect({
  redirectPath: (state, ownProps) => '/404',
  allowRedirectBack: false,
  authenticatedSelector: (props) => {
    const {firebase: {auth, profile}} = props;
    const isAuthenticated = isCorrector({auth, profile});
    return isAuthenticated;
  },
  authenticatingSelector: ({firebase: {auth, profile}}) =>
    auth === undefined || !auth.isLoaded || !profile.isLoaded,
  AuthenticatingComponent: Loading,
  wrapperDisplayName: 'UserIsCorrector'
});

// Is a Konch Corrector Admin?
// only those with key, konch employee and isCorrector in DB
export const UserIsCorrectorAdmin = connectedRouterRedirect({
  redirectPath: (state, ownProps) => '/404',
  allowRedirectBack: false,
  authenticatedSelector: (props) => {
    const {firebase: {auth, profile}} = props;
    const isAuthenticated = isCorrector({auth, profile});
    const isOkayEmailDomain = !!(profile?.email || '').split('@').slice(1).join('').match(/^(konch\.ai|ops\.getwingapp\.com)$/i);
    return isAuthenticated && hasAAK && isOkayEmailDomain;
  },
  authenticatingSelector: ({firebase: {auth, profile}}) =>
    auth === undefined || !auth.isLoaded || !profile.isLoaded,
  AuthenticatingComponent: Loading,
  wrapperDisplayName: 'UserIsCorrectorAdmin'
});

export default UserIsAuthenticated;
