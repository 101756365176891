// import axios from 'axios'
import {
  // createAsyncThunk,
  createEntityAdapter,
  createSelector,
  // current,
  createSlice
} from '@reduxjs/toolkit';
import _omit from 'lodash/omit';

// import {toast} from 'components/common/Toaster'

// Slice
export const contentAdapter = createEntityAdapter();
const initialState = contentAdapter.getInitialState({
  pendingRequests: []
});

// Selectors
const sliceSelector = state => state.content;
const {selectById} = contentAdapter.getSelectors(sliceSelector);

export const selectContent = createSelector(
  [selectById],
  content => _omit(content, 'id')
);

// Actions

export const slice = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers: builder => {

  }
});

const reducer = slice.reducer;
export default reducer;
