import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  current
} from '@reduxjs/toolkit';
import {fetchVersions as fetchVersionsApi} from 'api/versions';

// Actions
export const fetchVersions = createAsyncThunk(
  'versions/fetchAll',
  async ({collectionId, locale, versionIdMarker}) => {
    try {
      const {data} = await fetchVersionsApi(collectionId, locale, versionIdMarker);
      return {id: collectionId, versions: data};
    } catch (error) {
      console.log(error);
    }
  });

// Slice
export const versionsAdapter = createEntityAdapter();
const initialState = versionsAdapter.getInitialState({
  loading: [],
});

export const slice = createSlice({
  name: 'versions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    const addLoadingId = (state, {meta}) => {
      const {arg: {collectionId}} = meta;
      state.loading.push(collectionId);
    };

    const removeLoadingId = (state, {meta}) => {
      const {arg: {collectionId}} = meta;
      state.loading = state.loading.filter(_id => _id !== collectionId);
    };

    builder.addCase(fetchVersions.pending, addLoadingId);
    builder.addCase(fetchVersions.rejected, removeLoadingId);
    builder.addCase(fetchVersions.fulfilled, (state, {meta, payload}) => {
      const {collectionId, versionIdMarker} = meta?.arg || {};
      const entity = (current(state)?.entities || {})[collectionId];
      if (payload) {
        console.log({payload, versionIdMarker, entity});
        if (versionIdMarker && entity) {
          // this is a new page of versions so append it to existing
          const versions = entity.versions.concat(payload.versions);
          console.log({versions});
          versionsAdapter.upsertOne(state, {id: collectionId, versions});
        } else {
          // else just replace the current versions
          versionsAdapter.upsertOne(state, payload);
        }
      }
      removeLoadingId(state, {meta});

    });
  }
});

const reducer = slice.reducer;
export default reducer;

// Selectors
const sliceSelector = state => state.versions;
const {selectById} = versionsAdapter.getSelectors(sliceSelector);

export const selectVersionsByCollectionId = createSelector(
  [selectById],
  ({versions = []} = {}) => versions
);

export const selectAreVersionsLoading = createSelector(
  [sliceSelector, (_, id) => id],
  ({loading}, id) => loading.includes(id)
);
