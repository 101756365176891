import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(55, 64, 74, 0.9);
  color: white;
`;
const Cont = styled.div`
  height: 100%;
  width: 100%;
`;

const Dialog = ({ children, className, onClose }) => (
  <Root className={className} onClick={onClose}>
    <Cont onClick={(evt) => evt.stopPropagation()}>{children}</Cont>
  </Root>
);

export default Dialog;
