import {createSelector} from '@reduxjs/toolkit';

const fireStoreSelector = ({firestore}) => firestore.data;
const fireStoreSelectorOrdered = ({firestore}) => firestore.ordered;
const firebaseSelector = ({firebase}) => firebase;

export const selectOrganizations = createSelector(
  [fireStoreSelector],
  ({Organizations, OwnerOrganizations}) => {
    const allOrganizations = Object.assign({}, Organizations, OwnerOrganizations);
    const entries = Object.entries(allOrganizations);
    return entries.map(([uid, org]) => ({uid, id: uid, ...org})).sort((a, b) => {
      if (b.creationTime === a.creationTime) {
        return (b.id < a.id ? -1 : 1);
      }
      return b.creationTime - a.creationTime;
    });
  }
);

export const selectOrganization = createSelector(
  [fireStoreSelector, (_, oid) => oid],
  ({Organizations, OwnerOrganizations}, oid) => {
    // console.log({Organizations, oid})
    if (Organizations && oid && Organizations[oid]) return {...Organizations[oid], uid: oid, id: oid, isLoaded: true} || {};
    if (OwnerOrganizations && oid && OwnerOrganizations[oid]) return {...OwnerOrganizations[oid], uid: oid, id: oid, isLoaded: true} || {};
    return {};
  }
);

export const selectInvitedOrganizations = createSelector(
  [fireStoreSelectorOrdered, (_, email) => email],
  ({invitedTo = []}, email) => invitedTo || []
);

export const selectProfile = createSelector(
  [firebaseSelector],
  ({profile, auth}) => ({...profile, uid: auth.uid})
);

export const selectSettings = createSelector(
  [selectProfile],
  ({settings}) => settings
);

export const selectProjects = createSelector(
  [selectProfile, (_, oid) => oid],
  ({projects = {}}, oid) => projects[oid] || {}
);

export const selectProjectList = createSelector(
  [selectProfile, (_, email) => email],
  ({projects = {}}, oid) => Object.entries(projects[oid] || {}).map(([id, value]) => ({id,...value}))
);

export const getEmailKey = (email) => (email.toString() || '').toLowerCase().replace(/\./g, '__dot__');
