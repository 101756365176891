import React, { useState, useEffect, Fragment } from 'react';
import FadeIn from 'react-fade-in';
import { Link as LinkTo } from 'react-router-dom';
import styled from 'styled-components';
import SSOIcon from '@material-ui/icons/Security';
import { useForm } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { PopUpAuth, loginByEmailPass } from 'utils/Firebase';
import GoogleIcon from 'static/images/google-icon.png';
import LogoImg from 'static/images/konch-logo-with-text.svg';
import { toast } from 'components/common/Toaster';
import Spinner from 'components/common/Spinner';
import KonchButton from 'components/common/Button';
import useProfile from 'hooks/useProfile';

const RegisterButton = styled(KonchButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
`;

const GeneralError = styled.div`
  height: 25px;
  color: #911;
  font-size: 0.8em;
  text-transform: uppercase;
`;

const ShowPasswordIconOn = styled(VisibilityIcon)`
  min-width: 48px;
  color: #999;
`;

const ShowPasswordIconOff = styled(VisibilityOffIcon)`
  min-width: 48px;
  color: #999;
`;

const Root = styled.div`
  height: 100vh;
  background: none;
`;

const Logo = styled.div`
  position: absolute;
  padding: 20px 0 0 20px;
  & img {
    width: 160px;
  }
  @media (max-width: 1000px) {
    & img {
      width: 120px;
    }
  }
`;

const Cont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 550px;
  .left {
    max-width: 740px;
  }
  & button {
    text-transform: initial;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    & .left {
      max-height: 600px;
    }
    & .right {
      justify-content: end;
      max-height: 900px;
    }
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  &.right {
    background: rgb(250, 250, 250);
    display: flex;
    align-items: left;
    flex-direction: column;
  }
`;

const H1 = styled.h1`
  margin-bottom: 35px;
  font-weight: 100;
  color: #444;
  @media (max-width: 700px) {
    font-size: 1.4em;
    padding: 0;
    margin: 19px;
  }
`;

// const PanelContent = styled.div`
//     display: flex;
//     align-items: left;
//     flex-direction: column;
//     padding: 30px 100px 0;
// `

// const H2 = styled.h1`
//   color: #444;
//   font-weight: bolder;
//   margin-bottom: 0;
// `

// const Subtitle = styled.p`
//   margin-bottom: 35px;
//   font-weight: 400;
//   color: #999;
//   line-height: 1.5em;
// `

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  width: 311px;
  text-align: left;
  margin-top: 50px;
  p {
    text-align: center;
  }
  input {
    text-align: left;
    display: block;
    padding: 6px 0 3px 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .MuiButton-containedPrimary {
    background-color: #4fae32;
    margin-top: 10px;
  }
`;

const InputCont = styled.div`
  width: 100%;
  position: relative;
  input {
    padding-right: 50px;
  }
  div {
    position: absolute;
    top: 6px;
    right: 0;
  }
`;

const OrCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 15px;
  width: 100%;
`;

const OrLine = styled.div`
  border-bottom: 1px solid #ccc;
  flex: 1;
`;

const Or = styled.div`
  border-bottom: none;
  flex: 0;
  padding: 0 20px;
  font-size: 0.9em;
  white-space: nowrap;
  color: #888;
`;

// const A = styled.a`
//   color: #2f85bb;
//   :hover {
//     text-decoration: underline;
//   }
// `

const Link = styled(LinkTo)`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  color: #777;
  padding: 10px;
  font-size: 0.9em;
  line-height: 1.5em;
  text-align: center;
`;

const Input = styled.input`
  ${({ error }) => error && 'border-color: #e25d5d;'}
`;

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 8px 0 6px 0;
  width: 100%;
  margin-left: 7px;
  color: #333;
  font-size: 0.9em;
  ${({ error }) => error && 'color: #e25d5d;'}
`;

const ErrorLabel = styled.span`
  min-height: 20px;
  font-size: 0.9em;
  &:empty {
    opacity: 0;
    transition: opacity 1s;
  }
  opacity: 1;
  color: #e25d5d;
  text-align: right;
  transition: opacity 1s;
  margin-right: 9px;
`;

const EnterprisePanel = ({ settings }) => {
  return (
    <Panel className="right" style={settings}>
      {settings.logo ? (
        <img
          src={settings.logo}
          alt="custom organization logo"
          style={settings.logoStyle || { maxHeight: '90%', maxWidth: '80%' }}
        />
      ) : (
        <h1>{settings.name}</h1>
      )}
    </Panel>
  );
};

// const DefaultPanel = () => (
//   <Panel className='right' role="contentinfo">
//     <PanelContent>
//       <H1>Welcome back!</H1>
//       <H2>A.I. toolings to help you succeed</H2>
//       <Subtitle>Konch is always improving to bring you the best-in-class user experience.</Subtitle>
//     </PanelContent>
//     <div style={{width: '100%', minHeight: '350px', padding: '0 25px', maxWidth: 692}}>
//       <img
//         src='https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5ff9fbe9b8c48cce41ef8f03_learning-hero.svg'
//         alt='classroom setting'
//         style={{maxHeight: 450}} />
//     </div>
//   </Panel>
// )

// <ErrorLabel direction='down'>{issues.all?.message}</ErrorLabel>
// watch()
export default (props) => {
  const { history, settings = {} } = props;
  const { profile } = useProfile();
  const [processing, setProcessing] = useState(false);
  const [processingSSO, setProcessingSSO] = useState(false);
  const [generalError, setGeneralError] = useState('');

  const { register, handleSubmit, errors, setError } = useForm({
    mode: 'onBlur',
    shouldFocusError: true
  });
  const [showPassword, setShowpassword] = useState(false);

  const onSubmit = async ({ email, password }) => {
    if (processing) return;
    setProcessing(true);
    const { error, message } = await loginByEmailPass({ email, password });
    if (!error) {
      history.push('/dashboard');
    } else {
      if (message.indexOf('The email address is already in use') >= 0) {
        setError('email', { type: 'manual', message: 'Email already taken.' });
        setGeneralError('Did you mean to login? Click here.');
      } else {
        console.log(error, message);
        toast.error(message, { autoClose: 5000 });
        setGeneralError(
          <span>
            No account, yet? <Link to="/register">Register here.</Link>
          </span>
        );
      }
    }
    setProcessing(false);
  };

  const onSSOAuth = async (provider) => {
    console.log('setProcessingSSO');
    setProcessingSSO(true);
    try {
      const popupResult = await PopUpAuth(provider, settings.organization);
      console.log({ popupResult });
      history.push('/dashboard');
    } catch (error) {
      console.log(error);
      toast.error(error.message, { autoClose: 5000 });
    }
    setProcessingSSO(false);
  };

  useEffect(() => {
    document.title = 'Login to Konch';
    return () => {
      document.title = 'Konch';
    };
  }, []);

  useEffect(() => {
    const skipAutoLogin = localStorage.getItem('konch-skip-auto-login');
    if (profile.isLoaded && !profile.isEmpty) {
      !skipAutoLogin &&
        history.push(`/${(profile.settings || {}).homePage || 'dashboard'}`);
    }
  }, [profile.isLoaded]);

  return (
    <Root>
      {!settings.active && (
        <Logo>
          <img src={LogoImg} alt="logo" />
        </Logo>
      )}
      <Cont>
        <Panel className="left" role="main">
          <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <H1>
              <b>Login</b> to your account
            </H1>
            {/* if not enterprise, or is enterprise and allows google*/}
            <div style={{ display: 'flex', width: '100%' }}>
              {(!settings.active || settings.allowGoogle) && (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  style={{
                    backgroundColor: '#FFF',
                    color: '#333',
                    border: '1px solid #ccc'
                  }}
                  startIcon={
                    processingSSO ? (
                      <Spinner small />
                    ) : (
                      <img
                        src={GoogleIcon}
                        width="20px"
                        height="20px"
                        alt="google icon"
                      />
                    )
                  }
                  onClick={() => onSSOAuth('google')}
                >
                  Login using Google
                </Button>
              )}

              {settings.provider && (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  style={{
                    backgroundColor: '#FFF',
                    color: '#333',
                    border: '1px solid #ccc'
                  }}
                  startIcon={
                    processingSSO ? (
                      <Spinner small />
                    ) : (
                      <SSOIcon
                        style={{ width: '20px', height: '20px' }}
                        alt="sso icon"
                      />
                    )
                  }
                  onClick={() => onSSOAuth(settings.provider)}
                >
                  Login using SSO
                </Button>
              )}
            </div>

            {(!settings.active || settings.allowEmail) && (
              <>
                {(!settings.active ||
                  settings.provider ||
                  settings.allowGoogle) && (
                  <OrCont>
                    <OrLine />
                    <Or>or sign in via email</Or>
                    <OrLine />
                  </OrCont>
                )}
                <GeneralError>
                  {generalError && <FadeIn>{generalError}</FadeIn>}
                </GeneralError>
                <Label htmlFor="email" error={errors.email}>
                  Email{' '}
                  <ErrorLabel>
                    {errors.email && ` ${errors.email.message}`}
                  </ErrorLabel>
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  error={errors.email}
                  className={errors.email ? 'small-shake' : ''}
                  autoComplete="off"
                  ref={register({
                    required: 'Please enter a valid email',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  })}
                />

                <Label htmlFor="password" error={errors.password}>
                  Password{' '}
                  <ErrorLabel>
                    {errors.password && ` ${errors.password.message}`}
                  </ErrorLabel>
                </Label>
                <InputCont>
                  <Input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    error={errors.password}
                    className={errors.password ? 'small-shake' : ''}
                    autoComplete="off"
                    ref={register({
                      required: 'Please enter a password.',
                      minLength: {
                        value: 6,
                        message: 'Must be at least 6 characters'
                      }
                    })}
                  />
                  <div>
                    <span
                      onClick={() => setShowpassword(!showPassword)}
                      tabIndex={0}
                      role="button"
                      styling="text"
                      aria-labelledby="Toggle Show Password"
                      aria-label="Toggle Show Password"
                      title="Toggle Show Password"
                      onKeyDown={(evt) =>
                        evt.keyCode === 13 && setShowpassword(!showPassword)
                      }
                      style={{ outline: 'none' }}
                    >
                      {showPassword ? (
                        <ShowPasswordIconOn />
                      ) : (
                        <ShowPasswordIconOff />
                      )}
                    </span>
                  </div>
                </InputCont>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: settings.buttonColor || '#21b573',
                    fontSize: '1em',
                    height: '48px'
                  }}
                  disableElevation
                  onClick={(evt) => {
                    console.log(evt);
                  }}
                >
                  {processing ? <Spinner small color="#FFF" /> : 'Login'}
                </Button>
                <Footer>
                  <p>
                    Don't have an account, yet?{' '}
                    <Link to="/register">Create one here</Link>.
                  </p>
                  <p>
                    Forgot your password?{' '}
                    <Link to="/reset/request">Reset it here</Link>.
                  </p>
                </Footer>
              </>
            )}
          </Form>
        </Panel>
        {settings.active ? <EnterprisePanel {...props} /> : ''}
      </Cont>
      {!settings.active && (
        <RegisterButton
          raised
          primary
          onClick={() => history.push('/register')}
        >
          Register for Free
        </RegisterButton>
      )}
    </Root>
  );
};
