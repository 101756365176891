import React, { useState } from 'react';
import styled from 'styled-components';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import Spinner from 'components/common/Spinner';
import DetailsForm from 'components/common/CreatorDialog/DetailsFormV2';

const Split = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UrlStatusIndicator = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DetailsFormCont = styled.div`
  width: 440px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkUploader = (props) => {
  const [isValidUrl, setIsValidUrl] = useState({
    loadingStatus: false,
    urlValidity: false
  });

  const setUrlValidityStatus = (urlStatus) => {
    setIsValidUrl(urlStatus);
  };

  return (
    <Split>
      <DetailsFormCont>
        <UrlStatusIndicator>
          {isValidUrl.loadingStatus ? (
            <>
              <h4>Checking URL</h4>
              <Spinner color="rgb(0, 128, 0)" />
            </>
          ) : (
            isValidUrl.urlValidity && (
              <CheckCircleOutline style={{ color: 'green', fontSize: '100' }} />
            )
          )}
        </UrlStatusIndicator>
        <DetailsForm isLinkUpload setUrlValidityStatus={setUrlValidityStatus} />
      </DetailsFormCont>
    </Split>
  );
};

export default LinkUploader;
