import React, {useEffect, useState} from 'react';
import {useInterval} from 'react-interval-hook';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Hidden from '@material-ui/core/Hidden';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import useCollection from 'hooks/useCollection';
import useProfile from 'hooks/useProfile';
import {columns} from './CollectionsTableView';
import EditorContexts from 'context/EditorContexts';
import CollectionsContext from 'context/CollectionsContext';
import TranscriptionProgress from 'components/common/CreatorDialog/TranscriptionProgress';
import Spinner from 'components/common/Spinner';
import {Name, Snippet, Status} from './CollectionCard';
import CollectionMenu from '../Popover/CollectionMenu';

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  ${({loading}) => loading ? 'animation: Pulsate 1s linear infinite;' : ''}
  ${({selected}) => selected ? 'background-color: #F0F0FF !important;' : ''}
  ${({deleting, selected}) => selected && deleting ? `
  opacity: 0.5;
  cursor: initial;
  ` : ''}

`;
const PaddedCell = styled(TableCell)`
padding: 16px 30px !important;
`;
// const Text = styled.p`
//   margin: 0;
//   color: #37404A;
//   font-size: 16px;
//   line-height: 24px;
//   font-weight: 500;
//   letter-spacing: 0.3px;
//   outline: none;
//   overflow: hidden;
//   max-height: 96px;
// `

export const CollectionsTableRow = ({id}) => {
  const history = useHistory();

  const {deleteCollection, removeCollectionFromState, fetchCollection, makeOwner, message, collection, isProcessing, shouldBeDoneButIsNot, expectedTimeLeft} = useCollection({id});
  const {uid} = useProfile();
  const {selectCollection, ids, deleting} = React.useContext(CollectionsContext);
  const {snippet, paused, isError, tProgress, props = {}, transcriptionStarted, started, tCompletion = {}} = collection;
  const [name, setName] = useState(collection.name ? collection.name : '-');
  const [nameIsEditable, setNameIsEditable] = useState(false);
  const [, setChangingOwner] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const isTextDoc = collection.type === 'text';
  const href = `/${isTextDoc ? 'doc' : 'transcript'}/${collection.id}/`;

  const {duration} = props;
  const waitFor = () => {
    if (!duration) return 5000;
    else if (expectedTimeLeft > 0) return 1000;
    else return 10000;
  };
  const handleEditName = () => setNameIsEditable(true);

  const handleDelete = evt => {
    setIsDeleting(true);
    deleteCollection();
  };

  const handleMoveWorkspace = () => {
    removeCollectionFromState();
  };

  const handleMakeOwner = async () => {
    setChangingOwner(true);
    await makeOwner(uid);
    setChangingOwner(false);
  };

  const {start, stop, isActive} = useInterval(
    () => {
      fetchCollection();
    },
    waitFor(),
    {
      autoStart: false,
      immediate: false,
      selfCorrecting: true,
      onFinish: () => {
        if (!snippet) fetchCollection();
      }
    }
  );
  const handleOnRetryComplete = () => {
    !isActive() && start();
  };
  const handleNameBlur = title => {
    setNameIsEditable(false);
    setName(title);
  };

  const handleCardClick = evt => {
    isError && evt.preventDefault();
    if (deleting && ids[collection.id]) return;
    if (evt.shiftKey) {
      document.getSelection().removeAllRanges();
      evt.preventDefault();
      if (evt.metaKey) {
        console.log(id);
      } else {
        selectCollection(id);
      }
    } else {
      history.push(href);
    }
  };

  useEffect(() => {
    isProcessing && start();
    tProgress !== undefined && tProgress === 100 && isActive() && stop();
  }, [tProgress]);

  const renderRow = (column) => {

    switch (column.id) {
    case 'started':
      return (
        <Hidden smDown key={column.id}>
          <TableCell onClick={handleCardClick} key={column.id} align={column.align}>
            {started && new Date(started * 1000).toLocaleDateString()}
          </TableCell>
        </Hidden>
      );
    case 'body':
      if (tProgress >= 100 || snippet || collection?.languageCode === '') return (
        <Hidden smDown key={column.id}>
          <TableCell onClick={handleCardClick} key={column.id} align={column.align}>
            <Snippet cid={id} style={{width: '99%'}} snippet={snippet} message={message} languageCode={collection?.languageCode} />
          </TableCell>
        </Hidden>
      );
      else if (duration && transcriptionStarted && ((!tCompletion.draft && !message) || tProgress < 100)) {
        return (
          <Hidden smDown key={column.id}>
            <TableCell onClick={handleCardClick} key={column.id} align={column.align}>
              <TranscriptionProgress bar started={transcriptionStarted} duration={duration} disabled={paused} />
            </TableCell>
          </Hidden>
        );
      } else return <TableCell />;
    case 'title':
      return (
        <TableCell onClick={handleCardClick} key={column.id} align={column.align}>
          <Name name={name} id={collection.id} nameIsEditable={nameIsEditable} onBlur={handleNameBlur} isError={isError} />
        </TableCell>
      );
    case 'status':
      return (
        <PaddedCell key={column.id} align={column.align} >
          <Status isError={isError} shouldBeDoneButIsNot={shouldBeDoneButIsNot} collection={collection} paused={paused} dontShowProgress isTableRow />
        </PaddedCell>
      );
    case 'actions':
      return (
        <TableCell width={35} key={column.id} align={column.align} style={{padding: 0}}>
          {
            deleting && ids[collection.id] ?
              <Spinner small style={{marginLeft: 14}} /> :
              <CollectionMenu
                {...collection}
                shouldBeDoneButIsNot={shouldBeDoneButIsNot}
                onEditName={handleEditName}
                onRetryComplete={handleOnRetryComplete}
                onChangeOwner={handleMakeOwner}
                onDelete={handleDelete}
                onMoveWorkspace={handleMoveWorkspace}
                isProcessing={isProcessing}
                isError={isError} >
                <MoreVertIcon style={{color: '#CCC'}} />
              </CollectionMenu>
          }
        </TableCell>
      );
    default:
      return (
        <TableCell key={column.id} align={column.align}>
          {column.id}
        </TableCell>
      );
    }
  };

  return (
    <EditorContexts collectionId={collection.id} isCorrector={false}>
      <StyledTableRow
        loading={isDeleting ? 1 : 0}
        selected={!!ids[collection.id]}
        deleting={deleting}
        hover role="checkbox"
        tabIndex={-1} key={id}
      >
        {columns.map(renderRow)}
      </StyledTableRow>
    </EditorContexts>
  );
};
