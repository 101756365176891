import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import Logo from 'static/images/logo.png';

const Root = styled.div`
  width: 100vw;
  height: 92px !important;
  background-color: #DEDEDE;
  display: flex;
  flex-direction: row  !important;
  border-bottom: 1px solid #CCC;
  z-index: 999;
`;

const AppIcon = styled.div`
  width: 70px;
  height: 70px;
  background-color: #FFF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
  }
`;

const Close = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    opacity: .3;
  }
`;

const Left = styled.div`
  padding: 10px;
`;

const Middle = styled.div`
  padding: 19px 10px;
  color: #333;
  flex: 1;
`;

const Right = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let expired;
    const hidStamp = window.localStorage.getItem('hide-app-download');
    if (hidStamp) {
      const hideSince = new Date().getTime() - hidStamp;
      const hidSinceHours = (((hideSince / 1000) / 60) / 60);
      console.log({hidSinceHours});
      expired = hidSinceHours > 12;
    }

    if (/iPhone/i.test(navigator.userAgent) && expired) {
      setShow(true);
    }
    return () => {};
  },[]);

  return (
    show &&
    <Root>
      <Close onClick={() => {
        setShow(false);
        window.localStorage.setItem('hide-app-download', new Date().getTime());
      }}>
        <CancelIcon />
      </Close>
      <Left>
        <AppIcon>
          <img src={Logo} alt='logo'/>
        </AppIcon>
      </Left>
      <Middle>
        <div style={{fontSize: 17, marginBottom: 2}}>Konch</div>
        <div style={{fontSize: 14, marginBottom: 2}}>konch.ai</div>
        <div style={{fontSize: 14, marginBottom: 2}}>Free - App Store</div>
      </Middle>
      <Right>
        <Button rel="noopener noreferrer"color='primary' style={{fontSize: 16, textTransform: 'uppercase'}} href='https://apps.apple.com/us/app/konch/id1463532655' target="_blank">View</Button>
      </Right>
    </Root>
  );
};
