import React, {Fragment, useState, useMemo} from 'react';
import styled from 'styled-components';
import Avatar from 'components/common/Avatar';

const Name = styled.div`
  margin-left: 10px;
`;

const Or = styled.p`
  color: #CCC;
`;

const AccountOption = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.6;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  padding: 12px 10px;
  margin: 5px;
  box-shadow: 0 0 0 rgba(0,0,0, 0);
  &:hover{
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
    box-shadow: 0 0 7px rgba(20,20,20,.1);
  }
  &:hover div {
    opacity: 1;
  }
  transition: background-color .5s, opacity .5s, box-shadow .3s;
`;

const Options = styled.div`
  overflow: scroll;
  max-height: 80px;
  width: 100%;
  padding: 0 20px;
  ${props => props.long && 'border-bottom: 1px solid #EFEFEF;'}
`;

const ProfileAvatar = styled(Avatar)`
  margin-right: 5px;
`;

const OtherAccount = styled.div`
  display: flex;
  align-items: center;
  color: #333;
  border-radius: 3px;
`;

const Action = styled.div`
  flex: 0;
  white-space: nowrap;
  color: #BBB;
  opacity: 0;
  font-size: .8em;
  padding-right: 10px;
  &:hover {
    color: #333;
  }
  transition: opacity .5s, color .5s;
`;

export default ({organizations = [], onSwitchAccount}) => {
  const [orgs, setOrgs] = useState([]);
  const hasOrgs = orgs.length > 1;

  useMemo(() => {
    setOrgs(organizations);
  }, [organizations]);

  return (
    hasOrgs &&
    <Fragment>
      {hasOrgs && <Or>- or select another account-</Or>}
      <Options long={orgs.length > 4}>
        {
          orgs.map((organization) => {
            const {uid, name} = organization;
            return (
              <AccountOption key={uid} onClick={() => onSwitchAccount && onSwitchAccount(uid)}>
                <OtherAccount key={uid}>
                  <ProfileAvatar size={20} profile={organization} />
                  <Name>{`${name}`}</Name>
                </OtherAccount>
                <Action>Select Account</Action>
              </AccountOption>
            );
          })}
      </Options>
    </Fragment>
  );
};
