import React, {useState, useEffect} from 'react';
import {useInterval} from 'react-interval-hook';
import styled from 'styled-components';
// import LinearProgress from 'components/common/LinearProgress'
import LinearProgress from '@material-ui/core/LinearProgress';
import {getHumanTime} from 'components/common/Timer/convert-time';

const Wrap = styled.div`
  width: 100%;
  min-width: 130px;
  min-height: 5px;
  background-color: #EEE;
  .MuiLinearProgress-root  {
    width: 100%;  
    min-width: 130px;
    min-height: 5px;
  }
  

  ${props => props.disabled && `
    & .MuiLinearProgress-barColorPrimary, 
    &.MuiLinearProgress-colorPrimary {
      background-color: #CCC;
    }
  `}
`;

export default ({bar, duration, prepend, disabled, started, style, id}) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [completed, setCompleted] = useState(2);

  const progress = () => {
    // console.log('check progress')
    const timePadding = 30; // little extra JIC
    const now = (new Date().getTime()) / 1000;
    // console.log({now: new Date(now * 100), started: new Date(started * 100)})
    const secondsElapsed = now - started;
    const processingEstimate = parseInt((duration) + timePadding, 10); // takes ~.5x(duration) to trasncribe
    const timeLeft = parseInt(processingEstimate - secondsElapsed, 10);
    const newCompleted = parseInt((secondsElapsed / processingEstimate) * 100, 10);
    // console.log('...update', {newCompleted, timeLeft, completed, processingEstimate, secondsElapsed})
    setCompleted(Math.min(newCompleted,100));
    setTimeLeft(Math.max(timeLeft, 0));
  };

  const {start, stop, isActive} = useInterval(progress, 5000);

  useEffect(() => {
    completed < 100 ? start() : isActive() && stop();
  }, [completed]);

  useEffect(() => {
    progress();
  }, []);

  const humanTimeLeft = getHumanTime(timeLeft);

  id === 'c4dc5c40-5077-4159-9ddd-dd8d83550096' && console.log({duration, completed, disabled, timeLeft, humanTimeLeft, started});

  return (
    <Wrap>
      {
        completed < 100 ?
          <LinearProgress variant='determinate' value={completed || 2} disabled={disabled} /> :
          <LinearProgress variant='indeterminate' />
      }
    </Wrap>

  );
};
