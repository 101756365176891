import React from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import TableContainer from '@material-ui/core/TableContainer';
// import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import {CollectionsTableRow} from './CollectionsTableRow';
import {makeStyles} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

TimeAgo.addLocale(en);

const useStyles = makeStyles({
  table: {
    margin: 0,
    padding: 0,
    width: '100%',
    border: 0,
    backgroundColor: '#FFF',
    '& td': {
      borderColor: '#EEE'
    },
    '& .MuiTableRow-root.MuiTableRow-hover': {
      transition: 'background-color .5s',
      '&:hover': {
        backgroundColor: '#F8F8F8',
        transition: 'background-color 1s'
      }
    }
  },
  paper: {
    minHeight: 50,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: '0 20px',

  },
  container: {
    borderRadius: 8
  }
});

export const columns = [
  {id: 'actions', align: 'right'},
  {id: 'title', label: 'Name', minWidth: 170},
  {id: 'body', label: 'Snippet', minWidth: 200},
  {id: 'started', label: 'Started'},
  {id: 'status', align: 'right', minWidth: 50},
];

const CollectionsTableView = (props) => {
  const {collections, usage, loading} = props;
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                // skip body and started if small screen
                if (column.id === 'body' || column.id === 'started')
                  return (
                    <Hidden key={column.id} smDown>
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{minWidth: column.minWidth}}
                      >
                        {column.label}
                      </TableCell>
                    </Hidden>
                  );

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{minWidth: column.minWidth}}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {collections.map(collection => <CollectionsTableRow usage={usage} key={collection.id} id={collection.id} />) }
            {
              loading ? (
                <TableRow>
                  <TableCell style={{width: 20}}/>
                  <TableCell style={{width: 100}}><Skeleton variant="text" /></TableCell>
                  <Hidden smDown>
                    <TableCell><Skeleton variant="text" /></TableCell>
                    <TableCell style={{width: 100}}><Skeleton variant="text" width={100} /></TableCell>
                  </Hidden>
                  <TableCell style={{width: 20}} />
                </TableRow>
              ) : !loading && collections.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} style={{textAlign: 'center', color: '#CCC'}}>NO CONTENT</TableCell>
                </TableRow>
              )
            }
          </TableBody>

        </Table>
      </TableContainer>

    </Paper>

  );
};

export default CollectionsTableView;
