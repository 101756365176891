import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Chip from '@material-ui/core/Chip';
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';

import BlogIcon from '@material-ui/icons/RssFeed';
import HumanIcon from 'components/common/HumanCheckedIcon.js';
import LightTooltip from 'components/common/LightTooltip';
import UpgradeModal from 'components/common/EditorV2/UpgradeModal';
import ConvertBlogModal from 'components/common/EditorV2/ConvertBlogModal';

import Spinner from 'components/common/Spinner';

const StyledChip = styled(Chip)`
  font-weight: 500;
  font-size: 0.6rem !important;

  ${({ label }) =>
    !label &&
    `
    .MuiChip-labelSmall {
      padding: 0 0 0 7px;
    }
  `}

  ${({ collapsed, mw, transform }) =>
    collapsed &&
    `
      ${transform ? `transform: translateX(${transform}) !important;` : ''}
      .MuiChip-labelSmall {
        opacity: 0;
        width: 0px;
        transition: opacity 0s, width 0s 0s;
      }
      &.MuiChip-outlined {
        border-color: transparent !important;
        transition: border-color 0s;
      }
      :hover {
        margin: 0 0 0 5px;
        .MuiChip-labelSmall {
          opacity: 1;
          width: ${mw || '130px'};
          transition: opacity .5s .2s, width .3s;
        }
        &.MuiChip-outlined {
          border-color: #7dbb90 !important;
          transition: border-color .6s;
        }
      }
  `}
`;

const Default = (props = {}) => {
  const {
    onClick,
    description,
    label,
    style,
    className,
    collapsed,
    processing,
    rightIcon,
    leftIcon,
    primaryColor = '#7dbb90',
    bgColor,
    maxWidth,
    transform
  } = props || {};

  return (
    <LightTooltip noWrapper title={description}>
      <StyledChip
        mw={maxWidth}
        className={className}
        collapsed={collapsed ? 1 : 0}
        style={style}
        transform={transform}
        deleteIcon={
          processing ? (
            <Spinner
              color="#7dbb90"
              style={{ height: 15, width: 15, margin: '0 5px 0 -1px' }}
              small
            />
          ) : (
            rightIcon || <></>
          )
        }
        avatar={leftIcon || <></>}
        label={label}
        variant="outlined"
        primarycolor={primaryColor}
        bgcolor={bgColor}
        size="small"
        onClick={(e) => !processing && onClick && onClick(e)}
        onDelete={(e) => !processing && onClick && onClick(e)}
      />
    </LightTooltip>
  );
};

export const CorrectingChip = () => {
  return (
    <Default
      rightIcon={
        <InsertEmoticon style={{ width: 20, height: 20, color: '#FFF' }} />
      }
      onClick={() => {}}
      label="Correcting"
      primaryColor={'#FFF'}
      bgColor={'#7dbb90'}
      description="Our certified team is correcting any errors they find with your transcript. It will be read-only until they are complete."
    />
  );
};

export const CorrectedChip = () => {
  return (
    <Default
      rightIcon={
        <HumanIcon
          style={{ width: 33, marginTop: -4, height: 27, opacity: 0.6 }}
        />
      }
      onClick={() => {}}
      primaryColor={'#FFF'}
      bgColor={'transparent'}
      style={{ border: 0 }}
      description="Our correctors have completed reviewing your transcript."
    />
  );
};

export const PausedChip = () => {
  const history = useHistory();
  return (
    <Default
      style={{ marginRight: 5 }}
      label="Paused"
      description={`
      Your transcript may have paused due to a lack of valid payment card on file,
      lack of credits, or not enough credits to back multiple files uploading at the same time.
    `}
      onClick={() => history.push('/plans')}
    />
  );
};

export const UpgradeChip = ({ processing, id }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [shake, setShake] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const animationRef = React.useRef({});

  React.useEffect(() => {
    clearTimeout(animationRef.current.collapse);
    clearTimeout(animationRef.current.shake);
    animationRef.current.collapse = setTimeout(() => setCollapsed(true), 5000);
    animationRef.current.shake = setTimeout(() => setShake(true), 2000);
    return () => {
      clearTimeout(animationRef.current.collapse);
      clearTimeout(animationRef.current.shake);
    };
  }, []);

  return (
    <>
      <Default
        rightIcon={<InsertEmoticon style={{ width: 20, height: 20 }} />}
        onClick={() => setOpen(true)}
        className={shake ? 'small-shake' : ''}
        processing={processing || 0}
        collapsed={collapsed || 0}
        maxWidth="130px"
        transform="5px"
        label="Improve Accuracy"
        description="Click here to have our certified team improve accuracy, create speakers seeprations, and add non-spoken sound cues."
      />

      {open && <UpgradeModal id={id} onClose={() => setOpen(false)} />}
    </>
  );
};

export const ConvertToBlog = ({ processing, id }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [shake, setShake] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const animationRef = React.useRef({});

  React.useEffect(() => {
    clearTimeout(animationRef.current.collapse);
    clearTimeout(animationRef.current.shake);
    animationRef.current.collapse = setTimeout(() => setCollapsed(true), 5000);
    animationRef.current.shake = setTimeout(() => setShake(true), 2000);
    return () => {
      clearTimeout(animationRef.current.collapse);
      clearTimeout(animationRef.current.shake);
    };
  }, []);

  return (
    <>
      <Default
        rightIcon={
          <BlogIcon style={{ width: 20, height: 20, marginBottom: 2 }} />
        }
        onClick={() => setOpen(true)}
        className={shake ? 'small-shake' : ''}
        transform="22px"
        processing={processing || 0}
        collapsed={collapsed || 0}
        maxWidth="115px"
        label="Convert to Blog"
        description="Convert your transcript into a A.I. generated blog post. The results will amaze you."
      />

      {open && <ConvertBlogModal id={id} onClose={() => setOpen(false)} />}
    </>
  );
};

export const PartialChip = (props) => (
  <Default
    label="Trimmed"
    {...props}
    description={`Partially complete using remaining promotion minutes. Please, update payment method and re-upload to get entire transcript.`}
  />
);

export default Default;
