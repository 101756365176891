import client from 'api/client';

export const transcribe = ({
  url,
  enhanced,
  uid,
  locale,
  languageCode,
  oid: orgId,
  hints,
  name,
  description,
  beta,
  isPrecision,
  dictionary,
  ignoreSentance,
  convertToBlog,
  project
}) =>
  client().post(`/pipeline/user/${uid}/collections/`, {
    url,
    enhanced,
    locale,
    orgId,
    languageCode,
    hints,
    description,
    beta,
    name,
    isPrecision,
    dictionary,
    ignoreSentance,
    convertToBlog,
    project
  });

export const check = ({url}) => client().post('/pipeline/check', {url});

export const createTextCollection = ({uid, cid, locale, oid: orgId, name, project}) =>
  client().post(`/pipeline/user/${uid}/collections/`, {type: 'text', cid, locale, orgId, name, project});
