import styled from 'styled-components';

const Spinner = styled.div`
  border-radius: 50%;
  box-sizing: border-box;

  ${(props) => props.inline && 'display: inline-flex;vertical-align: middle;'}
  ${(props) => props.center && 'margin: auto;'}

  ${(props) =>
    props.small
      ? `
    border: ${props.thickness !== undefined ? props.thickness : 2}px solid ${
      props.secondaryColor ? props.secondaryColor : 'rgba(151,151,151,0.31)'
    };
    border-top: ${
      props.secondThickness !== undefined ? props.secondThickness : 2
    }px solid ${props.color || props.defaultColor};
    width: ${props.size || 18}px;
    height: ${props.size || 18}px;
  `
      : `
    border: 5px solid ${props.borderColor || props.defaultBorderColor};
    border-top: 5px solid ${props.color || props.defaultColor};
    width: 60px;
    height: 60px;
  `}
  ${(props) =>
    props.thickness &&
    `
    border-width: ${props.thickness ? props.thickness : 2}px;
  `}
  ${(props) =>
    props.size &&
    `
    width: ${props.size}px;
    height: ${props.size}px;
  `}
  ${(props) =>
    props.light &&
    `
    border-color: rgba(255,255,255,.5);
    border-top-color: rgba(255,255,255,1);
  `}
  animation: spin 2s linear infinite;
`;

Spinner.defaultProps = {
  defaultColor: '#5173E7',
  defaultBorderColor: 'rgba(151,151,151,0.31)'
};

export default Spinner;
