import {useState, useEffect} from 'react';
import kaltura from 'kaltura-client';

const config = new kaltura.Configuration();
config.serviceUrl = 'https://www.kaltura.com';
const client = new kaltura.Client(config);

export default () => {
  const [kalturaAppKey, setKalturaAppKey] = useState();
  const [media, setMedia] = useState();

  const type = kaltura.enums.SessionType.USER;
  const expiry = 86400;
  const privileges = '';

  const connect = async ({secret, userId, partnerId}) => {
    try {
      const ks = await kaltura.services.session.start(secret, userId, type, partnerId, expiry, privileges).execute(client);
      localStorage.setItem('__sakk', ks);
      setKalturaAppKey(ks);
      return {ks};
    } catch (error) {
      console.log({error, message: error.message});
      return {error, message: error.message};
    }
  };

  const list = async () => {
    client.setKs(kalturaAppKey);
    let filter = new kaltura.objects.MediaEntryFilter();
    let pager = new kaltura.objects.FilterPager();
    try {
      const {objects} = await kaltura.services.media.listAction(filter, pager).execute(client);

      // let url = 'https://www.kaltura.com/p/[partnerId]/sp/[partnerId]00/playManifest/entryId/[entryId]/flavorId/301961/format/download/protocol/https/a.mp4'
      // url += '?ks=' + kalturaAppKey + '&referrer=Konch'
      const urlEnd = '/video.mp4?ks=' + kalturaAppKey + '&referrer=Konch';

      const media = objects.map(({id, thumbnailUrl, duration, name, dataUrl, partnerId}) => ({
        // downloadUrl: url.replace('[entryId]', id).replaceAll('[partnerId]', partnerId),
        url: dataUrl + urlEnd,
        duration,
        thumbnailUrl,
        name
      }));

      console.log(media);
      setMedia(media || []);

      return media;
    } catch (error) {
      console.log({error, message: error.message});
      return {error, message: error.message};
    }
  };

  useEffect(() => {
    const ks = localStorage.getItem('__sakk');
    ks && setKalturaAppKey(ks);
  }, []);

  return {
    connect,
    list,
    media,
    kalturaAppKey,
    setKalturaAppKey
  };
};
