import React, { useContext, useEffect, useRef, useState } from 'react';
import qs from 'query-string';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { toast } from 'components/common/Toaster';
import SelectLanguage from '../CreatorDialog/SelectLanguage';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@material-ui/icons/CloudUploadRounded';
import AudioIcon from '@material-ui/icons/Audiotrack';
import VideoIcon from '@material-ui/icons/VideocamRounded';
import DocIcon from '@material-ui/icons/FileCopy';
import CrossIcon from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';
import CustomButton from 'components/common/Button';
import uuid from 'uuid/v4';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FadeIn from 'react-fade-in/lib/FadeIn';
import useUpload from 'hooks/useUpload';
import Spinner from '../Spinner';
import { FeaturesContext } from 'providers/FeaturesProvider';
import { FeatureModalContext } from 'providers/FeatureModalProvider/FeatureModalProvider';
import { Features } from 'utils/constants';

const SubmitButton = styled(CustomButton)`
  ${(props) => props.disabled === true && 'opacity: 0;display: none;'}
  max-width: 100%;
  height: 30px;
  font-size: 0.8rem;
`;

const ContainerHeading = styled.h3`
  font-size: 1rem;
  text-align: center;
  color: var(--colorPrimary);
  margin: 0;
  padding: 2rem 0;
`;

const UploaderTitle = styled.h6`
  font-size: 0.8rem;
  color: var(--colorPrimary);
  margin: 0.8rem 0;
`;

const AcceptedFilesHeading = styled.h6`
  font-size: 0.9rem;
  color: var(--colorPrimary);
  margin-top: 1.2rem;
  margin-bottom: 0.7rem;
`;

const SelectLanguageHeading = styled.h6`
  font-size: 0.9rem;
  color: var(--colorPrimary);
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`;

const UploadIconContainer = styled.div`
  padding: 0.7rem;
  background: #e6edfa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AcceptedFilesContainer = styled.div`
  color: var(--colorPrimary);
  margin-bottom: 2rem;
`;

const SelectLangugeSectionContainer = styled.div``;

const useStyles = makeStyles((theme) => ({
  list: {
    paddingRight: '1rem',
    maxHeight: '1px',
    transition: 'max-height 0.25s ease-in-out',
    overflow: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7ab6f5',
      borderRadius: '1rem'
    }
  },
  listExpanded: {
    transition: 'max-height 0.25s ease-in-out',
    maxHeight: '100px',
    paddingRight: '0'
  },
  contentContainer: {
    borderRadius: '1.6rem',
    padding: '0 3rem 2rem 3rem',
    background: '#fff',
    transition: 'max-height 0.3s ease',

    maxHeight: '95%',
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  },
  contentContainerExpanded: {
    transition: 'max-height 0.3s ease',
    maxHeight: '95%'
  }
}));

const UploaderCont = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-height: 300px) {
    height: 50%;
  }
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: auto;
  font-weight: 500;
  font-size: 0.7rem;
  letter-spacing: 0.3px;
  color: var(--colorPrimary);
  margin-top: 2rem;
  span {
    white-space: nowrap;
  }
`;

const Underlined = styled.span`
  color: var(--colorSecondary);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
    color: #ff7d93;
    border-bottom: 2px solid #ff7d93;
  }
`;

const RecordIcon = styled.span`
  color: #fc600f;
  font-size: 11px;
  margin-right: 6px;
`;

const Uploader = styled.div`
  width: 100%;
  height: 170px;
  border: 2px dashed #7ab6f5;
  display: flex;
  background: #f5f9fc;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background 0.25s ease;
  ${({ reject }) => reject && 'background: rgba(255, 0,0,0.5);'}
  :hover {
    background: #eaf2f8;
  }
`;

const UploaderMessage = styled.div`
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-height: 300px) {
    margin-top: 8px;
    font-size: 17px;
  }

  @media (max-width: 900px) {
    span {
      display: none;
    }
    button {
      min-width: 220px;
    }
  }
`;

const UploadMessage = (props) => {
  console.log({ props });

  const { isDragReject } = props;

  if (isDragReject === true) {
    return (
      <span
        style={{
          color: '#2f2f33'
        }}
      >
        File type not supprted.
      </span>
    );
  }

  const { product } = qs.parse(window.location.search.substr(1));

  return (
    <>
      {product && (
        <h1 style={{ padding: 0, margin: 0 }}>
          Give us your audio/video, we'll make it a blog
        </h1>
      )}
      <UploaderMessage className="drag-and-drop-message-in-box">
        <UploadIconContainer>
          <UploadIcon
            style={{
              color: '#3895f2',
              fontSize: '1.7rem'
            }}
          />
        </UploadIconContainer>
        <UploaderTitle>Drag and drop your file</UploaderTitle>
        <SubmitButton primary raised small>
          Or choose a file
        </SubmitButton>
      </UploaderMessage>
    </>
  );
};

const FileUploader = (props) => {
  const { onUpload, showRecorder, showDropbox, showLink } = props;
  const classes = useStyles();

  const { isFreePlanUser } = useContext(FeaturesContext);
  const { showFeatureModal } = useContext(FeatureModalContext);

  const { defaultLanguage, setDefaultLanguage } = useUpload();

  const [preUploadedFiles, setPreUploadedFiles] = useState([]);
  const [languageCode, setLanguage] = useState(defaultLanguage);
  const [loading, setLoading] = useState();
  const mounted = useRef(true);

  const fileRejected = (file) => {
    toast.error(
      <div>
        <span
          tabIndex={0}
          role="button"
          styling="text"
          onKeyDown={(evt) =>
            evt.keyCode === 13 &&
            toast.dismissAll() &&
            window.Beacon('article', '5c33f84f2c7d3a31944fc438')
          }
          onClick={() => {
            toast.dismissAll();
            window.Beacon('article', '5c33f84f2c7d3a31944fc438');
          }}
        >
          {file} not supported. Read more about supported files{' '}
          <i>
            <u>here</u>
          </i>
          .
        </span>
      </div>,
      { autoClose: 5000 }
    );
  };

  const dropProps = useDropzone({
    // noClick: true,
    accept: 'video/*, audio/*',
    onDropRejected: (fileRejections) => {
      for (let { file } of fileRejections) {
        console.log({ file });
        fileRejected(file.name);
      }
    },
    onDropAccepted: async (files) => {
      // caveats
      const filteredFiles = files.filter((file) => {
        if (file.name.includes('.aif')) {
          fileRejected(file.name);
        } else return file;
      });

      // Append the new files to the existing ones.
      setPreUploadedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }
  });

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept
  } = dropProps;

  const getFileIcon = (type) => {
    const iconSize = {
      width: '0.6em',
      height: '0.6em'
    };
    if (type.startsWith('audio/')) {
      return (
        <AudioIcon
          style={{
            ...iconSize
          }}
        />
      );
    } else if (type.startsWith('video/')) {
      return (
        <VideoIcon
          style={{
            ...iconSize
          }}
        />
      );
    } else {
      return (
        <DocIcon
          style={{
            ...iconSize
          }}
        />
      );
    }
  };

  useEffect(() => {
    return () => {
      setPreUploadedFiles([]);
      mounted.current = false;
    };
  }, []);

  const handleRemovePreUploadedFile = (fileToRemove) => {
    setPreUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const handleLanguage = (lang) => {
    if (languageCode !== lang) {
      setLanguage(lang);
    }
  };

  const checkFileDuration = (file) => {
    const MAX_DURATION = 600; // 10 minutes in seconds

    const fileType = file.type.split('/')[0];

    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file);
      const media = document.createElement(fileType);
      media.src = url;

      media.onloadedmetadata = () => {
        const duration = media.duration;
        URL.revokeObjectURL(url); // Clean up the URL object
        if (duration <= MAX_DURATION) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      media.onerror = () => {
        URL.revokeObjectURL(url);
        reject(new Error('Error loading media file'));
      };
    });
  };

  const validateFiles = async (files) => {
    /**
     * If the user is not on a free plan, don't check the file duration
     * and return true immediately.
     * Only free plan users have the 10-minute limit.
     *  */

    if (!isFreePlanUser) {
      return true;
    }

    for (const file of files) {
      const isValid = await checkFileDuration(file);
      if (!isValid) {
        return false;
      }
    }

    return true;
  };

  const handleUpload = (files, language) => {
    setLoading(true);

    setTimeout(async () => {
      try {
        const areFilesValid = await validateFiles(files);

        if (areFilesValid) {
          await onUpload(files, language);
        } else {
          showFeatureModal({
            feature: Features.FileSizeLimit
          });

          setLoading(false);
        }
      } catch (error) {
        console.error('Error validating files:', error);
        setLoading(false);
      }
    }, 1000);
  };

  const getFileSize = (file) => {
    let fileSize = file.size;

    if (fileSize / 1000000 < 1) {
      return `${Math.round(fileSize / 1000)} KB`;
    } else if (fileSize / 1000000000 < 1) {
      return `${Math.ceil((fileSize / 1000000) * 10) / 10} MB`;
    } else {
      return `${Math.ceil((fileSize / 1000000000) * 10) / 10} GB`;
    }
  };

  const isButtonDisabled =
    preUploadedFiles.length === 0 || !languageCode || loading;

  return (
    <div
      className={clsx(classes.contentContainer, {
        [classes.contentContainerExpanded]: preUploadedFiles.length > 0
      })}
    >
      <UploaderCont>
        <ContainerHeading>Upload your file(s)</ContainerHeading>
        <Uploader {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />

          <UploadMessage
            isDragActive={isDragActive}
            isDragAccept={isDragAccept}
            acceptedFiles={acceptedFiles}
            fileRejections={fileRejections}
          />
        </Uploader>
        <div>
          <FadeIn delay={270}>
            <AcceptedFilesContainer>
              <SelectLangugeSectionContainer
                style={{
                  opacity: preUploadedFiles.length > 0 ? 1 : 0.1,
                  transition: 'opacity 0.25s ease'
                }}
              >
                <SelectLanguageHeading>{`Select the language of the file(s)`}</SelectLanguageHeading>
                <SelectLanguage
                  isLoaded
                  onSelectLanguage={handleLanguage}
                  onSetLanguageDefault={setDefaultLanguage}
                  defaultLanguage={defaultLanguage}
                />
              </SelectLangugeSectionContainer>
              <AcceptedFilesHeading
                style={{
                  opacity: preUploadedFiles.length > 0 ? 1 : 0.1,
                  transition: 'opacity 0.25s ease'
                }}
              >{`File(s) to be uploaded (${preUploadedFiles.length})`}</AcceptedFilesHeading>
              <List
                className={clsx(classes.list, {
                  [classes.listExpanded]: preUploadedFiles.length > 0
                })}
              >
                {preUploadedFiles.map((file) => (
                  <ListItem
                    key={uuid()}
                    style={{
                      border: '1px solid #e0e0e0',
                      padding: '0 1rem',
                      borderRadius: '0.6rem',
                      margin: '0.4rem 0'
                    }}
                  >
                    <ListItemIcon
                      style={{
                        minWidth: '0',
                        marginRight: '1rem'
                      }}
                    >
                      {getFileIcon(file.type)}
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      primaryTypographyProps={{
                        style: {
                          fontSize: '0.9rem'
                        }
                      }}
                      secondary={getFileSize(file)}
                      secondaryTypographyProps={{
                        style: {
                          fontSize: '0.7rem'
                        }
                      }}
                    />
                    <ListItemIcon
                      style={{
                        cursor: 'pointer',
                        minWidth: '0'
                      }}
                      onClick={() => handleRemovePreUploadedFile(file)}
                    >
                      <CrossIcon
                        style={{
                          fontSize: '1rem'
                        }}
                      />
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </AcceptedFilesContainer>
          </FadeIn>
          <FadeIn delay={450}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <span
                style={{
                  cursor: isButtonDisabled ? 'not-allowed' : 'pointer'
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isButtonDisabled}
                  size="medium"
                  style={{
                    textTransform: 'none'
                  }}
                  onClick={() => handleUpload(preUploadedFiles, languageCode)}
                >
                  Start transcribing
                  {loading && (
                    <Spinner small style={{ marginLeft: '0.5rem' }} />
                  )}
                </Button>
              </span>
            </div>
          </FadeIn>
        </div>
        <FadeIn delay={500}>
          <Message className="footer-options-for-uploader">
            <span>
              <RecordIcon className="material-icons">lens</RecordIcon>
              Or{' '}
              <Underlined onClick={showRecorder}>
                capture audio/video
              </Underlined>{' '}
              locally,&nbsp;
            </span>
            <span>
              <Underlined onClick={showLink}>submit a URL</Underlined>,&nbsp; or{' '}
              <Underlined onClick={showDropbox}>
                publish from Dropbox
              </Underlined>
            </span>
          </Message>
        </FadeIn>
      </UploaderCont>
    </div>
  );
};

export default FileUploader;
