import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 699px;
`;
export const Heading = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #3f3f3f;
  margin: 0 0 9px 5px;
`;
export const SubHeading = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #a6a6a6;
  margin: 0 0 46px 5px;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 27px 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-width: 750px;
  ${({ noMin }) => !noMin && `min-height: 572px;`}
  @media (max-width: 1041px) {
    min-width: initial;
  }
  @media (max-width: 624px) {
    min-width: 350px;
    padding: 25px;
  }
`;
export const TitleText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #3f3f3f;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ large }) => (large ? `font-size: 20px;` : '')}
  ${({ onClick }) => (onClick ? `cursor: pointer;` : '')}
`;

export const InfoText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: ${({ light }) => (light ? '#989898' : '#3F3F3F')};
  margin: 8px 0;
`;

export const Button = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #ffffff;
  padding: 4px;
  text-align: center;
  height: 32px;
  border-radius: 3px;
  cursor: pointer;
  background: #5173e7;
  min-width: ${({ width }) => width}px;
`;

export const NoData = styled.div`
  color: #999;
  padding: 20px;
  font-weight: 100;
  text-align: center;
`;

// // @deprecated
// export const ClickText = styled(ReactRouterLink)`
//   color: #1d70b8;
//   font-weight: 400;
//   cursor: pointer;
//   &:hover {
//     color: green;
//     transition: color 1s;
//   }
//   transition: color 1s;
// `
export const Link = styled(ReactRouterLink)`
  color: ${({ light }) => (light ? '#BBB' : '#1d70b8')};
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: ${({ light }) => (light ? '#1d70b8' : 'green')};
    transition: color 1s;
  }
  transition: color 1s;
`;
